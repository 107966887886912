.thankyoucard {
  background-color: #000;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.thankyoucard .container {
  max-width: 1440px;
  margin: 0px auto;
  padding: 0px 20px;
}
.thankyoucard .container img{
  max-width: 80%;
  margin: 0px auto;
}
.thankyoucard::before {
  content: "";
  background: linear-gradient(211deg, #0b132f, #0b132f);
  border-radius: 50%;
  filter: blur(60px);
  width: 580px;
  height: 640px;
  position: absolute;
  right: -350px;
  top: -380px;
  z-index: 1;

}
.thankyoucard::after {
  background: linear-gradient(211deg, #2a63a3, #2a63a3);
  border-radius: 50%;
  content: "";
  filter: blur(80px);
  height: 820px;
  position: absolute;
  left: -600px;
  bottom: -800px;
  width: 1100px;
  z-index: 1;
}
.thankyoucard h3 {
  color: #fff;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}
.thankyoucard p {
  color: #fff;
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
  opacity: 0.6;
}
.thankyoucard p.second{
    color:#00eaff;
    margin-bottom: 0px;
    opacity: 1;
}
.thankyoucard h3 span{
  color:#00eaff;
  font-size: 22px;
}
.thankyoucard p>span{
  opacity: 1;
  color:#00eaff;
  cursor: pointer;

}
.thankyoucard p>span:hover{
  color:#fff;
}


@media screen and (max-width: 767px) {
  .thankyoucard {
    height: 70vh;
  }
  .thankyoucard::after {
    height: 300px;
  }
  .thankyoucard::before {
    height: 270px;
  }
  .thankyoucard .container img{
    max-width: 90%;
  }
  .thankyoucard h3{
    font-size: 16px;
    margin-top: 20px;
  }
  .thankyoucard h3 span{
    font-size: 17px;
  }
  .thankyoucard p{
    font-size: 15px;
  }
}
