.comunity_bredcrumb.one {
  background-color: #f4f4f4;
  border-radius: 0;
  padding: 120px 80px 0 80px;
  position: fixed;
  width: 100%;
  /* height: 145px; */
  overflow: hidden;
  display: block;
  z-index: 3;
  max-width: 1920px;
  margin: auto;
}

.comunity_bredcrumb.one .header.two_pages {
  width: 100%;
  background-color: transparent;
}

.comunity_bredcrumb.one .header.two_pages:before {
  background: linear-gradient(180deg, #0a0a0a, hsla(0, 0%, 4%, 0));
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.comunity_bredcrumb.one .menues_subheader ul,
.comunity_bredcrumb.one .bredcrumb_ul {
  display: flex;
  gap: 15px;
  background-color: #f4f4f4;
  padding: 5px 0px;
  align-items: center;
  border-bottom: 1px solid #000;
  width: fit-content;
  margin-bottom: 5px;
}

.comunity_bredcrumb.one ul li {
  list-style-type: none;
}

.comunity_bredcrumb.one ul li a {
  font-size: 12px;
  color: #1e87f0;
  display: inline-block;
  font-weight: 700;
}

.comunity_bredcrumb.one ul li a span,
.comunity_bredcrumb.one ul.bredcrumb_ul li:nth-child(1) a {
    color: #000;
}

/* media quarry for bradcrumb */

@media screen and (max-width: 767px) {
  .comunity_bredcrumb.one {
    padding: 90px 15px 0 80px;
  }
}
