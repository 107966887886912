.custom_input_section textarea {
    width: 100%;
    background: none;
    color: #000;
    padding: 10px 10px 10px 0;
    display: block;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #c6c6c6;
    outline: 0;
    font-size: 18px;
    font-weight: 500;
  }