.common_video_inner .video_sect_home_inner{
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}
.common_video_inner .video_sect_home_inner .video_img_card{
    width: calc(33.33% - 30px);
}

@media screen and (max-width:767px) {
    .common_video_inner .video_sect_home_inner{
        flex-direction: column;
    }
    .common_video_inner .video_sect_home_inner .video_img_card{
        width: calc(100%);
    }
}