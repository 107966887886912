.community_categaries{
    padding: 20px 0px 40px 0px;
}
.community_categaries .categaries_ul {
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
  padding: 0px;
}

.community_categaries .categaries_ul li {
  display: flex;
  width: calc(20% - 20px);
  border: 1px solid #cccccc;
  height: 130px;
  flex-direction: column;
  gap: 10px;
  padding: 10px 10px;
  cursor: pointer;
}
.community_categaries .chips_img_cont {
  margin: 0px auto;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  padding: 10px;
  background-color: #f8aba1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.community_categaries .chips_img_cont img {
  max-width: 30px;
  height: 30px;
  width: 100%;
}
.community_categaries .categaries_ul li h6 {
  font-size: 16px;
  line-height: normal;
  font-weight: 600;
  color: #222222;
  padding: 5px 20px 0;
  max-width: 160px;
  text-align: center;
  font-family: "gothammedium";
  margin: 0px auto;
}

/* chips image background color  */

.community_categaries .chip2 .chips_img_cont{
  background-color: #53aad6;
}
.community_categaries .chip3 .chips_img_cont {
  background-color: #a6ddb9;
}
.community_categaries .chip4 .chips_img_cont {
  background-color: #fadc5e;
}
.community_categaries .chip5 .chips_img_cont {
  background-color: #cccccc;
}

.community_categaries .chip1#active {
  border-bottom: 6px solid #f8aba1;
}
.community_categaries .chip2#active {
  border-bottom: 6px solid #53aad6;
}
.community_categaries .chip3#active {
  border-bottom: 6px solid #a6ddb9;
}
.community_categaries .chip4#active {
  border-bottom: 6px solid #fadc5e;
}
.community_categaries .chip5#active {
  border-bottom: 6px solid #cccccc;
}


@media screen and (max-width:991px) {
  .community_categaries .categaries_ul li h6{
    max-width: 95%;
    padding: 10px 5px 0;
  }
  .community_categaries .categaries_ul li{
    padding: 5px 5px;
    width: calc(20% - 10px);
    height: auto;
  }
  .community_categaries .categaries_ul{
    gap: 10px;
  }
}

@media screen and (max-width:767px) {
  .community_categaries .chips_img_cont img{
    max-width: 20px;
    height: 20px;
  }
  .community_categaries .chips_img_cont{
    width: 40px;
    height: 40px;
  }
  .community_categaries .categaries_ul li{
    width: calc(50% - 10px);
  }
  .community_categaries .categaries_ul{
    flex-wrap: wrap;
  }
  .community_categaries .categaries_ul li h6{
    font-size: 13px;
  }
  .community_categaries{
    padding: 20px 0px 20px 0px;
  }
}