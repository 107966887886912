#lead_gen {
  background-color: #fff;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  position: relative;
}
#lead_gen ~ #footer {
  display: none;
}
#lead_gen .main-content-sec {
  position: relative;
  height: auto;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
}
#lead_gen .main-content-sec .content {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  min-height: 100vh;
}
#lead_gen .main-content-sec .left-sec {
  width: 66.66%;
}
#lead_gen .main-content-sec .left-sec img {
  width: 100%;
}
#lead_gen .main-content-sec .right-sec {
  width: 33.33%;
  padding: 30px 20px;
  background-color: #000;

  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
}
#lead_gen .right-sec .heading h2 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 8px;
}
#lead_gen .right-sec .heading p {
  font-size: 16px;
  color: #fff;
  margin-bottom: 10px;
}
#lead_gen form {
  padding: 25px 0px;
}
#lead_gen form .form_input {
  width: 100%;
  padding: 0px;
  position: relative;
  margin-bottom: 25px;
}
#lead_gen form .form_input legend {
  margin: 0px;
  font-size: 16px;
  color: #fff;
  line-height: 20px;
  padding: 0px;
  width: 100%;
  margin-bottom: 8px;
}
#lead_gen form .form_input legend::after {
  display: none;
}
#lead_gen form .form_input input {
  margin: 0px;
  padding: 10px 15px;
  border: 1px solid #ced4da;
  font-weight: 500;
  line-height: 1.5;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  min-height: 58px;
  width: 100%;
}
#lead_gen form .form_input input:focus {
  border-color: #86b7fe;
}
#lead_gen form .form_input span.error {
  position: relative;
  left: 0;
  font-size: 14px;
  bottom: inherit;
}
#lead_gen form .form_input span.success {
  position: relative;
  left: 0;
  font-size: 14px;
  bottom: inherit;
  color: green;
}
#lead_gen form .form_input select {
  margin: 0px;
  padding: 10px 15px;
  border: 1px solid #ced4da;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  font-size: 16px;
  border-radius: 5px;
  min-height: 58px;
  width: 100%;
}
#lead_gen form .form_input select:focus {
  outline: none;
  border-color: #86b7fe;
}
#lead_gen form .form_input ul {
  display: flex;
  padding-top: 5px;
  flex-wrap: wrap;
}
#lead_gen form .form_input ul li {
  padding: 8px 15px;
  background-color: #ccc;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  display: inline-block;
  margin-bottom: 10px;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
#lead_gen form .form_input ul li.active {
  background-color: #0e1f4b;
  color: #fff;
}
#lead_gen form .form_input ul li:last-child {
  margin-bottom: 0px;
}
#lead_gen form .btn_sec {
  margin-top: 0px;
}
#lead_gen form .btn_sec button {
  background-color: #fff;
  color: #0e1f4b;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  width: 100%;
  transform: none;
  margin-top: 20px;
}
#lead_gen form .btn_sec button[disabled] {
  /* opacity: 0.5; */
  pointer-events: none;
}
#lead_gen form .form_input .requestOtp {
  width: auto;
  position: absolute;
  right: 10px;
  top: 40px;
  padding: 6px 10px;
  font-size: 14px;
  background-color: #fff;
  border-radius: 5px;
  color: #0e1f4b;
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  #lead_gen .main-content-sec .left-sec {
    width: 60%;
  }
  #lead_gen .main-content-sec .right-sec {
    width: 40%;
    padding: 20px 15px;
  }
  #lead_gen form {
    padding: 15px 0px;
  }
  #lead_gen form .foem_cus {
    max-width: 100%;
  }
  #lead_gen form .form_input legend {
    font-size: 15px;
  }
  #lead_gen form .form_input input {
    min-height: 52px;
    padding: 10px;
  }
  #lead_gen form .form_input span.error,
  #lead_gen form .form_input span.success {
    font-size: 13px;
  }
  #lead_gen form .form_input .requestOtp {
    right: 5px;
    top: 40px;
    padding: 5px 8px;
    font-size: 12px;
    border-radius: 3px;
  }
  #lead_gen form .form_input {
    margin-bottom: 20px;
  }
  #lead_gen form .btn_sec button {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  #lead_gen .main-content-sec .left-sec,
  #lead_gen .main-content-sec .right-sec {
    width: 100%;
  }
}
