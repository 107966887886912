@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Khand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Audiowide&display=swap");

@font-face {
  font-family: "space-age";
  src: url("../src/assets/fonts/space-age.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
}
address, dl, fieldset, figure, ol, p, pre, ul{
  margin: 0px;
  padding: 0px;
}

body {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
}
body.popup-open {
  overflow: hidden;
}

.uk-container {
  max-width: 1170px;
  padding: 0px 15px;
}

a {
  color: #000;
  text-decoration: none !important;
}

a:hover {
  color: #000;
}

ul li {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
figure,
p,
ul {
  padding: 0px;
  margin: 0px;
}

/* .bg_color {
  background: #000;
} */

.header .right_sec ul {
  position: relative;
  z-index: 2;
}

.menu_bar
  button.navbar_lines.HamburgerMenu_hamburgeractive__uyy3x
  span:first-child {
  -webkit-transform: rotate(43deg) translate(3px, 10px);
  transform: rotate(43deg) translate(3px, 10px);
}

.menu_bar
  button.navbar_lines.HamburgerMenu_hamburgeractive__uyy3x
  span:nth-child(2) {
  opacity: 0;
  visibility: hidden;
}

.menu_bar
  button.navbar_lines.HamburgerMenu_hamburgeractive__uyy3x
  span:last-child {
  -webkit-transform: rotate(137deg) translate(-4px, 10px);
  transform: rotate(137deg) translate(-4px, 10px);
}

.header {
  background-color: #0c0a0c;
  padding: 20px 0px;
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 15px);
  /* width: calc(100%); */
}

.header .right_sec {
  display: flex;
  align-items: center;
  margin-right: 50px;
}

.header .right_sec ul li a {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6e1e5;
  padding: 0px;
}

.header .right_sec ul li {
  margin-right: 40px;
}

.menu_bar button.navbar_lines {
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in;
  position: absolute;
  right: 80px;
  top: 22px;
  z-index: 9;
}

.menu_bar button.navbar_lines:focus-visible {
  outline: none;
}

.menu_bar button.navbar_lines.HamburgerMenu_hamburgeractive__Psur4 {
  transform: none;
  z-index: 1000000;
  position: fixed;
  right: 40px;
  top: 20px;
}

/* .uk-flex-column {
  flex-direction: column;
}
.uk-flex {
  display: flex;
} */

button.navbar_lines.uk-flex.uk-flex-column.HamburgerMenu_hamburger__aXVme.HamburgerMenu_hamburgeractive__9iPUg {
  position: fixed;
  right: 60px;
  top: 40px;
  -webkit-transform: none;
  transform: none;
  z-index: 1000000;
  /* position: fixed;
  right: 100px;
  top: 35px;
  -webkit-transform: none;
  transform: none;
  z-index: 1000000; */
}

.menu_bar .navbar_lines span {
  background: #fff;
  width: 36px;
  height: 4px;
  border-radius: 2px;
  -webkit-transform: rotate(0deg) translate(0);
  margin: 6px 0px;
  position: relative;
  transition: all 0.3s ease-in;
  transform: rotate(0deg) translate(0px);
  display: block;
}

.menu_bar
  button.navbar_lines.HamburgerMenu_hamburgeractive__Psur4
  span:first-child {
  transform: rotate(43deg) translate(3px, 10px);
}

.menu_bar
  button.navbar_lines.HamburgerMenu_hamburgeractive__Psur4
  span:last-child {
  transform: rotate(137deg) translate(-4px, 10px);
}

.menu_bar
  button.navbar_lines.HamburgerMenu_hamburgeractive__Psur4
  span:nth-child(2) {
  opacity: 0;
  visibility: hidden;
}

.menu_bar .navbar_lines span:nth-child(2) {
  width: 24px;
}

.menu_bar .navbar_lines span:nth-child(2):after {
  content: "";
  position: absolute;
  right: -12px;
  top: 0;
  height: 100%;
  width: 8px;
  background-color: #fff;
  border-radius: 2px;
}

.menu_bar > div.HamburgerMenu_navigationactive__Oe7O1 {
  left: 0%;
  opacity: 1;
  visibility: visible;
}

.menu_bar > div:after,
.menu_bar > div:before {
  content: "";
  position: absolute;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  /* background: linear-gradient(180deg, #1862a1 #AD00FF 100%); */
  background: #1862a1;
  -webkit-filter: blur(150px);
  filter: blur(150px);
  left: -100px;
  bottom: -100px;
  z-index: -1;
  /* animation: animate8 infinite 5s; */
}

.menu_bar > div:before {
  left: calc(100% - 350px);
  bottom: inherit;
  top: -100px;
  /* animation-name: animate7; */
  width: 450px !important;
  height: 350px !important;
  background: #1862a1 !important;
}

@keyframes animate7 {
  0% {
    left: calc(100% - 350px);
    top: -100px;
  }

  25% {
    left: calc(100% - 300px);
    top: -25px;
  }

  50% {
    left: calc(100% - 250px);
    top: 50px;
  }

  75% {
    left: calc(100% - 300px);
    top: -25px;
  }

  100% {
    left: calc(100% - 350px);
    top: -100px;
  }
}

@keyframes animate8 {
  0% {
    left: -100px;
    bottom: -100px;
  }

  25% {
    left: -50px;
    bottom: -25px;
  }

  50% {
    left: 0px;
    bottom: 50px;
  }

  75% {
    left: -50px;
    bottom: -25px;
  }

  100% {
    left: -100px;
    bottom: -100px;
  }
}

.menu_bar > div .logo_sec {
  margin-left: auto;
  display: inline-block;
  max-width: 50px;
}

.menu_bar > div .main_sec {
  display: flex;
  width: 100%;
  padding: 20px 0px;
  height: calc(100vh - 93px);
}

.menu_bar .main_sec .left_sec {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.menu_bar .main_sec .left_sec .img_sec {
  max-width: 300px;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.41);
  backdrop-filter: blur(30px);
}

.menu_bar .main_sec .left_sec .img_sec img {
  width: 100%;
  height: 330px;
  object-fit: cover;
}

.menu_bar .main_sec .left_sec .img_sec span {
  font-size: 26px;
  color: #e6e1e5;
  padding: 20px;
  display: block;
  font-weight: 600;
  line-height: 24px;
}

.menu_bar .main_sec .right_sec {
  width: 30%;
  border-left: 2px solid #938f99;
  z-index: 10;
}

.menu_bar .main_sec .right_sec ul {
  margin: 0px;
  padding: 0px 0px 0px 50px;
  width: 100%;
  max-width: 600px;
}

.menu_bar .main_sec .right_sec ul li {
  padding: 0px;
  margin: 0px 0px 15px 0px;
  width: 100%;
  cursor: pointer;
}

.menu_bar .main_sec .right_sec ul li:last-child {
  margin-bottom: 0px;
}

.menu_bar .main_sec .right_sec ul li:nth-child(1) p,
.menu_bar .main_sec .right_sec ul li:nth-child(2) p,
.menu_bar .main_sec .right_sec ul li:nth-child(3) p,
.menu_bar .main_sec .right_sec ul li:nth-child(4) p {
  font-size: 26px;
  font-weight: 800;
}

button.navbar_lines.uk-flex.uk-flex-column.HamburgerMenu_hamburger__aXVme.HamburgerMenu_hamburgeractive__9iPUg
  span:last-child {
  -webkit-transform: rotate(137deg) translate(-4px, 10px);
  transform: rotate(137deg) translate(-4px, 10px);
}

button.navbar_lines.uk-flex.uk-flex-column.HamburgerMenu_hamburger__aXVme.HamburgerMenu_hamburgeractive__9iPUg
  span:first-child {
  -webkit-transform: rotate(43deg) translate(3px, 10px);
  transform: rotate(43deg) translate(3px, 10px);
}

button.navbar_lines.uk-flex.uk-flex-column.HamburgerMenu_hamburger__aXVme.HamburgerMenu_hamburgeractive__9iPUg
  span:nth-child(2) {
  opacity: 0;
  visibility: hidden;
}

.menu_bar .main_sec .right_sec ul li:nth-child(4) {
  margin-bottom: 50px;
}




.menu_bar .main_sec .right_sec ul li p {
  font-size: 18px;
  line-height: normal;
  color: #e6e1e5;
  text-transform: inherit;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-family: Lato,sans-serif;
  letter-spacing: 1px;
  font-style: normal;
  margin: 0;
  padding:0;
}

.menu_bar .main_sec .right_sec ul li p img {
  /* width: 100%; */
  max-width: 20px;
  transform: translateX(0) rotate(0);
  transition: all 0.3s ease-in;
}

.menu_bar .main_sec .right_sec ul li p:hover img {
  transform: translateX(-25px) rotate(180deg);
}

.slider {
  background: #0a0a0a;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.slider span.btm-gradient {
  width: 100%;
  height: 180px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 100;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(10, 10, 10, 1) 50%
  );
}

.slider::after,
.slider::before {
  content: "";
  width: 350px;
  height: 200px;
  position: absolute;
  right: -70px;
  top: 250px;
  background: linear-gradient(180deg, #00eaff 0%, #00eaff 100%);
  border-radius: 50%;
  filter: blur(100px);
  z-index: 0;
  animation: animate5 infinite 5s;
}

.slider::before {
  right: inherit;
  left: -70px;
  background: linear-gradient(180deg, #ad00ff 0%, #ad00ff 100%);
  animation-name: animate6;
}

@keyframes animate5 {
  0% {
    right: -70px;
    top: 250px;
  }

  25% {
    right: -35px;
    top: 225px;
  }

  50% {
    right: 0px;
    top: 200px;
  }

  75% {
    right: -35px;
    top: 225px;
  }

  100% {
    right: -70px;
    top: 250px;
  }
}

@keyframes animate6 {
  0% {
    left: -70px;
    top: 250px;
  }

  25% {
    left: -35px;
    top: 225px;
  }

  50% {
    left: 0px;
    top: 200px;
  }

  75% {
    left: -35px;
    top: 225px;
  }

  100% {
    left: -70px;
    top: 250px;
  }
}

.circle-scroll {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: -45px;
}

.circle-scroll a.scroll-down {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: linear-gradient(180deg, #ad00ff 0%, #2603fb 100%);
  box-shadow: 0px 0px 23px rgba(0, 234, 255, 0.3);

  text-align: center;
  padding: 5px;
  z-index: 1;
  display: inline-block;
  margin: 0px auto;
  position: relative;
  margin-bottom: -30px;
}

.circle-scroll a.scroll-down img {
  transform: translateY(25px);
  transition: all 0.3s ease-in;
  max-width: 30px;
  width: 100%;
  margin: auto;
}

@keyframes upAndDown {
  0% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(40px);
  }

  55% {
    transform: translateY(20px);
  }

  70% {
    transform: translateY(40px);
  }

  100% {
    transform: translateY(0px);
  }
}

.circle-scroll a.scroll-down:after {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #ad00ff 0%, #2603fb 100%);
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  animation: animate3 infinite 2s;
  border-radius: 50%;
  display: none;
}

@keyframes animate3 {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.5;
  }

  100% {
    transform: translate(-50%, -50%) scale(2.5);
    opacity: 0;
  }
}

.circle-scroll a.scroll-down:before {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #ad00ff 0%, #2603fb 100%);
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  animation: animate4 infinite 2s;
  border-radius: 50%;
  display: none;
}

@keyframes animate4 {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.5;
  }

  100% {
    transform: translate(-50%, -50%) scale(1.75);
    opacity: 0;
  }
}

.slider .coming_img {
  max-width: 950px;
  margin: 0px auto;
  z-index: 10;
}

.slider .content_sec {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
  z-index: 999;
}

.slider .content_sec p {
  font-family: space-age;
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 62px;
  letter-spacing: 0.05em;
  color: #00eaff;
  margin-bottom: 0px;
}

.slider .content_sec p span {
  color: #e6e1e5;
  padding-right: 15px;
}

.slider .content_sec figure {
  margin: 0px auto 120px !important;
  max-width: 300px;
}

.slider .bike_sec {
  position: absolute;
  max-width: 950px;
  margin: 0px auto;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  bottom: 0;
  z-index: 100;
}

.coming_img figure img {
  width: 100%;
  transition: all 0.3s ease-in;
  transform: scale(1);
  animation: animate9 infinite 6s;
}

@keyframes animate9 {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.025);
  }

  100% {
    transform: scale(1);
  }
}

.sec_heading h2 {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  letter-spacing: 0.05em;
  color: #e6e1e5;
  margin: 0px;
}

.marque_sec {
  overflow: unset !important;
}

#knowmore_form1 {
  padding: unset !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

#knowmore_form1 .featured {
  /* font-size: 28px !important; */
  font-size: 30px !important;
  border: none !important;
  text-align: left !important;
  color: #fff !important;
  z-index: 99999999;
  position: absolute;
  top: -120px;
}

#knowmore_form1 .featured h2 {
  font-size: 34px !important;
  line-height: 34px;
  font-weight: 600;
  color: #fff !important;
  border: none !important;
}

.marquee_sec_home {
  padding: 100px 0px;
  background-color: #0a0a0a;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  scroll-snap-align: start;
}

.marquee_sec_home::after {
  background: linear-gradient(211deg, #2a63a3, #2a63a3);
  border-radius: 50% 80% 0% 0%;
  content: "";
  filter: blur(80px);
  height: 720px;
  position: absolute;
  left: -450px;
  bottom: -450px;
  width: 900px;
  z-index: 0;
}

.marquee_sec_home::before {
  background: linear-gradient(211deg, #14204e, #14204e);
  border-radius: 50%;
  content: "";
  filter: blur(60px);
  height: 800px;
  position: absolute;
  right: -400px;
  top: -400px;
  width: 800px;
  z-index: 0;
}

.marque_heading h2 {
  position: absolute;
  top: 10%;
  font-size: 40px;
  line-height: 40px;
  font-weight: 700;
  color: #fff;
  text-align: left;
  left: 20px;
  z-index: 99;
}

.featured_heading {
  max-width: 1440px;
  margin: 0px auto;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 15%;
}

.Want_knowmore {
  padding: 100px 0px;
  background-color: #0a0a0a;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  scroll-snap-align: start;
}

.Want_knowmore form > fieldset {
  margin: 0px auto;
}

.Want_knowmore .uk-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

/* .Want_knowmore::before{
  content: "";
  width: 450px;
  height: 450px;
  position: absolute;
  right: -80px;
  bottom: -80px;
  background: #0F204C;
  border-radius: 50%;
  filter: blur(120px);
} */

.Want_knowmore::before {
  content: "";
  background: linear-gradient(211deg, #0b132f, #0b132f);
  border-radius: 50%;
  filter: blur(100px);
  z-index: 1;
  width: 800px;
  height: 750px;
  position: absolute;
  right: -300px;
  top: -350px;
}

/* .Want_knowmore::after{
  content: "";
  width: 450px;
  height: 450px;
  position: absolute;
  left: -150px;
  top: -150px;
  background: #1862A1;
  border-radius: 50%;
  filter: blur(100px);
} */

.Want_knowmore::after {
  background: linear-gradient(211deg, #2a63a3, #2a63a3);
  border-radius: 50%;
  content: "";
  filter: blur(100px);
  height: 850px;
  position: absolute;
  left: -450px;
  bottom: -450px;
  width: 900px;
  z-index: 1;
}

/* 
.Want_knowmore .uk-container {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY( -50% );
  z-index: 2;
} */

.foem_cus {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  max-width: 940px;
  margin: auto;
}

.foem_cus .form_input {
  width: 50%;
  padding: 5px 12px;
}

.foem_cus .form_input legend.uk-legend {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #e6e1e5;
  margin-bottom: 2px;
  position: relative;
  padding-left: 30px;
  margin-top: 15px;
}

.foem_cus .form_input .error {
  font-size: 12px;
  color: red;
  position: absolute;
  left: 30px;
  bottom: -5px;
  display: block;
  width: 100%;
}

.foem_cus .form_input .uk-margin {
  margin: 0px !important;
  position: relative;
}

.foem_cus .form_input .uk-margin .uk-input {
  height: auto;
  border-bottom: 2px solid #938f99;
  background: transparent;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  color: #fff;
  padding: 8px 30px;
  margin-bottom: 20px;
  width: 100%;
}
.foem_cus .form_input .uk-margin .uk-input:focus{
  outline: none;
}

.Want_knowmore .sec_heading {
  padding-bottom: 65px;
  position: relative;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Want_knowmore .sec_heading h2 {
  position: absolute;
  z-index: 1;
}

.btn_sec button.uk-button {
  background: #00eaff;
  backdrop-filter: blur(30px);
  border-radius: 15px;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  padding: 15px 55px;
  font-weight: 900;
  transition: all 0.3s ease-in;
  position: relative;
  overflow: hidden;
  color: #000000;
  letter-spacing: 2px;
  transform: scaleX(1) scaleY(1);
}

.btn_sec button.uk-button[disabled] {
  color: #000000;
  background-color: #fff;
}

.btn_sec button.uk-button[disabled]:hover {
  transform: none;
  border-radius: 15px;
}

.btn_sec button.uk-button:hover {
  transform: scaleX(1.1) scaleY(1.05);
  border-radius: 0px;
}

.btn_sec {
  text-align: center;
  width: 100%;
  margin-top: 50px;
}

.foem_cus .form_input legend.uk-legend span {
  color: #ff0000;
  font-size: 22px;
}

.foem_cus .form_input legend.uk-legend::after {
  content: "";
  background: rgb(38, 3, 251);
  background: linear-gradient(
    90deg,
    rgba(38, 3, 251, 1) 0%,
    rgba(220, 6, 255, 1) 100%
  );
  width: 66px;
  height: 4px;
  position: absolute;
  left: 30px;
  bottom: -45px;
  border-radius: 2px;
  z-index: 1;
}

footer.footer {
  background-color: #060606;
  backdrop-filter: blur(30px);
  position: relative;
  overflow: hidden;
  z-index: 2;
}

#footer .footer_bottom {
  position: relative;
  z-index: 2;
  margin-top: -1px;
}

#footer {
  position: relative;
  height: 100vh;
  width: 100%;
  /* background-color: #0a0a0a; */
  overflow: hidden;
  scroll-snap-align: start;
}

footer.footer::after {
  background: linear-gradient(211deg, #2a63a3, #2a63a3);
  border-radius: 50%;
  content: "";
  filter: blur(80px);
  height: 870px;
  position: absolute;
  left: -600px;
  bottom: -800px;
  width: 1100px;
  z-index: 1;
}

/* footer.footer::after{
 display: none;
} */

footer.footer::before {
  content: "";
  background: linear-gradient(211deg, #0b132f, #0b132f);
  border-radius: 50%;
  filter: blur(60px);
  width: 700px;
  height: 760px;
  position: absolute;
  right: -350px;
  top: -380px;
  z-index: 1;
}

@keyframes animate1 {
  0% {
    right: -70px;
    top: -70px;
  }

  25% {
    right: -35px;
    top: -35px;
  }

  50% {
    right: 0px;
    top: 0px;
  }

  75% {
    right: -35px;
    top: -35px;
  }

  100% {
    right: -70px;
    top: -70px;
  }
}

@keyframes animate2 {
  0% {
    left: -50px;
    bottom: -50px;
  }

  25% {
    left: -25px;
    bottom: -15px;
  }

  50% {
    left: 0px;
    bottom: 15px;
  }

  75% {
    left: -25px;
    bottom: -15px;
  }

  100% {
    left: -50px;
    bottom: -50px;
  }
}

.footer_row {
  display: flex;
  flex-wrap: wrap;
}

.footer_row .footer_col {
  width: 20%;
  text-align: center;
  padding: 0px 15px;
  z-index: 1;
  padding-top: 40px;
}

.footer_row .footer_col:first-child {
  padding-top: 0;
}

.footer_col .logo {
  margin-bottom: 24px;
}

.footer_col .logo p {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #e6e1e5;
}

.footer_col .logo a {
  display: block;
  margin-bottom: 15px;
}

.follow ul li {
  padding: 0px 10px;
}

/* harish */

.follow ul li:last-child {
  margin-left: -7px;
}

.footer_row .footer_col .follow h5 {
  font-weight: 400;
  margin-bottom: 0;
}

.footer h5 {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 56px;
  text-align: center;
  letter-spacing: 0.05em;
  color: rgb(255 255 255 / 80%);
  margin-bottom: 8px;
}

.footer_link li a {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgb(255 255 255 / 60%);
  position: relative;
  letter-spacing: 1px;
}

.footer_link li a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 2px;
  width: 0%;
  background: rgb(38, 3, 251);
  background: linear-gradient(
    90deg,
    rgba(38, 3, 251, 1) 0%,
    rgba(220, 6, 255, 1) 100%
  );
  border-radius: 2px;
  transition: all 0.3s ease-in;
}

.footer_link li a:hover::after {
  width: 100%;
}

.footer_link li {
  margin-bottom: 8px;
}

ul.footer_link.space_bot {
  margin-bottom: 25px;
}

.footer_row .footer_col ul {
  padding-left: 0;
  margin: 5px 0px;
  align-items: center;
}

.Privacy_Policy {
  padding: 0px;
  margin-bottom: 15px;
}

.Privacy_Policy ul li {
  text-align: center;
  color: rgb(255 255 255 / 80%);
  padding: 0px 30px;
  font-size: 16px;
  font-family: "Lato", sans-serif;
}

.Privacy_Policy ul li a {
  color: rgb(255 255 255 / 80%);
  padding: 0px 5px;
  font-family: "Lato", sans-serif;
  font-size: 16px;
}

.footer_bottom {
  background: #0a0a0a;
  padding: 42px 0px 42px;
}

.Copyright {
  text-align: center;
}

.Copyright p {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  text-align: center;
  color: rgb(255 255 255 / 80%);
  margin-bottom: 5px;
  margin-top: 0px;
}

.footer .uk-container {
  padding-bottom: 60px;
  padding-top: 60px;
}

.footer_bottom .uk-container {
  padding: 0px;
}

/* 2-7-2022 */

.menu_bar button.navbar_lines.HamburgerMenu_hamburgeractive__KSL7I {
  position: fixed;
  right: 40px;
  top: 35px;
  -webkit-transform: none;
  transform: none;
  z-index: 1000000;
}

.menu_bar
  button.navbar_lines.HamburgerMenu_hamburgeractive__KSL7I
  span:first-child {
  transform: rotate(43deg) translate(3px, 10px);
}

.menu_bar
  button.navbar_lines.HamburgerMenu_hamburgeractive__KSL7I
  span:last-child {
  transform: rotate(137deg) translate(-4px, 10px);
}

.menu_bar
  button.navbar_lines.HamburgerMenu_hamburgeractive__KSL7I
  span:nth-child(2) {
  opacity: 0;
  visibility: hidden;
}

/* 2-7-2022 */

video.mobile {
  display: none;
}

.slider {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  scroll-snap-align: start;
}

.slider video.desktop {
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-position: center;
}

.slider::after {
  display: none;
}

.slider::before {
  display: none;
}

/* new-footer-css */

#home ~ #footer {
  display: none;
}

#home > #footer {
  width: 100%;
  height: 100vh;
  scroll-snap-align: start;
}

#teascr_page {
  position: relative;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  width: 100%;
}

#teascr_page ~ #footer {
  display: none;
}

#teascr_page > #footer {
  width: 100%;
  height: 100vh;
  scroll-snap-align: start;
}

.marquee_sec_home .marque_main img {
  height: 22px;
}

.marque_heading {
  position: relative;
}

.marque_heading h2 {
  color: #e6e1e5;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

/*=============================== responsive-start =============================================*/

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .Want_knowmore::before {
    filter: blur(80px);
    width: 700px;
    height: 700px;
    right: -400px;
    top: -400px;
  }

  .Want_knowmore::after {
    filter: blur(80px);
    height: 700px;
    left: -400px;
    bottom: -400px;
    width: 700px;
  }

  .sec_heading h2 {
    font-size: 45px;
    line-height: 56px;
  }

  .footer_row .footer_col .follow h5 {
    font-size: 30px;
  }

  .footer_row .footer_col {
    padding-top: 30px;
  }

  .footer h5 {
    font-size: 30px;
    line-height: 44px;
  }

  footer.footer::before {
    height: 700px;
  }

  .footer .uk-container {
    padding-bottom: 50px;
    padding-top: 50px;
  }

  .footer_bottom {
    padding: 35px 0px 35px;
  }

  /* #footer::after {
  height: 1200px;
  left: -500px;
  bottom: -750px;
  width: 1000px;
 } */
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .sec_heading h2 {
    font-size: 40px;
    line-height: 40px;
  }

  .Want_knowmore::before {
    filter: blur(80px);
    width: 700px;
    height: 700px;
    right: -400px;
    top: -400px;
  }

  .Want_knowmore::after {
    filter: blur(80px);
    height: 700px;
    left: -400px;
    bottom: -400px;
    width: 700px;
  }

  .footer_row .footer_col {
    padding: 0px 5px;
    padding-top: 50px;
  }

  .footer_col .logo {
    margin-bottom: 30px;
  }

  .footer_col .logo p {
    font-size: 14px;
  }

  .footer h5 {
    font-size: 25px;
    line-height: 28px;
    margin-bottom: 15px;
  }

  .follow ul li {
    padding: 0px 5px;
  }

  .footer_link li a {
    font-size: 15px;
  }

  .footer_link li {
    margin-bottom: 5px;
  }

  footer.footer::before {
    height: 600px;
    top: -300px;
  }

  .footer .uk-container {
    padding-bottom: 50px;
    padding-top: 50px;
  }

  .footer_bottom {
    padding: 35px 0px 35px;
  }

  .Copyright p {
    font-size: 15px;
  }

  .Privacy_Policy ul {
    margin-bottom: 15px;
  }

  /* #footer::after {
  height: 1050px;
  left: -600px;
  bottom: -650px;
  width: 1000px;
} */
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .Want_knowmore::before {
    filter: blur(60px);
    width: 400px;
    height: 400px;
    right: -200px;
    top: -200px;
  }

  .Want_knowmore::after {
    filter: blur(60px);
    height: 400px;
    left: -200px;
    bottom: -200px;
    width: 400px;
  }

  .footer_row .footer_col {
    padding: 0px 5px;
    padding-top: 30px;
  }

  .footer_col .logo {
    margin-bottom: 30px;
  }

  .footer_col .logo p {
    font-size: 12px;
  }

  .footer h5 {
    font-size: 22px;
    line-height: 23px;
    margin-bottom: 12px;
  }

  .follow ul li {
    padding: 0px 8px;
  }

  .footer .uk-container {
    padding-bottom: 60px;
    padding-top: 80px;
  }

  .footer_link li a {
    font-size: 15px;
  }

  .footer_link li {
    margin-bottom: 5px;
  }

  .slider .coming_img {
    max-width: 840px;
  }

  .slider .bike_sec {
    max-width: 840px;
  }

  .slider .content_sec figure {
    margin: 0px auto 100px;
    max-width: 270px;
  }

  .slider .content_sec p {
    font-size: 54px;
    line-height: 54px;
  }

  .Want_knowmore {
    padding: 60px 0px 80px;
  }

  .uk-container {
    max-width: 900px;
  }

  .Want_knowmore .sec_heading {
    padding-bottom: 50px;
  }

  .sec_heading h2 {
    font-size: 34px !important;
    line-height: 34px !important;
  }

  .foem_cus {
    max-width: 800px;
  }

  .footer_link li {
    margin-bottom: 5px;
  }

  .footer_col .logo {
    margin-bottom: 20px;
  }

  .follow ul li {
    padding: 0px 5px;
  }

  .footer_bottom {
    background: #161616;
    padding: 0px 0px;
  }

  .Privacy_Policy {
    padding: 10px 0px 5px;
    margin-bottom: 0px;
  }

  .Privacy_Policy ul {
    margin-bottom: 10px;
  }

  footer.footer::before {
    height: 500px;
    top: -250px;
    width: 550px;
    right: -300px;
  }

  .footer .uk-container {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .footer_bottom {
    padding: 20px 0px 20px;
  }

  .Copyright p {
    font-size: 15px;
  }

  .Privacy_Policy ul {
    margin-bottom: 15px;
  }

  .footer_row .footer_col .follow h5 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .menu_bar > div{
    padding: 40px 30px;
  }
  .sec_heading h2 {
    font-size: 34px !important;
    line-height: 34px !important;
  }

  .marque_heading h2 {
    font-size: 32px;
  }

  .marquee_sec_home {
    scroll-snap-align: inherit;
  }

  .Want_knowmore::before {
    filter: blur(60px);
    width: 300px;
    height: 300px;
    right: -150px;
    top: -150px;
  }

  .Want_knowmore::after {
    filter: blur(60px);
    height: 300px;
    left: -150px;
    bottom: -150px;
    width: 300px;
  }

  .footer_row .footer_col {
    padding: 0px 5px;
    padding-top: 30px;
    width: 33.33%;
  }

  .footer_col .logo p {
    font-size: 14px;
  }

  .footer h5 {
    font-size: 23px;
    line-height: 23px;
    margin-bottom: 12px;
  }

  .follow ul li {
    padding: 0px 8px;
  }

  .footer .uk-container {
    padding-bottom: 60px;
    padding-top: 80px;
  }

  .footer_link li a {
    font-size: 15px;
  }

  .footer_link li {
    margin-bottom: 5px;
  }

  .circle-scroll a.scroll-down {
    width: 70px;
    height: 70px;
  }

  .foem_cus .form_input legend.uk-legend {
    font-size: 16px;
  }

  .foem_cus .form_input legend.uk-legend::after {
    bottom: -39px;
  }

  .foem_cus .form_input .uk-margin .uk-input {
    padding: 5px 30px;
    margin-bottom: 12px;
  }

  .slider .coming_img {
    max-width: 90%;
  }

  .slider .bike_sec {
    max-width: 90%;
  }

  .slider .content_sec figure {
    margin: 0px auto 80px;
    max-width: 30%;
  }

  .slider .content_sec p {
    font-size: 42px;
    line-height: 54px;
  }

  .Want_knowmore {
    padding: 60px 0px 60px;
  }

  .uk-container {
    max-width: 100%;
  }

  .Want_knowmore .sec_heading {
    padding-bottom: 0px;
    min-height: 100px;
  }

  .sec_heading h2 {
    font-size: 32px;
    line-height: 32px;
  }

  .foem_cus {
    max-width: 90%;
  }

  .Privacy_Policy {
    padding: 0px 0px 5px;
    margin-bottom: 0px;
  }

  /* #footer::after {
    height: 800px;
    width: 800px;
    bottom: -500px;
  } */

  .footer h5 {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  .footer_link li {
    margin-bottom: 2px;
  }

  .footer_col .logo {
    margin-bottom: 20px;
  }

  .follow ul li {
    padding: 0px 8px;
  }

  .footer_bottom {
    background: #161616;
    padding: 0px 0px;
  }

  .foem_cus .form_input {
    width: 50%;
    padding: 10px;
  }

  .foem_cus .form_input legend.uk-legend {
    font-size: 18px;
    line-height: 20px;
    margin-top: 15px;
  }

  ul.footer_link.space_bot {
    margin-bottom: 20px;
  }

  .slider a.scroll-down {
    width: 65px;
    height: 65px;
  }

  .slider .content_sec {
    bottom: 30px;
  }

  .header .right_sec ul li {
    margin-right: 20px;
  }

  .Want_knowmore::after {
    height: 350px;
  }

  .menu_bar button.navbar_lines {
    right: 20px;
  }

  footer.footer::before {
    height: 600px;
    top: -300px;
    width: 600px;
    right: -300px;
  }

  .footer .uk-container {
    padding-bottom: 20px;
    padding-top: 40px;
  }

  .footer_bottom {
    padding: 20px 0px 20px;
  }

  .Copyright p {
    font-size: 15px;
  }

  .Privacy_Policy ul {
    margin-bottom: 5px;
  }

  .footer_row .footer_col .follow h5 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .marque_heading {
    padding: 150px 0px !important;
  }

  .marque_heading::before {
    background: linear-gradient(211deg, #13204d, #13204d);
    border-radius: 50%;
    content: "";
    position: absolute;
    right: -200px;
    top: -300px;
    z-index: 0;
    height: 450px;
    width: 400px;
    filter: blur(50px);
  }

  .marque_heading::after {
    height: 350px;
    width: 500px;
    filter: blur(50px);
    background: linear-gradient(211deg, #2a63a3, #2a63a3);
    border-radius: 0% 80% 0% 0%;
    content: "";
    position: absolute;
    left: -300px;
    bottom: -150px;
    z-index: 0;
  }

  .marquee_sec_home::before {
    display: none;
  }

  .marquee_sec_home::after {
    display: none;
  }

  .featured_heading {
    top: 2%;
  }

  /* .marquee_sec_home::before{
    content: " FEATURED IN";
    position: absolute;
    top: -45px;
    font-size: 22px;
    font-weight: 700;
    z-index: 99;
  } */
  .marquee_sec_home .marque_main .marque_sec {
    margin-top: 0px;
  }

  .marquee_sec_home .marque_main .marquee,
  .marquee_sec_home .marque_main .marquee1,
  .marquee_sec_home .marque_main .marquee2 {
    padding: 20px 0px;
  }

  .marquee_sec_home .marque_main img {
    height: 18px;
  }

  .marque_heading h2 {
    top: 85px;
    font-size: 22px;
    font-weight: 700;
    z-index: 99;
    left: 15px;
  }

  .marquee_sec_home {
    height: auto !important;
    scroll-snap-align: inherit !important;
  }

  .Want_knowmore::before {
    filter: blur(40px);
    width: 300px;
    height: 300px;
    right: -150px;
    top: -150px;
  }

  .Want_knowmore::after {
    filter: blur(40px);
    height: 300px;
    left: -150px;
    bottom: -150px;
    width: 300px;
  }

  .subscribe_sec {
    z-index: 9;
  }

  .Want_knowmore form > fieldset {
    z-index: 9;
    position: relative;
  }

  #teascr_page > #footer {
    height: auto;
    overflow: auto;
  }

  .header {
    padding: 15px 0px 0px;
  }

  .slider .coming_img {
    max-width: 90%;
  }

  .slider .coming_img > figure {
    margin-bottom: 0px;
  }

  .slider .bike_sec {
    max-width: 95%;
    top: 50%;
  }

  .slider .content_sec figure {
    margin: 0px auto 35px !important;
    max-width: 300px;
  }

  .slider .content_sec p {
    font-size: 30px;
    line-height: 26px;
  }

  .Want_knowmore {
    padding: 100px 10px 120px;
  }

  .uk-container {
    max-width: 100%;
  }

  .Want_knowmore .sec_heading {
    padding-bottom: 0px;
    min-height: auto;
  }

  .Want_knowmore::after {
    content: "";
    width: 200px;
    height: 200px;
    left: -100px;
    bottom: -100px;
    z-index: -1;
  }

  .Want_knowmore {
    position: relative;
    z-index: 2;
  }

  .Want_knowmore .sec_heading h2 {
    font-size: 32px;
    line-height: 32px;
    position: relative;
    margin: 0px;
    text-align: center;
    margin-bottom: 15px;
  }

  .foem_cus {
    max-width: 100%;
  }

  .footer .uk-container {
    padding-bottom: 30px;
    padding-top: 30px;
  }

  /* footer.footer::after, footer.footer::before {
        width: 250px;
        height: 250px;
        right: -30px;
        top: -40px;
        filter: blur(95px);
        animation: none;
    } */
  footer.footer::before {
    right: -250px;
    filter: blur(40px);
    top: -270px;
    height: 500px;
    width: 500px;
  }

  footer.footer::after {
    content: "";
    background: linear-gradient(211deg, #2a63a3, #2a63a3);
    border-radius: 50%;
    filter: blur(50px);
    width: 450px;
    height: 450px;
    position: absolute;
    left: -270px;
    bottom: -30px;
    z-index: 0;
  }

  .footer h5 {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 10px;
    font-weight: 400;
  }

  .footer_link li {
    margin-bottom: 2px;
  }

  .footer_col .logo {
    margin-bottom: 20px;
  }

  .follow ul li {
    padding: 0px 15px;
  }

  .footer_bottom {
    background: #161616;
    padding: 0px 0px;
  }

  .footer_bottom .uk-container {
    padding: 15px 0px 15px;
  }

  .Privacy_Policy ul li {
    font-size: 14px;
  }

  .Privacy_Policy ul li a {
    font-size: 14px;
  }

  .foem_cus .form_input {
    width: 100%;
    padding: 0px;
    margin-bottom: 15px;
  }

  .foem_cus .form_input legend.uk-legend {
    font-size: 16px;
    line-height: 16px;
    margin-top: 5px;
    padding-left: 20px;
  }

  ul.footer_link.space_bot {
    margin-bottom: 10px;
  }

  .header .uk-container {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .header .uk-container .logo {
    width: auto;
    text-align: center;
  }

  .header .right_sec ul li {
    margin-right: 25px;
  }

  .slider .content_sec {
    bottom: 0;
    position: relative;
    top: 35px;
    margin-bottom: 50px !important;
  }

  .circle-scroll {
    padding-top: 20px;
    min-height: auto;
  }

  .circle-scroll a.scroll-down {
    width: 60px;
    height: 60px;
  }

  .slider span.btm-gradient {
    bottom: 100px;
  }

  .slider a.scroll-down {
    width: 55px;
    height: 55px;
    bottom: -100px;
  }

  .foem_cus .form_input .uk-margin .uk-input {
    padding: 0px 20px 5px;
  }

  .foem_cus .form_input legend.uk-legend::after {
    height: 3px;
    left: 20px;
    bottom: -33px;
  }

  .btn_sec {
    margin-top: 20px;
  }

  .btn_sec button.uk-button {
    font-size: 16px;
    line-height: 20px;
    padding: 15px 40px;
  }

  .footer_row .footer_col {
    width: 100%;
    margin-bottom: 10px;
    padding-top: 0px;
  }

  .follow ul {
    margin-top: 10px;
  }

  .footer_row .footer_col .follow ul,
  .footer_row .footer_col .footer_link {
    padding-left: 0px;
  }

  .header .right_sec ul.uk-nav.uk-flex {
    display: none;
  }

  .header .right_sec ul.uk-nav.uk-flex {
    display: none;
  }

  .header .uk-container .logo a {
    display: block;
    text-align: left;
  }

  .header .uk-container .logo a img {
    width: 100%;
    max-width: 45px;
  }

  .slider {
    overflow-x: clip;
  }

  .Want_knowmore::before {
    content: "";
    width: 200px;
    height: 200px;
    right: -100px;
    top: -100px;
    filter: blur(50px);
    z-index: 0;
  }

  .slider::after,
  .slider::before {
    width: 180px;
    height: 100px;
    filter: blur(75px);
    animation: none;
  }

  .slider .content_sec p span {
    padding-right: 8px;
  }

  .coming_img figure img {
    max-width: 195% !important;
    left: -50%;
    right: inherit;
    top: 0;
    margin: auto;
    position: relative;
    padding-bottom: 0;
    width: auto;
  }

  .footer_link li a {
    font-size: 14px;
  }

  .Privacy_Policy ul.uk-flex {
    flex-wrap: wrap;
    margin-bottom: 5px;
  }

  .Privacy_Policy ul li {
    width: 100%;
    margin-bottom: 10px;
  }

  .Privacy_Policy ul li:last-child {
    margin-bottom: 0px;
  }

  .Copyright p {
    font-size: 14px;
  }

  .menu_bar .main_sec .left_sec {
    display: none;
  }

  .menu_bar .main_sec .right_sec {
    width: 100%;
    border-left: none;
  }

  .menu_bar .main_sec .right_sec ul {
    padding-left: 0px;
  }

  .menu_bar > div {
    padding: 20px;
  }

  .menu_bar .main_sec .right_sec ul li:nth-child(1) p,
  .menu_bar .main_sec .right_sec ul li:nth-child(2) p,
  .menu_bar .main_sec .right_sec ul li:nth-child(3) p,
  .menu_bar .main_sec .right_sec ul li:nth-child(4) p {
    font-size: 20px;
  }

  .menu_bar .main_sec .right_sec ul li p {
    font-size: 16px;
  }

  .menu_bar .main_sec .right_sec ul li:nth-child(4) {
    margin-bottom: 30px;
  }

  .menu_bar > div:after,
  .menu_bar > div:before {
    width: 300px;
    height: 300px;
    animation: none;
    filter: blur(100px);
  }

  .menu_bar > div:before {
    left: calc(100% - 200px);
  }

  .menu_bar > div .main_sec {
    height: auto;
    padding: 30px 0px 20px;
  }

  .menu_bar button.navbar_lines.HamburgerMenu_hamburgeractive__Psur4 {
    right: 15px;
  }

  .menu_bar .navbar_lines span {
    width: 26px;
    height: 3px;
  }

  .menu_bar
    button.navbar_lines.HamburgerMenu_hamburgeractive__Psur4
    span:first-child {
    transform: rotate(43deg) translate(2px, 10px);
  }

  .menu_bar
    button.navbar_lines.HamburgerMenu_hamburgeractive__Psur4
    span:last-child {
    transform: rotate(137deg) translate(-2.5px, 10px);
  }

  .Privacy_Policy > ul {
    padding-left: 0px;
  }

  .Privacy_Policy ul li:first-child {
    margin-bottom: 10px;
  }

  .menu_bar .navbar_lines span:nth-child(2) {
    width: 16px;
  }

  .menu_bar .navbar_lines span:nth-child(2):after {
    width: 6px;
    right: -10px;
  }

  .Privacy_Policy {
    padding: 0px 0px 5px;
    margin-bottom: 0px;
  }

  .menu_bar button.navbar_lines {
    right: 24px;
    top: 15px;
  }

  .footer_col .logo p {
    font-size: 14px;
  }

  .footer_row .footer_col .follow h5 {
    margin-bottom: 11px;
  }

  .footer_row .footer_col .follow {
    margin-bottom: 15px;
  }

  /* #footer::after {
      display: none;
    } */

  #home > #footer {
    height: auto;
  }

  video.desktop {
    height: 100%;
    object-fit: cover;
  }

  video.mobile {
    display: block;
    height: 100%;
    object-fit: cover;
  }

  .circle-scroll {
    margin-top: -50px;
  }

  .circle-scroll a.scroll-down img {
    transform: translateY(15px);
  }

  #footer {
    height: auto;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer_row .footer_col {
    width: 50%;
    padding-top: 20px;
  }

  .footer_row {
    align-items: center;
  }
}

/* .menu_humburger */
