.loadMoreBtn{
    display: flex;
    justify-content: center;
}
.loadMoreBtn button {
  color: #4d4e53;
  border: 1px solid #4d4e53;
  max-width: 360px;
  width: 100%;
  background-color: #ffffff;
  padding: 12px 30px;
  margin: 10px 0;
  transition: all 0.3s;
  font-weight: 500;
  text-transform:uppercase;
}
.loadMoreBtn button:hover{
    color: #fff;
    background-color: #4d4e53;
}

@media screen and (max-width:767px) {
  .loadMoreBtn button{
    font-size: 14px;
  }
}
