.left_card_image {
  max-width: 90%;
  width: 100%;
  margin: 0px auto;
  display: flex;
  column-gap: 20px;
}
.left_card_image .left_card {
  position: relative;
  width: 66.66%;
}
.left_card_image .mini_card_right {
  position: relative;
  width: 33.33%;
}

.left_card_image .image_container .content_For_image {
  /* position: absolute;
  top: 85%;
  left: 20px; */
  position: absolute;
  /* top: ; */
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(248, 80, 50, 0) 0,
    rgba(0, 0, 0, 0.66) 49%,
    black 74%
  );
  bottom: 0;
  right: 0;
  padding: 0px 20px;
  padding-top: 50px;
  padding-bottom: 15px;
}
.left_card_image .image_container p span {
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 14px;
  color: #fff;
}
.left_card_image .image_container p span:nth-child(1) {
  border-right: 1px solid #fff;
  padding-right: 15px;
  margin-right: 15px;
}
.left_card_image .image_container h4 a {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
  color: #fff;
  overflow: hidden;
  display: block;
}
.slideand_viewall {
  margin-bottom: 50px;
}
@media screen and (max-width: 1024px) {
 
  .left_card_image .left_card,
  .left_card_image .mini_card_right{
    width: 50%;
  }
  
}

@media screen and (max-width: 767px) {
  .left_card_image {
    flex-direction: column;
    gap: 20px;
    max-width: 95%;
  }
  .left_card_image .left_card,
  .left_card_image .mini_card_right{
    width: 100%;
  }
  .slideand_viewall .slider_parent .image_container{
    margin: 0px;
  }
}
