/* body {
  font-family: Inter, sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  background-color: #171717;
} */

@import url("https://fonts.googleapis.com/css2?family=Antonio:wght@100;200;300;400;500;600;700&family=Montaga&display=swap");

.height_one_csr_leo {
  width: 100%;
  /* height: 100vh; */
  /* overflow-x: hidden; */
  /* overflow-y: scroll; */
  position: relative;
  /* scroll-snap-type: y mandatory; */
  width: 100%;
}

.Hop_leo_main {
  position: relative;
  height: 100%;
  width: 100%;
  scroll-snap-align: start;
}

.height_one_csr_leo::-webkit-scrollbar,
.hop-leo::-webkit-scrollbar {
  display: none;
}

.hop-leo {
  background-color: #060606;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.hop-leo .uk-container {
  max-width: 1280px;
}

#footer {
  background-color: #060606;
}

.height_one_csr_leo + #footer + .fix_social_sec {
  position: fixed;
}

/*******************/
.banner_slider {
  width: 100%;
  display: block;
  background: linear-gradient(to right, #3780f2, #224e9b, #17346e, #00071a);
  position: relative;
  overflow: hidden;
  cursor: url(/src/assets/images/cursor.png), auto;
}

.banner_slider .overlay_img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.banner_slider .overlay_img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.banner_slider figure {
  width: 100%;
  height: 100%;
  display: block;
}

.banner_slider ._2kfM8 {
  cursor: url(/src/assets/images/cursor.png), auto;
}

.hop-leo .banner_slider div#viewerImage {
  max-width: 50%;
  margin: auto;
  object-fit: cover;
  display: flex;
  cursor: url(/src/assets/images/cursor.png), auto;
}

.hop-leo .banner_slider div#viewerImage img {
  width: 100%;
  margin: auto;
  object-fit: cover;
  height: 100vh;
}

/*******************/
.product-gallery-block {
  padding: 70px 0;
}

.gallery-flex {
  display: flex;
  flex-wrap: wrap;
}

.gallery-flex > * {
  box-sizing: border-box;
  padding: 8px;
  margin: 0 !important;
}

.gallery-flex .width-60 {
  width: 61%;
}

.gallery-flex .width-40 {
  width: 39%;
}

.gallery-flex img {
  width: 100%;
  border: 1px solid #202020;
}

.modalpopup .pure-modal-backdrop {
  background-color: #000;
  transition: opacity 0.15s linear;
  touch-action: pinch-zoom;
}

.modalpopup .panel-body {
  background-color: #000;
}

.modalpopup .pure-modal .close {
  background-color: #3780f2;
  color: #fff;
  letter-spacing: 0.08em;
  border: 1px solid #3780f2;
  text-transform: uppercase;
  transition: 0.3s all ease-in;
  height: 32px;
  width: 32px;
  border-radius: 0;
  font-size: 45px;
  font-weight: 100;
  line-height: 19px;
  right: 10px;
  top: 10px;
}

.modalpopup .featur_para.pop_slideron {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.modalpopup .pure-modal-backdrop .panel-body {
  padding: 0;
}

.modalpopup .slick-slide img {
  margin: 0 auto;
}

.modalpopup .slick-prev,
.modalpopup .slick-next {
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99;
  background-image: url(/src/assets/images/slider-right-arrow.png);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: center;
  opacity: 0.5;
}

.modalpopup .slick-prev {
  transform: rotate(180deg);
  margin-top: -25px;
}

.modalpopup .slick-prev::before,
.modalpopup .slick-next::before {
  display: none;
}

.modalpopup .slick-prev {
  left: 30px;
}

.modalpopup .slick-next {
  right: 30px;
}

.modalpopup caption {
  display: block;
  width: 100%;
  padding: 10px 10px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  position: absolute;
  bottom: 20px;
}

.gallery-flex div {
  position: relative;
  cursor: pointer;
}

.gallery-flex div::after {
  content: "\271B";
  position: absolute;
  top: 50%;
  left: 50%;
  color: #eee;
  font-size: 3rem;
  transform: translateX(-50%) translateY(-50%);
}

/*******************/
.description_sec {
  text-align: center;
  margin: 35px auto;
  max-width: 1000px;
  padding: 0 15px;
}

.description_sec h2 {
  font-size: 48px;
  line-height: 62px;
  color: #00eaff;
  font-weight: bold;
  font-family: "Antonio", sans-serif;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.description_sec p {
  color: #fff;
  text-transform: uppercase;
}

.full_video_bike {
  z-index: 1;
  position: relative;
  padding: 0px 0px 0px 0px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  scroll-snap-align: start;
  margin-bottom: 70px;
}

/*******************/
.product-details-block {
  overflow-x: hidden;
}

.d-flex,
.product-details-section {
  display: flex;
  flex-wrap: wrap;
}

.bg-blue {
  background-color: #181818;
  color: #fff;
}

.product-details-section {
  align-items: center;
}

.product-details {
  font-size: 16px;
}

.product-details-section > div {
  width: 50%;
}

.product-details-section .produt-img {
  background-color: #fff;
}

.bg-blue * {
  color: #fff;
}

.product-details-section .product-details h2 {
  font-size: 32px;
  margin-bottom: 15px;
}

.product-details h2,
.product-details-section .product-details h2 {
  font-family: "Antonio", sans-serif;
  font-size: 51px;
  line-height: 1;
  color: #00eaff;
}

.uppercase {
  text-transform: uppercase;
}

.font-24 {
  font-size: 24px;
}

.font-24 b {
  color: #00eaff;
}

.product-details .font-24 {
  margin-top: 30px;
  margin-bottom: 10px;
}

/*******************/
.tabing_section {
  margin-top: 70px;
}

.tabing_section h2 {
  text-align: center;
  font-size: 3rem;
  line-height: 1.3;
  margin-bottom: 40px;
  color: #00eaff;
}

.tabing_section .btn-group-price {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: none;
  border-bottom: 1px solid #202020;
  margin-bottom: 20px;
  height: auto;
  border-radius: 0;
  background-color: transparent;
}

.tabing_section button.btn-price-oxo {
  padding: 5px 30px;
  font-size: 24px;
  color: #fff;
  text-transform: uppercase;
  transition: 0.1s ease-in-out;
  text-align: center;
  border-bottom: 1px solid transparent;
}

.tabing_section button.btn-price-oxo:hover {
  background-color: #181818;
}

.tabing_section button.btn-price-oxo.activeBtnOxo1 {
  font-weight: bold;
  color: #00eaff;
  border-color: #00eaff;
  background: transparent;
  width: auto;
  border-radius: 0;
  margin-top: 0;
}

.tabing_data {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.tabing_data > div {
  width: 33.33%;
  text-align: center;
  padding: 40px;
}

.tabing_data .data_box p {
  color: #fff;
}

.tabing_data .data_box h1 {
  font-size: 4rem;
  font-weight: 700;
  color: #00eaff;
}

.tabing_data .data_box h3 {
  font-size: 32px;
  margin-bottom: 40px;
  color: #00eaff;
}

.tabing_data .details p {
  margin-bottom: 15px;
  color: #fff;
}

.tabing_section .bottom_img img {
  width: 100%;
}

/*******************/
.product_slider {
  margin-top: 150px;
  margin-bottom: 0;
  position: relative;
  margin-bottom: 70px;
}

.productSlideBackImage {
  position: absolute;
  top: -10%;
  left: 0;
  right: 0;
  text-align: center;
  height: 121%;
  z-index: 0;
  opacity: 0.1;
}

.productSlideBackImage img {
  max-width: 100%;
  height: 100%;
  margin: auto;
}

.product_slider .slick-slider {
  margin-bottom: 30px;
  padding-top: 25px;
}

.product_slider .productSlideItem--title {
  font-size: 3rem;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
}

.product_slider .slick-dots li button:before {
  font-size: 12px;
  color: #fff;
}

/* vikash li dots css  */

/* .product_slider .slick-dots li button:hover:before {
  opacity: 1;
} */
.product_slider .slick-dots li button:focus:before {
  opacity: 0.25;
}
.product_slider .slick-dots li.slick-active button:before{
  opacity: 1;
}

/*******************/
.newsletter {
  position: relative;
  z-index: 9;
  text-align: center;
  padding: 90px 0;
}

.newsletter .widget-title {
  font-size: 2rem;
  font-weight: 500;
  color: #fff;
}

.newsletter a.sliderLink {
  font-size: 20px;
  letter-spacing: 3px;
  color: #00eaff;
  border-bottom: 1px solid #00eaff;
  padding-bottom: 5px;
  font-weight: 500;
}

.formpopup .pure-modal {
  max-width: 600px;
  width: 100%;
}

.formpopup .panel-body {
  padding: 30px;
}

.formpopup .from_title {
  font-size: 2rem;
  line-height: 1.3;
  margin-bottom: 30px;
}

.formpopup .form-group {
  margin-bottom: 18px;
}

.formpopup input.form-control {
  width: 100%;
  border: 1px solid #707070;
  border-radius: 5px;
  color: #707070;
  background-color: transparent;
  font-size: 18px;
  letter-spacing: 0;
  font-weight: normal;
  padding: 10px;
}

.formpopup input[type="submit"] {
  background-color: #000;
  padding: 15px 30px;
  border: 3px solid #000;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  cursor: pointer;
}

.formpopup input[type="submit"]:hover {
  background-color: transparent;
  color: #000;
}

.formpopup .pure-modal .close {
  border-radius: 100%;
  top: 0;
  right: 0;
  transform: translateX(50%) translateY(-50%);
  z-index: 1;
  background-color: #3780f2;
  color: #fff;
  font-size: 30px;
  border: 2px solid #3780f2;
  transition: 0.3s all ease-in;
  width: 36px;
  height: 36px;
  text-align: center;
  line-height: 27px;
}

.formpopup .pure-modal .close:hover {
  background-color: transparent;
  border-color: #000;
  color: #000;
}

.infor_tool_main {
  position: relative;
  transition: all 0.1s ease-in-out;
}

.info_tooltip {
  display: none;
  position: absolute;
  /* top: -20px; */
  /* top: 0; */
  top: -36px;
  /* right: -105px; */
  right: 0;
  padding: 5px 10px;
  border: 1px solid #00eaff;
  z-index: 9999;
  background-color: #00eaff;
  border-radius: 2px;
  color: #000;
}

.infor_tool_main:hover .info_tooltip {
  display: block;
}

.table_section_inforsection {
  /* max-height: 540px; */
  overflow-y: scroll;
  height: 55vh;
}

.table_section_inforsection::-webkit-scrollbar {
  display: none;
}

/* booktestride css  */

.booktest_modal .booktest_direction {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 20px 0px;
  padding-bottom: 0px;
}

.my_book_modal .pure-modal-backdrop {
  max-width: 500px;
  background-color: #081119 !important;
}

/*////////////////////////////////////////// css for hop leo select bike section ///////////////////////////////////// */

.select_modal_image {
  height: 100vh;
  display: flex;
  background: linear-gradient(to right, #3780f2, #224e9b, #17346e, #00071a);
}

.select_modal_image .left_bike {
  width: 40%;
}

.select_modal_image .right_bike {
  width: 60%;
  padding: 3rem 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  z-index: 1;
  position: relative;
}

.select_modal_image .right_bike .select_modal h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  color: #fff;
}

.select_modal_image .right_bike .tab_three button {
  background: transparent;
  border: 1px solid #ffffff8a;
  box-shadow: none !important;
  outline: none !important;
  color: #fff;
  letter-spacing: 0.08em;
  width: auto;
  border-radius: 3px;
  padding: 10px 40px;
}

.select_modal_image .right_bike .tab_three button:hover,
.select_modal_image .right_bike .tab_three button.active {
  background-color: #41000080;
  color: #fff;
}

.select_modal_image .right_bike .tab_three {
  display: flex;
  gap: 20px;
}

/* .select_modal_image .right_bike .select_color h3 {
  margin-top: 50px;
} */

.select_modal_image .right_bike .Price_sectionINR {
  font-size: 28px;
  color: #fff;
  margin: 50px 0px;
}

.select_modal_image .Chek_box_book .Chek_box div {
  margin-top: 20px;
}

.select_modal_image .Chek_box_book .Chek_box div,
.select_modal_image .Chek_box_book .Book_now {
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.select_modal_image .Chek_box_book .Book_now {
  margin-top: 40px;
}

.select_modal_image .Chek_box_book .Book_now button {
  background: #3780f2;
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.08em;
  box-shadow: 8px 8px 9px rgb(0 0 0 / 25%);
  border: 1px solid #3780f2;
  text-transform: uppercase;
  transition: 0.3s all ease-in;
  padding: 10px 15px;
  height: 100%;
  align-self: flex-end;
}

.select_modal_image .Chek_box_book .Book_now button:hover {
  color: #fff;
  background: transparent;
  border-color: #fff;
}

.select_modal_image .Chek_box_book .INR_price h5 {
  font-size: 28px;
  font-weight: 400;
  color: #fff;
  margin: 0px;
}

.select_modal_image .Chek_box_book .INR_price p {
  font-size: 14px;
  font-weight: 400;
}

.select_modal_image .select_color {
  display: flex;
  justify-content: start;
  margin-top: 50px;
  margin-bottom: 15px;
  gap: 20px;
  align-items: center;
}

.select_modal_image .right_bike .select_modal {
  width: 100%;
  max-width: 556px;
  margin: 0px auto;
}
.select_modal_image .right_bike .select_modal p,
.select_modal_image .right_bike .select_modal h3 {
  padding: 0px;
  margin: 0px;
}

.select_modal_image .right_bike .Chek_box_book .Chek_box span {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 16px;
}

.select_modal_image .right_bike .Chek_box_book .Chek_box input {
  border-width: 1px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 25px;
  height: 25px;
  outline-offset: 2px;
}

.select_modal_image .form-check input#white::before {
  background: #fff;
}

.select_modal_image .color_step form {
  gap: 30px;
  flex-wrap: initial;
}

.select_modal_image .left_bike figure {
  height: 100%;
  display: flex;
  align-items: center;
}

.select_modal_image .left_bike figure img {
  object-position: right;
  object-fit: cover;
  max-height: 90%;
}
.select_modal_image .form-check .white::before {
  background: #fff !important;
}

.select_modal_image .right_bike .select_modal > h3 {
  margin-bottom: 12px;
}

.select_modal_image .color_step.onmobile_360 {
  display: block;
}

/* vikash css after uikit remove  */

.Hop_leo_main .uk-padding-large {
  padding: 70px;
}

/*******************/
/***** MEDIA CSS START *****/
/*******************/

@media (max-width: 991px) {
  .banner_slider {
    padding-bottom: 50px;
  }

  .hop-leo .banner_slider div#viewerImage {
    max-width: 100%;
  }

  .hop-leo .banner_slider div#viewerImage img {
    width: 100%;
    margin: auto;
    object-fit: contain;
    height: auto;
  }

  .gallery-flex > * {
    padding: 2px;
  }

  .d-flex,
  .product-details-section {
    display: block;
    margin-bottom: 15px;
  }

  .Hop_leo_main .full_video_bike {
    height: 50vh;
  }

  .Hop_leo_main .full_video_bike .vimeo-video {
    display: block;
    width: 100%;
    left: 0;
  }

  .product-details-section > div {
    width: 100%;
  }

  .tabing_data > div {
    padding: 20px;
  }

  .tabing_data .data_box h3 {
    font-size: 26px;
  }
}

@media (max-width: 767px) {
  .Hop_leo_main .full_video_bike .vimeo-video iframe {
    top: 0 !important;
  }

  .select_modal_image .right_bike .Price_sectionINR {
    font-size: 24px;
  }
  .select_modal_image .right_bike .select_modal h3 {
    width: 100%;
    font-size: 16px;
  }

  .select_modal_image .color_step.onmobile_360 {
    margin-right: auto;
  }

  .select_modal_image .select_color {
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
  }

  .select_modal_image .right_bike .Price_sectionINR {
    margin: 40px 0px;
  }

  .select_modal_image .right_bike .tab_three button {
    padding: 7px 30px;
  }

  .select_modal_image .left_bike {
    width: 60%;
    padding-top: 50px;
  }

  .select_modal_image {
    flex-direction: column;
    height: auto;
  }

  .select_modal_image .left_bike figure {
    height: auto;
  }

  .my_book_modal .pure-modal-backdrop {
    background-color: inherit !important;
  }

  .select_modal_image .right_bike {
    width: 100%;
  }

  /* .faq_page .faq-questions .faq_container{
    height: 100vh;
  }
  .faq_page .faq-questions{
    margin-top: 50px;
  } */
  .faq_page .faq_sec .faq-block {
    padding-top: 100px !important;
  }

  .faq_page .faq_sec {
    height: auto;
  }

  .banner_slider {
    padding-bottom: 20px;
  }

  .product-gallery-block {
    padding: 30px 0;
  }

  .gallery-flex div::after {
    font-size: 1.5rem;
  }

  .modalpopup .pure-modal .close {
    height: 20px;
    width: 20px;
    font-size: 30px;
    line-height: 13px;
  }

  .modalpopup .slick-prev,
  .modalpopup .slick-next {
    width: 30px;
    height: 30px;
    background-size: 10px;
  }

  .modalpopup .slick-prev {
    margin-top: -15px;
    left: 0;
  }

  .modalpopup .slick-next {
    right: 0;
  }

  .description_sec h2 {
    font-size: 36px;
    line-height: 47px;
  }

  .Hop_leo_main .full_video_bike {
    height: 45vh;
    width: 90%;
    margin: 0 auto 40px auto;
  }

  .Hop_leo_main .full_video_bike::after {
    display: none;
  }

  .product-details-section .uk-padding-large {
    padding: 30px 20px;
  }

  .product-details h2,
  .product-details-section .product-details h2 {
    font-size: 48px;
  }

  .tabing_section h2 {
    font-size: 2.25rem;
  }

  .tabing_data {
    display: block;
  }

  .tabing_data > div {
    width: 100%;
    border-bottom: 1px solid #202020;
  }

  .tabing_data > div:last-child {
    border: none;
  }

  .tabing_section button.btn-price-oxo {
    padding: 5px 15px;
    width: fit-content;
    margin-bottom: 8px;
    font-size: 20px;
  }

  .product_slider {
    margin-top: 50px;
  }

  .product_slider .productSlideItem--title {
    font-size: 1rem;
  }

  .product_slider .slick-dots li {
    margin: 0;
  }

  .product_slider .slick-dots li button:before {
    font-size: 7px;
  }

  .newsletter {
    padding: 50px 0;
  }

  .newsletter .widget-title {
    font-size: 1.2rem;
  }

  .newsletter a.sliderLink {
    font-size: 12px;
  }

  .formpopup .pure-modal {
    max-width: 90%;
  }

  .formpopup .panel-body {
    padding: 20px;
  }

  .formpopup .from_title {
    font-size: 1.5rem;
  }

  .formpopup input.form-control {
    font-size: 16px;
  }

  .formpopup input[type="submit"] {
    padding: 7px 25px;
    font-size: 18px;
  }

  .formpopup .pure-modal .close {
    width: 30px;
    height: 30px;
    line-height: 21px;
  }

  .blank_box {
    padding: 35px;
  }
  .full_video_bike {
    margin-bottom: 30px;
  }
}

@media (max-width: 479px) {
  .Hop_leo_main .full_video_bike {
    height: 24vh !important;
  }
  /* .full_video_bike .video_sec_step{
    height: 130% !important;
  } */
}
