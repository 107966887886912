.DatePicker_dateDayItem__3IPnW {
  font-weight: bold !important;
  width: 45px !important;
  height: 45px !important;
  border-top: 0px solid blue !important;
  border-right: 0px solid blue !important;
  border-left: 0px solid blue !important;
  border-radius: 0px !important;
  color: white !important;
}
#home .location_error {
  font-size: 16px;
  margin-bottom: 10px;
  display: block;
}

.ddd {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.top_header_pm:before {
  content: "";
  background: linear-gradient(
    180deg,
    rgba(10, 10, 10, 1) 0%,
    rgba(20, 20, 20, 0) 100%
  );
  position: absolute;
  top: 0;
  height: 100px;
  left: 0;
  right: 0;
  z-index: 1;
}

.top_header_pm {
  position: relative;
  overflow: hidden;
  background: #060606;
  height: 100vh;
  scroll-snap-align: start;
}

.dealers_add {
  border-radius: 16px;
  position: relative;
  padding: 32px;
  max-width: calc(100% - 24px);
  margin: 0 auto;
  word-break: break-word;
}

.dealers_add::before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(30px);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 16px;
  z-index: -1;
}

.dealers_add h6 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
  font-family: "Lato", sans-serif;
  color: #fff;
}

.dealers_add p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
  font-family: "Lato", sans-serif;
  color: rgb(255 255 255 / 60%);
  margin-top: 0;
}

.dealers_add .default_btn {
  font-size: 16px !important;
  cursor: pointer;
  padding: 15px 20px;
  width: 100%;
}

button.bg_tra_btn {
  background: rgba(0, 234, 255, 0.1);
  border: 1px solid #00eaff;
  backdrop-filter: blur(30px);
  color: #00eaff;
}

.mobile_showlogo {
  display: none;
}

/* testride-map-start*/
.map_right_address {
  padding-bottom: 50px !important;
}

.testride_map {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#graphics {
  width: 100%;
}

.testride_map .map_left {
  width: 70%;
  height: 100%;
}

.logo_right_cont {
  padding: 50px 0px 30px 0px;
}

.testride_map .testride_righsec {
  width: 30%;
  text-align: center;
  padding: 64px 64px;
  position: relative;
  z-index: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.testride_map .testride_righsec::-webkit-scrollbar {
  display: none;
}

.testride_map .testride_righsec {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.testride_map .testride_righsec::before {
  background: linear-gradient(211deg, #0b132f, #0b132f);
  border-radius: 50%;
  content: "";
  filter: blur(80px);
  height: 1000px;
  position: absolute;
  right: -400px;
  left: 0;
  top: -500px;
  width: 800px;
  z-index: -1;
  margin-left: auto;
}

.logo_right_cont img {
  margin-bottom: 15px !important;
}

.testride_map .map_left figure {
  height: 100%;
}

.testride_map .map_left figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo_right_cont p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Lato", sans-serif;
  color: #fff;
  margin-top: 0;
}

.order_id .next_btn {
  margin-top: 24px;
}

.testride_righsec h3 {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 32px;
}

.testride_righsec .form-group .form-control {
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  padding: 13px 32px;
  line-height: 36px;
  width: 100%;
  margin-bottom: 24px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.testride_righsec .form-group ::placeholder {
  color: #ffffff;
}

.next_btn button.default_btn {
  outline: none;
  border: none;
  width: 100%;
  cursor: pointer;
}

.next_btn {
  margin-top: 16px;
}

.date_piker ul li button {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
  background: #081119;
  border-radius: 8px;
  padding: 12px 20px;
  width: 100%;
  color: rgb(255 255 255 / 60%);
  border: 1px solid rgb(255 255 255 / 60%);
}

.date_piker ul li button.btn-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.booktest_ride .next_btn {
  margin-top: 50px;
}

.book_map .booktest_ride {
  padding-bottom: 60px !important;
}

.order_id .order_sec_id strong {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 48px;
  text-align: center;
  text-transform: capitalize;
  color: #00eaff;
}

.order_id .order_sec_id strong span {
  font-weight: 600;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.6);
}

.order_id .logo_right_cont {
  padding-bottom: 30px;
}

.Confirmation_left {
  position: relative;
}

.Confirmation_left .after_savings {
  position: absolute;
  bottom: 120px;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 1012px;
  z-index: 0;
}

.after_savings ul {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 16px 16px 0px 0px;
  padding: 16px 0px;
  position: relative;
  margin: 0px 38px;
}

.after_savings ul::before {
  content: "";
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(30px);
  z-index: -1;
  border-radius: 16px 16px 0px 0px;
}

.after_savings ul li p {
  margin-bottom: 0;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.09em;
  text-transform: capitalize;
  color: rgb(255 255 255 / 60%);
}

.after_savings ul li {
  text-align: center;
  width: 50%;
  padding: 6px 15px;
}

.after_savings ul li p span {
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
  text-transform: capitalize;
  color: #fff;
  padding-right: 10px;
}

.after_savings ul li:first-child {
  border-right: 2px solid #d9d9d9;
}

.Confirmation_left::after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  height: 230px;
  width: 85%;
  bottom: -90px;
  z-index: 0;
  background: linear-gradient(211deg, #0a0a0a, #0a0a0a);
  border-radius: 50%;
  filter: blur(50px);
}

.mobile_show {
  display: none;
}

.date_show {
  border-bottom: 1px solid rgb(255 255 255 / 50%);
  margin-bottom: 34px;
}

.date_show ul {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  padding: 0;
  justify-content: center;
  margin-bottom: 14px;
}

.date_show ul li {
  width: 16.66%;
  padding: 0px 15px;
  position: relative;
}

.date_show ul li:first-child ::before {
  content: "";
  background: #00eaff;
  height: 2px;
  width: 80%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -15px;
  margin: auto;
  border-radius: 5px;
}

.date_show ul li button {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

/* new-css-start */

.date_show .DatePicker_daysContainer__2H2vN .DatePicker_dateDayItem__teHyJ {
  color: #fff;
}

.date_show
  .DatePicker_daysContainer__2H2vN
  .DatePicker_dateDayItem__teHyJ
  .DatePicker_dateLabel__0BkYt {
  line-height: 15px;
}

.date_show
  .DatePicker_daysContainer__2H2vN
  .DatePicker_dateDayItem__teHyJ
  .DatePicker_dayLabel__W2gP8 {
  text-transform: uppercase;
}

/* new-css-end */

.booktest_ride {
  padding: 64px 0px !important;
}

.booktest_ride .logo_cont_show {
  padding: 0px 64px;
}

.booktest_ride .logo_cont_show .logo_right_cont {
  padding-bottom: 30px;
}

/* .map_img_add .map_left figure img {
    height: 1055px;
    object-fit: cover;
} */

/* testride-map-start*/

/* HomeBookTestride-start */

.to_colors_sec strong {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 48px;
  text-align: center;
  text-transform: capitalize;
  color: #00eaff;
}

.to_colors_sec strong span {
  font-weight: 600;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.6);
}

.homebook_testride .booktest_ride .next_btn {
  margin-top: 32px;
}

/* HomeBookTestride-end */

/* payment -start */
.payment_secmain {
  height: 100%;
  position: relative;
}

.payment_secmain .payment_cont {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  right: 15px;
}

.payment_secmain .payment_cont h2 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
}

.payment_secmain::before {
  background: linear-gradient(211deg, rgb(19 32 77), rgb(19 32 77));
  border-radius: 50%;
  content: "";
  filter: blur(30px);
  height: 400px;
  position: absolute;
  right: -150px;
  left: 0;
  top: -150px;
  width: 400px;
  z-index: 1;
  margin-left: auto;
}

.payment_secmain::after {
  background: linear-gradient(211deg, #2a63a3, #2a63a3);
  border-radius: 0% 80% 0% 0%;
  content: "";
  filter: blur(50px);
  height: 400px;
  position: absolute;
  left: -150px;
  bottom: -150px;
  width: 400px;
  z-index: 0;
}

.payment_cont .next_btn {
  max-width: 270px;
  margin: auto;
  margin-top: 20px;
}

/* payment -end */

.popup_slider {
  margin-right: -100px;
  margin-left: 200px;
}

.popup_slider button.slick-arrow {
  display: none !important;
}

.sliderpopup .pure-modal-backdrop .pure-modal {
  padding-right: 0px !important;
}

.height_one_csrol::-webkit-scrollbar {
  display: none;
}
.height_one_csrol {
  position: relative;
  background: #0a0a0a;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  width: 100%;
}

.height_one_csrol > #footer {
  width: 100%;
  height: 100vh;
  scroll-snap-align: start;
  overflow: hidden;
}

.height_one_csrol ~ #footer {
  display: none;
}

/* --======================= */

.Confirmation_left .after_savings figure img {
  height: auto;
}

.bike_detailcus {
  margin: 100px 40px 15px;
}

.bike_detailcus .search_bar {
  position: relative;
  margin-bottom: 30px;
}

.search_bar .search_inp {
  background: #d9d9d9;
  border-radius: 8px;
  font-size: 20px;
  color: #000;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: "Lato", sans-serif;
  border: none;
  outline: none;
  padding: 15px 30px;
  width: 100%;
}

.search_bar .search_icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  border-radius: 0px 5px 5px 0px;
}

.search_bar .search_icon button {
  padding: 20px 13px;
}

.select_dealer .date_times {
  margin-bottom: 10px;
}

/* .event_detail .event_btn {
    text-align: center;
} */

.booktr_slider .select_dealer .date_times span {
  font-family: "Lato";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgb(255 255 255 / 80%);
  margin-right: 5px;
  text-transform: uppercase;
}

.booktr_slider {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}

.dealer_border {
  position: relative;
  border-bottom: 2px solid #007a87;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.dealer_border::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -1px;
  background: #50dfdb;
  animation: booktr_slider_animate 4s linear infinite;
}

@keyframes booktr_slider_animate {
  0% {
    transform: scaleX(1);
    transform-origin: center;
  }

  100% {
    transform: scaleX(0);
    transform-origin: center;
  }
}

.booktr_slider .select_dealer {
  width: calc(100% - 20px) !important;
  margin: 0px 10px;
  padding: 15px 20px;
  border-radius: 10px;
  background: #081119;
  color: rgb(255 255 255 / 60%);
  border: 1px solid rgb(255 255 255 / 60%);
  height: 100%;
}

.booktr_slider .select_dealer strong {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 10px;
  display: block;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.booktr_slider .select_dealer p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgb(255 255 255 / 60%);
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 0px 0px 10px 0px;
}

.default_tran {
  background: rgba(0, 234, 255, 0.1);
  border: 1px solid #00eaff;
  color: #00eaff;
  transform: translate(0, -80%);
}

.default_tran:hover {
  background: #00eaff;
}

.booktr_slider .select_dealer .default_btn {
  font-size: 13px;
  padding: 10px 14px;
  border-radius: 8px;
}

.date_show .DatePicker_monthContainer__Ay4eF .DatePicker_monthYearLabel__w6HMp {
  display: none;
}

.date_show .DatePicker_monthContainer__Ay4eF .DatePicker_monthYearLabel__w6HMp {
  display: none;
}

.date_show
  .DatePicker_monthContainer__Ay4eF
  .DatePicker_daysContainer__OfZcM
  .DatePicker_dateDayItem__IHL2S {
  font-family: "Lato";
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  height: 56px !important;
  text-align: center;
  text-transform: capitalize;
  color: #fff !important;
  margin: 0px 5px 0px;
  flex-direction: column-reverse;
  border-radius: 0px !important;
  border-left: none !important;
  border-top: none !important;
  border-right: none !important;
  border-bottom: 2px solid #000;
}

.date_show
  .DatePicker_monthContainer__Ay4eF
  .DatePicker_daysContainer__OfZcM
  .DatePicker_dateDayItem__IHL2S
  .DatePicker_dayLabel__7S2Ec {
  font-size: 14px;
  text-transform: uppercase;
  /* margin: -2px; */
}

/* .date_show .DatePicker_monthContainer__Ay4eF .DatePicker_daysContainer__OfZcM .DatePicker_dateDayItem__IHL2S .DatePicker_dateLabel__gumhX {
    margin: -3px;
} */

.date_show
  .DatePicker_monthContainer__Ay4eF
  .DatePicker_daysContainer__OfZcM
  .DatePicker_dateDayItem__IHL2S
  .DatePicker_dateLabel__gumhX {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.date_show .DatePicker_container__en6M9 .DatePicker_dateListScrollable__nQSC- {
  margin: 0px 10px;
}

.date_show
  .DatePicker_container__en6M9
  .DatePicker_buttonWrapper__K15TF
  button.DatePicker_button__SIan2 {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background: rgb(47 47 47) !important;
  border: 1px solid rgb(100 100 100 / 69%);
}

.date_piker ul li {
  margin: 0px 10px 10px;
}

.order_sec_id p.destop_show {
  font-weight: 600;
  font-size: 19px;
  line-height: 34px;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 15px;
}

.test_ridestep ul li a {
  padding: 10px 10px;
  width: 100%;
  display: block;
  margin: 10px 0px;
  font-size: 18px;
  border-radius: 12px;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  transition: all 0.3s;
  font-weight: 600;
  background: rgba(0, 234, 255, 0.1);
  border: 1px solid #00eaff;
  color: #00eaff;
}

.test_ridestep ul li a:hover {
  background: #fff;
  color: #000;
}

.test_ridestep ul li .drop_menu {
  background: #212121;
  padding: 10px 15px;
  border-radius: 0px 0px 8px 8px;
}

.test_ridestep .dropdown_menu a {
  margin-bottom: 0;
}

.time_next {
  margin-bottom: 15px;
}
.bike_detailcus .bookheding_sec .date_piker ul {
  padding-left: 0px;
}
.booktr_slider .slick-arrow {
  background: #00eaff;
  border: 1px solid rgb(100 100 100 / 69%);
  border-radius: 50px;
  height: 35px;
  width: 35px;
  z-index: 8;
  transform: translate(0, -80%);
}

.booktr_slider .slick-arrow.slick-prev {
  margin-left: 15px;
}

.booktr_slider .slick-arrow.slick-next {
  margin-right: 15px;
}

.booktr_slider .slick-arrow.slick-next::before {
  transform: rotate(-180deg);
  position: absolute;
  top: 8px;
  left: 10px;
}
.booktr_slider .slick-prev:hover,
.booktr_slider .slick-prev:focus,
.booktr_slider .slick-next:hover,
.booktr_slider .slick-next:focus {
  outline: inherit;
  background: #00eaff;
  color: #ffffff;
}
.booktr_slider .slick-prev.slick-disabled,
.booktr_slider .slick-next.slick-disabled {
  background-color: transparent;
}

.booktr_slider .slick-arrow::before {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00eaff;
  content: "";
  background-image: url("../../assets/images/slider-left-arrow.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  opacity: 1;
  transform: translate(-50%, -50%);
}

.booktr_slider .slick-arrow:before,
.booktr_slider .slick-arrow:before {
  color: #000;
}

.cancel_ride .form-check {
  width: 100%;
  margin-bottom: 20px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: baseline;
}

.cancel_ride .form-check input {
  width: 20px;
  height: 20px;
}

.cancel_ride .form-check .form-check-label {
  font-family: "Lato", sans-serif;
  color: #fff;
  font-size: 20px;
  text-transform: capitalize;
  width: calc(100% - 20px);
  padding-left: 15px;
}

.more_ifor {
  cursor: pointer;
  text-transform: lowercase;
  padding: 0px 15px;
}

/* ========= events_main start ============== */

.event_cont .open_info figure {
  margin-right: 15px !important;
}

.event_cont .open_info h2 {
  font-size: 54px;
  color: #000;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
}

.eventdetail_popup .event_cont .event_name {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin: 60px 0px;
  width: 100%;
}

.event_cont h4 {
  font-size: 32px;
  color: rgb(255 255 255 / 90%);
  font-family: "Lato", sans-serif;
  font-weight: 700;
  text-align: center;
}

.date_time p {
  color: #fff;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 15px;
}

.date_time p em {
  color: #00eaff;
  text-transform: uppercase;
}

.date_time p span {
  text-transform: capitalize;
  background: #00eaff;
  padding: 5px 8px;
  color: #000;
  border-radius: 5px;
  margin: 5px;
}

.bookheding_sec > p {
  text-align: left !important;
  width: 100%;
  font-size: 26px !important;
  margin-bottom: 20px;
  padding-left: 10px;
}

.eventdetail_popup .event_cont {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.eventdetail_popup .pure-modal-backdrop .pure-modal {
  padding: 50px 0px !important;
  background: rgb(0 0 0 / 30%);
  border-radius: 10px;
}

.eventdetail_popup .event_cont .event_name .open_info {
  background: #00eaff;
  padding: 20px 30px;
  backdrop-filter: blur(10px);
  border-radius: 10px 0px 0px 10px;
  min-width: 350px;
  margin-left: 30px;
}

.eventdetail_popup .event_cont .event_name .open_info h2 {
  color: #000;
  font-size: 34px;
  margin-bottom: 0;
  line-height: normal;
}

.eventdetail_popup .pure-modal-backdrop {
  max-width: 75%;
  margin: auto;
  height: 90vh;
  border-radius: 10px;
  left: -270px;
}

.eventdetail_popup .pure-modal-backdrop .pure-modal::-webkit-scrollbar {
  display: none;
}

#book-test-ride .eventdetail_popup .pure-modal-backdrop::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/event_bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.4s ease-out;
  z-index: -1;
  background-size: cover;
  border-radius: 10px;
}

.eventdetail_popup .panel-body.scrollable {
  padding: 0px !important;
}

.eventdetail_popup .event_cont .event_name h4 {
  color: #fff;
  font-size: 48px;
  /* max-width: 680px; */
  font-weight: 800;
  text-align: left;
  padding-left: 30px;
  text-transform: uppercase;
}

.event_cont .date_time {
  text-align: left;
  max-width: 50%;
  padding: 40px 30px;
  border-radius: 0px 20px 20px 0px;
  background: rgb(0 0 0 / 40%);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  margin-bottom: 50px;
}

.eventdetail_popup .event_cont .event_name h4 span {
  color: #00eaff;
}

.event_cont .event_booktr button.no_tns {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  display: block;
  width: 100%;
}

.event_cont .event_booktr {
  width: 50%;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 80px 10px;
}

.event_cont .event_booktr .default_btn {
  font-size: 22px;
  margin-bottom: 20px;
}

.event_cont .date_time .aling_cont {
  display: flex;
}

.event_cont .date_time .aling_cont em {
  min-width: 145px;
  padding-right: 10px;
}

/*==================== events_main end ===================*/
#booktest-ridepage .slick-slider.slick-initialized {
  width: 100%;
}

#booktest-ridepage .highlight strong {
  color: #00eaff;
}

#booktest-ridepage .highlight p {
  color: #00eaff;
}

#booktest-ridepage .highlight {
  border: 3px solid #00eaff;
}

#booktest-ridepage .eventhighlight strong {
  color: #00eaff;
}

#booktest-ridepage .eventhighlight {
  border: 3px solid #00eaff;
}

#booktest-ridepage .eventhighlight .date_times p,
#booktest-ridepage .eventhighlight .date_times p span {
  color: #00eaff;
}

#booktest-ridepage .next_btn.bo_ather .home_testride {
  background: #00eaff;
  color: #fff;
  border: 2px solid #00eaff;
}

.bo_ather figure img {
  width: 40px;
  margin-right: 10px;
}

.next_btn.bo_ather .avelabail_home {
  font-size: 26px;
  font-family: "Lato", sans-serif;
  transition: all 0.3s;
  text-align: left;
  background: rgba(0, 234, 255, 0.1);
  border: 1px solid #00eaff;
  color: #00eaff;
  margin: 30px 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  border-radius: 10px;
  width: 100%;
}

/* .next_btn.bo_ather .avelabail_home:hover{
     color: #fff;
     background: #00eaff;
} */

.thak_test {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50px;
  right: 50px;
}

.booktest_ride .next_btn button.default_btn {
  margin-bottom: 15px;
}

.thak_test p {
  line-height: normal !important;
  font-size: 24px !important;
  margin-bottom: 25px !important;
}

.requested .test_ridestep ul li {
  text-align: center;
}

.requested {
  padding: 10px 15px;
}

.requested > ul > li {
  color: #fff;
  font-size: 18px;
  margin-bottom: 10px;
  list-style: disc;
}

.requested > ul {
  padding-left: 20px;
  margin-bottom: 25px;
}

.requested p {
  font-size: 18px;
  margin-bottom: 15px;
}

.requested p span {
  padding-right: 5px;
  font-size: 20px;
  margin-bottom: 5px;
  text-transform: capitalize;
  color: #00eaff;
  font-weight: 500;

  /* display: block; */
}

.requested p span img {
  display: inline-block;
  margin-right: 3px;
}

.requested p.location_ali span {
  display: block;
}

#cancel_testride .pure-modal-backdrop .scrollable {
  padding: 0;
}

#service_cancel .pure-modal-backdrop .scrollable {
  padding: 0;
}

#cancel_testride .pure-modal-backdrop .scrollable .loca_info {
  padding: 0;
}

#service_cancel .pure-modal-backdrop .scrollable .loca_info {
  padding: 0;
}

#cancel_testride .pure-modal-backdrop {
  /* max-height: 75vh; */
  max-height: 550px;
  height: 90%;
  width: 600px;
  border-radius: 20px;
  top: 50%;
  transform: translateY(-50%);
  left: calc(35% - 300px);
  padding: 10px 0px;
  border: 1px solid #4b4949;
}

#service_cancel .pure-modal-backdrop {
  /* height: 30vh; */
  width: 600px;
  border-radius: 20px;
  top: 50%;
  transform: translateY(-50%);
  left: calc(35% - 300px);
  padding: 10px 0px;
  border: 1px solid #4b4949;
}

#cancel_testride .pure-modal-backdrop::after {
  border-radius: 20px;
}

#service_cancel .pure-modal-backdrop::after {
  border-radius: 20px;
}

#cancel_testride .pure-modal-backdrop .pure-modal::-webkit-scrollbar {
  display: none;
  padding: 10px 0px;
}

#service_cancel .pure-modal-backdrop .pure-modal::-webkit-scrollbar {
  display: none;
  padding: 10px 0px;
}

.requested_popup .pure-modal-backdrop {
  width: 28% !important;
  position: absolute;
  right: 20px;
  height: 80vh;
  border: 1px solid #4b4949;
  border-radius: 15px;
  padding: 20px 15px;
  background: #000;
  margin-left: auto;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.requested_popup .pure-modal .scrollable::-webkit-scrollbar {
  display: none;
}

.requested_popup .pure-modal .scrollable {
  background: #000;
  padding: 0px;
}

.requested h3 {
  font-size: 32px;
  color: #fff;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 25px;
  padding-right: 60px;
}

#cancel_testride .requested h3 {
  padding: 0;
}

#service_cancel .requested h3 {
  padding: 0;
}

.requested p {
  color: #fff;
  font-size: 18px;
  line-height: normal;
}
.requested .book_anpo button {
  text-align: center;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 28px;
  text-transform: uppercase;
  padding: 10px 40px;
  border-radius: 8px;
  backdrop-filter: blur(15px);
}
.book_anpo button.user_btn {
  width: auto;
  margin: 10px 15px;
  border: 1px solid #00eaff;
  background: #00eaff;
}

.book_anpo button.user_btn:hover {
  background: transparent;
  color: #fff;
}

.book_anpo button.border_btn:hover {
  background: #00eaff !important;
  color: #000 !important;
}

.book_anpo button.border_btn {
  background: transparent;
}

.close_button .close {
  top: 30px !important;
  right: 30px !important;
  width: 50px;
  height: 50px;
  background: rgba(0, 234, 255, 0.1) !important;
  border: 1px solid #00eaff;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  border-radius: 50px;
  padding: 6px;
  position: absolute !important;
}

.close_button .close .close_btn::before {
  background: #00eaff;
  height: 3px;
  width: 25px;
}

.close_button .close .close_btn::after {
  background: #00eaff;
  height: 3px;
  width: 25px;
}

.close_button .close .close_btn {
  margin-right: 25px;
}

.cancel_conf p {
  font-size: 20px !important;
  margin-bottom: 30px;
  line-height: 32px !important;
}

.cancel_conf .thank_int {
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  margin-top: 20px;
}

.event_mainl .date_times {
  margin-bottom: 15px;
}

.event_mainl .date_times p {
  margin-bottom: 5px;
}

.testride_righsec h4 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  text-transform: capitalize;
  color: rgb(255 255 255 / 80%);
  margin-bottom: 10px;
}

.close_button .close {
  /* top: 15px !important;
  right: 20px !important; */
  top: -35px !important;
  right: -14px !important;
}

.requested_popup .close {
  top: 0px !important;
  right: 5px !important;
}

.hometr_date .dM {
  padding-top: 0;
}

.hometr_date .Tx .or {
  font-family: "Lato";
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  /* margin: 0px 10px 10px; */
  flex-direction: column-reverse;
  border-radius: 5px;
  border: 2px solid #000;
  background-color: transparent;
  flex-direction: column-reverse;
  border-radius: 0px !important;
  border-left: none !important;
  border-top: none !important;
  border-right: none !important;
  border-bottom: 2px solid #000;
}

.hometr_date .Tx > .ourclass {
  border: 2px solid #00eaff;
  background: transparent !important;
}

.hometr_date .Tx .or .Lx {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0;
}

.hometr_date .Tx .or ._1g {
  color: #fff !important;
  background-color: transparent !important;
  font-weight: 500;
  font-size: 16px;
}

.hometr_date .dM button.Kq {
  width: 55px;
  height: 55px;
  border-radius: 5px;
  background: rgb(47 47 47) !important;
  border: 1px solid rgb(100 100 100 / 69%);
}

.hometr_date .dM button.Ez {
  margin-right: 5px;
}

.hometr_date .dM button.WP {
  margin-left: 5px;
}

.hometr_date .dM button.Kq svg {
  fill: #ffffff;
}

.hometr_date .dr {
  width: 100%;
  margin: 0px 5px;
}

.date_show
  .DatePicker_container__en6M9
  .DatePicker_dateListScrollable__nQSC-
  .DatePicker_daysContainer__OfZcM {
  margin-left: 0px !important;
}

/* date */

.date_show .DatePicker_buttonWrapper__UxlE5 button.DatePicker_button__iBgLD {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background: rgb(47 47 47) !important;
  border: 1px solid rgb(100 100 100 / 69%);
}

.date_show .DatePicker_daysContainer__9htA9 .DatePicker_dateDayItem__XwRQy {
  flex-direction: column-reverse;
  border-bottom: 2px solid #000;
  margin: 0px 5px;
  height: auto !important;
}

.date_show .DatePicker_daysContainer__9htA9 {
  margin-top: 10px;
  margin-left: 0px !important;
}

.date_show .dM {
  padding-top: 0;
  padding-bottom: 0;
}

.date_show .dM button.Kq {
  width: 66px;
  height: 50px;
  border-radius: 5px;
  background: rgb(47 47 47) !important;
  border: 1px solid rgb(100 100 100 / 69%);
  margin-top: 35px;
  color: #fff;
}

.date_show .dM button.Kq::after {
  display: none;
}

.date_show .dM .jb {
  color: #fff;
  margin-left: 0px;
}

.date_show .dM ._3n {
  background-color: transparent;
  flex-direction: column-reverse;
  border-bottom: 2px solid #000;
  border-radius: 0;
}

.date_show .dM ._3n .Lx {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 0;
}

.date_show .dM ._3n ._1g {
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}

.date_show .dM .ourclass {
  border-bottom: 2px solid #00eaff;
}

.date_show .DatePicker_dateListScrollable__UAG0R {
  max-width: 100%;
  margin: 0;
}

.date_show
  .DatePicker_dateListScrollable__UAG0R
  span.DatePicker_monthYearLabel__T6Ong {
  color: #fff !important;
}

.date_show
  .DatePicker_daysContainer__9htA9
  .DatePicker_dateDayItem__XwRQy
  .DatePicker_dayLabel__o2Brk {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
}

.date_show
  .DatePicker_daysContainer__9htA9
  .DatePicker_dateDayItem__XwRQy
  .DatePicker_dateLabel__KeEIO {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 6px;
}

.date_show .dM ._3n:hover {
  background-color: transparent;
}

/* date-end */

.booktr_slider .slick-slide {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
}

.booktr_slider .slick-slide > div {
  width: 50%;
  margin-bottom: 15px;
}

.date_show .DatePicker_buttonWrapper__tnGHD button.DatePicker_button__qRJ00 {
  width: 66px;
  height: 50px;
  border-radius: 5px;
  background: rgb(47 47 47) !important;
  border: 1px solid rgb(100 100 100 / 69%);
  margin-top: 35px;
  color: #fff;
}

.date_show .DatePicker_monthContainer__1pi4A {
  width: auto;
  margin: 0;
}

.date_show .DatePicker_monthContainer__1pi4A span {
  color: #fff !important;
  margin-left: 0px;
  width: auto;
}

.date_show .DatePicker_daysContainer__AuJox .DatePicker_dateDayItem__o6SxQ {
  background-color: transparent;
  flex-direction: column-reverse;
  border-radius: 0px !important;
  margin: 0px 5px;
  border-bottom: -1px solid #fff !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.date_show
  .DatePicker_daysContainer__AuJox
  .DatePicker_dateDayItem__o6SxQ
  .DatePicker_dateLabel__xgtHw {
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
}

.date_show
  .DatePicker_daysContainer__AuJox
  .DatePicker_dateDayItem__o6SxQ
  .DatePicker_dayLabel__IPjvc {
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}

.testride_righsec1 .next_btn1 .button {
  padding: 12px 15px !important;
  border-radius: 12px;
  font-size: 16px;
  margin-bottom: 15px;
}

.destop_show1 P {
  margin-bottom: 10px;
}

/*===========================*/

.bike_detailcus .booktr_slider {
  overflow: inherit;
}

.date_show .DatePicker_buttonWrapper__yp7Go button {
  width: 66px;
  height: 50px;
  border-radius: 5px;
  background: rgb(47 47 47) !important;
  border: 1px solid rgb(100 100 100 / 69%);
  margin-top: 35px;
  color: #fff;
}

.date_show span.DatePicker_monthYearLabel__-nc3P {
  color: #fff !important;
  margin-left: 0px;
}

.date_show .DatePicker_dateDayItem__3IPnW {
  background-color: transparent;
  flex-direction: column-reverse;
  border-radius: 0px !important;
  margin: 0px 5px;
  border-bottom: -1px solid red;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.date_show .DatePicker_dateDayItem__9UMy1 .DatePicker_dateLabel__M0yv4 {
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
}

.date_show .DatePicker_dateDayItem__9UMy1 .DatePicker_dayLabel__3sg5q {
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}

/* service requst page css  */

.serv_req .react_form_group .mo_numb p {
  color: #ffffffb9;
  padding: 5px 10px;
  font-size: 22px;
}

.serv_req .react_form_group .mo_numb p span {
  color: #fff;
  font-weight: 600;
  border-left: 1px solid #00eaff;
  padding: 0px 10px;
  /* min-width: 100px; */
  display: inline-block;
  margin-right: 20px;
}

.serv_req .dropdown {
  margin-top: 30px;
  /* width: 300px; */
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.serv_req .dropdown-header {
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.serv_req .dropdown-body {
  padding: 5px;
  border-top: 1px solid #5e88c0;
  display: none;
}

.serv_req .dropdown-body.open {
  display: block;
}

.serv_req .dropdown-item {
  padding: 10px;
}

.dropdown-item:hover {
  cursor: pointer;
}

.serv_req .dropdown-item-dot {
  opacity: 0;
  color: #91a5be;
  transition: all 0.2s ease-in-out;
}

.serv_req .dropdown-item-dot.selected {
  opacity: 1;
}

.serv_req .icon {
  font-size: 13px;
  color: #91a5be;
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
}

.serv_req .icon.open {
  transform: rotate(90deg);
}

.serv_req .mo_numb .dropdown select:focus-visible {
  outline: none;
}

.serv_req .dropdown select {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: inherit;
}

.serv_req .mo_numb.servic_sec {
  margin-bottom: 30px !important;
}

/* appointment_data css start */
.testride_righsec .appointment_data h3 {
  padding-right: 0;
  text-align: left;
}

.appointment_data .requested > ul > li {
  text-align: left;
}

.appointment_data .requested p {
  text-align: left;
}

.search_bar .search_icon button svg {
  display: none;
}
.booktr_slider .select_dealer {
  height: 250px;
}

.home_testride_landing_page .select_dealer {
  height: 100%;
}

.home_testride_landing_page .testride_righsec {
  overflow-y: scroll !important;
}

/* cross icon changes according new css  */

.login_popup_detail .close_btn {
  width: 50px;
  height: 50px;
  margin-right: 0px !important;
  margin-top: 0px !important;
}
.login_popup_detail .close_btn::before,
.login_popup_detail .close_btn::after {
  top: 50%;
  left: 0;
  right: 0;
}

.main_login_pop .pure-modal-backdrop .popup_cont form span.error {
  color: #f00;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -28px;
}

/*======================================= RESPONSIVE START ===============================================*/

@media screen and (max-width: 1565px) {
  .booktr_slider .select_dealer {
    height: 260px;
  }
  .home_testride_landing_page .select_dealer {
    height: 100%;
  }
}

@media only screen and (max-width: 1499px) {
  .booktr_slider .select_dealer {
    height: 270px;
  }
  .home_testride_landing_page .select_dealer {
    height: 100%;
  }
  .booktr_slider .select_dealer .default_btn {
    font-size: 11px;
    padding: 10px 13px;
  }

  div#book-test-ride .select_dealer .map_left {
    width: 65%;
  }

  div#book-test-ride .select_dealer .testride_righsec {
    width: 35%;
  }

  .date_show .DatePicker_buttonWrapper__tnGHD button.DatePicker_button__qRJ00 {
    height: 35px;
    width: 50px;
    margin-top: 45px;
  }

  .date_show
    .DatePicker_daysContainer__AuJox
    .DatePicker_dateDayItem__o6SxQ
    .DatePicker_dateLabel__xgtHw {
    font-size: 14px;
  }

  .date_show
    .DatePicker_daysContainer__AuJox
    .DatePicker_dateDayItem__o6SxQ
    .DatePicker_dayLabel__IPjvc {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1440px) {
  .payment_secmain::before {
    height: 300px;
    right: -100px;
    top: -100px;
    width: 300px;
  }

  .payment_secmain::after {
    height: 300px;
    left: -100px;
    bottom: -150px;
    width: 300px;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .logo_right_cont img {
    width: 280px;
    margin: auto;
  }

  .dealers_add {
    padding: 25px;
  }

  .logo_right_cont {
    padding: 50px 0px 30px 0px;
  }

  .testride_righsec h3 {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .date_show {
    margin-bottom: 30px;
  }

  .testride_righsec .form-group .form-control {
    padding: 12px 25px;
    line-height: 34px;
    margin-bottom: 20px;
  }

  .next_btn {
    margin-top: 10px;
  }

  /* .map_img_add .map_left figure img {
        height: 930px;
        object-fit: cover;
    } */

  .booktest_ride .next_btn {
    margin-top: 30px;
  }

  .Confirmation_left .after_savings {
    max-width: 900px;
  }

  .after_savings ul {
    margin: 0px 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .dealers_add .default_btn {
    font-size: 14px !important;
    padding: 12px 20px;
  }

  .dealers_add {
    padding: 15px;
  }

  .logo_right_cont img {
    width: 300px;
    margin: auto;
  }

  .logo_right_cont {
    padding: 40px 0px 20px 0px;
  }

  .testride_righsec h3 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 20px;
  }

  .date_show {
    margin-bottom: 25px;
  }

  .testride_righsec .form-group .form-control {
    padding: 10px 20px;
    line-height: 30px;
    margin-bottom: 18px;
  }

  .next_btn {
    margin-top: 10px;
  }

  .booktest_ride .next_btn {
    margin-top: 20px;
  }

  .date_show
    .DatePicker_monthContainer__Ay4eF
    .DatePicker_daysContainer__OfZcM
    .DatePicker_dateDayItem__IHL2S
    .DatePicker_dayLabel__7S2Ec {
    font-size: 14px;
  }

  .date_show
    .DatePicker_monthContainer__Ay4eF
    .DatePicker_daysContainer__OfZcM
    .DatePicker_dateDayItem__IHL2S
    .DatePicker_dateLabel__gumhX {
    font-size: 14px;
  }

  .testride_map .testride_righsec::before {
    height: 280px;
    right: -100px;
    top: -80px;
    width: 400px;
  }

  .booktest_ride .logo_cont_show {
    padding: 0px 40px;
  }

  .date_piker ul li button {
    font-size: 15px;
    line-height: 15px;
  }

  .date_show ul li {
    padding: 0px 10px;
  }

  .date_show ul li button {
    font-size: 14px;
    line-height: 20px;
  }

  /* .map_img_add .map_left figure img {
        height: 750px;
        object-fit: cover;
    } */

  /* .Confirmation_left .after_savings {
        max-width: 780px;
    } */

  .after_savings ul {
    margin: 0px 25px;
  }

  .Confirmation_left .after_savings figure img {
    width: 80%;
    margin: auto;
  }

  .Confirmation_left .after_savings figure {
    text-align: center;
  }

  .after_savings ul li p {
    font-size: 16px;
    line-height: 24px;
  }

  .after_savings ul li p span {
    font-size: 20px;
    line-height: 30px;
    padding-right: 8px;
  }

  .after_savings ul li {
    width: 50%;
    padding: 0px 10px;
  }

  .order_id .order_sec_id strong {
    font-size: 20px;
    line-height: 40px;
  }

  /* HomeBookTestride-start */

  .to_colors_sec strong {
    font-size: 20px;
    line-height: 40px;
  }

  /* HomeBookTestride-end */
  .popup_slider {
    margin-left: 50px;
  }

  .testride_map .testride_righsec {
    padding: 60px 40px;
  }

  .bike_detailcus {
    margin: 100px 30px 15px;
  }

  .date_show
    .DatePicker_monthContainer__Ay4eF
    .DatePicker_daysContainer__OfZcM
    .DatePicker_dateDayItem__IHL2S {
    font-size: 14px;
    line-height: 22px;
    margin: 0px 5px 0px;
  }

  .date_show
    .DatePicker_container__en6M9
    .DatePicker_buttonWrapper__K15TF
    button.DatePicker_button__SIan2 {
    width: 45px;
    height: 45px;
    font-size: 21px;
  }

  .date_show
    .DatePicker_container__en6M9
    .DatePicker_dateListScrollable__nQSC- {
    margin: 0px 6px;
  }

  .search_bar .search_icon button {
    padding: 16px 13px;
  }

  .search_bar .search_inp {
    font-size: 18px;
    padding: 12px 45px 12px 15px;
  }

  .date_show
    .DatePicker_monthContainer__Ay4eF
    .DatePicker_daysContainer__OfZcM
    .DatePicker_dateDayItem__IHL2S
    .DatePicker_dayLabel__7S2Ec {
    font-size: 14px;
  }

  .date_show
    .DatePicker_monthContainer__Ay4eF
    .DatePicker_daysContainer__OfZcM
    .DatePicker_dateDayItem__IHL2S
    .DatePicker_dateLabel__gumhX {
    font-size: 14px;
  }

  .next_btn.bo_ather .avelabail_home {
    font-size: 22px;
  }

  .requested_popup .pure-modal-backdrop {
    right: 12px;
  }

  .requested {
    padding: 0px 0px;
  }

  .requested h3 {
    font-size: 26px;
  }

  .testride_righsec h4 {
    font-size: 22px;
    line-height: 36px;
  }
}

@media only screen and (max-width: 1440px) {
  .date_time p {
    font-size: 24px;
  }

  .event_cont h4 {
    font-size: 26px;
  }

  .date_time {
    border-radius: 16px;
  }

  .eventdetail_popup .event_cont .event_name .open_info h2 {
    font-size: 24px;
  }

  .eventdetail_popup .event_cont .event_name .open_info {
    padding: 20px 25px;
  }

  .eventdetail_popup .event_cont .event_name h4 {
    font-size: 40px;
    /* max-width: 650px; */
  }

  .event_cont .date_time .aling_cont em {
    min-width: 115px;
  }

  .thak_test {
    left: 20px;
    right: 20px;
  }

  .thanks_testride .testride_righsec {
    height: auto;
  }

  .next_btn.bo_ather .avelabail_home {
    font-size: 20px;
  }

  .bo_ather figure img {
    width: 28px;
  }

  .eventdetail_popup .event_cont .event_name .open_info {
    min-width: 260px;
  }

  .cancel_ride .form-check .form-check-label {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1199px) {
  .testride_map .testride_righsec {
    padding: 60px 40px;
  }

  .bike_detailcus {
    margin: 80px 20px 15px;
  }

  .date_show
    .DatePicker_monthContainer__Ay4eF
    .DatePicker_daysContainer__OfZcM
    .DatePicker_dateDayItem__IHL2S {
    font-size: 14px;
    line-height: 22px;
    margin: 0px 5px 0px;
  }

  .date_show
    .DatePicker_container__en6M9
    .DatePicker_buttonWrapper__K15TF
    button.DatePicker_button__SIan2 {
    width: 35px;
    height: 35px;
    font-size: 17px;
  }

  .date_show .dM button.Kq {
    height: 35px;
    width: 50px;
    margin-top: 45px;
  }

  .date_show
    .DatePicker_container__en6M9
    .DatePicker_dateListScrollable__nQSC- {
    margin: 0px 6px;
  }

  .search_bar .search_icon button {
    padding: 16px 13px;
  }

  .search_bar .search_inp {
    font-size: 18px;
    padding: 12px 45px 12px 15px;
  }

  .date_show .dM ._3n ._1g {
    font-size: 14px;
  }

  .date_show .dM ._3n .Lx {
    font-size: 14px;
  }

  .date_show
    .DatePicker_daysContainer__9htA9
    .DatePicker_dateDayItem__XwRQy
    .DatePicker_dateLabel__KeEIO {
    font-size: 14px;
  }

  .date_show
    .DatePicker_daysContainer__9htA9
    .DatePicker_dateDayItem__XwRQy
    .DatePicker_dayLabel__o2Brk {
    font-size: 14px;
  }

  .date_show
    .DatePicker_monthContainer__Ay4eF
    .DatePicker_daysContainer__OfZcM
    .DatePicker_dateDayItem__IHL2S
    .DatePicker_dayLabel__7S2Ec {
    font-size: 14px;
  }

  .date_show
    .DatePicker_monthContainer__Ay4eF
    .DatePicker_daysContainer__OfZcM
    .DatePicker_dateDayItem__IHL2S
    .DatePicker_dateLabel__gumhX {
    font-size: 14px;
  }

  .date_piker ul li {
    margin: 0px 5px 10px;
  }

  .test_ridestep ul li a {
    font-size: 15px;
  }

  .eventdetail_popup .pure-modal-backdrop {
    left: 0px;
    max-width: 90%;
  }

  .event_cont .date_time {
    padding: 30px 20px;
  }

  .eventdetail_popup .event_cont .event_name h4 {
    font-size: 32px;
  }

  .event_cont .event_booktr .default_btn {
    font-size: 18px;
    padding: 15px 15px;
    border-radius: 8px;
  }

  .date_time p {
    font-size: 22px;
  }

  .bookheding_sec > p {
    font-size: 20px !important;
    margin-bottom: 15px;
  }

  .booktr_slider .slick-arrow {
    height: 30px;
    width: 30px;
  }

  .booktr_slider .slick-arrow::before {
    width: 14px;
    height: 14px;
  }

  .requested_popup .pure-modal-backdrop {
    right: 12px;
  }

  .requested {
    padding: 0px 0px;
  }

  .testride_righsec h4 {
    font-size: 18px;
    line-height: 24px;
  }

  .requested h3 {
    font-size: 24px;
  }

  .requested p {
    font-size: 16px;
  }

  .requested p span {
    font-size: 18px;
  }

  .requested > ul > li {
    font-size: 16px;
  }

  .cancel_ride .form-check .form-check-label {
    font-size: 16px;
  }

  .cancel_ride .form-check {
    margin-bottom: 15px;
  }

  .cancel_ride .form-check input {
    width: 14px;
    height: 14px;
  }

  .booktr_slider .select_dealer {
    width: calc(100% - 15px) !important;
    padding: 15px 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #home .location_error {
    font-size: 14px;
  }
  .booktr_slider .select_dealer .default_btn {
    font-size: 10px;
    padding: 8px 10px;
  }
  .popup_slider {
    margin-left: 50px;
  }

  .dealers_add {
    padding: 15px;
  }

  .logo_right_cont {
    padding: 40px 0px 20px 0px;
  }

  .testride_righsec h3 {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 15px;
  }

  .testride_righsec .form-group .form-control {
    padding: 8px 15px;
    line-height: 28px;
    margin-bottom: 15px;
    border-radius: 12px;
    font-size: 14px;
  }

  .booktest_ride .next_btn {
    margin-top: 20px;
  }

  .next_btn {
    margin-top: 10px;
  }

  .testride_map .testride_righsec {
    padding: 50px 25px;
  }

  .testride_map .testride_righsec::before {
    height: 280px;
    right: -100px;
    top: -80px;
    width: 350px;
  }

  .logo_right_cont p {
    font-size: 14px;
    line-height: 20px;
  }

  .next_btn button.default_btn {
    padding: 12px 15px;
    border-radius: 12px;
    font-size: 16px;
  }

  .booktest_ride .logo_cont_show {
    padding: 0px 25px;
  }

  .date_piker ul li button {
    font-size: 14px;
    line-height: 15px;
    padding: 10px 12px;
  }

  .date_show ul li {
    padding: 0px 10px;
  }

  .date_show ul li button {
    font-size: 12px;
    line-height: 18px;
  }

  .date_show {
    margin-bottom: 25px;
  }

  /* .map_img_add .map_left figure img {
        height: 640px;
        object-fit: cover;
    } */

  .Confirmation_left .after_savings {
    max-width: 650px;
  }

  .after_savings ul {
    margin: 0px 5px;
  }

  .Confirmation_left .after_savings figure img {
    width: 80%;
    margin: auto;
  }

  .Confirmation_left .after_savings figure {
    text-align: center;
  }

  .after_savings ul li p {
    font-size: 14px;
    line-height: 22px;
  }

  .after_savings ul li p span {
    font-size: 18px;
    line-height: 24px;
    padding-right: 5px;
  }

  .after_savings ul li {
    width: 50%;
    padding: 0px 10px;
  }

  .order_id .order_sec_id strong {
    font-size: 18px;
    line-height: 28px;
  }

  .order_id .order_sec_id strong span {
    font-size: 16px;
  }

  .order_id .order_sec_id {
    margin-bottom: 20px;
  }

  /* HomeBookTestride-start */

  .to_colors_sec strong {
    font-size: 18px;
    line-height: 28px;
  }

  .to_colors_sec strong span {
    font-size: 16px;
  }

  /* HomeBookTestride-end */
}

@media only screen and (max-width: 991px) {
  .top_header_pm {
    height: auto;
    min-height: auto;
    scroll-snap-align: inherit;
  }

  .height_one_csrol > #footer {
    height: auto;
    scroll-snap-align: inherit;
  }

  .testride_map .map_left {
    height: auto;
  }

  .date_piker ul li {
    margin: 0px 0px 10px;
  }

  .search_bar .search_icon button {
    padding: 15px 12px;
  }

  .date_time p {
    font-size: 22px;
  }

  .event_cont h4 {
    font-size: 22px;
  }

  .date_time {
    border-radius: 16px;
  }

  .date_time {
    max-width: 90%;
  }

  .payment_secmain .payment_cont {
    position: inherit;
    padding: 220px 20px;
    top: auto;
    transform: none;
    left: 0;
    right: 0;
  }

  #footer {
    height: auto;
  }

  .eventdetail_popup .event_cont .event_name {
    margin: 40px 0px 10px;
  }

  .eventdetail_popup .event_cont .event_name h4 {
    font-size: 30px;
    padding-left: 20px;
  }

  .eventdetail_popup .event_cont .event_name .open_info h2 {
    font-size: 20px;
  }

  .eventdetail_popup .event_cont .event_name .open_info {
    padding: 15px 15px;
  }

  .event_cont .date_time {
    padding: 20px 20px;
    text-align: center;
    border-radius: 20px;
    max-width: 100%;
    margin: auto;
  }

  .event_cont .event_booktr {
    padding: 20px 20px;
    text-align: center;
    max-width: 100%;
    margin: auto;
  }

  .event_cont .date_time .aling_cont {
    justify-content: center;
  }

  .thak_test {
    position: inherit;
    left: 0;
    padding-top: 50px;
  }

  .thak_test p {
    font-size: 20px !important;
  }

  .requested_popup .pure-modal-backdrop {
    height: auto;
    top: 90px;
    transform: none;
    width: 32% !important;
    right: 15px;
  }

  .close_button .close {
    width: 40px;
    height: 40px;
    padding: 4px;
  }

  .close_button .close .close_btn {
    margin-top: 26px;
  }

  .test_ridestep ul li a {
    border-radius: 8px;
  }

  #cancel_testride .pure-modal-backdrop {
    left: calc(32% - 200px);
    width: 400px;
  }

  #service_cancel .pure-modal-backdrop {
    left: calc(32% - 200px);
    width: 400px;
  }

  .booktr_slider .slick-slide > div {
    width: -webkit-fill-available;
  }

  .booktr_slider .select_dealer {
    width: calc(100% - 0px) !important;
    margin: 0px 0px;
    min-height: 130px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #home .location_error {
    font-size: 12px;
  }
  .modal_popup.test_ride_popup
    .pure-modal-backdrop
    .popup_cont
    form
    .ser_mailmo
    .mo_numb
    input {
    padding: 15px 15px 15px 60px;
  }

  .serv_req form .react_form_group .mo_numb textarea {
    height: 150px;
  }

  .booktest_ride .next_btn {
    margin-top: 30px;
  }

  .popup_slider {
    margin-left: 20px;
  }

  .dealers_add {
    padding: 15px;
  }

  .testride_righsec h3 br {
    display: none;
  }

  .logo_right_cont {
    padding: 50px 0px 20px 0px;
  }

  .order_sec_id p.destop_show {
    font-size: 16px;
    line-height: 24px;
  }

  .testride_righsec h3 {
    font-size: 26px;
    line-height: 38px;
    margin-bottom: 15px;
  }

  .testride_righsec .form-group .form-control {
    padding: 8px 15px;
    line-height: 28px;
    margin-bottom: 15px;
    border-radius: 12px;
    font-size: 14px;
  }

  .next_btn {
    margin-top: 10px;
  }

  .testride_map .testride_righsec {
    padding: 50px 25px;
    width: 35%;
  }

  .testride_map .map_left {
    width: 65%;
  }

  .testride_map .testride_righsec::before {
    height: 280px;
    right: -100px;
    top: -80px;
    width: 350px;
  }

  .logo_right_cont p {
    font-size: 14px;
    line-height: 20px;
  }

  .next_btn button.default_btn {
    padding: 12px 15px;
    border-radius: 12px;
    font-size: 16px;
  }

  .booktest_ride .logo_cont_show {
    padding: 0px 25px;
  }

  .date_piker ul li button {
    font-size: 16px;
    line-height: 16px;
  }

  .date_show ul li {
    padding: 0px 10px;
  }

  .date_show ul li button {
    font-size: 12px;
    line-height: 18px;
  }

  .date_show {
    margin-bottom: 25px;
  }

  .booktest_ride .logo_cont_show .logo_right_cont {
    padding-bottom: 20px;
  }

  .logo_right_cont img {
    width: 220px;
    margin: auto;
  }

  .booktest_ride {
    padding: 50px 0px !important;
  }

  /* .map_img_add .map_left figure img {
        height: 640px;
        object-fit: cover;
    } */

  /* .Confirmation_left .after_savings {
        max-width: 600px;
    } */

  .Confirmation_left .after_savings {
    bottom: 70px;
  }

  .after_savings ul {
    margin: 0px 5px;
  }

  .Confirmation_left .after_savings figure img {
    width: 90%;
    margin: auto;
  }

  .Confirmation_left .after_savings figure {
    text-align: center;
  }

  .after_savings ul li p {
    font-size: 14px;
    line-height: 22px;
  }

  .after_savings ul li p span {
    font-size: 18px;
    line-height: 24px;
    padding-right: 5px;
    display: block;
    width: 100%;
  }

  .after_savings ul li {
    width: 50%;
    padding: 0px 10px;
  }

  .order_id .logo_right_cont {
    padding-bottom: 15px;
  }

  .order_id .order_sec_id strong {
    font-size: 18px;
    line-height: 28px;
  }

  .order_id .order_sec_id strong span {
    font-size: 16px;
  }

  .order_id .order_sec_id {
    margin-bottom: 20px;
  }

  /* HomeBookTestride-start */

  .to_colors_sec strong {
    font-size: 15px;
    line-height: 24px;
  }

  .to_colors_sec strong span {
    font-size: 15px;
  }

  /* HomeBookTestride-end */
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .booktr_slider .select_dealer {
    height: 100%;
  }
  .order_id_confirmation,
  .confirmation_booked_location {
    text-align: left !important;
  }
  #home .location_error {
    font-size: 10px;
  }
  .Confirmation .testride_righsec.order_id .test_ridestep ul {
    padding-left: 0px !important;
  }

  /* CANCEL TESTRIDE VIKASH CSS FOR POPUP */
  .requested p {
    color: #fff;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.7px;
    opacity: 0.7;
  }

  .another_test_book_time {
    display: flex;
    flex-direction: column;
  }
  .another_test_book_time span {
    margin: 0px;
    color: #fff;
    opacity: 1;
  }
  #height_one_home_confirmation .test_ridestep ul {
    padding-left: 0px !important;
  }

  #home_Confirmation .requested > ul {
    padding-left: 0px !important;
  }

  #home_Confirmation .requested > ul li {
    list-style-type: none;
  }

  #home_Confirmation .requested > ul li {
    display: flex;
  }

  #home_Confirmation .requested > ul li span {
    display: flex;
    align-items: center;
  }

  #home_Confirmation .requested > ul li span:nth-child(1) {
    margin-right: 15px;
  }

  #home_Confirmation .requested > ul li span:nth-child(1) svg {
    font-size: 20px;
    color: #00eaff;
  }

  #home_Confirmation .requested p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  #home_Confirmation .requested p span {
    display: block;
    color: #ffffff;
    font-weight: 600;
    font-size: 18px;
    line-height: 15px;
  }

  .search_bar .search_icon button svg {
    display: block;
    color: #ffffff;
    width: 15px;
    height: 15px;
  }

  .do_you_cancel {
    color: #fff;
    text-align: center;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.54px;
    margin: 25px 0px 0px 0px;
  }

  .search_bar .search_icon button img {
    display: none;
  }

  .search_bar .search_inp {
    background: rgba(45, 44, 44, 0.35);
    backdrop-filter: blur(15px);
    border-radius: 16px;
    letter-spacing: 0.05em;
    color: rgb(255, 255, 255, 0.6);
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 16px 15px 16px 45px;
  }

  .search_bar .search_icon {
    left: 5px;
    right: inherit;
  }

  .requested p span {
    font-size: 16px;
  }

  .testride_map .graphics_rigth .only_mobileshow {
    padding: 20px 0px 10px 0px;
  }

  .testride_map .graphics_rigth .only_mobileshow img {
    margin-bottom: 15px !important;
    margin: auto;
  }

  .testride_map .graphics_rigth .only_mobileshow h3.mobile_show {
    display: none;
  }

  .testride_map .graphics_rigth h3.mobile_show {
    display: none;
  }

  .logo_right_cont {
    padding: 70px 0px 20px 0px;
  }

  .testride_righsec h3 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 15px;
    font-weight: 500;
    text-align: left;
  }

  .color_book_text {
    color: #00eaff;
  }

  .testride_righsec h4 {
    text-align: left;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.03em;
    color: #ffffff;
  }

  .testride_righsec .form-group .form-control {
    padding: 15px 15px;
    line-height: 24px;
    margin-bottom: 15px;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid rgb(255 255 255 / 25%);
  }

  .testride_righsec .form-group ::placeholder {
    color: rgb(255 255 255 / 60%);
  }

  .next_btn {
    margin-top: 10px;
  }

  .testride_map .testride_righsec {
    padding: 40px 24px;
    width: 100%;
  }

  .testride_map .map_left {
    display: none;
  }

  .Confirmation.graphics_sec .map_left.Confirmation_left figure {
    display: none;
  }

  .Confirmation.graphics_sec .map_left.Confirmation_left .after_savings {
    display: block !important;
    position: relative;
    bottom: 5px;
  }

  .Confirmation.graphics_sec .map_left.Confirmation_left .after_savings figure {
    display: block;
  }

  .graphics_sec .testride_righsec.graphics_rigth .only_mobileshow.mobile_show {
    display: none;
  }

  .graphics_sec .Confirmation_left {
    padding: 100px 20px 5px 20px;
    position: inherit;
  }

  .graphics_sec .testride_righsec.graphics_rigth::before {
    display: none;
  }

  .graphics_sec .Confirmation_left .after_savings figure.gra_logo {
    max-width: 300px;
    margin: auto !important;
  }

  .graphics_sec .Confirmation_left .after_savings figure img {
    margin: auto;
    margin-bottom: 15px !important;
  }

  .graphics_sec .testride_righsec.graphics_rigth {
    background: #000000a1;
    margin: 0px 20px 40px;
    z-index: 8;
    position: relative;
    padding: 15px;
    border-radius: 10px;
  }

  #graphics .testride_map.graphics_sec {
    position: relative;
    height: auto;
  }

  #graphics .testride_map.graphics_sec::after {
    background: linear-gradient(211deg, #2a63a3, #2a63a3);
    border-radius: 0% 80% 0% 0%;
    content: "";
    filter: blur(50px);
    height: 350px;
    position: absolute;
    left: -250px;
    bottom: -50px;
    width: 500px;
    z-index: 1;
  }

  .testride_map .testride_righsec::before {
    height: 330px;
    right: -150px;
    top: -100px;
    width: 400px;
  }

  .logo_right_cont p {
    font-size: 14px;
    line-height: 20px;
  }

  .next_btn button.default_btn {
    padding: 11px 15px;
    border-radius: 12px;
    font-size: 18px;
  }

  .destop_show {
    display: none;
  }

  .mobile_show {
    display: block;
  }

  .only_mobileshow {
    padding: 80px 0px 0px 0px;
  }

  .testride_map.Confirmation .only_mobileshow {
    width: 100%;
  }

  .testride_map.Confirmation .only_mobileshow img {
    margin: 0px;
    width: 100%;
    margin-bottom: 0px !important;
  }

  .only_mobileshow img {
    margin-bottom: 20px !important;
    margin: auto;
  }

  .only_mobileshow h3.mobile_show {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 0;
  }
  .Confirmation .only_mobileshow h3.mobile_show {
    text-align: center;
    margin-top: 15px;
  }

  .top_header_pm:before {
    z-index: 0;
  }

  .next_btn .mobile_show {
    margin-top: 120px;
  }

  .booktest_ride .logo_cont_show {
    padding: 0px 24px;
  }

  .date_show ul li {
    padding: 0px 5px;
  }

  .date_show ul li button {
    font-size: 15px;
    line-height: 24px;
  }

  .date_show {
    margin-bottom: 30px;
  }

  .booktest_ride .logo_cont_show .logo_right_cont {
    padding-bottom: 20px;
  }

  .booktest_ride {
    padding: 40px 0px !important;
  }

  .popup_slider {
    margin-right: 0;
    margin-left: 0;
  }

  .dealers_add {
    padding: 32px 20px;
  }

  .order_id .order_sec_id strong {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }

  .order_id .order_sec_id strong span {
    font-weight: 400;
    font-size: 16px;
  }

  /* HomeBookTestride-start */

  .to_colors_sec strong {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }

  .to_colors_sec strong span {
    font-weight: 400;
    font-size: 16px;
  }

  /* HomeBookTestride-end */

  /* payment -start */

  .payment_secmain::before {
    height: 200px;
    right: -80px;
    top: -80px;
    width: 200px;
  }

  .payment_secmain .payment_cont h2 {
    font-size: 22px;
    line-height: 38px;
  }

  .payment_secmain::after {
    height: 200px;
    left: -80px;
    bottom: -80px;
    width: 200px;
  }

  /* payment -end */
  .Order_Confirmation .map_left.Confirmation_left {
    display: none;
  }

  .mobile_show img {
    margin: auto;
  }

  .height_one_csrol > #footer {
    height: auto;
  }

  .date_show .DatePicker_buttonWrapper__yp7Go button {
    height: 35px;
    width: 50px;
    margin-top: 45px;
  }

  .date_show .DatePicker_dateDayItem__9UMy1 .DatePicker_dateLabel__M0yv4 {
    font-size: 14px;
  }

  .date_show .DatePicker_dateDayItem__9UMy1 .DatePicker_dayLabel__3sg5q {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .appointment_popup .pure-modal-backdrop {
    /* margin: 0% auto !important; */
    margin: 0px auto !important;
    height: fit-content !important;
    top: 8% !important;
  }
  .appointment_popup .pure-modal-backdrop .close {
    right: -25px !important;
    top: -35px !important;
  }

  .bike_detailcus {
    margin: 60px 15px 15px;
  }

  .displeymobile_none {
    display: none;
  }

  .booktest_ride .next_btn {
    margin-top: 30px;
  }

  .book_map .booktest_ride {
    padding-bottom: 40px !important;
  }

  .date_piker ul li {
    /* width: calc(50% - 20px); */
    margin: 0px 10px 20px;
  }

  .event_cont {
    transform: none;
    top: 0;
    padding-top: 0px;
    display: block;
  }

  .event_cont .open_info {
    width: 100%;
    padding: 15px 15px;
  }

  .event_cont .open_info h2 {
    font-size: 24px;
  }

  .event_cont h4 {
    font-size: 20px;
  }

  .date_time {
    max-width: 100%;
    width: 100%;
    padding: 15px 15px;
  }

  .date_time p {
    font-size: 18px;
  }

  .booktr_slider .select_dealer strong {
    text-align: center;
  }

  .booktr_slider .select_dealer p {
    text-align: center;
  }

  .eventdetail_popup .pure-modal-backdrop::before {
    display: none;
  }

  #book-test-ride .eventdetail_popup .pure-modal-backdrop::after {
    filter: blur(0px) !important;
  }

  .eventdetail_popup .pure-modal-backdrop {
    left: 0;
    max-width: 90%;
  }

  .eventdetail_popup .event_cont .event_name {
    margin: 20px 0px 10px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .eventdetail_popup .event_cont .event_name h4 {
    font-size: 20px;
    max-width: 100%;
    padding-left: 0;
    text-align: center;
    padding: 0px 15px 10px !important;
  }

  .eventdetail_popup .event_cont .event_name .open_info {
    padding: 10px 15px;
    border-radius: 0;
    min-width: 100%;
    margin: 0;
  }

  .event_cont .date_time {
    padding: 15px 15px;
    border-radius: 0px;
  }

  .Confirmation_left .after_savings {
    padding: 40px;
  }

  .close_button .close {
    width: 40px;
    height: 40px;
    padding: 5px;
  }

  .close_button .close {
    right: 15px !important;
  }

  .event_cont .event_booktr {
    width: 100%;
    margin-bottom: 20px;
  }

  .date_time p em {
    display: block;
    margin-bottom: 10px;
  }

  .event_cont .date_time .aling_cont {
    display: block;
  }

  .event_cont .date_time .aling_cont em {
    min-width: auto;
    padding: 0px;
  }

  .order_sec_id {
    margin-bottom: 25px;
    text-align: center;
  }

  .date_piker ul li button {
    font-size: 16px;
  }

  .test_ridestep ul li a {
    font-size: 16px;
  }

  .requested_popup .pure-modal-backdrop {
    width: 90% !important;
    right: 0;
    margin: auto;
  }

  .requested h3 {
    color: #fff;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.54px;
    margin-bottom: 15px;
  }

  #cancel_testride .pure-modal-backdrop::after,
  #cancel_testride .pure-modal-backdrop::before {
    display: none;
  }

  #service_cancel .pure-modal-backdrop::after,
  #service_cancel .pure-modal-backdrop::before {
    display: none;
  }

  #cancel_testride .pure-modal-backdrop {
    width: 90% !important;
    top: 0px;
    transform: none;
    left: 0;
    margin: auto;
    overflow: initial;
    border: none;
    background: transparent;
  }

  #service_cancel .pure-modal-backdrop {
    /* width: 90% !important; */
    top: 0px;
    transform: none;
    left: 0;
    margin: auto;
    overflow: initial;
    border: none;
    background: transparent;
  }

  #cancel_testride .pure-modal-backdrop .pure-modal {
    overflow: initial;
    border: 1px solid #4b4949;
    background: #000;
    border-radius: 20px;
    padding: 20px 0px;
    height: auto !important;
  }

  #service_cancel .pure-modal-backdrop .pure-modal {
    overflow: initial;
    border: 1px solid #4b4949;
    background: #000;
    border-radius: 20px;
    padding: 20px 0px;
    height: auto !important;
  }

  .close_button .close {
    right: -10px !important;
    top: -25px !important;
  }

  /* #cancel_testride .requested h3 {
        padding-right: 60px;
        text-align: left;
    } */

  #cancel_testride .requested {
    padding: 5px 15px;
  }
  #cancel_testride .requested.previous_ride {
    text-align: left;
  }
  #cancel_testride .requested.previous_ride .book_anpo {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }

  #service_cancel .requested {
    padding: 5px 15px;
  }

  div#book-test-ride .select_dealer .map_left {
    width: 100%;
  }

  div#book-test-ride .select_dealer .testride_righsec {
    width: 100%;
  }

  .booktr_slider .select_dealer {
    min-height: auto;
  }
}

@media only screen and (max-width: 575px) {
  .date_piker ul li {
    /* width: calc(100% - 0px); */
    margin: 0px 0px 20px;
  }
  .sure_cncel_popup.close_btn::before .sure_cncel_popup.close_btn::after {
    display: block !important;
  }
}
@media only screen and (max-width: 500px) {
  .sure_cncel_popup.close_btn::before .sure_cncel_popup.close_btn::after {
    display: block !important;
  }
  /* vikash css - 17-10-23 for login page popup  start*/

  .login_popup_detail .close_btn::before,
  .login_popup_detail .close_btn::after {
    display: block !important;
  }
  .login_popup_detail .pure-modal-backdrop {
    height: 100vh;
  }
  /* vikash css - 17-10-23 for login page popup  end*/
}
