/* .vieew_all_btn button{
    font-size: 14px;
    color: #fff;
    background-color: #183883;
    padding: 15px 30px;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 0;
    margin: 10px 0;
    transition: all .3s;
    font-weight: 500;
    border: solid 1px transparent;
}
.vieew_all_btn button:hover{
    color: #183883;
    border: solid 1px #183883;
    background-color: #ffffff;
} */
.vieew_all_btn{
    display: flex;
    justify-content: center;
    margin-top: 40px;
}


@media screen and (max-width:767px) {
    .vieew_all_btn button{
        padding: 10px 30px;
        font-size: 16px;
        
    }   
}