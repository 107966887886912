/* section 1 css */

.sec_1_content{
    background-color: #0a0a0a;
    overflow: hidden;
    position: relative;
    padding-top:100px;
    width: 100%;
}

.sec_1_content .container_about_us {
    padding: 76px 0 0 0;
}

.sec_1_content:before {
    background: linear-gradient(211deg,#14204e,#14204e);
    border-radius: 50%;
    content: "";
    -webkit-filter: blur(60px);
    filter: blur(60px);
    height: 650px;
    position: absolute;
    right: -400px;
    top: -400px;
    width: 650px;
    z-index: 0;
}
.sec_1_content:after {
    background: linear-gradient(211deg,#2a63a3,#2a63a3);
    border-radius: 50% 80% 0 0;
    bottom: -450px;
    content: "";
    -webkit-filter: blur(80px);
    filter: blur(80px);
    height: 650px;
    left: -450px;
    position: absolute;
    width: 650px;
    z-index: 0;
}

.sec_1_content .text-block {
    width: 100%;
    max-width: 1160px;
    margin: 0px auto 100px auto;
}

.sec_1_content .text-block h3{
    color: #fff;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 30px;
    font-family: Lato, sans-serif;
    font-weight: 500;
    line-height: 54px;
    text-transform: uppercase;
    margin-bottom: 20px;
    text-align: center;
}

.sec_1_content .text-block span{
    color: #00EAFF;;
    font-family: Lato, sans-serif;
    font-weight: 700;
    line-height: 54px;
    text-transform: uppercase;
}

.sec_1_content .text-block .sm-description{
    color: rgba(255, 255, 255, 0.60);
    text-align: center;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 24px;
    font-family: Lato, sans-serif;
    font-weight: 500;
    line-height: 150%;
    text-transform: inherit;
    width: 100%;
    max-width: 1125px;
}

.bike_drawing_img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* media query */

@media screen and (max-width:1200px) {
    .sec_1_content .text-block h3{
        font-size: 26px;
        line-height: normal;
    }
}

@media only screen and (max-width: 767px){
    .sec_1_content .container_about_us {
        padding: 0px 16px 0 16px !important;
    }
    
    .sec_1_content .text-block h3 {
        font-size: 18px;
        line-height: 36px;
        text-align: left;
    }

    .sec_1_content .text-block span{
        line-height: 30px;
    }
    .sec_1_content .text-block .sm-description{
        font-size: 18px;
        line-height: 160%;
        text-align: left;
    }

    .sec_1_content .text-block{
        width: 100%;
        margin: 0px auto 20px auto;
        /* text-align: center; */
    }

    .sec_1_content:before, .sec_1_content:after{
        height: 530px;
        width: 600px;
    }
}