#new_lead_gen{
    background-color: #fff;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
    position: relative;
}
#new_lead_gen ~ #footer{
    display: none;
}
#new_lead_gen .main-content-sec{
    position: relative;
    height: auto;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
}
#new_lead_gen .main-content-sec .content{
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    min-height: 100vh;
}
#new_lead_gen .main-content-sec .left-sec{
    width: 66.66%;
}
#new_lead_gen .main-content-sec .left-sec img{
    width: 100%;
}
#new_lead_gen .main-content-sec .right-sec{
    width: 33.33%;
    padding: 30px 20px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
}
#new_lead_gen .right-sec .heading h2{
    font-size: 20px;
    color: #212529;
    font-weight: 600;
    margin-bottom: 8px;
}
#new_lead_gen .right-sec .heading p{
    font-size: 16px;
    color: #212529;
    margin-bottom: 10px;
}
#new_lead_gen form {
    padding: 25px 0px;
}
#new_lead_gen form .form_input{
    width: 100%;
    padding: 0px;
    position: relative;
    margin-bottom: 25px;
}
#new_lead_gen form .form_input legend{
    margin: 0px;
    font-size: 16px;
    color: #212529;
    line-height: 20px;
    padding: 0px;
    width: 100%;
    margin-bottom: 8px;
}
#new_lead_gen form .form_input legend::after{
    display: none;
}
#new_lead_gen form .form_input input{
    margin: 0px;
    padding: 10px 15px;
    border: 1px solid #ced4da;
    font-weight: 500;
    line-height: 1.5;
    color: #212529;
    font-size: 16px;
    border-radius: 5px;
    min-height: 58px;
}
#new_lead_gen form .form_input input:focus{
    border-color: #86b7fe;
}
#new_lead_gen form .form_input span.error{
    position: relative;
    left: 0;
    font-size: 14px;
    bottom: inherit;
}
#new_lead_gen form .form_input span.success{
    position: relative;
    left: 0;
    font-size: 14px;
    bottom: inherit;
    color: green;
}
#new_lead_gen form .form_input select{
    margin: 0px;
    padding: 10px 15px;
    border: 1px solid #ced4da;
    font-weight: 500;
    line-height: 1.5;
    color: #212529;
    font-size: 16px;
    border-radius: 5px;
    min-height: 58px;
    width: 100%;
}
#new_lead_gen form .form_input select:focus{
    outline: none;
    border-color: #86b7fe;
}
#new_lead_gen form .form_input ul{
    display: flex;
    padding-top: 5px;
    flex-wrap: wrap;
}
#new_lead_gen form .form_input ul li{
    padding: 8px 15px;
    background-color: #ccc;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    display: inline-block;
    margin-bottom: 10px;
    height: 100%;
    cursor: pointer;
    transition: all 0.3s ease-in;
}
#new_lead_gen form .form_input ul li.active{
    background-color: #0E1F4B;
    color: #fff;
}
#new_lead_gen form .form_input ul li:last-child {
    margin-bottom: 0px;
}
#new_lead_gen form .btn_sec{
    margin-top: 0px;
}
#new_lead_gen form .btn_sec button{
    background-color: #0e1f4b;
    color: #fff;
    padding: 15px 30px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    width: 100%;
    transform: none;
    margin-top: 20px;
}
#new_lead_gen form .btn_sec button[disabled]{
    opacity: 0.5;
    pointer-events: none;
}
#new_lead_gen form .form_input .requestOtp{
    width: auto;
    position: absolute;
    right: 10px;
    top: 40px;
    padding: 6px 10px;
    font-size: 14px;
    background-color: #0e1f4b;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}


@media only screen and (max-width: 991px) {
    #new_lead_gen .main-content-sec .left-sec {
        width: 60%;
    }
    #new_lead_gen .main-content-sec .right-sec{
        width: 40%;
        padding: 20px 15px;
    }
    #new_lead_gen form {
        padding: 15px 0px;
    }
    #new_lead_gen form .foem_cus{
        max-width: 100%;
    }
    #new_lead_gen form .form_input legend{
        font-size: 15px;
    }
    #new_lead_gen form .form_input input{
        min-height: 52px;
        padding: 10px;
    }
    #new_lead_gen form .form_input span.error,
    #new_lead_gen form .form_input span.success{
        font-size: 13px;
    }
    #new_lead_gen form .form_input .requestOtp {
        right: 5px;
        top: 40px;
        padding: 5px 8px;
        font-size: 12px;
        border-radius: 3px;
    }
    #new_lead_gen form .form_input{
        margin-bottom: 20px;
    }
    #new_lead_gen form .btn_sec button{
        margin-top: 10px;
    }
}


@media only screen and (max-width: 767px) {
    #new_lead_gen .main-content-sec .left-sec,
    #new_lead_gen .main-content-sec .right-sec {
        width: 100%;
    }
}