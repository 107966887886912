.faq_page {
	background-color: #060606;
	padding-bottom: 50px;
}

.faq-block .heading-4 {
	color: #00eaff;
	font-size: 20PX;
	text-transform: capitalize;
	margin: 0.2em 0 0 0;
	font-weight: 700;
	padding: 8px 0px;
	border-bottom: 1px dotted #00eaff;
	display: inline-block;
}

.faq-block {
	margin-bottom: 20px;
	padding-top: 2px;
}

/* offset space */

.faq-block::before {
	content: "";
	display: none;
	height: 60px;
	margin: -60px 0 0;
}

.faq_right_sec p {
	font-size: 16px;
}

.faq_right_sec p,
ul>li {
	line-height: 1.5;
	color: #bbb;
}

.faq_page .faq_sec {
	display: flex;
	box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgb(239 239 239 / 12%) 0px 2px 16px 0px;
	border-radius: 2%;
	padding: 20px 30px;
	height: 100vh;
}

.faq_page .faq_sec::before,
.faq_page .faq_sec::after {
    display: none;
}

.faq_sec .heading-3 {
	font-size: 24px;
	text-transform: capitalize;
	margin: 0.2em 0 0.5em 0;
	font-weight: 700;
	padding: 12px 10px;
	backdrop-filter: blur(30px);
	background: rgba(0, 234, 255, .1);
	border: 1px solid #00eaff;
	color: #00eaff;
	border-radius: 5px;

}

.faq-inner {
	padding: 10px 0px 10px 20px;
}

.faq_sec .faq-block h3 {
	font-size: 15px;
	line-height: 1.5;
	margin-bottom: 0.5rem;
	font-weight: 900;
	color: #fff;
}

.faq_right_sec {
	margin-top: 20px;
	scroll-behavior: smooth;
}

.faq_right_sec::-webkit-scrollbar {
	display: none;
}

.left-headers {
	display: flex;
	flex-direction: column;
	position: sticky;
	height: calc(100vh);
	overflow-y: auto;
	margin-top: 20px;
}

.faq_sec .left-headers a {
	color: #fff;
	font-size: 16px;
	border: 1px dotted #00e1f740;
	display: block;
	border-bottom: 0;
	border-left: 0;
	border-right: 0;
	padding: 10px;
}

/* banner section */

.faq-section-banner {
	padding: 180px 20px 80px;
	background-image: url("../../../assets/images/faq_bg_logo.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: inherit;
	box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgb(239 239 239 / 21%) 0px 2px 16px 0px;

}

.text-white {
	color: #fff !important;
}

.heading-top {
	font-size: 40px;
	text-transform: uppercase;
	margin-bottom: 1.5rem;
	font-weight: 800;
	line-height: 1;
}

.faq-section-banner>.faq_container.top-section>p {
	margin: 20px 0 50px;
	line-height: 1.5;
}

.faq-section-banner>.faq_container.top-section>p a {
	color: #46bcc7;
	padding: 0 0 0 6px;
}

.breadcrumb-section {
	width: 100%;
}

.breadcrumbs {
	padding: 10px 20px;
}

.breadcrumbs ul {
	display: flex;
	padding: 0;
	margin: 0 0 1rem 0;
}

.breadcrumbs ul li:nth-child(1)::after {
	content: ">";
	margin-left: 5px;
	color: #fff;
}

.breadcrumbs ul li a {
	color: #fff;
	text-decoration: none;

}

.breadcrumbs ul li {
	margin: 0 10px 0 0;
	display: flex;
	list-style: none;
}

.dropdown-mob .dropdown-span {
	display: none;
}


/* responsive start */

@media only screen and (max-width: 767px) {
	/* .faq_header .faq-block{
		margin-top: 100px;
	} */
	

	.faq-block::before {
		content: "";
		display: block;
		height: 60px;
		margin: -60px 0 0;
	}

	.faq_page .faq_sec .faq-block .faq-inner h3 {
		font-size: 15px;
	}

	.faq_page .faq_sec .faq-block .faq-inner p {
		font-size: 14px;
	}

	.faq_page .faq_sec .left-headers a {
		color: #060606;
		border: 1px dotted #00000059;
		border-bottom: 0;
		border-left: 0;
		border-right: 0;
	}

	.left-headers {
		display: none;
	}

	.faq_page .faq_sec {
		display: block;
        padding:20px 0px;
	}

	.faq_left_sec,
	.faq_right_sec {
		max-width: 100%;
	}

	.faq_right_sec {
		padding-left: 0 !important;
		margin-top: 15px;
	}

	.faq_page .faq_sec .heading-3 {
		font-size: 22px;
	}
    .faq-inner {
        padding: 10px 0px 10px 0px;
    }

	/* banner section */

	.faq-section-banner {
		padding: 100px 15px 50px;
	}

	.heading-top {
		font-size: 22px;
	}

	.faq-section-banner>.faq_container.top-section>p {
		margin-bottom: 20px;
	}

	.faq_container.bread,
	.faq_container.top-section {
		padding: 0;
	}

	/* mob */

	.faq_left_sec {
		height: auto;
	}

	.dropdown-mob {
		position: sticky;
		position: -webkit-sticky;
		top: 0;
		z-index: 1;
		padding: 0;
		background: #fff;
		margin-bottom: 15px;
	}

	.dropdown-mob .dropdown-list {
		position: absolute;
		background: #fff;
		z-index: 1;
		width: 100%;
		left: 0;
		top: 45px;
		right: 0;
		margin: 0 auto;
		height: 250px;
		overflow-y: auto;
		box-shadow: 0 10px 20px rgb(0 0 0 / 15%);
		border-top: 1px solid #f7f7f7;
		border-bottom: 1px solid #f7f7f7;
	}

	.dropdown-mob .dropdown-span {
		width: 100%;
		display: block;
		padding: 15px 10px;
		background: #fff;
		position: relative;
		padding-right: 30px;
		box-shadow: 0 10px 20px rgb(0 0 0 / 5%);
		box-sizing: border-box;
	}

	.dropdown-mob .dropdown-span:after {
		content: " \203A";
		color: #00EAFF;
		position: absolute;
		top: 50%;
		transform: translateY(-50%) rotateZ(90deg);
		right: 10px;
		font-size: 30px;
		transition: all 0.3s linear;
	}

	.left-headers>a.active {
		background: #00EAFF;
		color: #060606;
		transition: all 0.3s linear;
	}

}

@media only screen and (max-width: 991px) {


	.faq_page .faq_sec {
		margin: 0;
		box-shadow: none;
	}

	.faq_right_sec {
		padding-left: 20px;
	}

	.faq_sec .heading-3 {
		font-size: 24px;
		margin-bottom: 0.5em;
	}

	/* banner section */

	.heading-top {
		font-size: 34px;
	}
}

@media screen and (min-width: 768px) {
	.left-headers {
		display: block !important;
	}
}