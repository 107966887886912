/* / DatePicker_container__+oJC- /
/ DatePicker_buttonWrapper__E1qmG /
   / DatePicker_button__x4H0C /
/ DatePicker_dateListScrollable__K9OVC /
   / DatePicker_monthContainer__1xKNs /
      / DatePicker_monthYearLabel__0s193 /
      / DatePicker_daysContainer__9Rmuk /
         / DatePicker_dateDayItem__1sMFj /
            / DatePicker_dayLabel__9M9IJ /
            / DatePicker_dateLabel__zttZi-llg /
/ DatePicker_buttonWrapper__E1qmG / */

  .DatePicker_dateDayItem__1sMFj {
    font-weight: bold !important;
    width: 45px !important;
    height: 45px !important;
    border-top: 0px solid blue !important;
    border-right: 0px solid blue !important;
    border-left: 0px solid blue !important;
    border-radius: 0px !important;
    color: white !important;
  }
  
  .date_show .DatePicker_daysContainer__9Rmuk .DatePicker_dateDayItem__1sMFj {
    color: #fff;
  }
  
  .date_show
    .DatePicker_daysContainer__9Rmuk
    .DatePicker_dateDayItem__1sMFj
    .DatePicker_dateLabel__zttZi-llg {
    line-height: 15px;
  }
  
  .date_show
    .DatePicker_daysContainer__9Rmuk
    .DatePicker_dateDayItem__1sMFj
    .DatePicker_dayLabel__9M9IJ {
    text-transform: uppercase;
  }
  
  .date_show
    .DatePicker_monthContainer__1xKNs
    .DatePicker_daysContainer__9Rmuk
    .DatePicker_dateDayItem__1sMFj {
    font-family: "Lato";
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    height: 56px !important;
    text-align: center;
    text-transform: capitalize;
    color: #fff !important;
    margin: 0px 5px 0px;
    flex-direction: column-reverse;
    border-radius: 0px !important;
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    border-bottom: 2px solid #000;
  }
  
  .date_show
    .DatePicker_monthContainer__1xKNs
    .DatePicker_daysContainer__9Rmuk
    .DatePicker_dateDayItem__1sMFj
    .DatePicker_dayLabel__9M9IJ {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 4px;
  }
  
  
  
  .date_show
    .DatePicker_container__+oJC-
    .DatePicker_dateListScrollable__K9OVC {
    margin: 0px 10px;
  }
  
  .date_show
    .DatePicker_container__+oJC-
    .DatePicker_buttonWrapper__E1qmG
    button.DatePicker_button__x4H0C {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    background: rgb(47 47 47) !important;
    border: 1px solid rgb(100 100 100 / 69%);
  }
  
  .date_show
    .DatePicker_container__+oJC-
    .DatePicker_dateListScrollable__K9OVC
    .DatePicker_daysContainer__9Rmuk {
    margin-left: 0px !important;
  }
  
  .date_show
    .DatePicker_container__+oJC-
    .DatePicker_buttonWrapper__E1qmG
    button.DatePicker_button__x4H0C {
    width: 45px;
    height: 45px;
    font-size: 21px;
  }
  .DatePicker_button__x4H0C {
    width: 40px !important;
    height: 40px !important;
    margin-bottom: 10px !important;
  }
  .DatePicker_dateListScrollable__K9OVC {
    margin: unset !important;
  }
  .date_show
    .DatePicker_container__+oJC-
    .DatePicker_dateListScrollable__K9OVC {
    margin: 0px 6px;
  }
  
  .date_show
    .DatePicker_container__+oJC-
    .DatePicker_buttonWrapper__E1qmG
    button.DatePicker_button__x4H0C {
    width: 35px;
    height: 35px;
    font-size: 17px;
  }
  
  .date_show
    .DatePicker_container__+oJC-
    .DatePicker_dateListScrollable__K9OVC {
    margin: 0px 6px;
  }
  
  
  .date_show .DatePicker_monthContainer__1xKNs .DatePicker_monthYearLabel__0s193 {
    display: none;
  }
  
  .date_show .DatePicker_monthContainer__1xKNs .DatePicker_monthYearLabel__0s193 {
    display: none;
  }
  .date_show
    .DatePicker_dateListScrollable__K9OVC
    span.DatePicker_monthYearLabel__0s193 {
    color: rgb(0, 234, 255);
    margin: 0;
  }
  
  .date_show span.DatePicker_monthYearLabel__0s193 {
    color: rgb(0, 234, 255);
    margin-left: 0px;
  }
  
  .date_show .DatePicker_dateDayItem__1sMFj .DatePicker_dateLabel__zttZi-llg {
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
  }
  
  .date_show .DatePicker_dateDayItem__1sMFj .DatePicker_dayLabel__9M9IJ {
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
  }
  
  .date_show .DatePicker_dateDayItem__1sMFj {
    background-color: transparent;
    flex-direction: column-reverse;
    border-radius: 0px !important;
    margin: 0px 5px;
    border-bottom: -1px solid red;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
  }
  
  .date_show
    .DatePicker_monthContainer__1xKNs
    .DatePicker_daysContainer__9Rmuk
    .DatePicker_dateDayItem__1sMFj
    .DatePicker_dateLabel__zttZi-llg {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
  }
  
  .date_show
    .DatePicker_container__+oJC-
    .DatePicker_dateListScrollable__K9OVC
    .DatePicker_daysContainer__9Rmuk {
    margin-left: 0px !important;
  }
  
  .date_show .DatePicker_daysContainer__9Rmuk {
    margin-left: 0px !important;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .date_show .DatePicker_dateDayItem__1sMFj .DatePicker_dateLabel__zttZi-llg {
      font-size: 14px;
    }
  
    .date_show .DatePicker_dateDayItem__1sMFj .DatePicker_dayLabel__9M9IJ {
      font-size: 14px;
    }
  }
  