.community_music .community_music_chipsSec {
    display: flex;
    gap: 40px;
    padding: 20px 0px 0px;
}

.community_music .community_music_chipsSec .right_video_sec{
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 30%;
}

.community_music .community_music_chipsSec .left_video_sec{
   width: 70%;
}
.community_music .video_img_card .content_card h6{
    padding: 5px 0px 5px 0px;
    font-size: 18px;
}

@media screen and (max-width:767px) {
    .community_music .community_music_chipsSec{
        flex-direction: column;
        gap: 10px;
    }
    .community_music .community_music_chipsSec .right_video_sec{
        width: 100%;
    }
    
    .community_music .community_music_chipsSec .left_video_sec{
       width: 100%;
    }
    .community_music .video_img_card .content_card h6{
        font-size: 16px;
        margin-bottom: 0px;
    }
}