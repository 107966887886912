 .popup_slider .slick-slider .slick-list .slick-track {
     display: flex;
     align-items: stretch;
 }

 .popup_slider .slick-slider .slick-list .slick-track .slick-slide {
     height: auto;
 }

 .popup_slider .slick-slider .slick-list .slick-track .slick-slide div {
     height: 100%;
 }

 .step_part ul {
     padding: 0;
     margin: 0;
 }

 .right_sec .step_part ul {
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .right_sec .step_part ul li {
     width: calc(33% - 10px);
     margin: 0px 5px;
     text-align: center;
 }

 .right_sec .step_part ul li span {
     height: 22px;
     width: 22px;
     text-align: center;
     outline: 1px solid #00EAFF;
     outline-offset: 8px;
     background: #000000;
     display: block;
     border-radius: 50%;
     margin: auto;
     margin-bottom: 20px;
 }

 .right_sec .step_part ul li:first-child span {
     background: #00EAFF;
 }

 .right_sec .step_part ul li {
     position: relative;
     z-index: 0;
 }

 .right_sec .step_part ul li::before {
     content: "";
     background-color: #000;
     height: 2px;
     width: 100%;
     position: absolute;
     top: 10px;
     z-index: -1;
 }

 .right_sec .step_part ul li:first-child:after {
     content: "";
     background-color: #00EAFF;
     height: 2px;
     width: 100%;
     position: absolute;
     top: 10px;
     z-index: -1;
     width: 50%;
 }

 .right_sec .step_part ul li:last-child:before {
     display: none;
 }

 .right_sec .step_part ul li p {
     font-size: 16px;
     color: #9ca0a3;
     font-weight: 900;
     letter-spacing: 1px;
     margin-top: 0px;
     font-family: 'Lato', sans-serif;
 }

 .step_part {
     margin-bottom: 40px;
 }

 .form-group select {
     border: 1px solid #00EAFF;
     background: #060b11;
     padding: 17px 20px;
     display: inline-block;
     width: 100%;
     border-radius: 16px;
     font-size: 16px;
     color: #9ca0a3;
     font-weight: 900;
     letter-spacing: 1px;
     font-family: 'Lato', sans-serif;
 }

 .form_sec .form-group {
     margin-bottom: 32px;
 }

 .form_sec .form-group:last-child {
     margin-bottom: 0px;
 }

 .form_sec {
     margin: 0px 45px;
 }

 .form-group .css-1s2u09g-control {
     background: #060b11;
     border: 1px solid #060b11;
     border-radius: 16px;
     padding: 9px 15px;
     box-shadow: none;
     cursor: pointer;
 }

 .form-group .css-1pahdxg-control {
     background: #060b11;
     border: 1px solid #50ebff;
     border-radius: 16px;
     padding: 9px 15px;
     box-shadow: none;
 }

 .form-group .css-1s2u09g-control #react-select-5-placeholder {
     font-size: 16px;
     color: #9ca0a3;
     font-weight: 900;
     letter-spacing: 1px;
     font-family: 'Lato', sans-serif;
 }

 .form-group .css-b62m3t-container .css-1s2u09g-control .css-319lph-ValueContainer .css-qc6sy-singleValue {
     font-size: 16px;
     color: #9ca0a3;
     font-weight: 900;
     letter-spacing: 1px;
     font-family: 'Lato', sans-serif;
 }

 .form-group .css-1pahdxg-control .css-319lph-ValueContainer .css-qc6sy-singleValue {
     font-size: 16px;
     color: #9ca0a3;
     font-weight: 900;
     letter-spacing: 1px;
     font-family: 'Lato', sans-serif;
 }

 .form-group input {
     font-size: 16px;
     color: #9ca0a3;
     font-weight: 900;
     letter-spacing: 1px;
     font-family: 'Lato', sans-serif;
 }

 .form_sec .form-group span.css-1okebmr-indicatorSeparator {
     display: none;
 }

 .form_sec .form-group .css-tlfecz-indicatorContainer {
     color: #00eafd;
     border-color: #50ebff;
 }

 .form_sec .form-group .css-1s2u09g-control:hover {
     border: 1px solid #50ebff;
 }

 .form-group .css-1pahdxg-control:hover {
     border: 1px solid #50ebff;
     border-color: #50ebff;
     box-shadow: none;
 }

 .form-group .css-1hb7zxy-IndicatorsContainer .css-tlfecz-indicatorContainer {
     color: #50ebff;
 }

 /* date-start */
 .form-group .react-datepicker-wrapper .react-datepicker__input-container svg {
     position: absolute;
     right: 28px;
     color: #01e9fd;
     font-weight: 800;
     font-size: 18px;
     top: 20px;
 }

 .form-group .react-datepicker-wrapper .react-datepicker__input-container label {
     display: block;
     padding: 15px 20px;
     border-radius: 16px;
     outline: none;
     background: #000;
     border: 1px solid #000;
     width: 100%;
     transition: all 0.3s;
     font-size: 16px;
     color: #9ca0a3;
     font-weight: 900;
     letter-spacing: 1px;
     font-family: 'Lato', sans-serif;
     cursor: pointer;
 }

 .form-group .react-datepicker-popper .react-datepicker {
     background: #1b1b1b;
     border: none;
 }

 .form-group .react-datepicker-popper .react-datepicker button.react-datepicker__navigation {
     background: #1e4549;
     border-radius: 50px;
     margin: 10px;
     height: 26px;
     width: 26px;
     padding: 15px;
 }

 .form-group .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header {
     background: #1b1b1b;
     border: none;
     padding-top: 10px;
 }

 .form-group .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__current-month {
     color: #fff;
     margin-bottom: 15px;
 }

 .form-group .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name {
     color: #fff;
 }

 .form-group .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day {
     color: #fff;
 }

 .react-datepicker__day--keyboard-selected,
 .react-datepicker__month-text--keyboard-selected,
 .react-datepicker__quarter-text--keyboard-selected,
 .react-datepicker__year-text--keyboard-selected {
     border-radius: 0.3rem;
     background-color: #00e8fd;
     color: #000 !important;
     border-radius: 50px;
 }

 .form-group .react-datepicker-popper .react-datepicker .react-datepicker__triangle::before {
     display: none;
 }

 .form-group .react-datepicker-popper .react-datepicker .react-datepicker__triangle::after {
     display: none;
 }

 .form-group .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:hover {
     background: #0098a6;
     border-radius: 50px;
 }

 .form-group .react-datepicker-wrapper .react-datepicker__input-container label:hover {
     border: 1px solid #0098a6;
 }

 .react-datepicker__day--selected,
 .react-datepicker__day--in-selecting-range,
 .react-datepicker__day--in-range,
 .react-datepicker__month-text--selected,
 .react-datepicker__month-text--in-selecting-range,
 .react-datepicker__month-text--in-range,
 .react-datepicker__quarter-text--selected,
 .react-datepicker__quarter-text--in-selecting-range,
 .react-datepicker__quarter-text--in-range,
 .react-datepicker__year-text--selected,
 .react-datepicker__year-text--in-selecting-range,
 .react-datepicker__year-text--in-range {
     background-color: #00e8fd;
     color: #000 !important;
     border-radius: 50px;
 }

 .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
 .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
     border-bottom: none;
 }

 .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
 .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
 .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after,
 .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
     border: 8px solid #1b1b1b;
 }



 /* popup-start */

 .test_ride_popup .give_detail h2 {
     margin-bottom: 32px !important;
 }

 .test_ride_popup .give_detail .react_form_group .mo_numb input {
     padding: 24px 15px 24px 60px !important;
 }

 .test_ride_popup .give_detail .in_img img {
     border-radius: 0;
 }

 .test_ride_popup .by_continuing {
     text-align: center;
     /* margin-top: -20px; */
 }

 .test_ride_popup .mo_optpopup ::placeholder {
     color: #fff;
 }



 .test_ride_popup .give_detail .gi_nameinp .mo_numb {
     margin-bottom: 0px !important;
 }

 .test_ride_popup .give_Search h2 {
     margin-bottom: 10px !important;
 }

 .test_ride_popup .by_continuing p .privacy_po {
     border-bottom: 1px solid #fff;
     margin: 0px 3px;
 }

 .test_ride_popup .by_continuing p .privacy_po:hover {
     color: #fff;
 }

 .test_ride_popup .by_continuing p {
     margin-bottom: 0;
     color: #fff;
     font-weight: 400;
     font-size: 12px;
     line-height: 20px;
     font-family: 'Lato', sans-serif;
     text-transform: uppercase;
 }

 .modal_popup.test_ride_popup .pure-modal-backdrop::after {
     content: "";
     position: absolute;
     height: 100%;
     width: 100%;
     z-index: -1;
     background: rgb(0 0 0 / 40%);
     backdrop-filter: blur(30px);
 }

 .modal_popup.test_ride_popup .pure-modal-backdrop .panel.panel-default {
     width: 100%;
     height: auto;
 }

 .modal_popup.test_ride_popup .pure-modal-backdrop .panel.panel-default .panel-heading {
     display: none;
 }



 .modal_popup.test_ride_popup .pure-modal-backdrop .panel.panel-default .close {
     background: transparent;
     position: absolute !important;
     top: 67px;
     right: 128px;
     font-size: 24px;
     font-weight: 600;
     color: #fff;
 }

 .modal_popup.test_ride_popup .pure-modal-backdrop .panel.panel-default .panel-body.scrollable {
     background: transparent;
     padding: 15px;
     text-align: center;
 }

 .modal_popup.test_ride_popup .pure-modal-backdrop .popup_cont h2 {
     color: rgb(255 255 255 / 80%);
     font-family: 'Raleway', sans-serif;
     font-size: 48px;
     font-weight: 700;
     letter-spacing: .05em;
     margin-bottom: 15px;
     line-height: 72px;
     margin-top: 0;
 }

 .modal_popup.test_ride_popup .pure-modal-backdrop .popup_cont form {
     text-align: left;
     max-width: 456px;
     margin: auto;
     font-family: 'Raleway', sans-serif;
 }

 .modal_popup.test_ride_popup .pure-modal-backdrop .pure-modal {
     height: 100% !important;
     width: 100% !important;
     padding: 50px;
     overflow: initial;
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .modal_popup.test_ride_popup .pure-modal-backdrop .city_dealers form {
     max-width: 492px;
 }

 .modal_popup.test_ride_popup .pure-modal-backdrop .city_dealers .city_spa {
     padding: 0px 18px;
 }

 .test_ride_popup .mo_optpopup .react_form_group input {
     color: #fff !important;
     text-align: center;
     font-weight: 800 !important;
 }

 .test_ride_popup .popup_cont form .react_form_group .mo_numb {
     margin-bottom: 32px;
 }

 .test_ride_popup .popup_cont form .react_form_group label {
     color: rgb(255 255 255 / 80%);
     font-family: 'Lato', sans-serif;
     font-size: 24px;
     font-weight: 700;
     letter-spacing: .05em;
     margin-bottom: 32px;
     margin-top: 0;
     width: 100%;
     text-align: center;
     display: block;
 }

 .test_ride_popup .popup_cont form .react_form_group input {
     font-size: 20px;
     font-weight: 400;
     color: rgb(255 255 255 / 35%);
     width: 100%;
     outline: none;
     padding: 24px 20px;
     background: linear-gradient(114.11deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.25) 100%);
     border-radius: 16px;
     border: 1px solid rgba(255, 255, 255, 0.5);
 }

 .test_ride_popup .popup_cont form .react_form_group {
     margin-bottom: 32px;
 }

 .test_ride_popup .btn_co_book::before {
     display: none;
 }


 .test_ride_popup .close_btn {
     position: relative;
     margin-right: 80px;
     margin-top: 30px;
 }

 .test_ride_popup .close_btn::before {
     content: "";
     background: #fff;
     height: 4px;
     width: 36px;
     position: absolute;
     transform: rotate(45deg);
     border-radius: 2px;
     cursor: pointer;
     bottom: 10px;
 }

 .test_ride_popup .close_btn::after {
     content: "";
     background: #fff;
     height: 4px;
     width: 36px;
     position: absolute;
     transform: rotate(135deg);
     border-radius: 2px;
     cursor: pointer;
     bottom: 10px;
 }

 .test_ride_popup .close {
     right: 300px;
     top: 200px;
 }

 .test_ride_popup .form-group .css-1s2u09g-control .css-319lph-ValueContainer {
     font-size: 16px;
     color: #9ca0a3;
     font-weight: 900;
     letter-spacing: 1px;
     font-family: 'Lato', sans-serif;
 }

 .test_ride_popup .react_form_group.dealers_btn {
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;
 }

 .test_ride_popup .react_form_group.dealers_btn button.default_btn {
     width: 50%;
     padding: 17px 15px;

 }

 .test_ride_popup .in_img {
     display: flex;
     align-items: center;
     position: absolute;
     /* top: 40px; */
     top: 50%;
     left: 25px;
     transform: translateY(-50%);
 }

 #otpsendOrder.test_ride_popup form span.error {
     margin-top: 10px;
     display: block;
     position:absolute;
     bottom: -28px;
 }

 .test_ride_popup .in_img img {
     border-radius: 50%;
     margin-right: 15px;
 }

 .test_ride_popup .give_detail .indiaf_phone .in_img img {
     border-radius: 50%;
     width: 30px;
 }

 .test_ride_popup .give_detail .indiaf_phone .mo_numb input {
     padding: 24px 15px 24px 120px !important;
 }


 .test_ride_popup .in_img span {
     font-size: 24px;
     color: #fff;
     font-family: 'Lato', sans-serif;
     font-weight: 400;
 }

 .test_ride_popup .mo_numb {
     position: relative;
 }

 .test_ride_popup .popup_cont form .react_form_group .mo_numb input {
     padding: 24px 15px 24px 135px;
     background: linear-gradient(114.11deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.25) 100%);
     border-radius: 16px;
 }


 .test_ride_popup .popup_cont form .react_form_group .mo_numb input {
     color: #fff;
 }



 .test_ride_popup .react_form_group.dealers_btn button.default_btn:nth-child(1) {
     margin-right: 6px;
     width: calc(50% - 6px);
 }

 .test_ride_popup .react_form_group.dealers_btn button.default_btn:nth-child(2) {
     margin-left: 6px;
     border: 1px solid #00EAFF;
     width: calc(50% - 6px);
 }

 .test_ride_popup .react_form_group button.default_btn {
     width: 100%;
     cursor: pointer;
     padding: 17px 32px;
     border: none;
     font-size: 20px;

 }

 /* popup-end */



 .right_sec .form_sec .form-group .css-1s2u09g-control .css-1d8n9bt div#react-select-2-placeholder {
     color: #9ca0a3;
     font-family: Lato, sans-serif;
     font-size: 16px;
     font-weight: 900;
     letter-spacing: 1px;
 }

 .right_sec .form_sec .form-group .css-1s2u09g-control .css-1d8n9bt .css-qc6sy-singleValue {
     color: #9ca0a3;
     font-family: Lato, sans-serif;
     font-size: 16px;
     font-weight: 900;
     letter-spacing: 1px;
 }

 .right_sec .form_sec .form-group .css-1pahdxg-control .css-1d8n9bt div#react-select-2-placeholder {
     color: #9ca0a3;
     font-family: Lato, sans-serif;
     font-size: 16px;
     font-weight: 900;
     letter-spacing: 1px;
 }

 .right_sec .form_sec .form-group .css-1pahdxg-control .css-1d8n9bt .css-qc6sy-singleValue {
     color: #9ca0a3;
     font-family: Lato, sans-serif;
     font-size: 16px;
     font-weight: 900;
     letter-spacing: 1px;
 }

 .test_ride_popup .popup_cont form .react_form_group.by_continuing.mobile_policy {
     display: none;

 }

 .test_ride_popup .mo_show_noly {
     display: none !important;
 }




 /* service-start */

 .test_ride_popup .popup_cont form .ser_mailmo .mo_numb .in_img img {
     border-radius: 0;
 }

 .modal_popup.test_ride_popup .pure-modal-backdrop .popup_cont form .ser_mailmo .mo_numb input {
     padding: 24px 15px 24px 60px;
 }

 .or_sec {
     margin: 17px 0px;
 }

 .or_sec p {
     color: #fff;
     font-weight: 700;
     font-size: 20px;
     line-height: 32px;
     font-family: Lato, sans-serif;
     position: relative;
     width: 100%;
     text-align: center;
 }

 .or_sec p::before {
     content: "";
     position: absolute;
     left: 0;
     height: 1px;
     background: rgb(255 255 255 / 60%);
     width: 46%;
     top: 18px;
 }

 .or_sec p::after {
     content: "";
     position: absolute;
     right: 0;
     height: 1px;
     background: rgb(255 255 255 / 60%);
     width: 46%;
     top: 18px;
 }

 .service_mo form .react_form_group.india_f {
     margin-bottom: 40px !important;
 }

 .service_mo form .react_form_group.india_f .mo_numb {
     margin-bottom: 0px !important;
 }

 .service_mo h2 {
     margin-bottom: 40px !important;
 }

 .user button {
     margin-bottom: 15px;
 }

 .popup_cont.mar_both2 h2 {
     margin-bottom: 8px !important;
 }

 .test_ride_popup .popup_cont form .react_form_group textarea {
     font-size: 16px;
     font-weight: 400;
     color: rgb(255 255 255 / 60%);
     width: 100%;
     outline: none;
     line-height: 24px;
     padding: 24px 27px;
     background: linear-gradient(114.11deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.25) 100%);
     border-radius: 16px;
     border: 1px solid rgba(255, 255, 255, 0.5);
     font-family: Lato, sans-serif;
     letter-spacing: 1px;
 }

 .serv_req form .react_form_group .mo_numb textarea {
     height: 277px;
     text-transform: capitalize;
 }

 .give_detail.serv_req form .react_form_group {
     margin-bottom: 30px !important;
 }

 .give_detail.serv_req form .react_form_group .mo_numb {
     margin-bottom: 0;
 }

 .allid_sec {
     margin-bottom: 32px;
 }

 .allid_sec p {
     font-family: 'Lato';
     font-style: normal;
     font-weight: 400;
     font-size: 24px;
     line-height: 24px;
     text-align: center;
     text-transform: capitalize;
     color: #00EAFF;
 }

 .allid_sec p span {
     color: rgba(255, 255, 255, 0.6);
 }



 .sign_up p {
     color: #fff;
     text-align: center;
     font-size: 20px;
     font-weight: 500;
     font-family: 'Raleway', sans-serif;
     margin: 15px 10px;
 }

 .sign_up p a {
     color: #00EAFF;
     margin-left: 5px;
 }

 .my_book_modal.book_test .close {
     position: inherit !important;
 }



 /*======================================== RESPONSIVE START================================================ */

 @media only screen and (min-width:1600px) and (max-width:1799px) {

     .test_ride_popup .popup_cont form .react_form_group {
         margin-bottom: 30px;
     }

     .modal_popup.test_ride_popup .pure-modal-backdrop .popup_cont form .ser_mailmo .mo_numb input {
         padding: 21px 15px 21px 60px;
     }

     .popup_slider {
         margin-left: 50px;
     }

 }

 @media only screen and (min-width:1200px) and (max-width:1475px) {

     /* popup-start */

     .modal_popup.test_ride_popup .pure-modal-backdrop .popup_cont h2 {
         font-size: 38px;
         line-height: 55px;
     }

     .test_ride_popup .popup_cont form .react_form_group label {
         font-size: 18px;
         margin-bottom: 22px;
     }


     .test_ride_popup .popup_cont form .react_form_group input {
         padding: 21px 20px;
     }

     .test_ride_popup .form-group button.default_btn {
         padding: 16px 32px;
         font-size: 20px;
     }

     .modal_popup.test_ride_popup .pure-modal-backdrop .popup_cont form .ser_mailmo .mo_numb input {
         padding: 21px 15px 21px 60px;
     }

     .serv_req form .react_form_group .mo_numb textarea {
         height: 230px;
     }

     .test_ride_popup .popup_cont form .react_form_group .mo_numb input {
         padding: 21px 15px 21px 125px;
     }

     .test_ride_popup .in_img span {
         font-size: 20px;
     }

     .test_ride_popup .popup_cont form .react_form_group {
         margin-bottom: 25px;
     }

     .test_ride_popup .panel .give_detail .indiaf_phone .mo_numb input {
         padding: 21px 15px 21px 110px !important;
     }

     /* .test_ride_popup .in_img {
         top: 37px;
     } */

     .test_ride_popup .give_detail .react_form_group .mo_numb input {
         padding: 21px 15px 21px 60px !important;
     }

     .test_ride_popup .react_form_group.dealers_btn button.default_btn {
         padding: 15px 15px;

     }

     .modal_popup.test_ride_popup .pure-modal-backdrop .panel.panel-default .close {
         top: 40px;
         right: 70px;
     }

     .test_ride_popup .popup_cont form .react_form_group .mo_numb {
         margin-bottom: 35px;
     }


     .test_ride_popup .react_form_group button.default_btn {
         padding: 15px 32px;
         font-size: 18px;
     }

     .right_sec .step_part ul li span {
         height: 20px;
         width: 20px;
         outline-offset: 6px;
     }

     .right_sec .step_part ul li p {
         font-weight: 600;
     }

     .form_sec {
         margin: 0px 25px;
     }

     .form_sec .form-group {
         margin-bottom: 27px;
     }

 }


 @media only screen and (min-width:992px) and (max-width:1199px) {


     /* popup-start */

     .modal_popup.test_ride_popup .pure-modal-backdrop .popup_cont h2 {
         font-size: 34px;
         line-height: 44px;
     }

     .test_ride_popup .popup_cont form .react_form_group label {
         font-size: 16px;
         margin-bottom: 20px;
     }

     .test_ride_popup .popup_cont form .react_form_group input {
         font-size: 20px;
         padding: 18px 20px;
     }

     .test_ride_popup .form-group button.default_btn {
         padding: 15px 25px;
         font-size: 18px;
     }

     .test_ride_popup .popup_cont form .react_form_group .mo_numb input {
         padding: 18px 15px 18px 130px;
     }

     .modal_popup.test_ride_popup .pure-modal-backdrop .popup_cont form .ser_mailmo .mo_numb input {
         padding: 18px 15px 18px 60px;
     }

     .serv_req form .react_form_group .mo_numb textarea {
         height: 230px;
     }


     .test_ride_popup .panel .give_detail .indiaf_phone .mo_numb input {
         padding: 18px 15px 18px 115px !important;
     }

     /* .test_ride_popup .in_img {
         top: 35px;
     } */


     .test_ride_popup .give_detail .react_form_group .mo_numb input {
         padding: 18px 15px 18px 60px !important;
     }

     .test_ride_popup .react_form_group.dealers_btn button.default_btn {
         padding: 15px 15px;
         font-size: 18px;
     }

     .dealers_add .default_btn {
         font-size: 14px !important;
         padding: 12px 15px;
     }

     .modal_popup.test_ride_popup .pure-modal-backdrop .panel.panel-default .close {
         top: 40px;
         right: 70px;
     }

     .test_ride_popup .popup_cont form .react_form_group .mo_numb {
         margin-bottom: 33px;
     }

     .test_ride_popup .popup_cont form .react_form_group {
         margin-bottom: 35px;
     }

     .test_ride_popup .react_form_group button.default_btn {
         padding: 15px 32px;
         font-size: 18px;
     }

     /* popup-end */

     .right_sec .step_part ul li span {
         height: 18px;
         width: 18px;
         outline-offset: 5px;
     }

     .right_sec .step_part ul li p {
         font-weight: 600;
         font-size: 14px;
     }

     .form_sec {
         margin: 0px 15px;
     }

     .form_sec .form-group {
         margin-bottom: 25px;
     }

     .right_sec .step_part ul li::before {
         top: 8px;
     }

     .right_sec .step_part ul li:first-child:after {
         top: 8px;
     }

     .step_part {
         margin-bottom: 30px;
     }

     .form-group .css-1s2u09g-control {
         padding: 8px 15px;
     }

 }



 @media only screen and (min-width:768px) and (max-width:991px) {

     /* popup-start */
     .test_ride_popup .popup_cont form .react_form_group input {
         border-radius: 12px;
     }

     .default_btn {
         border-radius: 8px !important;
     }

     .modal_popup.test_ride_popup .pure-modal-backdrop .popup_cont h2 {
         font-size: 28px;
         line-height: 34px;
     }

     .test_ride_popup .popup_cont form .react_form_group label {
         font-size: 15px;
         margin-bottom: 18px;
     }

     .test_ride_popup .popup_cont form .react_form_group input {
         font-size: 18px;
         padding: 15px 20px;
     }

     .test_ride_popup .form-group button.default_btn {
         padding: 15px 25px;
         font-size: 18px;
     }



     .test_ride_popup .panel .give_detail .indiaf_phone .mo_numb input {
         padding: 15px 15px 15px 95px !important;
     }

     /* .test_ride_popup .in_img {
         top: 29px;
     } */

     .test_ride_popup .give_detail .react_form_group .mo_numb input {
         padding: 15px 15px 15px 45px !important;
     }

     .test_ride_popup .mo_numb .in_img {
         left: 15px;
     }


     .test_ride_popup .react_form_group.dealers_btn button.default_btn {
         padding: 13px 12px;
         font-size: 15px;
     }

     .modal_popup.test_ride_popup .pure-modal-backdrop .panel.panel-default .close {
         top: 30px;
         right: 40px;
     }

     .test_ride_popup .popup_cont form .react_form_group .mo_numb {
         margin-bottom: 30px;
     }

     .test_ride_popup .in_img span {
         font-size: 20px;
     }

     .test_ride_popup .india_f .mo_numb .in_img img {
         margin-right: 10px;
         width: 30px;
     }

     .test_ride_popup .popup_cont form .react_form_group {
         margin-bottom: 20px;
     }

     .modal_popup.test_ride_popup .pure-modal-backdrop .popup_cont form {
         max-width: 356px;
     }

     .test_ride_popup .give_Search .mo_numb .in_img img {
         width: 20px;
     }

     /* popup-end */



     .right_sec .step_part ul li span {
         height: 18px;
         width: 18px;
         outline-offset: 5px;
     }

     .right_sec .step_part ul li p {
         font-weight: 600;
         font-size: 14px;
         word-break: break-word;
     }

     .form_sec {
         margin: 0px 15px;
     }

     .form_sec .form-group {
         margin-bottom: 25px;
     }

     .right_sec .step_part ul li::before {
         top: 8px;
     }

     .right_sec .step_part ul li:first-child:after {
         top: 8px;
     }

     .step_part {
         margin-bottom: 30px;
     }

     .form-group button.default_btn {
         padding: 14px 32px;
         font-size: 16px;
     }

     .test_ride_popup .react_form_group button.default_btn {
         padding: 14px 25px;
         font-size: 15px;
     }

     .dealers_add h6 {
         font-size: 15px;
         line-height: 20px;
         margin-bottom: 6px;
     }

     .dealers_add p {
         line-height: 22px;
         margin-bottom: 15px;
     }
 }


 @media only screen and (min-width:320px) and (max-width:767px) {
    

     /* popup-start */


     .modal_popup.test_ride_popup .pure-modal-backdrop .popup_cont h2 {
         font-weight: 600;
         font-size: 20px !important;
         line-height: 28px;
         letter-spacing: 0.03em;
         color: #FFFFFF;
         opacity: 0.8;
         margin-bottom: 10px !important;
         /* font-size: 24px;
        line-height: 34px;
        margin-bottom: 50px;
        color: rgb(255 255 255); */
     }

     .modal_popup.test_ride_popup .pure-modal-backdrop .popup_cont h4 {
         font-size: 16px;
         line-height: 22px;
         letter-spacing: 0.05em;
         color: #DDDDDD;
         opacity: 0.8;
     }

     .test_ride_popup .popup_cont form .react_form_group label {
         font-size: 18px;
         margin-bottom: 16px;
         font-weight: 400;
         line-height: 28px;
     }

     .test_ride_popup .popup_cont form .react_form_group input {
         font-size: 16px;
         padding: 15px 20px;
     }

     .test_ride_popup .form-group button.default_btn {
         padding: 15px 25px;
         font-size: 18px;
     }

     .test_ride_popup .popup_cont form .react_form_group .mo_numb input {
         padding: 15px 15px 15px 85px;
     }


     .test_ride_popup .panel .give_detail .indiaf_phone .mo_numb input {
         padding: 15px 15px 15px 85px !important;
     }

     /* .test_ride_popup .in_img {
         top: 28px;
     } */

     .test_ride_popup .give_detail .react_form_group .mo_numb input {
         padding: 15px 15px 15px 45px !important;
     }

     .test_ride_popup .mo_numb .in_img {
         left: 15px;
     }

     .test_ride_popup .by_continuing {
         display: none;
     }

     .test_ride_popup .react_form_group.dealers_btn button.default_btn {
         padding: 14px 12px;
         font-size: 14px;
     }

     .modal_popup.test_ride_popup .pure-modal-backdrop .panel.panel-default .close {
         top: 15px;
         right: 20px;
     }

     .test_ride_popup .close_btn {
         margin-right: 30px;
         margin-top: 26px;
     }

     .test_ride_popup .popup_cont form .react_form_group .mo_numb {
         margin-bottom: 25px;
     }

     .test_ride_popup .in_img span {
         font-size: 16px;
     }

     .test_ride_popup .india_f .mo_numb .in_img img {
         margin-right: 7px;
         width: 30px;
     }

     .test_ride_popup .popup_cont form .react_form_group {
         margin-bottom: 25px;
     }

     .modal_popup.test_ride_popup .pure-modal-backdrop .popup_cont form {
         max-width: 356px;
     }

     .test_ride_popup .give_Search .mo_numb .in_img img {
         width: 20px;
     }


     .modal_popup.test_ride_popup .pure-modal-backdrop {
         background: #000;
     }

     .modal_popup.test_ride_popup .pure-modal-backdrop::after {
         background: linear-gradient(211deg, #13204d, #13204d);
         border-radius: 50%;
         content: "";
         filter: blur(40px);
         height: 320px;
         position: absolute;
         right: -150px;
         top: -150px;
         width: 350px;
         z-index: -1;
     }

     .modal_popup.test_ride_popup .pure-modal-backdrop::before {
         background: linear-gradient(211deg, #2a63a3, #2a63a3);
         border-radius: 0% 80% 0% 0%;
         content: "";
         filter: blur(50px);
         height: 300px;
         position: absolute;
         left: -300px;
         bottom: -100px;
         width: 500px;
         z-index: -1;
     }


     .modal_popup.test_ride_popup .pure-modal-backdrop .pure-modal {
         padding: 0;
     }

     .modal_popup.test_ride_popup .pure-modal-backdrop .panel.panel-default .panel-body.scrollable {

         padding: 80px 20px 20px;
     }

     .test_ride_popup .mobile_showlogo {
         display: block;
     }

     .test_ride_popup .mobile_showlogo img {
         margin: 0px auto 24px;
     }

     .modal_popup.test_ride_popup .pure-modal-backdrop .popup_cont form .react_form_group.next_popu {
         margin-top: 50px;
     }

     .test_ride_popup .react_form_group button.default_btn {
         padding: 11px 32px;
         font-size: 18px;
     }

     .test_ride_popup .by_continuing p {
         color: rgb(255 255 255 / 60%);
     }

     .modal_popup.test_ride_popup .pure-modal-backdrop .pure-modal {
         overflow: auto;
     }

     .test_ride_popup .close_btn::before {
         height: 3px;
         width: 30px;
     }

     .test_ride_popup .close_btn::after {
         height: 3px;
         width: 30px;
     }

     .modal_popup.test_ride_popup .pure-modal-backdrop .popup_cont h2.otp_send {
         margin-bottom: 5px;

         line-height: 28px;
         font-size: 18px !important;
     }

     .modal_popup.test_ride_popup .pure-modal-backdrop .popup_cont h2.otp_no {
         margin-bottom: 16px;
         font-size: 18px !important;
     }

     .modal_popup.test_ride_popup .pure-modal-backdrop .popup_cont.mo_optpopup .react_form_group label {
         display: block;
     }

     .test_ride_popup .popup_cont form .react_form_group.by_continuing.mobile_policy {
         display: block;
         margin-bottom: 0;
         margin-top: -15px;
         padding: 0px 15px;
     }



     .test_ride_popup .popup_cont .mo_remove_noly {
         display: none;
     }

     .modal_popup.test_ride_popup .pure-modal-backdrop .city_dealers .city_spa {
         padding: 0px 0px;
     }

     .test_ride_popup .mo_show_noly {
         display: block !important;
     }

     .test_ride_popup .react_form_group.dealers_btn button.default_btn:nth-child(1) {
         margin-right: 0px;
         width: calc(100% - 0px);
         margin-bottom: 20px;
     }

     .test_ride_popup .react_form_group.dealers_btn button.default_btn:nth-child(2) {
         margin-right: 0px;
         width: calc(100% - 0px);
     }

     .dealers_add {
         padding: 30px 20px;
         max-width: 100%;
     }

     .dealers_add .default_btn {
         font-size: 16px !important;
         padding: 18px 15px;
         width: 100%;
     }

     .popup_cont.back_home .mo_marginbo {
         margin-bottom: 10px !important;
     }

     /* popup-end */



     .right_sec .step_part ul li span {
         height: 18px;
         width: 18px;
         outline-offset: 5px;
         background: #484b50;
     }

     .right_sec .step_part ul li p {
         font-weight: 600;
         font-size: 14px;
         word-break: break-word;
     }

     .form_sec {
         margin: 0px 15px;
     }

     .right_sec .step_part ul li::before {
         top: 8px;
     }

     .right_sec .step_part ul li:first-child:after {
         top: 8px;
     }

     .step_part {
         margin-bottom: 30px;
     }

     .form-group .css-1s2u09g-control {
         border-radius: 10px;
     }


     .right_sec .step_part ul li::before {
         background-color: #484b50;
     }

     .form-group .css-1s2u09g-control #react-select-5-placeholder {
         font-weight: 600;
     }

     .form-group .css-b62m3t-container .css-1s2u09g-control .css-319lph-ValueContainer .css-qc6sy-singleValue {
         font-weight: 600;
     }

     .form-group .css-1pahdxg-control .css-319lph-ValueContainer .css-qc6sy-singleValue {
         font-weight: 600;
     }

     footer.footer {
         z-index: 0;
     }

     .modal_popup.test_ride_popup .pure-modal-backdrop .popup_cont form .ser_mailmo .mo_numb input {
         padding: 15px 15px 15px 50px;
     }

     .serv_req form .react_form_group .mo_numb textarea {
         height: 200px;
     }

     .test_ride_popup .popup_cont form .react_form_group textarea {
         padding: 15px 20px;
     }

     .serv_req form .mob_respsubm {
         margin-top: 50px !important;
     }

     .test_ride_popup .serv_req h2 {
         margin-bottom: 32px !important;
     }

     .submitted .mobile_showlogo {
         margin-bottom: 50px;
     }

     .sign_up p {
         font-size: 16px;
     }

     .close_button .close .close_btn:after,
     .close_button .close .close_btn::before {
         display: block !important;
     }



 }