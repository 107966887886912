.featured_main_sec {
  background-image: radial-gradient(circle farthest-side at 50% 50%, #171717, transparent 0%, transparent 50%, transparent 63%, #171717);
}

.featured_main_sec .marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 130px;
  overflow-x: hidden;
  display: flex;
  align-items: center;
}

.featured_main_sec .track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 15s linear infinite;
}

.featured_main_sec .track1 {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 10s linear infinite;
}

.featured_main_sec .track2 {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 23s linear infinite;
}

.featured_main_sec .content {
  display: flex;
  gap: 70px;
}
.featured_main_sec .marquee img {
  height: 35px;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}


@media screen and (max-width:767px) {
  .featured_main_sec .marquee {
    height: 70px;
  }

  .featured_main_sec .marquee img {
    height: 20px;
  }

  .featured_main_sec .content {
    gap: 80px;
  }
  
.featured_main_sec .track {
  animation: marquee 11s linear infinite;
}

.featured_main_sec .track1 {
  animation: marquee 7s linear infinite;
}

.featured_main_sec .track2 {
  animation: marquee 14s linear infinite;
}

}