
.para_header h1{
    font-size: 26px;
    font-display: swap;
    font-weight: 700;
    margin: 25px 0px;
    color: #000;
}

@media screen and (max-width:767px) {
    .para_header h1{
        font-size: 22px;
    }
}