
/* section 5 css */

.sec_2_content{
    background-color: #0a0a0a;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.sec_2_content .container_about_us{
    max-width: 1440px;
    padding: 76px 140px 76px 140px;
    margin: 0px auto;
}

.sec_2_content:before {
    background: linear-gradient(211deg,#14204e,#14204e);
    border-radius: 50%;
    content: "";
    -webkit-filter: blur(60px);
    filter: blur(60px);
    height: 650px;
    position: absolute;
    right: -400px;
    top: -400px;
    width: 650px;
    z-index: 0;
}
.sec_2_content:after {
    background: linear-gradient(211deg,#2a63a3,#2a63a3);
    border-radius: 50% 80% 0 0;
    bottom: -450px;
    content: "";
    -webkit-filter: blur(80px);
    filter: blur(80px);
    height: 650px;
    left: -450px;
    position: absolute;
    width: 650px;
    z-index: 0;
}

/* bike images css */

.sec_2_content .container_about_us h2 {
    color: #FFF;
    font-size: 54px;
    font-family: Lato, sans-serif;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 5.4px;
    text-transform: uppercase;
    margin-bottom: 80px;
    text-align: center;
}

.sec_2_content .container_about_us .image_box_wrapper {
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.sec_2_content .container_about_us .image_box_wrapper .image_box_list {
    margin: 0px 30px 0px 30px;
}

/* images name */

.image_box_list .bikes_type {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
}

.image_box_wrapper .image_box_list .bikes_type .bike_name1 {
    text-align: left;
}


.image_box_wrapper .image_box_list .bikes_type .bike_name2 {
    text-align: right;
}

.image_box_wrapper .image_box_list .bikes_type .bike_name2 p.hop_leo,
.image_box_wrapper .image_box_list .bikes_type .bike_name1 p.hop_lf  {
    color: #FFF;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 0.17px;
    text-transform: uppercase;
}

.image_box_wrapper .image_box_list .bikes_type .bike_name1 p.lfe_desc,
.image_box_wrapper .image_box_list .bikes_type .bike_name2 p.leo_desc {
    color: #FFF;
    font-size: 13px;
    line-height: 25px;
}

/* oxo */

.image_box_wrapper .image_box_list img {
    margin-bottom: 15px;
}

.image_box_wrapper .image_box_list .bike_name3 {
    text-align: center;
}

.image_box_wrapper .image_box_list .bike_name3 p.hop_ox{
    color: #FFF;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 0.17px;
    text-transform: uppercase;
}

.image_box_wrapper .image_box_list .bike_name3 p.ox_desc {
    color: #FFF;
    font-size: 13px;
    line-height: 25px;
    letter-spacing: 1px;
}

/* media query */

@media only screen and (max-width: 991px){

	.image_box_wrapper .image_box_list img {
		margin: auto;
	}

	.image_box_list .bikes_type {
		display: flex;
		justify-content: space-around !important;
		align-items: center;
	}
}

@media only screen and (max-width: 767px){

    .sec_2_content .container_about_us {
        padding: 50px 16px;
        width: 100%;
    }

    .sec_2_content .container_about_us h2{
        margin-bottom: 15px;
        font-size: 24px;
        line-height: 36px;
    }

    .sec_2_content:before, 
    .sec_2_content:after {
        height: 530px;
        width: 600px;
    }

    .sec_2_content .container_about_us .image_box_wrapper {
        display: block;
    }

    .sec_2_content .container_about_us .image_box_wrapper .image_box_list {
        margin-bottom: 50px;
        margin-left: 0;
        margin-right: 0;
    }

    .sec_2_content .container_about_us .image_box_wrapper .image_box_list.mb-0{
        margin-bottom: 0;
    }

    .image_box_wrapper .image_box_list .bikes_type .bike_name2 p.hop_leo, 
    .image_box_wrapper .image_box_list .bikes_type .bike_name1 p.hop_lf, 
    .image_box_wrapper .image_box_list .bike_name3 p.hop_ox {
        font-size: 18px;
        line-height: 30px;
    }

    .image_box_wrapper .image_box_list .bikes_type .bike_name1 p.lfe_desc, 
    .image_box_wrapper .image_box_list .bikes_type .bike_name2 p.leo_desc,
    .image_box_wrapper .image_box_list .bike_name3 p.ox_desc {
        font-size: 12px;
        line-height: 20px;
    }

    .image_box_wrapper .image_box_list img {
        margin-bottom: 8px;
    }
}