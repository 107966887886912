@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");

#home h1 {
  color: #ff0062;
}

.pure-modal .panel-body,
.pure-modal .panel-footer,
.pure-modal .panel-title {
  word-break: break-word;
}

.pricing_sec .price-content ul li:nth-last-child(2) span.left {
  text-transform: initial;
}

.landing_page_container {
  max-width: 1440px;
  margin: auto;
  padding: 0px 20px;
}

/* youtube_facebook_sec-start */
.youtube_facebook_sec {
  background: #0a0a0a;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
}

.youtube_facebook_sec .landing_page_container {
  padding: 60px 20px 60px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.youtube_facebook_sec::after {
  background: linear-gradient(211deg, #2a63a3, #2a63a3);
  border-radius: 50%;
  content: "";
  filter: blur(100px);
  height: 850px;
  position: absolute;
  left: -450px;
  bottom: -450px;
  width: 900px;
  z-index: 0;
}

.youtube_facebook_sec::before {
  content: "";
  background: linear-gradient(211deg, #0b132f, #0b132f);
  border-radius: 50%;
  filter: blur(100px);
  width: 900px;
  height: 850px;
  position: absolute;
  right: -400px;
  bottom: -450px;
  z-index: 0;
}

.logo_secchange {
  text-align: center;
  margin-bottom: 20px;
}

.logo_secchange img {
  margin-bottom: 15px !important;
  margin: auto;
  width: 300px;
}

.logo_secchange p {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 32px;
  line-height: 50px;
  background: linear-gradient(90deg, #85fc65 0%, #00eaff 99.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  letter-spacing: 0.05em;
}

.youtube_facebook_sec h3 {
  font-family: "Lato";
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.05em;
  background: linear-gradient(89.99deg, #85fc65 -0.06%, #00eaff 99.16%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 25px;
}

.youtube_facebook_sec h3 {
  font-weight: 800;
}

.social_sec {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 2;
  max-width: 1000px;
  width: 100%;
  margin-bottom: 40px;
}

.social_sec .social_link {
  width: auto;
  margin: 0px 10px;
  background: rgb(0 0 0 / 60%);
  border-radius: 16px;
  width: calc(50% - 20px);
}

.social_sec .social_link .social_text figure img {
  border-radius: 0px;
}

.social_sec .social_link a figure img {
  border-radius: 16px;
  width: 100%;
}

.social_sec .social_link a > figure {
  height: 260px;
}

.social_sec .social_link .social_text {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 20px 20px;
}

.social_sec .social_link .social_text figure {
  padding-right: 15px;
}

.social_sec .social_link .social_text p {
  font-family: "Lato";
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.subscribe_sec {
  text-align: center;
}

.social_popup .social_popup_cont h2 {
  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(270deg, #00eaff 30.92%, #8fff00 80.57%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Lato";
  font-weight: 900;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 24px;
}

.social_popup .social_popup_cont p {
  font-family: "Lato";
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 50px;
}

.social_popup .social_popup_cont form {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  max-width: 940px !important;
  margin: auto;
}

.social_popup .social_popup_cont form .form_input .uk-margin .uk-input {
  border-radius: 0;
}

.social_popup .social_popup_cont form .form_input .uk-legend::after {
  background: linear-gradient(90deg, #8fff00 0%, #00eaff 100%);
  border-radius: 2px;
}

.social_popup .social_popup_cont form .foem_cus {
  margin: auto;
}

.social_popup .social_popup_cont form .btn_sec .default_btn {
  padding: 12px 45px;
}

.thanks_popup .thanks_popup_cont .changethegame h2 {
  font-family: "Raleway";
  font-weight: 600;
  font-size: 50px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 0.05em;
  background: linear-gradient(90deg, #85fc65 0%, #00eaff 99.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-transform: capitalize;
  margin-bottom: 30px;
}

.thanks_popup .thanks_popup_cont .changethegame p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  letter-spacing: 0.05em;
  background: linear-gradient(89.99deg, #85fc65 -0.06%, #00eaff 99.16%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 0px;
}

.thanks_popup .thanks_popup_cont .changethegame p span {
  font-weight: 800;
}

#teascr_page > #footer {
  overflow: hidden;
}

.social_popup .pure-modal .panel-default .close .close_btn {
  padding: 10px;
  height: 64px;
  width: 64px;
  line-height: 64px;
  text-align: center;
  background: rgba(0, 234, 255, 0.1);
  border: 1px solid #00eaff;
  border-radius: 50%;
}

.social_popup .pure-modal .panel-default .close .close_btn::before {
  background: #00eaff;
  bottom: 28px;
  left: 14px;
}

.social_popup .pure-modal .panel-default .close .close_btn::after {
  background: #00eaff;
  bottom: 28px;
  left: 14px;
}

/* youtube_facebook_sec-end */

.landing_banner p {
  font-size: 24px;
  line-height: 33px;
  color: #9b9b9b;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
}

.landig_banner_cont img {
  margin-bottom: 25px;
}

.landing_banner strong {
  font-size: 34px;
  line-height: 38px;
  color: #fff;
  display: block;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.default_btn {
  background: #00eaff;
  padding: 21px 49px;
  border-radius: 16px;
  font-size: 20px;
  color: #000;
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  transition: all 0.3s;
  text-transform: uppercase;
}

.default_btn.Book {
  background: rgb(0 0 0 / 40%);
  color: #00eaff;
  border: 1px solid #00eaff;
}

.btn_banner .Book.drstop_btn {
  margin-right: 0;
}

.btn_banner .default_btn {
  margin: 0px 0px 0px 20px;
}

.btn_banner button {
  border: none;
  cursor: pointer;
}

.default_btn:hover {
  background: #fff;
  color: #000;
}

.width_sec .steps ul {
  display: flex;
  flex-direction: column;
}

.steps ul li {
  text-align: center;
  padding: 15px 30px;
  margin-bottom: 30px;
  position: relative;
  z-index: 0;
  background: transparent;
}

.steps ul li:last-child {
  margin-bottom: 0px;
}

.steps ul li::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(30px);
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  z-index: -1;
}

.hop_oxo {
  display: flex;
  align-items: center;
}

.hop_oxo img {
  margin-bottom: 0;
  width: 100%;
  max-width: 75px;
}

.hop_oxo strong {
  margin-bottom: 0;
  padding-left: 15px;
  font-size: 32px;
  line-height: 38px;
  color: #fff;
  display: block;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
}

.hop_oxo > strong {
  font-weight: 600 !important;
}

.hop_oxo .rs_sec {
  border-left: 2px solid #dddee0;
  padding-left: 48px;
  margin-left: 48px;
}

.hop_oxo .rs_sec strong {
  padding-left: 0;
  letter-spacing: 0.09em;
  font-family: "Lato", sans-serif;
}

.hop_oxo .rs_sec p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 4px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.09em;
  font-family: "Lato", sans-serif;
}

.steps ul li p {
  font-size: 16px;
  text-align: left;
  color: rgb(255 255 255 / 60%);
  font-weight: 700;
  margin: 0;
  line-height: 23px;
  letter-spacing: 2px;
}

.steps ul li span {
  font-size: 48px;
  margin: 0;
  color: #fff;
  line-height: normal;
  display: block;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  display: flex;
  align-items: baseline;
  line-height: 65px !important;
}

.steps ul li span p {
  font-size: 24px;
  color: #fff;
  padding-left: 6px;
}

.landig_banner_cont {
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 0;
  padding: 34px 64px;
  position: absolute;
  left: 20px;
  width: calc(100% - 40px);
  bottom: 55px;
}

.landig_banner_cont::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(30px);
  z-index: -1;
  border-radius: 24px;
}

.bike_color .landing_page_container {
  position: relative;
  width: 100%;
  height: 100%;
}

.bike_color .landing_page_container .sec_heading {
  position: absolute;
}

.steps ul {
  padding: 0;
  margin-bottom: 0;
}

.logo_cont {
  position: absolute;
  left: 20px;
  width: 445px;
  top: 25%;
  z-index: 1;
  padding: 15px;
}

.width_sec .steps {
  position: absolute;
  right: 20px;
  width: auto;
  top: 50%;
  transform: translateY(-50%);
}

.logo_cont::before {
  content: "";
  background: rgb(0 0 0 / 40%);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  border-radius: 10px;
}

.logo_cont p {
  font-size: 16px !important;
  line-height: 24px !important;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  margin: 0;
}

.logo_cont figure img {
  margin-bottom: 16px;
  width: 100%;
}

.steps::after {
  display: none;
}

.landing_banner {
  position: relative;
  padding: 0px 0px 0px 0px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  scroll-snap-align: start;
  min-height: 100vh;
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
}

.video_sec_step video.desktop {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.video_sec_step {
  height: 100%;
  width: 100%;
}

.video_sec_step img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

#home {
  position: relative;
  background: #0a0a0a;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  width: 100%;
}

#home::before {
  display: none;
}

header.header .uk-container {
  position: relative;
  z-index: 9;
}

.landing_banner .width_sec {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 20px;
}

.full_video_bike {
  z-index: 1;
  position: relative;
  padding: 0px 0px 0px 0px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  scroll-snap-align: start;
}

.full_video_bike .vimeo-video {
  width: 200%;
  height: 100%;
  position: relative;
  left: -50%;
}

.vimeo-video > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.vimeo-video.mobile {
  display: none;
}

.full_video_bike .width_sec {
  position: absolute;
  left: 0;
  right: 0;
}

.full_video_bike .cont_btnsec {
  text-align: center;
  background: rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(12.5755px);
  border-radius: 40px;
  max-width: 1416px;
  margin: auto;
  padding: 230px 100px;
}

.full_video_bike button.click_on_video {
  background: transparent;
  border: none;
}

.full_video_bike .cont_btnsec {
  text-align: center;
  background: rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(12.5755px);
  border-radius: 40px;
  max-width: 1416px;
  margin: auto;
  padding: 230px 100px;
}

.cont_btnsec p {
  font-size: 26px;
  line-height: 41px;
  font-weight: 400;
  color: #fff;
  font-family: "Lato", sans-serif;
  margin: 22px 0px;
}

.cont_btnsec strong {
  display: block;
  font-size: 34px;
  line-height: 41px;
  font-weight: 400;
  color: #fff;
  font-family: "Lato", sans-serif;
  margin: 20px 0px;
  letter-spacing: 2px;
}

.cont_btnsec .default_btn {
  font-size: 18px;
  padding: 16px 58px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.full_video_bike figure img {
  width: 100%;
}

button.click_on {
  background: transparent;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: navajowhite;
}

figure {
  margin: 0px !important;
  padding: 0px;
}

.bike_color .img_bike {
  position: relative;
}

.bike_color .img_bike::after {
  background: linear-gradient(211deg, #2a63a3, #2a63a3);
  border-radius: 50%;
  content: "";
  filter: blur(50px);
  position: absolute;
  left: 10px;
  height: 290px;
  width: 910px;
  box-sizing: border-box;
  z-index: -1;
  top: 200px;
}

.bike_color .img_bike::before {
  background: url(../../assets/images/bg_logo.png);
  content: "";
  height: 345px;
  right: 0px;
  left: 0;
  width: 100%;
  margin: auto;
  top: 27%;
  transform: translateY(-50%);
  position: absolute;
  background-size: cover;
  background-position: center;
  z-index: 0;
}

.bike_color .img_bike > figure {
  max-width: 80%;
  margin: auto !important;
  min-height: 530px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.bike_color .img_bike > figure .loader-img {
  width: 100%;
  max-width: 800px;
  height: 100%;
  max-height: 530px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.bike_color .sec_heading {
  text-align: left;
}

.bike_color {
  background-color: #0a0a0a;
  text-align: center;
  position: relative;
  padding: 60px 0px 30px 0px;
  z-index: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  scroll-snap-align: start;
  min-height: 100vh;
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
}

.bike_color::before {
  background: linear-gradient(211deg, #0b132f, #0b132f);
  border-radius: 50%;
  content: "";
  filter: blur(50px);
  height: 1000px;
  position: absolute;
  right: -400px;
  left: 0;
  top: -500px;
  width: 800px;
  z-index: 0;
  margin-left: auto;
}

.rotate_sec {
  position: relative;
}

.rotate_sec strong {
  color: #fff;
  font-size: 24px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  position: absolute;
  bottom: 15px;
}

.bike_color::after {
  background: linear-gradient(211deg, #2a63a3, #2a63a3);
  border-radius: 50%;
  content: "";
  filter: blur(80px);
  height: 750px;
  position: absolute;
  left: -500px;
  top: 95%;
  transform: translateY(-50%);
  width: 950px;
  z-index: -1;
  margin-left: auto;
}

.bike_color .uk-container {
  position: relative;
}

.btn_slide_bike {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -15px;
}

.btn_slide_bike span {
  background: #828282;
  height: 24px;
  width: 24px;
  cursor: pointer;
  display: inline-block;
  border-radius: 50%;
  margin: 0px 34px;
}

.btn_slide_bike span:nth-child(2) {
  background: #50ebff;
  height: 32px;
  width: 32px;
  display: inline-block;
  outline: 1px solid #fff;
  outline-offset: 15px;
  margin-bottom: -5px;
}

.rotade_bike {
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  z-index: 1;
}

.bike_color .img_bike {
  z-index: 1;
  max-width: 1000px;
  top: 40%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.bike_color .img_bike figure img {
  width: 100%;
}

.change_color_bike .color_step {
  width: calc(100% - 130px);
}

.bike_color .change_color_bike .hop_oxo {
  border-right: 2px solid #dddee0;
  width: 100px;
  margin-right: 30px;
  padding-right: 15px;
}

.bike_color .change_color_bike {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 18px 32px;
  position: relative;
  z-index: 1;
  bottom: 0;
  position: absolute;
  width: calc(100% - 40px);
}

.bike_color .change_color_bike::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(30px);
  z-index: -1;
  border-radius: 16px;
}

.color_step form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.color_step form .form-check {
  width: 20%;
  text-align: left;
  transform: translateX(15px);
}

.color_step form .form-check label {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  color: #fff;
  font-family: "Lato", sans-serif;
  cursor: pointer;
  padding-left: 30px;
}

.color_step form .form-check input {
  position: relative;
}

.color_step form .form-check input::before,
.color_step.onmobile_360 form .form-check label::before {
  content: "";
  position: absolute;
  height: 25px;
  width: 25px;
  text-align: center;
  border-radius: 50%;
  outline: 1px solid #fff;
  outline-offset: 8px;
  margin-right: 15px;
  cursor: pointer;
  background: #f00;
  z-index: 1;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.change_color_bike .color_step .base {
  width: 15%;
  position: relative;
  text-align: center;
  border-bottom: solid 1px rgb(255 255 255 / 50%);
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  padding-bottom: 5px;
}

.change_color_bike .color_step .base::after,
.change_color_bike .color_step .base::before {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  width: 1px;
  height: 10px;
  background-color: rgb(255 255 255 / 50%);
}

.change_color_bike .color_step .base::before {
  left: inherit;
  right: 0;
}

.change_color_bike .color_step .base.pro {
  margin-top: 23px;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 0px;
  border-top: solid 1px rgb(255 255 255 / 50%);
  border-bottom: none;
  width: 100%;
}

.change_color_bike .color_step .base.pro::after,
.change_color_bike .color_step .base.pro::before {
  bottom: 100%;
  top: inherit;
}

.color_step form .form-check input#Black::before,
.color_step.onmobile_360 form .form-check label.black::before {
  background: #000;
}

.color_step form .form-check input#Yellow::before,
.color_step.onmobile_360 form .form-check label.yellow::before {
  background: #ccff00;
}

.color_step form .form-check input#Blue::before,
.color_step.onmobile_360 form .form-check label.blue::before {
  background: #0080ff;
}

.color_step form .form-check input#Grey::before,
.color_step.onmobile_360 form .form-check label.grey::before {
  background: grey;
}

.uk-grid .uk-colum_8 {
  width: 60%;
  padding: 0px 15px;
}

.uk-grid .uk-colum_4 {
  width: 40%;
  padding: 0px 15px;
  align-self: center;
}

.features_slider_sec {
  background: url(../../assets/images/features.jpg);
  height: 100%;
  right: 0px;
  left: 0;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.features_sec {
  background-color: #0a0a0a;
  position: relative;
  padding: 20px 0px 60px 0px;
}

.features_sec .sec_heading h2 {
  margin-bottom: 60px;
}

.features_sec .uk-colum_8 figure img {
  width: 100%;
  border-radius: 0px 10px 10px 0px;
}

.img_sha {
  position: relative;
}

.img_sha figure {
  position: relative;
}

.img_sha figure::before {
  background: rgb(2, 2, 2);
  background: linear-gradient(
    0deg,
    rgba(2, 2, 2, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  border-radius: 10px 10px 0px 0px;
}

.bike_cont h4 {
  font-size: 32px;
  margin-bottom: 16px;
  color: #9b9b9b;
  font-family: "Lato", sans-serif;
  font-weight: 900;
}

.bike_cont p {
  font-size: 16px;
  margin-bottom: 15px;
  color: #9d9d9f;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  padding-right: 150px;
}

.text_right {
  text-align: right;
}

.text_right p {
  padding-right: 0px;
  padding-left: 150px;
}

.features_sec .first_gra {
  position: relative;
  z-index: 2;
}

.features_sec .first_gra::after {
  background: linear-gradient(211deg, #13204d, #13204d);
  border-radius: 50%;
  content: "";
  filter: blur(60px);
  height: 950px;
  position: absolute;
  right: -300px;
  top: -330px;
  width: 700px;
  z-index: -1;
}

.features_sec .second_gra {
  position: relative;
  z-index: 1;
  margin-top: 60px !important;
}

.features_sec .three_gra {
  position: relative;
  z-index: 2;
  margin-top: 60px !important;
}

.features_sec .second_gra::before {
  background: linear-gradient(211deg, #2a63a3, #2a63a3);
  border-radius: 50%;
  content: "";
  filter: blur(60px);
  height: 730px;
  position: absolute;
  left: -200px;
  top: -70px;
  width: 600px;
  z-index: -1;
}

.features_sec .three_gra::before {
  background: linear-gradient(211deg, #13204d, #13204d);
  border-radius: 50%;
  content: "";
  filter: blur(60px);
  height: 730px;
  position: absolute;
  right: -200px;
  top: -70px;
  width: 600px;
  z-index: -1;
}

.second_gra .uk-colum_8 {
  padding-right: 0;
}

.uk-grid .uk-colum_6 {
  width: 50%;
  padding: 0px 15px;
}

.text_sec {
  position: absolute;
  bottom: 40px;
  left: 40px;
}

.text_sec a {
  color: #fff;
  font-family: "Khand", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0.05em;
  transition: all 0.3s;
}

.text_sec a:hover {
  color: rgb(172, 172, 172);
}

.second_gra .uk-colum_8 figure img {
  border-radius: 10px 0px 0px 10px;
}

.features {
  background-color: #0a0a0a;
  padding: 60px 0px;
  position: relative;
}

.features .img_sha figure img {
  border-radius: 10px;
}

.features .bg_colorfu {
  background: rgb(0 0 0 / 40%);
  position: relative;
  z-index: 2;
  padding: 60px 0px;
}

.features::after {
  background: linear-gradient(211deg, #2a63a3, #2a63a3);
  border-radius: 50%;
  content: "";
  filter: blur(60px);
  height: 730px;
  position: absolute;
  left: -200px;
  top: -70px;
  width: 600px;
  z-index: 1;
}

.features .bg_colorfu .uk-grid .uk-colum_6.img_sha {
  width: calc(50% - 30px);
  margin: 0px 15px;
  padding: 0;
}

.tech_specs_sec {
  background-color: #0a0a0a;
  position: relative;
  z-index: 2;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  scroll-snap-align: start;
  min-height: 100vh;
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
}

.tech_specs_sec::before {
  background: linear-gradient(211deg, #14204e, #14204e);
  border-radius: 50%;
  content: "";
  filter: blur(60px);
  height: 800px;
  position: absolute;
  right: -400px;
  top: -400px;
  width: 800px;
  z-index: 0;
}

.tech_specs_sec::after {
  background: linear-gradient(211deg, #2a63a3, #2a63a3);
  border-radius: 50% 80% 0% 0%;
  content: "";
  filter: blur(80px);
  height: 900px;
  position: absolute;
  left: -450px;
  bottom: -450px;
  width: 900px;
  z-index: 0;
}

.tech_specs_sec .mobile-select-variant {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #ffffff;
  opacity: 0.6;
  font-family: "Raleway";
  margin-bottom: 10px;
  display: none;
}

.tech_specs_sec .panel-body.scrollable .sec_heading.on_pop_Specs {
  display: none;
}

.locater::after {
  background: linear-gradient(211deg, #2a63a3, #2a63a3);
  border-radius: 50%;
  content: "";
  filter: blur(80px);
  height: 800px;
  position: absolute;
  left: -400px;
  top: -400px;
  width: 840px;
  z-index: -1;
}

.tech_specs_sec .pure-modal-backdrop .pure-modal {
  width: 100% !important;
  max-width: 100%;
  margin-top: 0;
  height: 100%;
  max-height: calc(100% - 130px);
  background: #0a0a0a;
  border-radius: 64px 64px 0px 0px;
  padding: 106px 240px 0px 240px;
}

.tech_specs_sec
  .pure-modal-backdrop
  .pure-modal
  > .panel.panel-default::-webkit-scrollbar {
  display: none;
}

.tech_specs_sec .pure-modal-backdrop .pure-modal > .panel.panel-default {
  height: 100%;
  overflow: auto;
}

.tech_specs_sec
  .pure-modal-backdrop
  .pure-modal
  > .panel.panel-default
  > .panel-body {
  height: fit-content;
  overflow: auto;
  padding-bottom: 106px;
}

.tech_specs_sec .pure-modal-backdrop .pure-modal .close {
  left: 0;
  right: 0;
  top: -32px;
  margin: 0px auto;
  background: rgba(0, 234, 255, 0.1);
  border: 1px solid #00eaff;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border-radius: 50%;
  color: #00eaff;
  height: 64px;
  width: 63px;
  line-height: 53px;
  font-size: 45px;
}

.tech_specs_sec .pure-modal-backdrop {
  background: transparent;
  justify-content: flex-end;
}

.pricing_sec {
  background-color: #0a0a0a;
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  scroll-snap-align: start;
  min-height: 100vh;
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
}

.pricing_sec::before {
  background: linear-gradient(211deg, #14204e, #14204e);
  border-radius: 50%;
  content: "";
  filter: blur(60px);
  height: 800px;
  position: absolute;
  right: -400px;
  top: -400px;
  width: 800px;
  z-index: 0;
}

.pricing_sec::after {
  background: linear-gradient(211deg, #2a63a3, #2a63a3);
  border-radius: 50% 80% 0% 0%;
  content: "";
  filter: blur(80px);
  height: 900px;
  position: absolute;
  left: -450px;
  bottom: -450px;
  width: 900px;
  z-index: 0;
}

.pricing_sec .landing_page_container {
  position: relative;
  z-index: 2;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pricing_sec .sec_heading {
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
  display: inline-table;
}

.pricing_sec .content {
  display: flex;
  flex-wrap: wrap;
}

.pricing_sec .left_sec {
  width: 50%;
  padding-right: 80px;
}

.pricing_sec .left_sec .image {
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  box-shadow: -1px 1px 1px 0px grey;
  position: relative;
  /* border: 1px solid #fff; */
}

.pricing_sec .left_sec .image:before {
  background: linear-gradient(211deg, #14204e, #14204e);
  border-radius: 50%;
  content: "";
  filter: blur(60px);
  height: 800px;
  position: absolute;
  right: -400px;
  top: -400px;
  width: 800px;
  z-index: -1;
}

.pricing_sec .left_sec img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  object-fit: contain;
  object-position: center;
  max-width: 550px;
  margin: 0px auto;
}

.pricing_sec .right_sec {
  width: 50%;
}

.pricing_sec .right_sec .btn-group-price {
  margin: 0px;
  height: auto;
  width: auto;
  display: inline-block;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 25px;
}

.pricing_sec .right_sec .btn-group-price button {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: #fff;
  background: transparent;
  border-radius: 12px;
  padding: 10px 30px;
  text-align: center;
  min-width: 200px;
  border: none;
}

.pricing_sec .right_sec .btn-group-price button.active-btn {
  background-color: #00eaff;
  color: #000;
}

.pricing_sec .select-sec {
  margin-right: 15px;
  width: calc(50% - 15px);
  display: inline-block;
  margin-bottom: 20px;
}

.pricing_sec .select-sec.color {
  display: block;
}

.pricing_sec .select-sec.last {
  margin-right: 0px;
  margin-left: 15px;
}

.pricing_sec .varient {
  font-size: 22px;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: #ffffff;
  opacity: 0.6;
  margin-bottom: 10px;
  padding: 0px;
}

.pricing_sec .oxo > .varient {
  margin-bottom: 15px;
}

.pricing_sec .select-sec select {
  margin: 0;
  padding: 15px 12px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #ffffff;
  background-color: transparent;
  width: 100%;
}

.pricing_sec .select-sec .price-content {
  width: 100%;
  display: block;
  overflow: hidden;
}

.pricing_sec .select-sec select:focus-visible {
  outline: none;
}

.pricing_sec .select-sec select option {
  margin: 0;
  background: #fff;
  color: #000;
  cursor: pointer;
}

.pricing_sec .price-content ul {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 20px;
}

.pricing_sec .price-content ul li {
  width: 100%;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.pricing_sec .price-content ul li:last-child {
  display: flex;
  align-items: center;
  justify-content: center;

  border-bottom: none;
}

.pricing_sec .price-content ul li span {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  text-transform: capitalize;
  color: #ffffff;
}

.pricing_sec .price-content button.book-nowbtn {
  min-width: 280px;
  text-align: center;
  background: #00eaff;
  border-radius: 15px;
  padding: 15px 30px;
  font-size: 20px;
  line-height: 32px;
  text-transform: uppercase;
  color: #000000;
  font-weight: bold;
}

.pricing_sec .price-content button.view-prize-breakup {
  display: flex;
  align-items: center;
  padding: 10px;
}

.pricing_sec .price-content button.view-prize-breakup span {
  font-size: 16px;
  line-height: 24px;
  margin-left: 10px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  text-decoration-line: underline;
}

.pricing_sec p.info {
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 5px;
}

.pricing_sec .mobile {
  display: none;
}

#PricingBreakupModal.modal_popup.test_ride_popup .pure-modal-backdrop {
  background-color: #000;
}

#PricingBreakupModal.modal_popup.test_ride_popup .pure-modal-backdrop:before,
#PricingBreakupModal.modal_popup.test_ride_popup .pure-modal-backdrop:after {
  display: none;
}

#PricingBreakupModal .pure-modal {
  position: relative;
  padding: 30px 20px;
}

.learn_more_popupcont .price_sec > ul:last-child > li:last-child {
  text-transform: initial;
}

#PricingBreakupModal .pure-modal .close {
  right: 30px !important;
  top: 30px !important;
}

#PricingBreakupModal .pure-modal .panel-body {
  max-width: 700px;
  margin: 0px auto;
  width: 100%;
  text-align: left;
  padding: 0px;
}

#PricingBreakupModal .panel-body h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 25px;
}

#PricingBreakupModal ul {
  margin-bottom: 30px;
  width: 100%;
  padding: 0px;
  list-style-type: none;
}

#PricingBreakupModal ul:last-child {
  margin-bottom: 0px;
}

#PricingBreakupModal ul li {
  width: 100%;
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  text-transform: capitalize;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  position: relative;
}

#PricingBreakupModal ul:last-child li {
  padding: 5px 0px;
}

#PricingBreakupModal ul li:after {
  position: absolute;
  content: "";
  width: 95%;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: rgb(255 255 255 /40%);
}

#PricingBreakupModal ul:last-child li:after {
  display: none;
}

#PricingBreakupModal ul li span:last-child {
  white-space: nowrap;
  min-width: 100px;
  text-align: right;
}

#PricingBreakupModal ul li span:first-child {
  padding-right: 20px;
}

.tech_specs_sec .sec_heading p {
  font-size: 24px;
  margin-bottom: 15px;
  color: #9d9d9f;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

.Specs_conbg {
  background: transparent;
  position: relative;
  border: none;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.hop-leo .Specs_conbg::after {
  display: none;
}

.Specs_conbg::after {
  background: url(../../assets/images/specs_logo.png);
  content: "";
  height: 493px;
  right: 0px;
  left: 0;
  width: calc(100% - 40px);
  margin: auto;
  top: 40%;
  transform: translateY(-50%);
  position: absolute;
  background-size: contain;
  background-position: center;
  z-index: -1;
  background-repeat: no-repeat;
}

.tech_deails {
  display: flex;
  flex-wrap: wrap;
  padding: 46px;
  position: relative;
  z-index: 1;
  margin-bottom: 0px;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  height: calc(100% - 110px);
  margin: 0px;
}

.click_bottom button figure img {
  z-index: 8;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
}

.tech_specs_sec .sec_heading {
  margin-bottom: 50px;
  display: inline-table;
}

.click_bottom button {
  background: #0edafb;
  height: 100px;
  width: 100px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0px -10px 15px #0edafb;
  border: none;
}

.click_bottom {
  text-align: center;
  margin-top: -90px;
  position: relative;
}

.features_sec .three_gra::before {
  background: linear-gradient(211deg, #13204d, #13204d);
  border-radius: 50%;
  content: "";
  filter: blur(60px);
  height: 730px;
  position: absolute;
  right: -200px;
  top: -70px;
  width: 600px;
  z-index: -1;
}

.locater .sec_heading {
  position: relative;
  z-index: 0;
}

.locater {
  background-color: #0a0a0a;
  position: relative;
  z-index: 0;
  margin-top: -1px;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 60px);
  scroll-snap-align: start;
  padding-bottom: 60px;
}

.locater .landing_page_container {
  padding-top: 60px;
}

.tech_specs_sec .landing_page_container {
  position: relative;
  z-index: 2;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 60px 20px;
}
.tech_specs_sec .landing_page_container .locater .sec_heading h2 {
  margin-bottom: 60px;
}
.home_testride_page .sec_heading h2 {
  margin-bottom: 60px;
}

.locater figure img {
  width: 100%;
}

.reviews {
  background-color: #0a0a0a;
  position: relative;
  overflow: hidden;
  z-index: 0;
  width: 100%;
  height: 100vh;
  scroll-snap-align: start;
}

.reviews .landing_page_container {
  height: 100%;
  width: 100%;
  padding: 60px 20px;
}

.reviews::after {
  background: linear-gradient(211deg, #2a63a3, #2a63a3);
  border-radius: 50%;
  content: "";
  filter: blur(70px);
  height: 740px;
  position: absolute;
  left: -250px;
  bottom: -300px;
  width: 660px;
  z-index: -1;
}

.reviews::before {
  background: linear-gradient(211deg, #14204e, #14204e);
  border-radius: 50%;
  content: "";
  filter: blur(60px);
  height: 450px;
  position: absolute;
  right: -200px;
  top: -150px;
  width: 600px;
  z-index: 0;
}

.reviews .uk-container {
  position: relative;
  z-index: 2;
}

.reviews .sec_heading h2 {
  margin-bottom: 42px;
}

.uk-grid .uk-colum_3 {
  width: 33.33%;
  margin: auto;
  padding: 0px 15px;
}

.reviews .three_img {
  position: relative;
  padding: 0;
  width: calc(33.33% - 26px);
  margin: 0px 13px;
  background: rgb(0 0 0 / 60%);
  border-radius: 10px;
  margin-bottom: 30px;
  height: calc(50% - 26px);
  overflow: hidden;
}

.three_img .click_img {
  padding: 24px 24px;
  border-radius: 0px 0px 10px 10px;
  position: absolute;
  bottom: 0;
}

.three_img .click_img img {
  width: auto;
}

.three_img .click_img button {
  background: transparent;
  border: none;
}

.three_img span {
  font-size: 24px;
  color: #ffffff;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  margin-bottom: 8px;
  display: block;
}

.three_img figure img {
  width: 100%;
  border-radius: 10px;
}

.three_img .click_img p {
  margin: 0px;
  color: rgb(255 255 255 / 60%);
  font-size: 14px;
}

/* .faq_sec {
    background-color: #0a0a0a;
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 100%;
    height: 100vh;
    scroll-snap-align: start;
} */

.faq_sec .landing_page_container {
  height: 100%;
  width: 100%;
  padding: 60px 20px;
  max-width: 1610px !important;
}

.faq_sec .Accordion_sec {
  height: calc(100% - 120px);
  padding-top: 30px;
  overflow-y: auto;
  display: none;
}

.faq_sec .Accordion_sec::-webkit-scrollbar {
  display: none;
}

.faq_sec::after {
  background: linear-gradient(211deg, #2a63a3, #2a63a3);
  border-radius: 0% 80% 0% 0%;
  content: "";
  filter: blur(60px);
  height: 460px;
  position: absolute;
  left: -300px;
  bottom: -150px;
  width: 900px;
  z-index: -1;
}

.faq_sec .uk-container {
  position: relative;
  z-index: 2;
}

/* .faq_sec .sec_heading {
    margin-bottom: 55px;
} */

.faq_sec .sec_heading {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 100%;
}

.faq_sec h2 {
  display: inline-block;
  margin: 0;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  padding: 0 40px;
  font-size: 70px;
  line-height: 1.2em;
  color: #00eaff;
}

.faq_sec h2:nth-child(1) {
  padding-left: 0px;
}

.faq_sec h2 a {
  color: #00eaff;
}

.faq_sec h2 a:hover,
.faq_sec h2 a:focus {
  color: #fff;
}

.faq_sec h2:nth-child(3) {
  border-right: none;
}

/* 
.faq_sec:before {
    background: linear-gradient(211deg, #13204d, #13204d);
    border-radius: 50%;
    content: "";
    filter: blur(60px);
    height: 650px;
    position: absolute;
    right: -200px;
    top: -300px;
    width: 650px;
    z-index: 0;
} */

.show_details_popup {
  color: #fff;
  font-family: "Khand", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0.05em;
  transition: all 0.3s;
  background: transparent;
  border: none;
  cursor: pointer;
}

.show_details_popup:hover {
  color: #e1e0e0cc;
}

.Dealers_sec {
  position: absolute;
  right: 250px;
  top: 180px;
  padding: 24px 24px;
  z-index: 0;
  border-radius: 16px;
}

.Dealers_sec ul {
  width: 456px;
  height: 360px;
  overflow: auto;
  border-radius: 16px;
}

.Dealers_sec ul::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(30px);
  border-radius: 16px;
}

.locater .map_sec .css-13c2lq1 {
  height: 90%;
  width: 100%;
}

.Dealers_sec h5 {
  font-size: 24px;
  color: #ffffff;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  margin-bottom: 24px;
}

.Dealers_sec ul li {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  padding: 24px 24px;
  margin-bottom: 8px;
}

.Dealers_sec ul li strong {
  font-size: 16px;
  color: #ffffff;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  margin-bottom: 8px;
  line-height: normal;
  display: block;
}

.Dealers_sec ul li button.default_btn {
  color: #000;
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  text-transform: capitalize;
  font-size: 14px;
  padding: 8px 38px 8px 22px;
  border: none;
}

.Dealers_sec ul li button.default_btn::after {
  background: url(../../assets/images/directions.png);
  content: "";
  height: 14px;
  right: 13px;
  width: 14px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}

.Dealers_sec ul {
  padding: 0;
  margin: 0;
}

.Dealers_sec ul li p {
  font-size: 14px;
  color: #9b9ea0;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  margin-bottom: 16px;
  margin-top: 0;
  text-transform: capitalize;
}

.btn_co_book::before {
  display: none;
}

.btn_co_book {
  width: 270px;
  margin: auto;
  margin-top: 60px;
}

.close_btn {
  position: relative;
  margin-right: 80px;
  margin-top: 30px;
}

.close_btn::before {
  content: "";
  background: #fff;
  height: 4px;
  width: 36px;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 2px;
  cursor: pointer;
  bottom: 10px;
}

.close_btn::after {
  content: "";
  background: #fff;
  height: 4px;
  width: 36px;
  position: absolute;
  transform: rotate(135deg);
  border-radius: 2px;
  cursor: pointer;
  bottom: 10px;
}

.close {
  right: 300px;
  top: 200px;
}

.form-group .css-1s2u09g-control .css-319lph-ValueContainer {
  font-size: 16px;
  color: #9ca0a3;
  font-weight: 900;
  letter-spacing: 1px;
  font-family: "Lato", sans-serif;
}

.slider_popup .popup_cont h2 {
  font-size: 36px !important;
}

.slider_popup .popup_cont {
  padding-top: 0px;
  text-align: left;
  max-width: 835px;
  margin: 15px auto;
}

.slider_popup .popup_cont h2 {
  font-size: 36px !important;
}

.slider_popup .slick-track img {
  width: 100%;
  margin: auto;
  border-radius: 10px;
}

.slider_popup .popup_cont .slick-dots {
  margin-bottom: 30px;
}

.slider_popup .popup_cont .slick-dots li button {
  height: 8px;
  width: 8px;
  background: #656565;
  border-radius: 50px;
  margin: 0;
}

.slider_popup .popup_cont .slick-dots .slick-active button {
  background: #c4c4c4;
}

.slider_popup .popup_cont .slick-slider .slide_bike_showcon {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0px 60px 50px;
}

.slider_popup .popup_cont .slick-slide {
  position: relative;
}

.slider_popup .popup_cont .slick-slide::before {
  background: rgb(2, 2, 2);
  background: linear-gradient(
    0deg,
    rgba(2, 2, 2, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  border-radius: 10px;
}

.slider_popup .popup_cont .slick-slider .slide_bike_showcon h5 {
  color: #ffffff;
  font-family: Khand, sans-serif;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
  line-height: 32px;
}

.slider_popup .popup_cont .slick-slider .slide_bike_showcon p {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  line-height: 32px;
  color: #ffffff;
}

.slider_popup .popup_cont button.slick-arrow {
  display: none !important;
}

.slider_popup .popup_cont .slick-dots li button::before {
  display: none;
}

.slider_popup .popup_cont button.slick-arrow {
  display: none !important;
}

.slider_popup .popup_cont .slick-dots li {
  width: auto;
  margin: 0 3px;
  height: auto;
}

.faqs {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px;
}

.faq.open .faq-question::after {
  right: 120px !important;
}

.faqs .faq {
  margin: -45px 0px 0px 0px;
  padding: 45px 210px 45px 120px;
  background-color: rgb(0 0 0 / 41%);
  border-radius: 24px;
  box-shadow: 0px 7px 7px rgb(0 0 0 / 20%);
  position: relative;
  cursor: pointer;
}

.faqs .faq .faq-question {
  font-size: 24px;
  transition: all 0.4s ease;
  font-family: Lato, sans-serif;
  font-weight: 500;
  line-height: 38px;
  color: rgb(255 255 255 / 60%);
}

.faqs .faq .faq-question::after {
  content: "";
  position: absolute;
  top: 55px;
  right: 150px;
  width: 20px;
  height: 20px;
  background-image: url("../../assets/images/arrow-d.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.4s ease-out;
}

.faqs .faq.open .faq-question::after {
  content: "";
  position: absolute;
  top: 40px;
  right: 150px !important;
  width: 20px;
  height: 20px;
  background-image: url("../../assets/images/arrow-u.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.4s ease-out;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.4s ease-out;
}

.faqs .faq.open .faq-question {
  margin-bottom: 10px;
  font-weight: 500;
  color: rgb(10 10 10 / 80%);
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
  font-size: 18px;
  color: rgb(10 10 10 / 80%);
  margin-bottom: 10px;
  font-family: Lato, sans-serif;
}

.faq.open {
  background: #0edafb;
  color: rgb(10 10 10 / 80%);
  margin-left: -110px;
  margin-right: -110px;
  box-shadow: 0px 4px 4px #0edafb;
  padding: 32px 200px 90px 120px;
}

/* 2-7-2022 */
.map_form {
  padding: 24px 72px;
  position: absolute;
  top: 32px;
  left: 0;
  right: 0;
  z-index: 9;
  margin: auto;
  max-width: 696px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  background: rgb(0 0 0 / 50%);
}

.map_form::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(30px);
  border-radius: 16px;
}

.map_sec {
  position: relative;
}

.map_form form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-group input {
  background: #d9d9d9;
  border-radius: 8px;
  font-size: 16px;
  color: #000;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: "Lato", sans-serif;
  border: none;
  outline: none;
  padding: 15px 37px;
}

.map_form form .search_bar {
  position: relative;
  margin-right: 24px;
}

.map_form form .search_bar button {
  border: none;
  background: transparent;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.map_form .form-group button.default_btn {
  border-radius: 8px;
  text-transform: capitalize;
}

.map_form form .search_bar:placeholder {
  color: #000;
}

.modal_popup .pure-modal-backdrop .popup_cont form span.error {
  color: #f00;
}

/*360-view css*/
.img_bike div#carDefault .v360-viewer-container .v360-viewport {
  background: transparent;
}

.img_bike .carContent .v360-viewer-container .v360-viewport {
  background: transparent;
}

i.fas.fa-expand.text-lg {
  background: transparent;
  color: transparent;
}

.reviews .three_img .click_img::before {
  display: none;
}

.bike_color .img_bike div#v360-menu-btns {
  display: none !important;
}

.uk-container {
  max-width: 1920px;
  margin: auto;
  padding: 0px 80px;
}

.reviews .uk-grid {
  height: calc(100% - 75px);
  width: calc(100% + 26px);
  margin: 0px -13px;
}

.features .bg_colorfu .uk-grid {
  margin: auto;
}

.features .img_sha figure img {
  width: 100%;
}

.tech_specs_sec .sec_heading {
  position: relative;
  z-index: 1;
}

.tech_specs_sec .btn-group-price {
  display: none;
}

.Features_section {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  scroll-snap-align: start;
  position: relative;
}

.Features_section.first_fedtsec {
  padding: 60px 0px 0px 0px;
  z-index: 2;
  position: relative;
}

.features_step {
  position: relative;
  height: 100%;
  width: 100%;
}

.features_step::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 23%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.7987570028011204) 100%
  );
}

.Features_section .features_step figure {
  height: calc(100% - 60px);
  width: 100%;
  overflow: hidden;
}

.Features_section .features_step figure video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Features_section .vimeo-video {
  width: 220%;
  height: 100%;
  position: relative;
  left: -60%;
  z-index: 0;
}

.Features_section .features_step figure video.mobile {
  display: none;
}

.Features_section .features_step .landing_page_container.fea_hea {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 30px;
  z-index: 10;
}

.Features_section::after {
  background: linear-gradient(211deg, #2a63a3, #2a63a3);
  content: "";
  border-radius: 50%;
  filter: blur(60px);
  height: 800px;
  position: absolute;
  left: -300px;
  top: -500px;
  width: 750px;
  z-index: -1;
}

.Features_section:before {
  background: linear-gradient(211deg, #13204d, #13204d);
  border-radius: 50%;
  content: "";
  filter: blur(60px);
  height: 600px;
  position: absolute;
  right: -400px;
  bottom: -300px;
  width: 800px;
  z-index: 0;
}

.Features_section.first_fedtsec .features_step {
  height: calc(100% - 130px);
}

.features_step .landing_page_container.features_cont {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  z-index: 0;
}

.featur_show .default_btn {
  background: rgba(0, 234, 255, 0.1);
  border: 1px solid #00eaff;
  color: #00eaff;
  font-size: 16px;
  padding: 18px 55px;
  cursor: pointer;
  transition: all 0.3s;
  width: 18%;
}

.featur_show .default_btn:hover {
  background: #fff;
  color: #000;
  border: 1px solid #fff;
}

.features_step h2.sec_ste {
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 20px;
  color: #ffffff;
  font-family: "Lato", sans-serif;
}

.features_cont .featur_show {
  padding: 40px 40px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 0;
}

.features_cont .featur_show::before {
  content: "";
  position: absolute;
  left: 20px;
  right: 0;
  top: 0;
  width: calc(100% - 40px);
  height: 100%;
  z-index: -1;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.featur_show .featur_para {
  width: 80%;
  padding-right: 30px;
}

.featur_show .featur_para h3 {
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 15px;
  color: rgb(255 255 255 / 80%);
  font-family: "Lato", sans-serif;
}

.featur_show .featur_para p {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 5px;
  color: rgb(255 255 255 / 60%);
  font-family: "Lato", sans-serif;
  line-height: 32px;
  margin-top: 0;
}

.features_cont .featur_show button.button {
  width: 20%;
  margin-left: 50px;
}

.Features_section .sec_heading {
  margin-bottom: 40px;
  z-index: 0;
  position: relative;
}

.features_step .pure-modal-backdrop .pure-modal .panel-body {
  padding: 20px 20px 20px 100px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.features_step .pure-modal-backdrop .pure-modal .panel-body > .slider_show {
  display: block;
  width: 100%;
  height: auto;
}

.features_step
  .pure-modal-backdrop
  .pure-modal
  .panel-body
  > .slider_show
  .slick-track {
  display: flex;
}

.features_step
  .pure-modal-backdrop
  .pure-modal
  .panel-body
  > .slider_show
  .slick-track
  .slick-slide {
  height: auto;
}

.features_step
  .pure-modal-backdrop
  .panel-body
  > .slider_show
  .slick-track
  .slick-slide
  > div {
  height: 100%;
  position: relative;
}

.features_step .pure-modal-backdrop .pure-modal {
  width: 90% !important;
  height: 100%;
  margin-left: auto;
  -webkit-animation-name: animateright;
  -webkit-animation-duration: 0.8s;
  animation-name: animateright;
  animation-duration: 0.6s;
  background: #0a0a0a;
  border-radius: 64px 0px 0px 0px;
}

.feature-content {
  -webkit-animation-name: animateright;
  -webkit-animation-duration: 0.8s;
  animation-name: animateright;
  animation-duration: 0.6s;
}

@-webkit-keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
}

@keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
}

.features_step .pure-modal-backdrop .pure-modal .close {
  left: -35px;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 234, 255, 0.1);
  border: 1px solid #00eaff;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border-radius: 50%;
  color: #00eaff;
  height: 64px;
  width: 64px;
  line-height: 52px;
  font-size: 45px;
  margin-left: 3px;
}

.features_step .slider_show .slick-list .pop_slideron figure img {
  width: 100%;
  height: 100%;
}

.features_step .slider_show .slick-list .pop_slideron .slide_cont {
  padding: 40px 32px;
  /* transform: translateY(-160px); */
  border-top: none;
  border-radius: 16px;
  position: relative;
  z-index: 1;
  height: 40%;
  position: absolute;
  left: 30px;
  bottom: 0;
  width: calc(100% - 60px);
}

.features_step .slider_show .slick-list .pop_slideron figure {
  background-color: #686868;
  border-radius: 24px;
  height: 80%;
  overflow: hidden;
}

.features_step .slider_show .slick-list .pop_slideron .slide_cont::before {
  content: "";
  background: rgba(10, 10, 10, 0.6);
  -webkit-backdrop-filter: blur(16.25px);
  backdrop-filter: blur(16.25px);
  border-radius: 0px 0px 24px 24px;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 0px;
  border-top: none;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to bottom, rgba(0 0 0 /0%), #00eaff) 1;
  border-top: none;
  background: rgba(10, 10, 10, 0.6);
  backdrop-filter: blur(16.25px);
}

.features_step .slider_show .slick-list .pop_slideron .slide_cont h3 {
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 10px;
  color: rgb(255 255 255 / 80%);
  font-family: "Lato", sans-serif;
}

.features_step .slider_show .slick-list .pop_slideron .slide_cont p {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 0px;
  color: rgb(255 255 255 / 60%);
  font-family: "Lato", sans-serif;
  line-height: 32px;
  margin-top: 0;
}

.features_step .slider_show .slick-slider button.slick-arrow {
  position: absolute;
  width: 50px;
  height: 50px;
  border: solid 1px #00eaff;
  border-radius: 50%;
  overflow: hidden;
  z-index: 10;
  background: rgba(0, 234, 255, 1);
  left: 5px;
}

.features_step .slider_show .slick-slider button.slick-arrow.slick-next {
  right: 5px;
  left: inherit;
}

.features_step .slider_show .slick-slider button.slick-arrow.slick-disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

.features_step .slider_show .slick-slider button.slick-arrow::before {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00eaff;
  content: "";
  background-image: url("../../assets/images/slider-left-arrow.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  opacity: 1;
  transform: translate(-50%, -50%);
}

.features_step
  .slider_show
  .slick-slider
  button.slick-arrow.slick-next::before {
  background-image: url("../../assets/images/slider-right-arrow.png");
}

.click_pop {
  text-align: center;
  z-index: 9;
  position: absolute;
  left: 0;
  right: 0;
}

.click_pop .open_pop_on {
  cursor: pointer;
  top: 60%;
  transform: translateY(-52%);
  background: rgba(0, 234, 255, 0.1);
  border: 1px solid #00eaff;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border-radius: 50%;
  color: #00eaff;
  height: 64px;
  width: 64px;
  font-size: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.btn_banner button.default_btn.mobile_btn {
  display: none;
}

.landing_banner:before {
  content: "";
  background: linear-gradient(
    180deg,
    rgba(10, 10, 10, 1) 0%,
    rgba(10, 10, 10, 0) 100%
  );
  position: absolute;
  top: 0;
  height: 240px;
  left: 0;
  right: 0;
}

.video_sec_step .slide_mobile {
  display: none;
}

.full_video_bike .logo_cont.on_mo {
  display: none;
}

.featur_para.pop_slideron {
  padding: 0px 30px;
  height: 100%;
  min-height: 700px;
}

.map_form.mobile_v {
  display: none;
}

.features_step .pure-modal-backdrop {
  background: transparent;
}

.features_step figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}

.on_mobile_mapslide {
  display: none;
}

span.review_btn {
  display: none;
}

.onmobile_360 {
  display: none;
}

.destop_v .form-group button.default_btn {
  font-size: 16px;
  padding: 12px 15px;
  line-height: 24px;
  font-weight: 700;
  width: 100%;
}

.map_form .search_bar {
  position: relative;
}

.map_form .search_bar input.pac-target-input {
  font-size: 16px;
  padding: 12px 16px;
  padding-right: 40px;
  line-height: 24px;
  font-weight: 700;
  color: rgb(0 0 0 / 80%);
  width: 100%;
}

.map_form .form-group.search_bar {
  margin-right: 24px;
}

.map_form .form-group {
  width: 50%;
}

.map_form .search_bar img {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

/* new- map css start  */
.locater .map_sec {
  width: 100%;
  height: 100%;
}

/* new- map css end  */

/* pradip 9-8-22 */
#circle1 {
  content: "";
  position: absolute;
  left: 272px;
  height: 41px;
  width: 41px;
  text-align: center;
  border-radius: 50%;
  outline: 2px solid #50ebff;
  outline-offset: 8px;
  margin-right: 15px;
  z-index: 1;
  top: 39px;
}

#circle2 {
  content: "";
  position: absolute;
  left: 559px;
  height: 41px;
  width: 41px;
  text-align: center;
  border-radius: 50%;
  outline: 2px solid #50ebff;
  outline-offset: 8px;
  margin-right: 15px;
  z-index: 1;
  top: 39px;
}

#circle3 {
  content: "";
  position: absolute;
  left: 886px;
  height: 41px;
  width: 41px;
  text-align: center;
  border-radius: 50%;
  outline: 2px solid #50ebff;
  outline-offset: 8px;
  margin-right: 15px;
  z-index: 1;
  top: 39px;
}

#circle4 {
  content: "";
  position: absolute;
  left: 1216px;
  height: 41px;
  width: 41px;
  text-align: center;
  border-radius: 50%;
  outline: 2px solid #50ebff;
  outline-offset: 8px;
  margin-right: 15px;
  z-index: 1;
  top: 39px;
}

/* pradip 9-8-22 */
.footer_col .logo a {
  display: inline-block;
}

.tech_specs_sec .panel-body.scrollable {
  background: transparent;
}

.color_step form .form-check input.acive::before,
.color_step.onmobile_360 form .form-check label.acive::before {
  outline: 1px solid #50ebff;
}

.bike_color .landing_page_container .sec_heading h2 {
  font-family: "Lato", sans-serif;
}

.bike_color .landing_page_container .sec_heading h2 sup {
  font-size: 16px;
  top: -1.5em;
}

/* new */
.newtech_specs {
  padding: 0px 35px 45px 35px;
}

.newtech_specs .top_sec_oxo_x {
  margin-bottom: 15px;
}

.newtech_specs .top_sec_oxo_x li {
  list-style: none;
  font-family: "Lato";
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #ffffff;
}

.newtech_specs ul {
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.newtech_specs .info {
  margin-top: 40px;
}

.newtech_specs .info p {
  font-size: 14px;
  color: #fff;
  margin-bottom: 10px;
}

.newtech_specs .info p:last-child {
  margin-bottom: 0px;
}

.newtech_specs h5 {
  width: 100%;
  font-family: "Raleway";
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  color: #ffffff;
  margin-bottom: 15px;
  margin-left: -15px;
}

.on_popup .newtech_specs h5 {
  margin-top: 20px;
}

.newtech_specs ul li {
  /* width: 33.33%; */
  width: 25%;
  text-align: left;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  color: rgb(255 255 255 / 60%);
  margin-bottom: 2px;
  list-style: disc;
  padding-right: 20px;
  word-break: break-word;
}

.on_popup .newtech_specs ul li {
  padding-right: 35px;
}

.on_popup .newtech_specs ul li ul.color-ul {
  padding: 0px !important;
}

.on_popup .newtech_specs ul li ul.color-ul li {
  width: 100%;
  padding: 0px;
  display: block !important;
  list-style-type: disc !important;
}

.newtech_specs ul li.firs_heading {
  color: #ffffff;
}

.map_sec .gmnoprint {
  left: 30px;
}

.graphics_sec .Confirmation_left .after_savings figure.gra_logo {
  display: none;
}

.imp_modal.pure-modal {
  background-color: rgb(0 0 0 / 40%);
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  width: calc(100% - 40px);
  max-width: 1000px;
  padding: 40px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin: 0px 20px;
}

.imp_modal.pure-modal .panel-body {
  background-color: transparent;
  padding: 0px;
  word-break: normal;
}

.imp_modal.pure-modal .close {
  background-color: transparent;
  color: #fff;
  opacity: 1;
  font-size: 36px;
  position: absolute;
  top: 10px;
  right: 10px;
  height: auto;
}

.imp_modal.pure-modal .panel-body h2 {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 500;
  border-bottom: solid 1px rgb(255 255 255 / 30%);
  padding-bottom: 20px;
  margin-bottom: 25px;
}

.imp_modal.pure-modal .panel-body h3 {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 15px;
}

.imp_modal.pure-modal .panel-body p {
  color: rgb(255 255 255 / 75%);
  font-size: 18px;
  margin-bottom: 15px;
}

.imp_modal.pure-modal .panel-body p:last-child {
  margin-bottom: 0px;
}

/* #privacy-policy .payment_secmain .payment_cont .content {
    width: 100%;
    height: 100%;
    overflow: hidden scroll;
} */

/* #privacy-policy .payment_secmain .payment_cont .content::-webkit-scrollbar {
    display: none;
} */

/* #privacy-policy .payment_secmain .payment_cont .container {
    max-width: 1000px;
    width: 100%;
    margin: 0px auto;
} */

/* #privacy-policy .payment_secmain .payment_cont p {
    color: rgb(255, 255, 255, 0.75);
    font-size: 16px;
    margin-bottom: 25px;
} */

/* #privacy-policy .payment_secmain .payment_cont h5 {
    color: #fff;
    font-size: 20px;
    margin-bottom: 25px;
    font-weight: 600;
} */

/* #privacy-policy .payment_secmain .payment_cont ul {
    padding-left: 40px;
    margin-bottom: 25px;
    list-style-type: disc;
} */

/* #privacy-policy .payment_secmain .payment_cont ul li {
    color: rgb(255, 255, 255, 0.75);
    font-size: 16px;
    margin-bottom: 15px;
    list-style-type: disc;
} */

#otpsendOrder form .react_form_group input {
  padding: 24px 15px 24px 60px;
}

#otpsendOrder form .react_form_group .in_img img {
  border-radius: 0px;
}

#otpsendOrder form .react_form_group .number input {
  padding-left: 135px;
}

#otpsendOrder form .react_form_group .number .in_img img {
  border-radius: 50%;
}

/* faq sec css started */

.faq_sec1 {
  display: flex;
  /* flex-wrap: wrap; */
  margin-left: 15px;
  margin-right: 15px;
  height: 100vh;
  padding: 20px 0px;
}

.faq_sec1 .heading-3 {
  color: #0edafb;
  font-size: 20px;
  text-transform: uppercase;
  /* margin-bottom: 0.5rem; */
  font-weight: 700;
}

.faq_sec1 .faq-inner p {
  color: #a5a5a5;
  margin-bottom: 30px;
}

.faq-inner {
  padding: 10px 0px;
}

.faq_sec1 .faq-block h3 {
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  color: rgb(14 218 251 / 85%);
}

.faq_container {
  max-width: 1140px;
  padding: 0px 20px;
  margin: 0px auto;
}

.faq_left_sec {
  flex: 0 0 25%;
  max-width: 25%;
  height: 100%;
  overflow-y: scroll;
}

.faq_left_sec a.scroller {
  display: block;
  padding: 10px;
  text-decoration: none;
  border-bottom: 1px solid aqua;
  color: #ffff;
}

.faq_right_sec {
  flex: 0 0 72%;
  max-width: 73%;
  height: 100%;
  overflow-y: scroll;
  padding-left: 30px;
}

.faq_right_sec::-webkit-scrollbar {
  display: none;
}

.left-headers {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 100px;
  height: calc(100vh);
  overflow-y: auto;
}

.faq_sec1 .left-headers a {
  color: #0edafb;
  font-size: 16px;
  border: 1px solid #9b9aa0;
  display: block;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  padding: 10px;
}

/* landing page footer css started */
.landing_footer {
  position: relative;
}

.landing_footer::before {
  content: "";
  background: linear-gradient(211deg, #0b132f, #0b132f);
  border-radius: 50%;
  filter: blur(60px);
  width: 700px;
  height: 760px;
  position: absolute;
  right: -350px;
  top: -380px;
  z-index: 3;
}

.landing_footer footer.footer::before {
  display: none !important;
}

.faq_sec {
  padding-top: 100px;
}

.for_hopleo .oxo_both_headar {
  text-align: left;
  color: #00eaff !important;
  font-size: 3rem !important;
  border-bottom: 1px solid #202020;
  line-height: normal !important;
  /* font-weight: 600; */
}

.for_hopleo .tabing_section h2 {
  margin-bottom: 20px;
}

.for_hopleo .tabing_section {
  margin-top: 30px !important;
  margin: auto;
}

.tabing_data_second {
  padding: 10px;
}

.tabing_data_second .content_sec h2 {
  font-size: 22px;
  text-align: left;
  margin-bottom: 5px;
  margin-top: 20px;
  color: #fff;
  margin-left: 7%;
}

.tabing_data_second .inner_cont {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 10px; */
  padding-left: 10px;
}

.tabing_data_second .inner_cont .content_main {
  display: flex;
}

.tabing_data_second .inner_cont .content_main,
.tabing_data_second .inner_cont .content_main li {
  /* width: 50%; */
  width: 100%;
  margin-bottom: 3px;
}

.tabing_data_second .inner_cont .content_main li {
  margin-left: 10%;
}

.tabing_data_second .inner_cont .content_main .left {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  list-style-type: disc;
}

.tabing_data_second .inner_cont .content_main .right,
.tabing_data_second .inner_cont .content_main .third {
  color: #8b8a8a;
  font-size: 18px;
  line-height: 30px;
}

.tech_specs_sec.for_hopleo {
  height: auto;
  scroll-snap-align: inherit;
  padding: 40px 0px;
}

.tech_specs_sec.for_hopleo::after,
.tech_specs_sec.for_hopleo::before {
  display: none;
}

.tabing_data_second .inner_cont .content_main li {
  width: 33.33%;
}

/* 
.tech_specs_sec.for_hopleo .tabing_data>div {
    width: auto;
} */

.tech_specs_sec.for_hopleo .pure-modal-backdrop {
  background-color: #000 !important;
}

.HOP_LEO_FAQ {
  background-color: #000;
  padding-top: 50px;
}

.Hop_leo_main .newsletter {
  padding: 0px !important;
  margin-top: 30px;
}

/* vikash css 25-05-2023 */
.form_btnsend.submit_buttons {
  display: block;
  margin: 10px auto 20px;
}

.submit_buttons {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  background: #00eaff;
  text-align: center;
  padding: 12px 25px;
  border-radius: 12px;
}

.form_main_sec select option {
  background: #fff;
  color: #000;
  cursor: pointer;
  margin: 0;
}

.last_ul_tech li:nth-child(2n + 1) {
  width: 30% !important;
}

.last_ul_tech li:nth-child(2n + 2) {
  width: 70% !important;
}

.right.oxo_both_headar,
.third.oxo_both_headar {
  border-bottom: none;
}

.oxo_both_headar.first {
  text-align: center;
}

.product_slider.for_leo .product_slider_tem {
  position: relative;
  /* height: 500PX; */
}

.product_slider.for_leo .product_slider_tem a p {
  position: absolute;
  bottom: 0px;
  left: 40%;
  font-size: 28px;
  min-width: 150px;
}

.product_slider.for_leo .product_slider_tem a figure {
  margin-top: -30px !important;
}

.my_book_modal.book_test .pure-modal-backdrop {
  height: fit-content !important;
}

.my_book_modal.book_test .pure-modal-backdrop .pure-modal {
  padding: 15px !important;
  overflow: initial;
}

.my_book_modal.book_test .panel.panel-default .close {
  top: -30px !important;
  right: -10px !important;
}

.coming_soon_sec {
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  color: #00eaffbd;
  font-weight: 600;
  margin-top: -10px;
  padding-top: 0px;
  /* text-align: center; */
}

.coming_soon_movbike:nth-child(1) {
  margin-left: 0px !important;
}

.coming_soon_movbike {
  font-size: 16px;
  margin-left: -16px !important;
  display: none;
  font-weight: 600;
}

.coming_soon_movbike2 {
  margin-left: 20px;
  margin-top: 10px;
}

/* 5-06-23 vikas css  */
.color_step.onmobile_360 form .form-check label {
  display: block;
}

.color_step.onmobile_360 form .form-check input {
  display: none;
}

/* new mobile view testride changs css  */

.onetwo_testcss {
  display: none;
}

.onetwo_testcss.enter_city {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.05em;
  color: #ffffff;
  opacity: 0.8;
  text-align: left;
}

.map_left.testride_map {
  display: none !important;
  height: 300px;
}

.show_mobile {
  display: none;
}

.mobile_labels_name {
  display: flex;
  padding-top: 30px;
}

.mobile_labels_name > div {
  width: 20%;
  color: #fff;
}

.bike_color_names {
  /* display: none; */
  opacity: 0;
}

.bike_color_names.acive {
  opacity: 1;
}

/* css for home testride  */
.home_testride_page #book-test-ride::before,
.home_testride_page .testride_righsec::before {
  display: none;
}

.home_testride_page .bike_detailcus {
  margin: 30px 40px 15px;
}

.home_testride_page .testride_map .testride_righsec {
  overflow: hidden;
  padding-top: 20px !important;
}
.home_testride_page .booktr_slider .select_dealer p {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
}
.home_testride_page .booktr_slider .select_dealer .default_btn {
  font-size: 12px;
  padding: 9px 11px;
  gap: 5px;
  width: fit-content;
}
/* .home_testride_page .search_bar .search_inp{
    background: rgba(0, 234, 255, 0.2);
    font-weight: 700;
} */
/* .home_testride_page .search_bar .search_icon button img{
  display: none;
}
.home_testride_page .search_bar .search_icon button svg path {
  fill:#00eaff ;
} */
/* .home_testride_page .search_bar .search_icon button svg{
  display: block;
} */
.home_testride_page .booktr_slider .select_dealer strong {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
}
::-webkit-scrollbar {
  display: none;
}

.home_testride_page .dealer_main .slick-prev,
.home_testride_page .dealer_main .slick-next {
  transform: translate(0, -70%);
}
/* .home_testride_page .booktr_slider .select_dealer p{
  color: #00eaff;
} */

.recaptchaPopup .pure-modal {
  padding: 0 !important;
  max-width: unset !important;
  width: auto !important;
  background-color: transparent !important;
  border: none !important;
  backdrop-filter: unset !important;
}

.recaptchaPopup .pure-modal .captcha {
  margin: 0;
  padding: 0;
}

/* ======================================= RESPONSIVE START =============================================== */

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .pricing_sec .sec_heading {
    margin-bottom: 30px;
  }

  .newtech_specs .top_sec_oxo_x li,
  .tabing_data_second .inner_cont .content_main .left {
    font-size: 24px;
    line-height: 28px;
  }

  .newtech_specs .top_sec_oxo_x li {
    font-size: 28px;
    line-height: 42px;
  }

  .newtech_specs ul li,
  .tabing_data_second .inner_cont .content_main .right,
  .tabing_data_second .inner_cont .content_main .third {
    font-size: 18px;
    line-height: 36px;
  }

  .newtech_specs h5 {
    font-size: 18px;
    line-height: 36px;
    margin-bottom: 15px;
  }

  .features_step .slider_show .slick-list .pop_slideron figure img {
    border-radius: 20px 20px 0px 0px;
  }

  .social_sec .social_link a > figure {
    height: 225px;
  }

  .youtube_facebook_sec .landing_page_container {
    padding: 50px 20px 50px 20px;
  }

  .youtube_facebook_sec h3 {
    font-size: 24px;
    line-height: 32px;
  }

  .youtube_facebook_sec::after {
    filter: blur(80px);
    height: 700px;
    left: -400px;
    bottom: -400px;
    width: 700px;
  }

  .youtube_facebook_sec::before {
    filter: blur(80px);
    width: 600px;
    height: 600px;
    right: -400px;
    bottom: -300px;
  }

  .subscribe_sec button.default_btn {
    padding: 15px 40px;
    font-size: 18px;
  }

  .social_sec .social_link .social_text {
    padding: 15px 20px;
  }

  .social_sec .social_link .social_text p {
    font-size: 18px;
    line-height: 22px;
  }

  .modal_popup.test_ride_popup .pure-modal-backdrop .popup_cont h2 {
    font-size: 38px;
    line-height: 62px;
  }

  .social_popup .social_popup_cont p {
    font-size: 22px;
    line-height: 26px;
  }

  .social_popup .social_popup_cont form .btn_sec {
    margin-top: 30px;
  }

  .thanks_popup .thanks_popup_cont .changethegame h2 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .thanks_popup .thanks_popup_cont .changethegame p {
    font-size: 26px;
    line-height: 34px;
  }

  .social_sec {
    max-width: 900px;
  }

  .graphics_colors form .form-check {
    position: relative;
  }

  .tech_specs_sec .pure-modal-backdrop .pure-modal {
    max-height: calc(100% - 100px);
  }

  .tech_specs_sec .pure-modal-backdrop .pure-modal {
    padding: 80px 110px 0px 110px;
  }

  .tech_specs_sec .panel-body.scrollable {
    padding-bottom: 80px;
  }

  .graphics_colors form .form-check label {
    margin-top: 0px;
  }

  .tech_specs_sec
    .pure-modal-backdrop
    .pure-modal
    > .panel.panel-default
    > .panel-body {
    padding-bottom: 80px;
  }

  .tech_specs_sec .landing_page_container {
    padding: 40px 20px;
  }

  .tech_specs_sec .sec_heading {
    margin-bottom: 20px;
  }

  .faq_sec .landing_page_container {
    padding: 40px 20px;
  }

  .faq_sec .Accordion_sec {
    bottom: 25px;
  }

  .faqs .faq {
    padding: 45px 180px 45px 90px;
  }

  .faq.open {
    margin-left: -110px;
    margin-right: -110px;
    padding: 32px 170px 90px 100px;
  }

  .faqs .faq.open .faq-question::after {
    right: 130px !important;
  }

  .reviews::before {
    height: 380px;
    right: -230px;
    top: -150px;
    width: 480px;
  }

  .reviews::after {
    height: 700px;
    left: -250px;
    bottom: -300px;
    width: 570px;
  }

  .features_step .pure-modal-backdrop .pure-modal .panel-default .panel-body {
    padding: 20px 0px 20px 60px;
  }

  .features_step .pure-modal-backdrop .pure-modal {
    width: 95% !important;
  }

  .Features_section:before {
    height: 500px;
    right: -300px;
    bottom: -200px;
    width: 550px;
  }

  .logo_cont {
    max-width: 390px;
    top: 20%;
  }

  .steps ul li span {
    font-size: 44px;
    line-height: 60px !important;
  }

  .steps ul li {
    margin-bottom: 25px;
  }

  .steps ul li span p {
    font-size: 22px;
  }

  .features_step h2.sec_ste {
    font-size: 38px;
  }

  .features_cont .featur_show {
    padding: 30px 30px;
  }

  .Features_section.first_fedtsec {
    padding: 40px 0px 0px 0px;
  }

  .Features_section.first_fedtsec .features_step {
    height: calc(100% - 90px);
  }

  .Features_section .sec_heading {
    margin-bottom: 30px;
  }

  .Features_section::after {
    height: 700px;
    left: -300px;
    top: -400px;
    width: 600px;
  }

  .featur_show .featur_para h3 {
    font-size: 30px;
  }

  .faq_sec::after {
    height: 400px;
    width: 700px;
  }

  .faq_sec:before {
    height: 550px;
    width: 550px;
  }

  .landig_banner_cont {
    padding: 25px 55px;
    bottom: 30px;
  }

  .Dealers_sec {
    right: 120px;
    top: 160px;
  }

  .Dealers_sec ul {
    width: 430px;
    height: 330px;
  }

  .Dealers_sec h5 {
    font-size: 22px;
    margin-bottom: 15px;
  }

  .default_btn {
    padding: 15px 40px;
  }

  .bike_color::after {
    height: 650px;
    width: 800px;
  }

  .tech_specs_sec::after {
    height: 750px;
    width: 800px;
  }

  .tech_specs_sec::before {
    height: 700px;
    width: 700px;
  }

  .locater::after {
    height: 750px;
    width: 720px;
  }

  .bike_color .change_color_bike {
    padding: 15px 30px;
  }

  .bike_color {
    padding: 40px 0px 25px 0px;
  }

  .bike_color .img_bike {
    max-width: 950px;
  }

  .bike_color .img_bike > figure {
    min-height: 450px;
  }

  .bike_color .img_bike::before {
    height: 330px;
    width: 950px;
  }

  .btn_slide_bike span:nth-child(2) {
    height: 26px;
    width: 26px;
    outline-offset: 10px;
  }

  .btn_slide_bike span {
    height: 20px;
    width: 20px;
    margin: 0px 30px;
  }

  .btn_slide_bike {
    bottom: -13px;
  }

  .bike_color::before {
    width: 800px;
    height: 900px;
  }

  .Specs_conbg::after {
    height: 440px;
    width: 1300px;
  }

  .tech_deails {
    height: calc(100% - 100px);
  }

  .locater {
    padding-bottom: 40px;
    height: calc(100vh - 40px);
  }

  .locater .landing_page_container {
    padding-top: 40px;
  }

  .locater .sec_heading h2 {
    margin-bottom: 40px;
  }

  .reviews .landing_page_container {
    padding: 30px 20px;
  }

  .reviews .sec_heading h2 {
    margin-bottom: 32px;
  }

  .three_img .click_img {
    padding: 15px 24px;
  }

  .three_img span {
    font-size: 22px;
  }

  .map_form {
    padding: 24px 50px;
  }

  .featur_para.pop_slideron {
    min-height: 620px;
  }

  .features_step .slider_show .slick-list .pop_slideron .slide_cont p {
    font-size: 18px;
    line-height: 30px;
  }

  .features_step .slider_show .slick-list .pop_slideron .slide_cont h3 {
    font-size: 26px;
  }

  .features_step .slider_show .slick-list .pop_slideron .slide_cont {
    padding: 25px 30px;
  }
}
/* @media only screen and (min-width: 767px) and (max-width: 1599px){
.home_testride_page .dealer_main  .slick-slider .slick-list .slick-track{
  
  margin-right: 10px;
}
} */
@media only screen and (max-width: 1599px) {
  .bike_color .img_bike::after {
    top: 188px;
    left: 4px;
    height: 190px;
    width: 700px;
    filter: blur(40px);
  }

  .pricing_sec .left_sec {
    width: 45%;
  }

  .pricing_sec .right_sec {
    width: 55%;
    padding-right: 10px;
  }

  .pricing_sec .landing_page_container {
    padding: 30px 20px;
  }

  .pricing_sec .varient {
    font-size: 20px;
    line-height: 30px;
  }

  .pricing_sec .sec_heading {
    margin-bottom: 30px;
  }

  .pricing_sec .oxo > .varient {
    margin-bottom: 10px;
  }

  .pricing_sec .select-sec select {
    padding: 12px;
  }

  .pricing_sec .price-content ul li {
    padding: 15px 0px;
  }

  .pricing_sec .price-content ul li span {
    font-size: 20px;
    line-height: 30px;
  }

  .pricing_sec .price-content button.book-nowbtn {
    min-width: 250px;
    border-radius: 12px;
    padding: 12px 25px;
  }

  .pricing_sec .right_sec .btn-group-price button {
    font-size: 22px;
    line-height: 32px;
  }

  .pricing_sec .right_sec .btn-group-price {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1599px) {
  .faq_sec .faq.open {
    margin-left: -65px;
    margin-right: -65px;
  }

  .faq_sec .faqs {
    max-width: 1300px;
  }

  .featur_para.pop_slideron {
    min-height: 450px !important;
  }

  .features_step .slider_show .slick-list .pop_slideron .slide_cont h3 {
    font-size: 20px !important;
    margin-bottom: 10px !important;
  }

  .features_step .slider_show .slick-list .pop_slideron .slide_cont p {
    font-size: 15px !important;
    line-height: 23px !important;
  }
}

@media only screen and (max-width: 1440px) {
  .product_slider.for_leo .product_slider_tem a p {
    left: 38%;
    font-size: 25px;
  }

  .pricing_sec .left_sec {
    width: 40%;
    padding-right: 50px;
  }

  .pricing_sec .right_sec {
    width: 55%;
    padding-right: 10px;
  }

  .pricing_sec .landing_page_container {
    padding: 30px 20px;
  }

  .pricing_sec .varient {
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 7px;
  }

  .pricing_sec .sec_heading {
    margin-bottom: 30px;
  }

  .pricing_sec .oxo > .varient {
    margin-bottom: 7px;
  }

  .pricing_sec .select-sec select {
    padding: 12px;
  }

  .pricing_sec .price-content ul li {
    padding: 12px 0px;
  }

  .pricing_sec .price-content ul li span {
    font-size: 18px;
    line-height: 30px;
  }

  .pricing_sec .price-content button.book-nowbtn {
    min-width: 250px;
    border-radius: 10px;
    padding: 10px 20px;
  }

  .pricing_sec .right_sec .btn-group-price button {
    font-size: 20px;
    line-height: 30px;
    border-radius: 7px;
    padding: 8px 20px;
    min-width: 200px;
  }

  .pricing_sec .right_sec .btn-group-price {
    margin-bottom: 5px;
    border-radius: 10px;
  }

  .pricing_sec .select-sec select {
    padding: 10px;
  }

  #otpsendOrder form .react_form_group input {
    padding: 18px 15px 18px 60px;
  }
  .pricing_sec .landing_page_container {
    height: auto;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .newtech_specs .top_sec_oxo_x li {
    font-size: 24px;
    line-height: 28px;
  }

  .newtech_specs .top_sec_oxo_x li {
    font-size: 28px;
    line-height: 42px;
  }

  .newtech_specs ul li {
    font-size: 18px;
    line-height: 30px;
  }

  .newtech_specs h5 {
    font-size: 18px;
    line-height: 36px;
    margin-bottom: 5px;
  }

  .newtech_specs .top_sec_oxo_x {
    margin-bottom: 10px;
  }

  .social_sec .social_link a > figure {
    height: 185px;
  }

  .featur_para.pop_slideron {
    min-height: 600px;
  }

  .features_step .slider_show .slick-list .pop_slideron .slide_cont p {
    font-size: 14px;
    line-height: 23px;
  }

  .features_step .slider_show .slick-list .pop_slideron .slide_cont h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .youtube_facebook_sec .landing_page_container {
    padding: 40px 20px 40px 20px;
  }

  .youtube_facebook_sec h3 {
    font-size: 20px;
    line-height: 22px;
  }

  .youtube_facebook_sec::after {
    filter: blur(80px);
    height: 550px;
    left: -300px;
    bottom: -300px;
    width: 600px;
  }

  .youtube_facebook_sec::before {
    filter: blur(80px);
    width: 500px;
    height: 500px;
    right: -300px;
    bottom: -300px;
  }

  .social_sec .social_link .social_text {
    padding: 18px 20px;
  }

  .logo_secchange img {
    width: 200px;
  }

  .logo_secchange p {
    font-size: 20px;
    line-height: 22px;
  }

  .logo_secchange {
    margin-bottom: 20px;
  }

  .social_sec .social_link .social_text p {
    font-size: 18px;
    line-height: 20px;
  }

  .modal_popup.test_ride_popup .pure-modal-backdrop .popup_cont h2 {
    font-size: 34px;
    line-height: 36px;
  }

  .modal_popup.test_ride_popup .pure-modal-backdrop .popup_cont form {
    max-width: 410px;
  }

  .test_ride_popup .give_detail h2 {
    margin-bottom: 20px !important;
  }

  .dealers_add h6 {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 5px;
  }

  .dealers_add p {
    line-height: 22px;
    margin-bottom: 15px;
  }

  .social_popup .social_popup_cont p {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 30px;
  }

  .social_popup .social_popup_cont form .btn_sec {
    margin-top: 20px;
  }

  .thanks_popup .thanks_popup_cont .changethegame h2 {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 20px;
  }

  .thanks_popup .thanks_popup_cont .changethegame p {
    font-size: 24px;
    line-height: 30px;
  }

  .social_sec {
    max-width: 750px;
    margin-bottom: 30px;
  }

  .Dealers_sec ul {
    width: 326px;
    height: 280px;
  }

  .locater {
    padding-bottom: 30px;
    height: calc(100vh);
  }

  .locater .landing_page_container {
    padding-top: 30px;
  }

  .locater .sec_heading h2 {
    margin-bottom: 30px;
  }

  .tech_specs_sec .pure-modal-backdrop .pure-modal {
    padding: 80px 80px 0px 80px;
    max-height: calc(100% - 80px);
  }

  .tech_specs_sec .panel-body.scrollable {
    padding-bottom: 80px;
  }

  .color_step form .form-check label {
    font-size: 18px;
    padding-left: 25px;
  }

  .Features_section::after {
    height: 700px;
    left: -400px;
    top: -400px;
    width: 660px;
  }

  .Features_section:before {
    height: 500px;
    right: -300px;
    bottom: -250px;
    width: 550px;
  }

  .features_step .pure-modal-backdrop .pure-modal .close {
    height: 54px;
    width: 54px;
    line-height: 46px;
    font-size: 38px;
  }

  .faq_sec:before {
    height: 550px;
    width: 500px;
  }

  .logo_cont {
    max-width: 350px;
    top: 25%;
  }

  .hop_oxo .rs_sec p {
    margin-bottom: 0px;
  }

  .logo_cont p {
    font-size: 14px !important;
    line-height: 22px !important;
  }

  .landig_banner_cont img {
    width: 50%;
  }

  .bike_color {
    padding: 40px 0px 20px 0px;
  }

  .faq_sec .landing_page_container {
    padding: 40px 20px;
  }

  .faqs .faq .faq-question {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .faqs .faq.open .faq-answer {
    font-size: 16px;
  }

  .faqs .faq {
    padding: 20px 80px 40px 30px;
    margin: -30px 0px 0px 0px;
  }

  .faqs .faq.open .faq-question::after {
    right: 80px !important;
    top: 30px;
  }

  .slider_popup .popup_cont {
    max-width: 600px;
  }

  .features_step .pure-modal-backdrop .pure-modal {
    width: 95% !important;
  }

  .steps ul li {
    padding: 10px 25px;
    margin-bottom: 15px;
  }

  .steps ul li span {
    font-size: 34px;
    line-height: 50px !important;
  }

  .default_btn {
    padding: 15px 40px;
    font-size: 16px;
    border-radius: 12px;
  }

  .steps ul li span p {
    font-size: 18px;
  }

  .landig_banner_cont {
    padding: 15px 40px;
    bottom: 20px;
  }

  header.header .uk-container {
    padding: 0px 20px;
  }

  .menu_bar button.navbar_lines {
    right: 40px;
  }

  .landig_banner_cont .hop_oxo img {
    width: auto;
  }

  .landig_banner_cont .hop_oxo .rs_sec {
    padding-left: 30px;
    margin-left: 30px;
  }

  .btn_banner .default_btn {
    margin: 0px 0px 0px 10px;
  }

  .hop_oxo .rs_sec strong {
    font-size: 24px;
  }

  .bike_color .img_bike::before {
    height: 245px;
  }

  .bike_color .img_bike {
    max-width: 700px;
    top: 35%;
  }

  .bike_color .img_bike > figure {
    min-height: 400px;
  }

  .rotade_bike {
    bottom: 0px;
  }

  .bike_color .change_color_bike {
    padding: 15px 30px;
  }

  .color_step form .form-check input::before {
    height: 20px;
    width: 20px;
  }

  .btn_slide_bike span:nth-child(2) {
    height: 25px;
    width: 25px;
    outline-offset: 12px;
    margin-bottom: -4px;
  }

  .Specs_conbg::after {
    height: 320px;
  }

  .Dealers_sec {
    right: 120px;
    top: 150px;
    padding: 15px 15px;
    width: 356px;
    height: 350px;
  }

  .Dealers_sec h5 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .map_form {
    padding: 20px 30px;
    top: 25px;
    max-width: 51%;
  }

  .sec_heading h2 {
    font-size: 40px;
    line-height: 40px;
  }

  .bike_color::after {
    height: 450px;
    left: -350px;
    top: 95%;
    width: 610px;
  }

  .tech_specs_sec::before {
    height: 700px;
    width: 700px;
  }

  .tech_specs_sec::after {
    height: 700px;
    width: 800px;
  }

  .locater::after {
    height: 600px;
    width: 730px;
  }

  .map_form .form-group button.default_btn {
    padding: 15px 25px;
  }

  .three_img span {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .reviews::before {
    height: 400px;
    width: 420px;
  }

  .faq_sec .sec_heading {
    margin-bottom: 40px;
  }

  .faq_sec .Accordion_sec {
    padding-top: 15px;
    height: calc(100% - 80px);
  }

  .reviews::after {
    height: 600px;
    left: -300px;
    bottom: -300px;
    width: 550px;
  }

  .faq_sec::after {
    height: 400px;
    width: 600px;
  }

  .faqs {
    max-width: 90%;
  }

  .faq.open .faq-question::after {
    right: 60px !important;
  }

  .faqs .faq .faq-question::after {
    right: 50px;
    top: 40px;
  }

  .faq.open {
    margin-left: -55px;
    margin-right: -55px;
    padding: 15px 80px 30px 30px;
  }

  .features_cont .featur_show {
    padding: 20px 25px;
  }

  .features_step h2.sec_ste {
    font-size: 30px;
  }

  .Features_section.first_fedtsec .features_step {
    height: calc(100% - 80px);
  }

  .featur_show .featur_para h3 {
    font-size: 24px;
    margin-bottom: 5px;
  }

  .featur_show .featur_para p {
    font-size: 16px;
    line-height: 24px;
  }

  .featur_show .default_btn {
    padding: 14px 30px;
    width: 20%;
  }

  .featur_show .featur_para {
    width: 80%;
    padding-right: 15px;
  }

  .features_step .pure-modal-backdrop .pure-modal .panel-default .panel-body {
    padding: 20px 30px;
  }

  .features_step .slider_show .slick-list .pop_slideron .slide_cont {
    padding: 15px 15px;
  }

  .features_step .slider_show .slick-list .pop_slideron .slide_cont h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .features_step .slider_show .slick-list .pop_slideron .slide_cont p {
    font-size: 16px;
    line-height: 27px;
  }

  .destop_v .form-group button.default_btn {
    font-size: 16px;
  }

  .bike_color::before {
    height: 850px;
    right: -360px;
    top: -530px;
    width: 700px;
  }

  .Features_section.first_fedtsec {
    padding: 40px 0px 0px 0px;
  }

  .Features_section .sec_heading {
    margin-bottom: 30px;
  }

  .tech_specs_sec .landing_page_container {
    padding: 30px 20px;
  }

  .tech_specs_sec .sec_heading {
    margin-bottom: 30px;
  }

  .tech_deails {
    padding: 30px;
  }

  .tech_deails {
    height: calc(100% - 90px);
  }

  .click_pop .open_pop_on {
    height: 54px;
    width: 54px;
    font-size: 35px;
  }

  .reviews .landing_page_container {
    padding: 40px 20px;
  }

  .reviews .uk-grid {
    height: calc(100% - 45px);
  }

  .reviews .sec_heading h2 {
    margin-bottom: 30px;
  }

  .three_img .click_img {
    padding: 15px 20px;
  }

  .features_step .slider_show .slick-list .pop_slideron figure img {
    border-radius: 15px 15px 0px 0px;
  }
}

@media only screen and (max-width: 1024px) {
  .pricing_sec .left_sec {
    padding-right: 40px;
  }

  .product_slider.for_leo .product_slider_tem a p {
    left: 36%;
    font-size: 22px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bike_color .img_bike::after {
    top: 158px;
    height: 192px;
    width: 590px;
    filter: blur(30px);
  }

  .newtech_specs .top_sec_oxo_x li {
    font-size: 24px;
    line-height: 28px;
  }

  .newtech_specs .top_sec_oxo_x li {
    font-size: 24px;
    line-height: 34px;
  }

  .newtech_specs ul li,
  .for_hopleo .tabing_section .content_main div {
    font-size: 16px;
    line-height: 30px;
  }

  .newtech_specs h5 {
    font-size: 18px;
    line-height: 36px;
    margin-bottom: 5px;
  }

  .newtech_specs .top_sec_oxo_x {
    margin-bottom: 10px;
  }

  .social_sec .social_link a > figure {
    height: 185px;
  }

  .subscribe_sec {
    bottom: 30px;
  }

  .youtube_facebook_sec .landing_page_container {
    padding: 30px 20px 30px 20px;
  }

  .youtube_facebook_sec h3 {
    font-size: 20px;
    line-height: 22px;
  }

  .youtube_facebook_sec::after {
    filter: blur(60px);
    height: 400px;
    left: -200px;
    bottom: -200px;
    width: 400px;
  }

  .youtube_facebook_sec::before {
    filter: blur(60px);
    width: 400px;
    height: 400px;
    right: -200px;
    bottom: -200px;
  }

  .social_sec .social_link .social_text {
    padding: 15px 15px;
  }

  .logo_secchange img {
    width: 180px;
  }

  .logo_secchange p {
    font-size: 18px;
    line-height: 20px;
  }

  .logo_secchange {
    margin-bottom: 15px;
  }

  .social_sec .social_link .social_text p {
    font-size: 16px;
    line-height: 20px;
  }

  .subscribe_sec button.default_btn {
    font-size: 16px;
    padding: 15px 30px;
  }

  .social_sec {
    max-width: 750px;
    margin-bottom: 20px;
    bottom: 85px;
  }

  .social_popup .social_popup_cont h2 {
    font-size: 28px !important;
    line-height: 28px !important;
  }

  .social_popup .social_popup_cont p {
    font-size: 20px;
    line-height: 22px;
  }

  .tech_specs_sec .pure-modal-backdrop .pure-modal {
    padding: 60px 50px 0px 50px;
    max-height: calc(100% - 70px);
  }

  .tech_specs_sec .panel-body.scrollable {
    padding-bottom: 60px;
  }

  .tech_specs_sec .landing_page_container {
    padding: 30px 20px;
  }

  .tech_specs_sec .sec_heading {
    margin-bottom: 30px;
  }

  .tech_deails {
    padding: 20px;
  }

  .click_pop .open_pop_on {
    height: 54px;
    width: 54px;
    font-size: 35px;
  }

  .tech_specs_sec::before {
    height: 550px;
    right: -300px;
    top: -300px;
    width: 550px;
  }

  .tech_specs_sec::after {
    height: 600px;
    left: -300px;
    bottom: -300px;
    width: 600px;
  }

  .locater::after {
    height: 600px;
    left: -300px;
    top: -300px;
    width: 600px;
  }

  .locater {
    padding-bottom: 30px;
    height: calc(100vh - 30px);
  }

  .locater .landing_page_container {
    padding-top: 30px;
  }

  .locater .sec_heading h2 {
    margin-bottom: 30px;
  }

  .reviews::before {
    height: 350px;
    width: 380px;
  }

  .reviews::after {
    height: 550px;
    width: 430px;
  }

  .faq_sec:before {
    height: 600px;
    width: 450px;
  }

  .faq_sec::after {
    width: 550px;
    height: 400px;
  }

  .faqs .faq.open .faq-answer {
    font-size: 16px;
  }

  .rotade_bike {
    bottom: 0px;
  }

  .bike_color .img_bike {
    max-width: 600px;
    top: 35%;
  }

  .bike_color .img_bike > figure {
    min-height: 380px;
  }

  .logo_cont {
    max-width: 320px;
  }

  .logo_cont p {
    font-size: 13px !important;
  }

  .logo_cont figure img {
    margin-bottom: 10px;
    width: 95%;
  }

  .slider_popup .popup_cont {
    max-width: 780px;
  }

  .slider_popup .popup_cont .slick-slider {
    width: 80%;
    margin: auto;
  }

  .slider_popup .popup_cont .slick-slider .slide_bike_showcon {
    padding: 0px 40px 50px;
  }

  .slider_popup .popup_cont .slick-slider .slide_bike_showcon h5 {
    font-size: 28px;
    line-height: 28px;
  }

  .slider_popup .popup_cont .slick-slider .slide_bike_showcon p {
    font-size: 18px;
    line-height: 30px;
  }

  .landig_banner_cont img {
    width: 50%;
  }

  .bike_color {
    padding: 40px 0px 20px 0px;
  }

  .Features_section.first_fedtsec {
    padding: 30px 0px 0px 0px;
  }

  .faq_sec .landing_page_container {
    padding: 30px 20px;
  }

  .landing_banner p {
    font-size: 22px;
    line-height: 30px;
  }

  .landing_banner strong {
    font-size: 30px;
    line-height: 36px;
  }

  .btn_banner .default_btn {
    padding: 12px 26px;
    border-radius: 12px;
  }

  .bike_cont p {
    padding-right: 50px;
  }

  .text_right p {
    padding-right: 0px !important;
    padding-left: 50px;
  }

  .features_sec .first_gra::after {
    height: 750px;
    width: 600px;
  }

  .features_sec .three_gra::before {
    height: 530px;
    top: 0px;
    width: 500px;
  }

  .features_sec .second_gra::before {
    height: 580px;
    top: -50px;
    width: 500px;
  }

  .bike_color::after {
    height: 400px;
    width: 700px;
  }

  .features::after {
    height: 630px;
    width: 500px;
  }

  .show_details_popup {
    font-size: 32px;
    line-height: 32px;
  }

  .text_sec {
    bottom: 30px;
    left: 30px;
  }

  .reviews .three_img .click_img button img {
    width: 120px;
  }

  .reviews .sec_heading h2 {
    margin-bottom: 30px;
  }

  .reviews .landing_page_container {
    padding: 30px 20px;
  }

  .reviews .uk-grid {
    height: calc(100% - 35px);
  }

  .reviews .uk-grid {
    width: calc(100% + 20px);
    margin: 0px -10px;
  }

  .reviews .three_img {
    width: calc(33.33% - 20px);
    margin: 0px 10px;
    margin-bottom: 30px;
  }

  .steps ul li {
    padding: 15px 25px;
    margin-bottom: 10px;
    border-radius: 18px;
  }

  .steps ul li span {
    font-size: 40px;
    line-height: 50px !important;
  }

  .steps ul li span p {
    font-size: 20px;
  }

  .landig_banner_cont {
    padding: 20px 30px;
    bottom: 30px;
  }

  .landing_banner strong {
    font-size: 26px;
    line-height: 26px;
  }

  .landig_banner_cont .hop_oxo img {
    width: 17%;
  }

  .landig_banner_cont .hop_oxo .rs_sec {
    padding-left: 30px;
    margin-left: 30px;
  }

  .btn_banner .default_btn {
    margin: 0px 0px 0px 10px;
    font-size: 16px;
  }

  .uk-container {
    padding: 0px 20px;
  }

  .menu_bar button.navbar_lines {
    right: 40px;
  }

  .Features_section.first_fedtsec .features_step {
    height: calc(100% - 70px);
  }

  /* ========== */
  .tech_specs_sec .pure-modal-backdrop .pure-modal .close {
    height: 54px;
    width: 54px;
    line-height: 44px;
    font-size: 35px;
  }

  .btn_slide_bike span {
    margin: 0px 20px;
    height: 20px;
    width: 20px;
  }

  .bike_color .change_color_bike {
    padding: 15px 20px;
  }

  .hop_oxo .rs_sec strong {
    font-size: 28px;
  }

  .bike_color .img_bike::before {
    height: 210px;
    width: 100%;
  }

  .color_step form .form-check label {
    font-size: 16px;
    padding-left: 18px;
  }

  .color_step form .form-check:last-child {
    margin-right: 0px;
  }

  .color_step form .form-check input::before {
    height: 18px;
    width: 18px;
  }

  .hop_oxo strong {
    padding-left: 10px;
    font-size: 28px;
  }

  .btn_slide_bike span:nth-child(2) {
    height: 22px;
    width: 22px;
    outline-offset: 8px;
    margin-bottom: -2px;
  }

  .Specs_conbg::after {
    height: 295px;
    width: 840px;
  }

  .tech_specs_sec
    .pure-modal-backdrop
    .pure-modal
    > .panel.panel-default
    > .panel-body {
    padding-bottom: 50px;
  }

  .tech_deails {
    height: calc(100% - 90px);
  }

  .Dealers_sec ul {
    width: 285px;
    height: 190px;
  }

  .Dealers_sec {
    right: 80px;
    top: 130px;
    padding: 15px 15px;
    width: 315px;
    height: 250px;
  }

  .Dealers_sec ul li strong {
    font-size: 13px;
    margin-bottom: 5px;
  }

  .Dealers_sec ul li p {
    margin-bottom: 10px;
  }

  .Dealers_sec ul li {
    border-radius: 15px;
    padding: 15px 15px;
  }

  .Dealers_sec h5 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .map_form {
    padding: 15px 20px;
    top: 15px;
    max-width: 64%;
  }

  .locater .sec_heading h2 {
    margin-bottom: 30px;
  }

  .locater figure img {
    height: 700px;
  }

  .three_img .click_img {
    padding: 10px 15px;
  }

  #footer::after {
    height: 1100px;
    left: -550px;
    bottom: -750px;
    width: 800px;
  }

  .three_img span {
    font-size: 18px;
    margin-bottom: 2px;
  }

  .faq_sec .sec_heading {
    margin-bottom: 30px;
  }

  .faq_sec .Accordion_sec {
    height: calc(100% - 65px);
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .faqs .faq.open {
    margin-left: -60px;
    margin-right: -60px;
  }

  .faqs {
    max-width: 86%;
    padding-bottom: 0px;
  }

  .faqs .faq {
    margin: -20px 0px 0px 0px;
    padding: 15px 90px 25px 30px;
  }

  .faqs .faq.open .faq-question::after {
    top: 30px;
    right: 65px !important;
    width: 16px;
    height: 16px;
  }

  .faqs .faq .faq-question::after {
    top: 30px;
    right: 60px;
    width: 16px;
    height: 16px;
  }

  .features_cont .featur_show {
    padding: 15px 20px;
  }

  .features_step h2.sec_ste {
    font-size: 30px;
  }

  .Features_section .features_step .landing_page_container.fea_hea {
    top: 25px;
  }

  .features_step .pure-modal-backdrop .pure-modal {
    width: 95% !important;
  }

  .Features_section::after {
    height: 700px;
    left: -300px;
    top: -350px;
    width: 500px;
  }

  .featur_show .featur_para h3 {
    font-size: 24px;
    margin-bottom: 5px;
  }

  .Features_section:before {
    height: 500px;
    right: -500px;
    bottom: -250px;
    width: 700px;
  }

  .featur_show .featur_para p {
    font-size: 16px;
    line-height: 24px;
  }

  .featur_show .default_btn {
    padding: 10px 15px;
    width: 20%;
    border-radius: 10px;
  }

  .features_step .pure-modal-backdrop .pure-modal .panel-default .panel-body {
    padding: 20px 15px 20px 50px;
  }

  .features_step .slider_show .slick-slider button.slick-arrow {
    left: -5px;
  }

  .features_step .slider_show .slick-slider button.slick-arrow.slick-next {
    right: -5px;
  }

  .featur_para.pop_slideron {
    padding: 0px 20px;
    height: 100%;
    min-height: 500px;
  }

  .features_step .slider_show .slick-list .pop_slideron .slide_cont {
    left: 20px;
    bottom: 0;
    width: calc(100% - 40px);
  }

  .features_step .slider_show .slick-list .pop_slideron .slide_cont {
    padding: 15px 15px;
  }

  .features_step .slider_show .slick-list .pop_slideron .slide_cont h3 {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .features_step .slider_show .slick-list .pop_slideron .slide_cont p {
    font-size: 14px;
    line-height: 23px;
  }

  .features_step .pure-modal-backdrop .pure-modal .close {
    height: 54px;
    width: 54px;
    line-height: 45px;
    font-size: 35px;
  }

  .click_pop .open_pop_on {
    height: 54px;
    width: 54px;
    font-size: 35px;
  }

  .bike_color::before {
    height: 550px;
    right: -240px;
    top: -280px;
    width: 450px;
  }

  .Features_section .sec_heading {
    margin-bottom: 30px;
  }

  .btn_slide_bike {
    bottom: -14px;
  }

  .faqs .faq .faq-question {
    font-size: 18px;
  }

  .faqs .faq.open .faq-question {
    margin-bottom: 5px;
  }

  .faqs .faq.open .faq-question {
    font-size: 20px;
  }

  .features_step .slider_show .slick-list .pop_slideron figure img {
    border-radius: 15px 15px 0px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .bike_color .img_bike::after {
    top: 158px;
    height: 200px;
    width: 536px;
    filter: blur(30px);
  }

  #otpsendOrder form .react_form_group input {
    padding: 15px 15px 15px 45px;
  }

  #otpsendOrder.test_ride_popup form span.error {
    font-size: 13px;
    margin-top: 5px;
    bottom: -25px;
  }

  #otpsendOrder form .react_form_group .number input {
    padding-left: 105px;
  }

  .pricing_sec .left_sec {
    display: none;
  }

  .pricing_sec .right_sec {
    width: 100%;
    padding-right: 0px;
  }

  .pricing_sec .right_sec .btn-group-price {
    width: 100%;
  }

  .pricing_sec .right_sec .btn-group-price button {
    min-width: auto;
    width: 50%;
  }

  .pricing_sec .sec_heading {
    margin-bottom: 20px;
  }

  .pricing_sec .price-content button.book-nowbtn {
    min-width: 220px;
    font-size: 16px;
    line-height: 30px;
  }

  .vimeo-video.mobile {
    display: block;
  }

  /* .vimeo-video {
        display: none;
    } */

  .Features_section .vimeo-video {
    width: 140%;
    height: 140%;
    position: relative;
    left: -20%;
    z-index: 0;
    top: -20%;
  }
}

/* new-css-start */

@media only screen and (max-width: 991px) {
  .landing_banner {
    height: auto;
    overflow: auto;
    scroll-snap-align: inherit;
  }

  .video_sec_step {
    height: 100%;
  }

  .full_video_bike {
    height: auto;
    scroll-snap-align: inherit !important;
  }

  .bike_color {
    height: 650px;
    min-height: auto;
    scroll-snap-align: inherit;
  }

  .Features_section {
    height: 600px;
    scroll-snap-align: inherit;
  }

  .tech_specs_sec {
    height: auto;
    min-height: auto;
    scroll-snap-align: inherit;
  }

  .pricing_sec {
    height: auto;
    min-height: auto;
    scroll-snap-align: inherit;
  }

  .locater {
    height: 600px !important;
    scroll-snap-align: inherit;
  }

  .Want_knowmore {
    height: auto;
    min-height: auto;
    scroll-snap-align: inherit;
  }

  .marquee_more {
    height: auto;
    min-height: auto;
    scroll-snap-align: inherit;
  }

  .Want_knowmore .uk-container {
    position: inherit;
    transform: translateY(0px);
  }

  .marquee_more .uk-container {
    position: inherit;
    transform: translateY(0px);
  }

  .faq_sec {
    height: auto;
    min-height: auto;
    scroll-snap-align: inherit;
  }

  #home > #footer {
    height: auto;
    scroll-snap-align: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .home_testride_page .booktr_slider .select_dealer {
    min-height: 160px !important;
  }

  .home_testride_page .dealer_main {
    margin-top: 0px !important;
  }
  .locater::before {
    z-index: 1 !important;
  }
  .home_testride_page .top_header_pm {
    z-index: 1 !important;
    background: transparent !important;
  }
  .home_testride_page .date_show {
    display: none;
  }
  .home_testride_page .dealer_main.booktr_slider.bookheding_sec > p {
    margin: 20px 0px !important;
  }
  .home_testride_page .search_bar .search_icon button svg path {
    fill: #00eaff;
  }
  .home_testride_page .bike_detailcus .search_bar {
    margin-bottom: 25px;
  }
  .locater::after {
    display: none;
  }
  .home_testride_page .bike_detailcus .search_bar {
    padding: 0px 15px;
  }
  .home_testride_page .search_bar .search_inp::placeholder {
    color: #00eaff;
    font-size: 12px;
  }
  .home_testride_page .search_bar .search_inp {
    border-radius: 8px;
    background: rgba(0, 234, 255, 0.2);

    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
  .home_testride_page .search_bar .search_icon {
    left: 15px;
  }
  .home_testride_page .select_dealer .map_left {
    height: 40vh !important;
  }
  .home_testride_page .select_dealer .testride_righsec {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .home_testride_page .bike_detailcus {
    margin: 0px;
  }
  .home_testride_page .height_one_csrol {
    height: auto;
    overflow-y: inherit;
    overflow-x: inherit;
  }
  .date_tme_mobile {
    display: flex;
    gap: 20px;
    background: rgba(221, 221, 221, 0.15);
    border-radius: 5px;
    padding: 12px 15px;
    flex-wrap: wrap;
  }

  .date_tme_mobile h3 {
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0px;
    color: #ffffff;
  }

  .date_tme_mobile p {
    width: calc(50% - 10px);
    display: flex;
    gap: 10px;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #ffffff !important;
    margin-bottom: 5px;
    opacity: 1;
  }

  .slot_after_p {
    display: inline-block !important;
    padding: 10px 15px;
    border: 1px solid #00eaff;
    margin-top: 20px;
    margin-bottom: 35px !important;
    width: 48%;
    border-radius: 5px;
  }

  .slot_after_p span,
  .slot_after_p a {
    font-size: 16px !important;
    line-height: 22px !important;
    color: #ffffff !important;
    font-weight: 600;
  }

  .check_radio.testride_righsec .form-check {
    align-items: center;
  }

  .check_radio.testride_righsec h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.03em;
    color: #ffffff;
    opacity: 0.8;
    margin-bottom: 30px;
  }

  .cancellation_confirm .only_mobileshow.mobile_show {
    padding: 60px 0px 50px 0px;
  }

  .check_confirm {
    background: #000000;
    border: 1px solid #00eaff;
    border-radius: 8px;
    padding: 30px 20px 0px;
    margin-bottom: 30px;
  }

  .check_confirm .checkmark_con_i {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #00eaff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    margin-bottom: 25px;
  }

  .cancellation_confirm .testride_righsec p {
    font-weight: 400;
    font-size: 16px !important;
    line-height: 28px;
    /* text-align: center; */
    color: rgba(221, 221, 221, 0.9);
  }

  .cancellation_confirm .testride_righsec .thank_int {
    font-weight: 700;
    font-size: 20px !important;
    line-height: 30px;
  }

  .check_confirm .checkmark_con_i {
    font-size: 30px;
  }

  .cancellation_confirm .testride_righsec h3 {
    text-align: center;
  }

  .cancellation_confirm .color_text_con {
    color: #00eaff !important;
  }

  .check_radio.testride_righsec .form-check .form-check-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #ffffff;
    opacity: 0.8;
  }

  .check_radio.testride_righsec .form-check input[type="radio"] {
    appearance: none;
    background-color: transparent;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid #00eaff;
    border-radius: 50%;
  }

  .check_radio.testride_righsec .form-check input[type="radio"]:checked {
    background-color: #00eaff;
  }

  .cancel_ride .date_tme_mobile {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .cancel_ride .pure-modal-backdrop {
    max-height: 80vh !important;
  }

  .cancel_ride .popup_cont h3,
  .cancel_ride .popup_cont p {
    text-align: left;
  }

  .appointment_popup .slot_after_p {
    padding: 10px !important;
  }

  .show_mobile {
    display: block;
  }

  .slot_after_p span img {
    margin-right: 5px !important;
  }

  .show_mobile.devider {
    display: inline;
    margin: 0px 5px;
  }

  .slot_after_p span {
    display: inline-block !important;
  }

  .date_tme_mobile p svg {
    color: #00eaff;
    font-size: 20px;
  }

  /* today css 19-06-2023 start */
  .booktest_ride .dealer_main .slick-list .slick-track {
    margin-left: 0px;
  }

  /* today css 19-06-2023 start */

  .tech_specs_sec .sec_heading h2:nth-child(2) {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .bike_color .img_bike::after {
    top: 35%;
    height: 40%;
    width: 100%;
    filter: blur(30px);
  }

  .bike_color {
    height: 580px;
  }

  /* testride new mobile changes, vikash css */
  .next_btn.bo_ather .map_left.testride_map {
    height: 360px;
  }

  .booktest_modal .booktest_direction {
    margin-top: 15px;
    margin-bottom: 25px;
  }

  .my_book_modal .booktest_direction > div,
  .my_book_modal .booktest_direction > p {
    padding: 10px;
    border: 1px solid #00eaff;
    border-radius: 3px;
    display: flex;
    align-items: center;
  }

  .my_book_modal .booktest_direction > p span {
    display: flex;
    align-items: center;
  }

  .date_piker ul li button {
    padding: 2px 8px;
    font-weight: 900;
    font-size: 12px;
    line-height: 34px;
    text-align: center;
    text-transform: uppercase;
    color: rgba(221, 221, 221, 0.5);
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .date_piker ul {
    display: flex;
    justify-content: space-between;
  }

  .date_piker ul li {
    width: calc(33.33% - 5px);
  }

  .hide_mob {
    display: none;
  }

  .icon_sec button.cancel_btn {
    margin-left: 15px;
  }

  .icon_sec button.cancel_btn img {
    border-radius: 50% !important;
    width: 22px !important;
  }

  .select_dealer .icon_sec > div {
    margin-left: 5px;
  }

  .select_dealer .icon_sec span,
  .select_dealer .icon_sec button {
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.05em;
    color: #dddddd;
  }

  .booktest_ride .slick-slider .slick-arrow {
    display: none !important;
  }

  .booktr_slider .slick-slide > div {
    margin-right: 10px;
  }

  .dealer_main .select_dealer strong,
  .dealer_main .select_dealer p {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #ffffff;
    text-align: left;
  }

  .dealer_main .select_dealer strong {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dealer_main .select_dealer p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .home_testride_page .booktr_slider .select_dealer p {
    -webkit-line-clamp: 2 !important;
  }
  .home_testride_page .booktr_slider .select_dealer strong {
    -webkit-line-clamp: 2 !important;
    text-align: center;
  }

  .dealer_main.booktr_slider.bookheding_sec > p {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.05em;
    color: #ffffff;
    padding-left: 0px;
    margin-bottom: 0px;
  }

  .bike_detailcus .search_bar {
    margin-bottom: 18px;
  }

  .dealer_main {
    margin-top: 0px !important;
    gap: 18px !important;
  }

  .map_left.testride_map {
    display: block !important;
    height: 150px;
    border-radius: 2px;
  }

  .onetwo_testcss {
    display: block;
  }

  .color_step.onmobile_360 form .form-check label::before {
    left: 0px !important;
    right: 0px !important;
  }

  .color_step.onmobile_360 {
    display: block !important;
  }

  .color_step {
    display: none;
  }

  .coming_soon_movbike {
    display: inline-block;
    color: #00eaffbd;
  }

  .product_slider.for_leo .product_slider_tem a p {
    left: 0;
    font-size: 12px;
    min-width: auto;
    bottom: -10px;
    right: 0;
  }

  .last_ul_tech li:nth-child(2n + 1) {
    width: 30%;
  }

  .last_ul_tech li:nth-child(2n + 2) {
    width: 70%;
  }

  /* hop-leo page media quarry start */
  .tabing_data_second .inner_cont .content_main .left,
  .tabing_data_second .inner_cont .content_main .right,
  .tabing_data_second .inner_cont .content_main .third {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 5px;
  }

  .Hop_leo_main .for_hopleo .panel-body.scrollable {
    padding: 70px 15px;
  }

  .for_hopleo .tabing_section h2 {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 5px;
    margin-top: 20px;
  }

  .for_hopleo .oxo_both_headar {
    font-size: 17px;
  }

  .tabing_data_second .inner_cont .content_main {
    font-size: 14px;
  }

  /* hop-leo page media quarry End */

  .sec_heading h2 {
    z-index: 5;
    padding: 0 20px;
  }

  .faq_sec {
    padding-top: 40px;
  }

  .landing_footer::before {
    background: linear-gradient(211deg, #13204d, #13204d);
    border-radius: 50%;
    content: "";
    position: absolute;
    right: -200px;
    top: -300px;
    z-index: 1;
    height: 450px;
    width: 400px;
    filter: blur(50px);
  }

  #otpsendOrder form .react_form_group .number input {
    padding-left: 90px;
  }

  .video_sec_step {
    height: 100%;
  }

  .full_video_bike .video_sec_step {
    height: 100%;
  }

  .full_video_bike {
    height: 70vh !important;
  }
  .vimeo-video > iframe {
    top: -60px !important;
  }

  .Features_section {
    height: auto;
  }

  .bike_color .landing_page_container .sec_heading {
    position: inherit;
  }

  .features_step .pure-modal-backdrop .pure-modal {
    border-radius: 0px 0px 0px 0px;
  }

  .tech_specs_sec {
    z-index: auto;
  }

  .home_testride_page {
    height: auto !important;
  }
}

/* new-css-end */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video_sec_step img {
    height: 550px;
  }

  .newtech_specs .top_sec_oxo_x li {
    font-size: 20px;
    line-height: 24px;
  }

  .newtech_specs .top_sec_oxo_x li {
    font-size: 20px;
    line-height: 24px;
  }

  .newtech_specs ul li {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600 !important;
    padding-right: 25px;
    margin-bottom: 10px;
  }

  .newtech_specs h5 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 5px;
  }

  .newtech_specs .top_sec_oxo_x {
    margin-bottom: 10px;
  }

  .on_popup .newtech_specs ul li {
    padding-right: 25px;
  }

  .social_sec .social_link a > figure {
    height: 160px;
  }

  .social_popup .pure-modal .panel-default .close .close_btn {
    height: 50px;
    width: 50px;
    line-height: 50px;
  }

  .social_popup .pure-modal .panel-default .close .close_btn::before {
    bottom: 22px;
    left: 10px;
    width: 30px;
  }

  .social_popup .pure-modal .panel-default .close .close_btn::after {
    bottom: 22px;
    left: 10px;
    width: 30px;
  }

  .youtube_facebook_sec .landing_page_container {
    padding: 30px 20px 30px 20px;
  }

  .youtube_facebook_sec h3 {
    font-size: 18px;
    line-height: 22px;
  }

  .youtube_facebook_sec::after {
    filter: blur(60px);
    height: 300px;
    left: -150px;
    bottom: -150px;
    width: 300px;
  }

  .youtube_facebook_sec::before {
    filter: blur(60px);
    width: 300px;
    height: 300px;
    right: -150px;
    bottom: -150px;
  }

  .social_sec .social_link .social_text {
    padding: 15px 10px;
  }

  .logo_secchange img {
    width: 150px;
  }

  .logo_secchange p {
    font-size: 16px;
    line-height: 20px;
  }

  .logo_secchange {
    margin-bottom: 15px;
  }

  .social_sec .social_link .social_text p {
    font-size: 16px;
    line-height: 20px;
  }

  .subscribe_sec button.default_btn {
    font-size: 16px;
    padding: 15px 30px;
  }

  .social_sec {
    max-width: 650px;
    margin-bottom: 20px;
    bottom: 90px;
  }

  .subscribe_sec {
    bottom: 30px;
  }

  .social_popup .social_popup_cont h2 {
    font-size: 26px !important;
    line-height: 28px !important;
  }

  .social_popup .social_popup_cont p {
    font-size: 18px;
    line-height: 20px;
  }

  .thanks_popup .thanks_popup_cont .changethegame p {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 0px !important;
  }

  .tech_specs_sec .pure-modal-backdrop .pure-modal {
    padding: 40px 5px 0px 5px;
    max-height: calc(100% - 80px);
  }

  .tech_specs_sec .panel-body.scrollable {
    padding-bottom: 40px;
  }

  .Features_section::after {
    height: 600px;
    left: -250px;
    top: -300px;
    width: 450px;
  }

  .Features_section.first_fedtsec .features_step {
    height: calc(100% - 40px);
  }

  .faqs {
    max-width: 88%;
    padding-bottom: 0px;
  }

  .destop_v .form-group button.default_btn {
    font-size: 17px;
  }

  .tech_specs_sec::before {
    height: 500px;
    right: -250px;
    top: -250px;
    width: 500px;
  }

  .tech_specs_sec::after {
    height: 500px;
    left: -250px;
    bottom: -250px;
    width: 500px;
  }

  .locater::after {
    height: 500px;
    left: -250px;
    top: -250px;
    width: 500px;
  }

  .reviews::before {
    height: 330px;
    width: 350px;
  }

  .reviews .three_img {
    width: calc(33.33% - 14px);
    margin: 0px 7px;
    margin-bottom: 15px;
  }

  .reviews .landing_page_container {
    padding: 30px 20px;
  }

  .reviews .uk-grid {
    height: calc(100% - 30px);
    width: calc(100% + 14px);
    margin: 0px -7px;
  }

  .reviews::after {
    height: 500px;
    width: 450px;
  }

  .faq_sec:before {
    height: 600px;
    width: 450px;
  }

  .faq_sec::after {
    width: 550px;
    height: 300px;
  }

  .bike_color .img_bike {
    max-width: 570px;
    top: 35%;
  }

  .bike_color .img_bike > figure {
    min-height: 380px;
  }

  .rotade_bike {
    bottom: 0px;
  }

  .Features_section.first_fedtsec {
    padding: 20px 0px 0px 0px;
  }

  .click_pop .open_pop_on {
    height: 44px;
    width: 44px;
    font-size: 25px;
  }

  .logo_cont {
    max-width: 290px;
    padding: 10px;
  }

  .steps ul li::before {
    border-radius: 15px;
  }

  .logo_cont p {
    font-size: 12px !important;
    line-height: 20px !important;
  }

  .logo_cont figure img {
    margin-bottom: 10px;
  }

  .slider_popup .popup_cont {
    max-width: 780px;
  }

  .slider_popup .popup_cont .slick-slider {
    width: 80%;
    margin: auto;
  }

  .slider_popup .popup_cont .slick-slider .slide_bike_showcon {
    padding: 0px 30px 40px;
  }

  .slider_popup .popup_cont .slick-slider .slide_bike_showcon h5 {
    font-size: 26px;
    line-height: 26px;
  }

  .slider_popup .popup_cont .slick-slider .slide_bike_showcon p {
    font-size: 16px;
    line-height: 26px;
  }

  .landig_banner_cont img {
    width: 15%;
    margin-bottom: 0;
  }

  .landing_banner::before {
    height: 200px;
  }

  .bike_color {
    padding: 30px 0px 20px 0px;
  }

  .Features_section .sec_heading {
    margin-bottom: 15px;
  }

  .faq_sec .landing_page_container {
    padding: 30px 20px;
  }

  .landing_banner p {
    font-size: 20px;
    line-height: 28px;
  }

  .hop_oxo strong {
    font-size: 20px;
    line-height: 25px;
  }

  .btn_banner .default_btn {
    padding: 12px 15px;
    border-radius: 12px;
    font-size: 14px;
    margin: 0px 0px 0px 5px;
  }

  .steps ul li {
    padding: 10px 20px;
    margin-bottom: 10px;
    border-radius: 15px;
  }

  .steps ul li p {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 22px;
  }

  .steps ul li span {
    font-size: 24px;
    line-height: 32px !important;
  }

  .bike_cont p {
    padding-right: 20px;
    margin-bottom: 10px;
  }

  .text_right p {
    padding-right: 0px !important;
    padding-left: 50px;
    padding-left: 20px;
  }

  #home::before {
    height: 580px;
    width: 600px;
  }

  .steps::after {
    height: 280px;
    width: 280px;
  }

  .uk-container {
    padding: 0px 20px;
  }

  button.click_on img {
    width: 150px;
  }

  .features_sec .sec_heading h2 {
    margin-bottom: 40px;
  }

  .bike_cont h4 {
    font-size: 26px;
    margin-bottom: 10px;
  }

  .uk-grid .uk-colum_8 {
    width: 50%;
  }

  .uk-grid .uk-colum_4 {
    width: 50%;
  }

  .bike_color::after {
    height: 300px;
    left: -200px;
    width: 400px;
    top: 100%;
  }

  .Features_section:before {
    height: 300px;
    right: -200px;
    bottom: -100px;
    width: 400px;
  }

  .features_sec .first_gra::after {
    height: 600px;
    width: 600px;
    top: -270px;
  }

  .features_sec .second_gra::before {
    height: 400px;
    width: 500px;
  }

  .features_sec .three_gra::before {
    height: 400px;
    width: 500px;
    top: -40px;
  }

  .features::after {
    height: 600px;
    width: 500px;
  }

  .show_details_popup {
    font-size: 26px;
    line-height: 26px;
  }

  .text_sec {
    bottom: 20px;
    left: 20px;
  }

  .features_sec {
    padding: 20px 0px 20px 0px;
  }

  .features .bg_colorfu {
    padding: 50px 0px;
  }

  .tech_specs_sec .landing_page_container {
    padding: 30px 20px;
  }

  .tech_specs_sec .sec_heading p {
    font-size: 20px;
  }

  .tech_deails {
    padding: 15px;
    height: calc(100% - 80px);
  }

  .click_bottom button {
    height: 70px;
    width: 70px;
  }

  .click_bottom {
    margin-top: -70px;
  }

  .locater .sec_heading h2 {
    margin-bottom: 40px;
  }

  .reviews .three_img .click_img button img {
    width: 90px;
  }

  .reviews .three_img span {
    font-size: 16px;
    margin-bottom: 2px;
  }

  .three_img .click_img p {
    font-size: 14px;
    line-height: normal;
  }

  .faq_sec .sec_heading {
    margin-bottom: 60px;
  }

  .faqs .faq .faq-question {
    font-size: 17px;
    line-height: 24px;
  }

  .faqs .faq.open .faq-question::after {
    top: 30px;
    right: 60px !important;
    width: 16px;
    height: 16px;
  }

  .faqs .faq .faq-question::after {
    top: 30px;
    right: 20px;
    height: 15px;
    width: 15px;
  }

  .faq_sec:before {
    height: 450px;
    width: 450px;
  }

  .faqs .faq.open .faq-answer {
    font-size: 15px;
  }

  /* ========== */
  .hop_oxo .rs_sec {
    padding-left: 20px;
    margin-left: 20px;
  }

  .landig_banner_cont {
    border-radius: 15px;
    padding: 15px 20px;
    bottom: 20px;
  }

  .landig_banner_cont::before {
    border-radius: 15px;
  }

  .landig_banner_cont .btn_banner {
    width: 50%;
    text-align: right;
  }

  .landig_banner_cont .btn_banner button.default_btn.Book {
    margin-bottom: 0;
  }

  .features_step .pure-modal-backdrop .pure-modal {
    width: 94% !important;
    border-radius: 40px 0px 0px 0px;
  }

  .header {
    padding: 15px 0px;
  }

  .steps ul li span p {
    font-size: 16px;
    padding-left: 5px;
  }

  .tech_specs_sec .pure-modal-backdrop .pure-modal .close {
    height: 44px;
    width: 44px;
    line-height: 36px;
    font-size: 30px;
  }

  .tech_specs_sec .sec_heading {
    margin-bottom: 30px;
  }

  .click_pop .open_pop_on img {
    width: 60%;
    margin: auto;
  }

  .btn_slide_bike span {
    margin: 0px 20px;
    height: 20px;
    width: 20px;
  }

  .bike_color .change_color_bike .hop_oxo {
    width: 80px;
    margin-right: 15px;
    display: none;
  }

  .change_color_bike .color_step {
    width: calc(100% - 0px);
  }

  .bike_color::before {
    right: -190px;
    top: -220px;
    width: 350px;
    height: 440px;
  }

  .bike_color .change_color_bike {
    padding: 15px 20px;
  }

  .change_color_bike .color_step .base {
    font-size: 12px;
    margin-bottom: 15px;
  }

  .change_color_bike .color_step .base.pro {
    margin-top: 18px;
  }

  .hop_oxo .rs_sec strong {
    font-size: 22px;
  }

  .bike_color .img_bike::before {
    height: 200px;
    width: 570px;
  }

  .color_step form .form-check input {
    width: 10px;
    height: 10px;
  }

  .color_step form .form-check {
    /* text-align: center; */
    padding: 0px;
  }

  .color_step form .form-check label {
    font-size: 14px;
    padding-left: 15px;
  }

  .color_step form .form-check:last-child {
    margin-right: 0px;
  }

  .color_step form .form-check input::before {
    height: 16px;
    width: 16px;
    outline-offset: 5px;
  }

  .btn_slide_bike span:nth-child(2) {
    height: 22px;
    width: 22px;
    outline-offset: 8px;
    margin-bottom: -2px;
  }

  .Specs_conbg::after {
    height: 225px;
    width: 640px;
  }

  .tech_specs_sec
    .pure-modal-backdrop
    .pure-modal
    > .panel.panel-default
    > .panel-body {
    padding-bottom: 40px;
  }

  .locater {
    padding-bottom: 30px;
    height: calc(100vh - 30px);
  }

  .locater .landing_page_container {
    padding-top: 30px;
  }

  .locater .sec_heading h2 {
    margin-bottom: 30px;
  }

  .Dealers_sec {
    right: 50px;
    top: 120px;
    padding: 15px 15px;
    max-width: 350px;
    height: 250px;
  }

  .Dealers_sec ul {
    width: 320px;
    height: 185px;
  }

  .Dealers_sec ul li {
    border-radius: 10px;
    padding: 15px 15px;
    margin-bottom: 5px;
  }

  .Dealers_sec ul li strong {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .Dealers_sec ul li p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .Dealers_sec ul li button.default_btn {
    border-radius: 5px;
    font-size: 13px;
    padding: 7px 36px 7px 20px;
  }

  .Dealers_sec h5 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .reviews .sec_heading h2 {
    margin-bottom: 25px;
  }

  .map_form {
    padding: 15px 20px;
    top: 15px;
    max-width: 615px;
  }

  .reviews::after {
    height: 470px;
    width: 400px;
  }

  .faq_sec::after {
    height: 320px;
    width: 550px;
  }

  .locater figure img {
    height: 700px;
  }

  .three_img .click_img {
    padding: 10px 10px;
  }

  .three_img span {
    font-size: 20px;
  }

  .faq_sec .sec_heading {
    margin-bottom: 30px;
  }

  .faq_sec .Accordion_sec {
    overflow-y: initial;
    padding-top: 5px;
  }

  .faqs .faq.open {
    margin-left: -40px;
    margin-right: -40px;
    padding: 15px 80px 20px 20px;
  }

  .faqs .faq.open .faq-question {
    font-size: 18px;
  }

  .faqs .faq {
    margin: -20px 0px 0px 0px;
    padding: 20px 50px 25px 15px;
    border-radius: 20px;
  }

  .features_cont .featur_show {
    padding: 15px 20px;
  }

  .features_step h2.sec_ste {
    font-size: 22px;
  }

  .Features_section .features_step .landing_page_container.fea_hea {
    top: 20px;
  }

  .featur_show .featur_para h3 {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .featur_show .featur_para p {
    font-size: 14px;
    line-height: 20px;
  }

  .featur_show .default_btn {
    padding: 10px 15px;
    width: 22%;
    border-radius: 10px;
  }

  .featur_show .featur_para {
    width: 78%;
    padding-right: 15px;
  }

  .features_step .pure-modal-backdrop .pure-modal .panel-default .panel-body {
    padding: 30px 30px 30px 60px;
  }

  .features_step .slider_show .slick-slider button.slick-arrow {
    width: 40px;
    height: 40px;
    left: -10px;
  }

  .features_step .slider_show .slick-slider button.slick-arrow::before {
    width: 20px;
    height: 20px;
  }

  .features_step .slider_show .slick-slider button.slick-arrow.slick-next {
    right: -10px;
  }

  .features_step .slider_show .slick-list .pop_slideron .slide_cont {
    padding: 15px 15px;
    left: 10px;
    bottom: 0;
    width: calc(100% - 20px);
  }

  .features_step .slider_show .slick-list .pop_slideron .slide_cont h3 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .features_step .slider_show .slick-list .pop_slideron .slide_cont p {
    font-size: 12px;
    line-height: 20px;
  }

  .features_step .slider_show .slick-list .pop_slideron figure {
    border-radius: 15px;
  }

  .featur_para.pop_slideron {
    padding: 0px 10px;
    min-height: 500px;
  }

  .features_step .pure-modal-backdrop .pure-modal .close {
    height: 44px;
    width: 44px;
    line-height: 38px;
    font-size: 25px;
    left: -25px;
  }

  .btn_slide_bike {
    bottom: -14px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .bike_color .landing_page_container .sec_heading h2 sup {
    font-size: 14px;
    top: -0.9em;
  }

  .imp_modal.pure-modal {
    width: calc(100% - 30px);
    margin: 0px 15px;
    padding: 20px;
  }

  .imp_modal.pure-modal .panel-body h2 {
    font-size: 20px;
    padding-bottom: 15px;
    margin-bottom: 20px;
  }

  .imp_modal.pure-modal .panel-body h3 {
    font-size: 16px;
  }

  .imp_modal.pure-modal .panel-body p {
    color: rgb(255 255 255 / 75%);
    font-size: 14px;
    margin-bottom: 15px;
  }

  /* #privacy-policy .payment_secmain .payment_cont p {
        font-size: 14px;
        margin-bottom: 20px;
    } */

  #privacy-policy .payment_secmain .payment_cont h5 {
    font-size: 18px;
    margin-bottom: 15px;
  }

  /* #privacy-policy .payment_secmain .payment_cont ul {
        padding-left: 20px;
    } */

  /* #privacy-policy .payment_secmain .payment_cont ul li {
        font-size: 14px;
        margin-bottom: 10px;
    } */

  .newtech_specs .info {
    margin-top: 20px;
    padding: 0px 15px;
  }

  .Features_section .vimeo-video {
    width: 160%;
    height: 190%;
    position: relative;
    left: -30%;
    z-index: 0;
    top: -30%;
  }

  .pricing_sec .oxo > .varient {
    margin-bottom: 5px;
  }

  .pricing_sec .varient {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 5px;
  }

  .pricing_sec .select-sec select {
    padding: 7px;
  }

  .pricing_sec .price-content ul {
    margin-bottom: 10px;
  }

  .pricing_sec .price-content ul li span.right {
    white-space: nowrap;
    align-self: center;
  }

  .pricing_sec .price-content ul li span {
    font-size: 16px;
    line-height: 22px;
    padding-right: 10px;
  }

  .pricing_sec .price-content ul li {
    padding: 8px 0px;
  }

  .pricing_sec .price-content ul li:last-child {
    display: none;
  }

  .pricing_sec .mobile {
    display: block;
    margin-top: 30px;
  }

  .pricing_sec .right_sec .btn-group-price button {
    font-size: 18px;
    line-height: 27px;
  }

  .pricing_sec::before {
    filter: blur(30px);
    height: 300px;
    right: -150px;
    top: -180px;
    width: 300px;
  }

  .pricing_sec::after {
    filter: blur(40px);
    height: 500px;
    left: -300px;
    bottom: -200px;
    width: 500px;
  }

  .pricing_sec .price-content button.view-prize-breakup span {
    font-size: 14px;
    color: #fff;
  }

  .pricing_sec .price-content button.view-prize-breakup {
    margin: 5px auto;
  }

  .pricing_sec .price-content button.book-nowbtn {
    margin: 0px auto;
    display: flex;
    justify-content: center;
    font-size: 14px;
    line-height: normal;
    min-width: 200px;
    align-items: center;
    padding: 12px 20px 10px;
  }

  #PricingBreakupModal ul {
    margin-bottom: 20px;
  }

  #PricingBreakupModal ul li {
    font-size: 16px;
    line-height: 30px;
  }

  #PricingBreakupModal .panel-body h6 {
    margin-bottom: 15px;
  }

  .tech_specs_sec .btn-group-price {
    display: flex;
  }

  .btn-group-price {
    background: #0a0a0a;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }

  .btn-group-price.oxo_both {
    height: 45px;
  }

  .btn-group-price button.btn-price-oxo {
    font-family: "Raleway";
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: #fff;
    /* width: 35%; */
    width: 27.33%;
    border-radius: 8px;
    padding: 12px 10px;
  }

  .btn-group-price button.btn-price-oxo.activeBtnOxo1 {
    background: #00eaff;
    color: #000;
    width: 40.33%;
    margin-top: -6px;
  }

  .btn-group-price button.btn-price-oxo.activeBtnOxo1 {
    background: #00eaff;
    color: #000;
  }

  .Specs_conbg.ActiveOxoContent
    .newtech_specs
    ul:last-child
    li:nth-child(3n + 2) {
    display: block;
  }

  .Specs_conbg.ActiveOxoContent
    .newtech_specs
    ul:last-child
    li:nth-child(3n + 3) {
    display: none;
  }

  .Specs_conbg.ActiveOxoXContent
    .newtech_specs
    ul:last-child
    li:nth-child(3n + 2) {
    display: none;
  }

  .Specs_conbg.ActiveOxoXContent
    .newtech_specs
    ul:last-child
    li:nth-child(3n + 3) {
    display: block;
    list-style: disc;
  }

  .newtech_specs ul:last-child li:nth-child(3n + 2) {
    padding-right: 0px;
  }

  .Specs_conbg.ActiveOxoContent .newtech_specs ul.top_sec_oxo_x,
  .Specs_conbg.ActiveOxoPrimeContent .newtech_specs ul.top_sec_oxo_x {
    display: none;
  }

  .Specs_conbg.ActiveOxoXContent .newtech_specs ul.top_sec_oxo_x {
    display: none;
  }

  .newtech_specs {
    padding: 0px 12px;
  }

  /* vikash css for tech specs section   */

  .newtech_specs ul li {
    width: 50%;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 5px;
    display: none !important;
  }

  .newtech_specs ul li.firs_heading {
    display: block !important;
  }

  .newtech_specs ul:last-child li:nth-child(4n + 1) {
    display: list-item !important;
  }

  .ActiveOxoPrimeContent .newtech_specs ul:last-child li:nth-child(4n + 2) {
    display: block !important;
  }

  .ActiveOxoContent .newtech_specs ul:last-child li:nth-child(4n + 3) {
    display: block !important;
  }

  .ActiveOxoXContent .newtech_specs ul:last-child li:nth-child(4n + 4) {
    display: block !important;
  }

  .newtech_specs h5 {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 5px;
  }

  .ActiveOxoContent .scrollable .on_popup .newtech_specs ul.top_sec_oxo_x {
    display: none;
  }

  .ActiveOxoXContent .scrollable .on_popup .newtech_specs ul.top_sec_oxo_x {
    display: none;
  }

  .ActiveOxoPrimeContent
    .pure-modal-backdrop
    .pure-modal
    .panel-default
    .scrollable
    .tech_deails.on_popup
    .newtech_specs
    ul
    li:nth-child(4n + 2) {
    padding-right: 0px;
    display: block !important;
  }

  .ActiveOxoContent
    .pure-modal-backdrop
    .pure-modal
    .panel-default
    .scrollable
    .tech_deails.on_popup
    .newtech_specs
    ul
    li:nth-child(4n + 3) {
    display: block !important;
  }

  .ActiveOxoXContent
    .pure-modal-backdrop
    .pure-modal
    .panel-default
    .scrollable
    .tech_deails.on_popup
    .newtech_specs
    ul
    li:nth-child(4n + 4) {
    display: block !important;
  }

  .pure-modal-backdrop
    .pure-modal
    .panel-default
    .scrollable
    .tech_deails.on_popup
    .newtech_specs
    ul {
    padding: 5px 10px 10px 25px;
  }

  .pure-modal-backdrop
    .pure-modal
    .panel-default
    .scrollable
    .tech_deails.on_popup
    .newtech_specs
    h5 {
    padding-left: 25px;
  }

  .features_step .slider_show .slick-list .pop_slideron figure img {
    border-radius: 15px 15px 0px 0px;
  }

  .social_sec .social_link a > figure {
    height: 115px;
  }

  .social_popup .pure-modal .panel-default .close .close_btn {
    height: 40px;
    width: 40px;
    line-height: 40px;
    margin-right: 0;
    margin-top: 5px;
  }

  .social_popup .pure-modal .panel-default .close .close_btn::before {
    bottom: 17px;
    left: 9px;
    width: 22px;
  }

  .social_popup .pure-modal .panel-default .close .close_btn::after {
    bottom: 17px;
    left: 9px;
    width: 22px;
  }

  .youtube_facebook_sec .landing_page_container {
    padding: 20px 20px 20px 20px;
    position: relative;
    z-index: 9;
  }

  .subscribe_sec {
    bottom: 20px;
  }

  .youtube_facebook_sec h3 {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 10px;
  }

  .youtube_facebook_sec::after {
    filter: blur(40px);
    height: 200px;
    left: -100px;
    bottom: -100px;
    width: 200px;
  }

  .youtube_facebook_sec::before {
    filter: blur(40px);
    width: 200px;
    height: 200px;
    right: -100px;
    top: -100px;
  }

  .social_sec .social_link .social_text {
    padding: 10px 15px;
  }

  .logo_secchange img {
    width: 150px;
  }

  .logo_secchange p {
    font-size: 14px;
    line-height: 22px;
  }

  .logo_secchange {
    margin-bottom: 5px;
  }

  .social_sec .social_link .social_text p {
    font-size: 14px;
    line-height: 18px;
  }

  .subscribe_sec button.default_btn {
    font-size: 12px;
    border-radius: 8px;
    padding: 14px 20px;
  }

  .social_sec {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .social_sec .social_link {
    border-radius: 8px;
    width: calc(100% - 0px);
    margin-bottom: 10px;
  }

  .social_sec .social_link a figure img {
    border-radius: 8px;
    height: 110px;
    object-fit: cover;
  }

  .social_popup .pure-modal .panel-default .close {
    right: 30px !important;
  }

  .social_sec .social_link a .social_text figure img {
    height: auto;
    width: 22px;
  }

  .social_popup .social_popup_cont h2 {
    font-size: 24px !important;
    line-height: 36px !important;
    margin-bottom: 15px !important;
  }

  .social_popup .social_popup_cont p {
    font-size: 16px;
    line-height: 24px;
  }

  .social_popup .social_popup_cont p {
    margin-bottom: 5px !important;
  }

  .social_popup
    .social_popup_cont
    form
    .foem_cus
    .form_input
    .uk-margin
    input.uk-input {
    margin-bottom: 0px;
  }

  .social_popup
    .pure-modal-backdrop
    .pure-modal
    .popup_cont.social_popup_cont
    h2 {
    font-size: 22px !important;
  }

  .social_popup .pure-modal-backdrop {
    background: transparent !important;
  }

  .social_popup .pure-modal-backdrop::before {
    display: none !important;
  }

  .social_popup .pure-modal-backdrop::after {
    display: none !important;
  }

  .social_popup .social_popup_cont form .foem_cus .btn_sec {
    margin-top: 10px;
  }

  .social_popup .social_popup_cont form .btn_sec .default_btn {
    font-size: 16px;
    border-radius: 8px;
  }

  .thanks_popup .thanks_popup_cont .changethegame p {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 0px !important;
  }

  .three_img .click_img {
    border-radius: 0px 0px 12px 12px;
  }

  .full_video_bike::after {
    content: "";
    background: linear-gradient(
      180deg,
      rgb(10 10 10 / 0%) 0%,
      rgb(10 10 10) 100%
    );
    position: absolute;
    bottom: 0;
    height: 150px;
    left: 0;
    right: 0;
    z-index: 0;
  }

  .logo_cont::before {
    display: none;
  }

  .logo_cont {
    padding: 0;
    max-width: 280px;
    top: 14%;
  }

  .steps ul li:last-child {
    margin-bottom: 0px !important;
  }

  .slider_popup .popup_cont {
    max-width: 780px;
  }

  .slider_popup .popup_cont .slick-slider {
    width: 100%;
    margin: auto;
  }

  .slider_popup .popup_cont .slick-slider .slide_bike_showcon {
    padding: 0px 15px 20px;
    text-align: center;
  }

  .slider_popup .popup_cont .slick-dots {
    margin-bottom: 0px;
  }

  .slider_popup .popup_cont .slick-slider .slide_bike_showcon h5 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 5px;
  }

  .slider_popup .popup_cont .slick-slider .slide_bike_showcon p {
    font-size: 14px;
    line-height: 20px;
  }

  .modal_popup .pure-modal-backdrop .popup_cont h2 {
    font-size: 24px !important;
  }

  .close_btn::before {
    width: 30px;
  }

  .close_btn::after {
    width: 30px;
  }

  .landig_banner_cont img {
    width: 100%;
    margin-bottom: 15px;
  }

  .landing_banner::before {
    height: 140px;
  }

  .Features_section .sec_heading {
    margin-bottom: 24px;
  }

  .bike_color .img_bike > figure {
    max-width: 100%;
    min-height: 200px;
  }

  .bike_color {
    padding: 40px 20px 20px;
  }

  .bike_color .landing_page_container {
    padding: 0px 0px;
  }

  .faq_sec .landing_page_container {
    padding: 20px 20px;
  }

  .faq_sec .Accordion_sec {
    height: calc(100% - 61px);
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .landing_banner p {
    font-size: 18px;
    line-height: 24px;
  }

  .btn_banner .default_btn {
    padding: 12px 26px;
    border-radius: 12px;
    font-size: 16px;
    margin: 0px 15px 15px 0px;
    display: block;
  }

  .steps ul li::before {
    border-radius: 8px;
  }

  .steps ul li {
    padding: 5px 5px;
    width: 90px;
    height: auto;
    margin-bottom: 8px !important;
    border-radius: 8px;
    display: flex;
    flex-direction: column-reverse;
  }

  .steps ul li p {
    font-size: 15px;
    text-align: center;
    color: rgb(255 255 255 / 60%);
    letter-spacing: 0px;
  }

  .steps ul li span {
    font-size: 20px;
    display: block;
    text-align: center;
    line-height: normal !important;
  }

  .steps ul li span p {
    font-size: 12px;
    text-align: center;
    padding: 0;
    color: rgb(255 255 255 / 60%);
  }

  .bike_cont p {
    padding-right: 20px;
    margin-bottom: 10px;
  }

  .text_right p {
    padding-right: 0px !important;
    padding-left: 50px;
    padding-left: 0px;
  }

  .features_sec .second_gra .uk-colum_4 {
    order: 1;
  }

  #home::before {
    height: 500px;
    width: 440px;
  }

  .steps::after {
    height: 0;
    width: 0;
  }

  .uk-container {
    max-width: 992px;
    padding: 0px 20px;
  }

  button.click_on img {
    width: 80px;
  }

  .features_sec .sec_heading h2 {
    margin-bottom: 30px;
  }

  .bike_cont h4 {
    font-size: 26px;
    margin-bottom: 10px;
  }

  .uk-grid .uk-colum_8 {
    width: 100%;
    padding: 0px 15px;
    margin-bottom: 30px;
  }

  .uk-grid .uk-colum_4 {
    width: 100%;
    padding: 0px 15px;
  }

  .features_sec .second_gra {
    margin-top: 30px !important;
    margin-left: 0;
  }

  .features_sec .three_gra {
    margin-top: 30px !important;
    margin-left: 0;
  }

  .text_right {
    text-align: left;
  }

  .bike_color::after {
    height: 250px;
    left: -300px;
    width: 480px;
    filter: blur(60px);
  }

  .bike_color::before {
    height: 300px;
    position: absolute;
    right: -200px;
    top: -130px;
    width: 350px;
    background: linear-gradient(211deg, #14214e, #14214e);
  }

  .features_sec .first_gra::after {
    height: 410px;
    width: 380px;
    top: 160px;
    right: -200px;
  }

  .features_sec .second_gra::before {
    height: 400px;
    width: 450px;
    top: 120%;
  }

  .features_sec .three_gra::before {
    height: 0px;
    width: 0px;
    top: 0px;
  }

  .features::after {
    height: 550px;
    width: 450px;
  }

  .show_details_popup {
    font-size: 26px;
    line-height: 26px;
  }

  .text_sec {
    bottom: 20px;
    left: 20px;
  }

  .features_sec {
    padding: 20px 0px 20px 0px;
  }

  .features .bg_colorfu {
    padding: 30px 0px;
  }

  .tech_specs_sec .landing_page_container {
    padding: 30px 20px;
    overflow: hidden;
  }
  .tech_specs_sec.for_hopleo {
    padding: 0px 0px 20px;
  }
  .for_hopleo .tabing_section,
  .description_sec {
    margin-top: 0px !important;
  }
  .Hop_leo_main .full_video_bike{
    margin-bottom: 20px !important;
  }
 


  .tech_specs_sec .sec_heading p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .click_pop .open_pop_on {
    left: 0;
    top: 130%;
    transform: translateY(-50%);
    height: 36px;
    width: 36px;
    font-size: 26px;
  }

  .tech_specs_sec .panel-body.scrollable {
    border-radius: 0px;
    padding: 90px 30px 20px 30px;
  }

  .tech_specs_sec .pure-modal-backdrop .pure-modal {
    width: 100% !important;
    margin-top: 0;
    padding: 0px 0px 0px 0px;
    max-height: calc(100% - 0px);
    border-radius: 0px 0px 0px 0px;
  }

  .tech_specs_sec .panel-body.scrollable .on_popup {
    padding: 0;
    border-radius: 8px;
    background: transparent;
  }

  .tech_specs_sec .pure-modal-backdrop .pure-modal .close {
    height: 40px;
    width: 40px;
    line-height: 33px;
    font-size: 28px;
    left: inherit;
    right: 24px;
    top: 24px;
  }

  .tech_specs_sec .panel-body.scrollable .sec_heading.on_pop_Specs {
    margin-bottom: 12px;
  }

  .tech_deails {
    padding: 15px 10px 15px 10px;
    padding-bottom: 30px;
    height: auto;
  }

  .click_pop .open_pop_on img {
    width: 22px;
  }

  .click_bottom button {
    height: 70px;
    width: 70px;
  }

  .click_bottom {
    margin-top: -70px;
  }

  .tech_specs_sec::after {
    height: 200px;
    width: 350px;
    bottom: 0px;
  }

  .locater .sec_heading h2 {
    margin-bottom: 14px;
  }

  .locater {
    padding: 20px 0px;
    height: calc(100vh - 0px);
  }

  .locater .map_sec .css-13c2lq1 {
    height: 40%;
  }

  .reviews .landing_page_container {
    padding: 20px 20px;
  }

  .locater::after {
    background: linear-gradient(211deg, #13204d, #13204d);
    border-radius: 50%;
    content: "";
    filter: blur(50px);
    height: 250px;
    position: absolute;
    right: -300px;
    left: 0;
    top: -60px;
    width: 500px;
    z-index: -1;
    margin-left: auto;
  }

  .reviews .three_img .click_img button img {
    width: 90px;
  }

  .reviews .three_img span {
    font-size: 20px;
    bottom: 0;
    margin-bottom: 4px;
  }

  .faq_sec .sec_heading {
    margin-bottom: 35px;
  }

  .faqs {
    max-width: 80%;
    padding: 0;
  }

  .faqs .faq {
    padding: 10px 35px 25px 15px;
    margin: -15px 0px 0px 0px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    border-bottom: none;
  }

  .faqs .faq.open .faq-question {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 5px;
  }

  .faq.open {
    margin-left: -13px !important;
    margin-right: -13px !important;
    padding: 15px 40px 15px 15px;
    border: 0px solid rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    margin-bottom: -15px;
  }

  .faqs .faq .faq-question {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: rgb(255 255 255 / 80%);
  }

  .faqs .faq .faq-question::after {
    top: 25px;
    right: 10px;
    height: 16px;
    width: 16px;
  }

  .faq_sec:before {
    height: 450px;
    width: 400px;
    filter: blur(50px);
  }

  .faq_sec::after {
    height: 350px;
    width: 500px;
    filter: blur(50px);
  }

  .faqs .faq.open .faq-answer {
    font-size: 13px;
    line-height: 20px;
    max-height: none;
  }

  .faqs .faq.open .faq-question::after {
    top: 20px;
    right: 12px !important;
    width: 14px;
    height: 14px;
  }

  .steps ul {
    flex-wrap: wrap;
    justify-content: left;
  }

  .features_sec .first_gra {
    margin-left: 0;
  }

  .sec_heading h2 {
    font-size: 24px;
    line-height: 26px;
  }

  .bike_color .change_color_bike::before {
    display: none;
  }

  .features_sec .uk-colum_8 figure img {
    border-radius: 10px 10px 10px 10px;
  }

  .features .bg_colorfu .uk-grid .uk-colum_6.img_sha {
    width: calc(100% - 0px);
    margin-bottom: 30px;
  }

  .features .bg_colorfu .uk-grid .uk-colum_6.img_sha:last-child {
    margin-bottom: 0px;
  }

  .features {
    padding: 30px 0px;
  }

  .reviews .three_img {
    width: 100%;
    margin-bottom: 30px !important;
    margin: 0px 0px;
  }

  .reviews .three_img:last-child {
    margin-bottom: 0px !important;
  }

  .reviews .sec_heading h2 {
    margin-bottom: 24px;
  }

  .tech_specs_sec::before {
    height: 300px;
    top: -120px;
    width: 400px;
    z-index: 0;
  }

  .reviews::after {
    height: 500px;
    width: 500px;
  }

  .modal_popup .pure-modal-backdrop .panel.panel-default .close {
    top: 0px;
  }

  /* book-order-button-start */

  .full_video_bike .btn_banner {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 9;
    display: flex;
    width: 100%;
    border: 1px solid #00eaff;
  }

  .btn_banner .default_btn {
    width: 50%;
    padding: 15px 10px;
    border-radius: 0;
    font-size: 14px;
    margin: 0;
    display: inline-block;
    border: none;
    background: #00eaff;
    border-radius: 15px 0px 0px 0px;
    line-height: 24px;
  }

  .landig_banner_cont .hop_oxo {
    padding: 0px 5px;
    width: 100%;
    text-align: center;
    justify-content: center;
    margin-bottom: 80px;
  }

  .hop_oxo .rs_sec p {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
    letter-spacing: 0.5px;
  }

  .btn_banner button.default_btn.Book {
    width: 50%;
    order: -1;
    border-radius: 0px 0px 0px 0px;
    background: transparent;
  }

  .landing_banner .btn_banner {
    position: inherit;
    bottom: 0;
    right: 0;
    z-index: 9;
    display: flex;
    width: 100%;
    border: 1px solid #00eaff;
  }

  .bike_color .change_color_bike .click_bike_color {
    height: 25px;
    width: 25px;
  }

  /* book-order-button-end*/
  .click_bottom button figure img {
    z-index: 7;
  }

  .bike_color .img_bike div#identifier {
    position: relative;
  }

  .three_img span {
    font-size: 22px;
  }

  .three_img .click_img {
    padding: 10px 15px;
    background: rgb(0 0 0 / 60%);
    width: 100%;
  }

  /* ========= */
  .video_sec_step img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .landig_banner_cont::before {
    display: none;
  }

  .logo_cont figure img {
    width: 200px;
    margin-bottom: 5px;
  }

  .logo_cont p {
    font-size: 12px !important;
    line-height: 20px !important;
  }

  .width_sec {
    max-width: 100%;
    z-index: 1;
  }

  .landing_banner .width_sec {
    padding: 0px;
    height: 100%;
    width: 100%;
  }

  .hop_oxo img {
    display: none;
  }

  .hop_oxo strong {
    display: none;
  }

  .width_sec .steps {
    left: 20px;
    top: 50%;
  }

  .hop_oxo .rs_sec {
    border-left: 0px solid #dddee0;
    padding-left: 0;
    margin-left: 0;
  }

  .hop_oxo .rs_sec strong {
    font-size: 24px;
    display: block;
    line-height: 30px;
  }

  .landig_banner_cont {
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0px;
    justify-content: space-between;
    padding: 0;
    align-items: center;
  }

  .reviews .pure-modal-backdrop .panel-body .three_img img {
    height: 260px;
  }

  .features_step h2.sec_ste {
    font-size: 20px;
    left: 0;
    top: 0;
    position: inherit;
    margin-bottom: 15px;
  }

  .featur_show .featur_para h3 {
    font-size: 16px;
    margin-bottom: 7px;
  }

  .featur_show .featur_para p {
    font-size: 12px;
    line-height: 20px;
  }

  .features_step .pure-modal-backdrop .pure-modal .panel-default .panel-body {
    border-radius: 0px 0px 0px 0px;
    padding: 100px 20px 20px 20px;
  }

  .features_step .slider_show .slick-slider button.slick-arrow {
    display: none !important;
  }

  .features_step
    .pure-modal-backdrop
    .pure-modal
    .panel-default
    .panel-body
    .slider_show
    .slick-list
    figure
    img {
    height: 260px;
  }

  .features_step .slider_show .slick-list .pop_slideron .slide_cont {
    padding: 15px 15px;
    margin-top: 0;
    border: none;
    border-radius: 10px;
    background: none;
  }

  .features_step .slider_show .slick-list .pop_slideron .slide_cont h3 {
    font-size: 22px;
    margin-bottom: 10px;
    line-height: 30px;
  }

  .features_step .slider_show .slick-list .pop_slideron .slide_cont p {
    font-size: 12px;
    line-height: 20px;
  }

  .featur_para.pop_slideron {
    padding: 0;
    margin-left: 0;
    min-height: auto;
  }

  .features_step .slider_show .slick-list .pop_slideron figure {
    height: auto;
    border-radius: 8px;
  }

  .features_step .pure-modal-backdrop .pure-modal .close {
    width: 40px;
    line-height: 30px;
    font-size: 28px;
    height: 40px;
    left: inherit;
    right: 24px;
    top: 24px;
    transform: translateY(0%);
  }

  .features_step .pure-modal-backdrop .pure-modal {
    width: 100% !important;
  }

  /* .btn_banner button.default_btn.drstop_btn {
        display: none;
    } */

  .btn_banner button.default_btn.mobile_btn {
    display: block !important;
  }

  .landing_banner::after {
    content: "";
    background: linear-gradient(
      180deg,
      rgb(10 10 10 / 0%) 0%,
      rgb(10 10 10) 100%
    );
    position: absolute;
    bottom: 0;
    height: 150px;
    left: 0;
    right: 0;
    z-index: 0;
  }

  .video_sec_step .slide_mobile {
    display: block !important;
  }

  .video_sec_step .slide_des {
    display: none;
  }

  .full_video_bike .logo_cont.on_mo {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    padding: 90px 20px 0px 20px;
    display: block !important;
    margin: auto;
    text-align: center;
  }

  .full_video_bike .logo_cont.on_mo img {
    width: 100%;
    margin: auto;
  }

  .bike_color .img_bike .rotade_bike figure {
    display: none;
  }

  .bike_color .landing_page_container .sec_heading {
    text-align: center;
    width: 100%;
  }

  .rotade_bike {
    position: initial;
    bottom: 0;
  }

  .btn_slide_bike span {
    height: 8px;
    width: 8px;
    margin: 0px 15px;
    box-shadow: 0px 0px 0px 5px #040404;
  }

  .btn_slide_bike span:nth-child(2) {
    height: 12px;
    width: 12px;
    outline: 1.5px solid #fff;
    outline-offset: 6px;
    margin-bottom: -1px;
  }

  .btn_slide_bike:before {
    content: "";
    background: rgb(255 255 255 / 50%);
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    width: 100%;
    top: 12px;
    z-index: -1;
  }

  .btn_slide_bike {
    position: relative;
    bottom: 0;
  }

  .bike_color .img_bike::before {
    display: none;
  }

  .bike_color .change_color_bike {
    background: transparent;
    border: none;
    max-width: 100%;
    padding: 0px;
    width: calc(100% - 0px);
    bottom: 40px;
  }

  .color_step:after {
    content: "";
    background: rgb(255 255 255 / 50%);
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    border-radius: 5px;
    bottom: -35px;
    display: none;
  }

  .color_step form .form-check.mo_on_color label {
    display: block !important;
    font-size: 16px;
    padding-top: 40px;
  }

  .color_step form .form-check.mo_on_color {
    margin: 0px 10px;
    padding-top: 60px;
    position: relative;
  }

  .color_step form .form-check.mo_on_color::before {
    content: "";
    background: #00eaff;
    position: absolute;
    height: 4px;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 39px;
    z-index: 1;
    border-radius: 2px;
  }

  .color_step form .form-check.mo_on_color input::before {
    left: -9px;
    height: 32px;
    width: 32px;
    outline-offset: 6px;
    top: -9px;
  }

  .color_step form .form-check input::before,
  .color_step.onmobile_360 form .form-check label::before {
    height: 16px;
    width: 16px;
    outline-offset: 5px;
    margin-right: 0px;
    transform: none;
    margin: auto;
    right: 0;
  }

  .change_color_bike .color_step {
    width: calc(100% - 0px);
  }

  .change_color_bike .color_step::after {
    display: none;
  }

  .Features_section
    .features_step
    .pure-modal
    .slick-list
    .slick-track
    .slick-slide {
    margin-bottom: 20px;
  }

  .color_step form .form-check {
    margin: 0px 5px;
    width: calc(20% - 10px);
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    transform: translateX(0px);
  }

  .color_step form .form-check input {
    border: none;
    width: 100%;
  }

  .bike_color .change_color_bike .hop_oxo {
    display: none;
  }

  .features_step:last-child {
    margin-bottom: 0px;
  }

  .features_step {
    padding: 0px 20px;
    height: auto;
  }

  .Features_section .features_step .landing_page_container.fea_hea {
    position: inherit;
    top: 0;
    padding: 0px 0px;
  }

  .Features_section.first_fedtsec .features_step {
    height: calc(100% - 300px);
  }

  .Features_section {
    padding: 20px 0px;
  }

  .features_step .landing_page_container.features_cont {
    position: initial;
    bottom: 0;
    background: rgb(0 0 0 / 60%);
    border-radius: 0px 0px 8px 8px;
    padding: 0;
  }

  .Features_section .features_step figure {
    height: 300px;
    overflow: hidden;
    border-radius: 8px 8px 0px 0px;
  }

  .Features_section.first_fedtsec .features_step figure {
    height: calc(100% - 10px);
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
  }

  .Features_section .features_step figure video.desktop {
    display: none;
  }

  .Features_section .features_step figure video.mobile {
    display: block;
    border-radius: 8px 8px 0px 0px;
  }

  .Features_section.first_fedtsec {
    overflow: hidden;
    padding: 30px 0px 30px 0px;
  }

  .features_cont .featur_show::before {
    border-radius: 0px 0px 16px 16px;
  }

  .Features_section .features_step .pure-modal .slick-list .slick-track {
    transform: none !important;
    width: 100% !important;
  }

  .features_step
    .pure-modal-backdrop
    .pure-modal
    .panel-body
    > .slider_show
    .slick-track {
    display: block;
  }

  .featur_show .featur_para {
    width: 100%;
    padding-right: 0;
    margin-bottom: 15px;
  }

  .featur_show .default_btn {
    padding: 12px 12px;
    width: 80%;
    margin: auto;
    border-radius: 12px;
    font-size: 14px;
  }

  .features_cont .featur_show {
    padding: 15px 15px 20px 15px;
    border-radius: 0px 0px 8px 8px;
  }

  .Features_section .uk-container {
    padding: 0;
  }

  .features_step figure img {
    border-radius: 8px 8px 0px 0px;
    height: 100%;
    object-fit: cover;
  }

  .Specs_conbg::after {
    display: none;
  }

  .tech_specs_sec .sec_heading {
    margin-bottom: 0px;
  }

  .locater .landing_page_container {
    padding-top: 0;
  }

  .tech_specs_sec .sec_heading h2,
  .locater .sec_heading h2 {
    margin-bottom: 15px;
    font-size: 22px;
    line-height: 22px;
    padding-left: 0px;
  }
  .pricing_sec .sec_heading{
    margin-bottom: 15px;
  }
  .pricing_sec .sec_heading h2{
    padding-left: 0px;
  }

  .tech_specs_sec .mobile-select-variant {
    display: block;
  }

  .tech_specs_sec
    .pure-modal-backdrop
    .pure-modal
    > .panel.panel-default
    > .panel-body {
    padding-bottom: 20px;
  }

  .map_form.destop_v {
    display: none;
  }

  .tech_specs_sec::before {
    height: 300px;
    position: absolute;
    right: -200px;
    top: -130px;
    width: 400px;
    background: linear-gradient(211deg, #14214e, #14214e);
    border-radius: 50%;
    content: "";
    filter: blur(50px);
    left: 0;
    z-index: 0;
    margin-left: auto;
  }

  .tech_specs_sec::after {
    background: linear-gradient(211deg, #2a63a3, #2a63a3);
    border-radius: 50%;
    content: "";
    filter: blur(50px);
    height: 450px;
    position: absolute;
    left: -250px;
    width: 480px;
    z-index: 0;
    margin-left: auto;
    bottom: -275px;
  }

  .map_form.mobile_v {
    display: block;
    position: initial;
    max-width: 100%;
    background: transparent;
    -webkit-backdrop-filter: blur(0px);
    backdrop-filter: blur(0px);
    top: 0;
    left: 0;
    right: 0;
    padding: 10px 20px 24px 20px;
  }

  .map_form.mobile_v form .search_bar {
    margin-right: 10px;
    width: calc(55% - 10px);
  }

  .map_form form .search_bar button {
    left: 8px;
    right: auto;
  }

  .map_form.mobile_v .search_bar input {
    background: rgba(0, 234, 255, 0.2);
    border-radius: 8px;
    font-size: 12px;
    padding: 8px 8px 8px 30px;
    color: #00eaff;
    text-align: left;
    width: 100%;
  }

  .map_form.mobile_v .form-group button.default_btn {
    padding: 8px 5px;
    font-size: 12px;
    width: 100%;
  }

  .map_form::before {
    display: none;
  }

  .map_form.mobile_v ::placeholder {
    color: #00eaff;
  }

  .map_form form {
    flex-wrap: wrap;
    justify-content: left;
  }

  .Dealers_sec ul::before {
    display: none;
  }

  .Dealers_sec {
    position: inherit;
    right: 0;
    left: 20px;
    top: 20px;
    padding: 0;
    max-width: 100%;
    margin: 0px auto;
    backdrop-filter: blur(0px);
    background: transparent;
  }

  .Dealers_sec h5 {
    font-size: 20px;
    color: rgb(255 255 255 / 80%);
    line-height: 32px;
    margin-bottom: 16px;
  }

  .Dealers_sec ul li {
    padding: 16px 20px;
    text-align: center;
  }

  .locater::before {
    background: linear-gradient(211deg, #2a63a3, #2a63a3);
    border-radius: 50%;
    content: "";
    filter: blur(60px);
    height: 400px;
    position: absolute;
    left: -200px;
    bottom: -200px;
    width: 450px;
    z-index: -1;
  }

  .reviews::before {
    height: 400px;
    width: 400px;
    filter: blur(50px);
  }

  .Dealers_sec ul li strong {
    line-height: 22px;
  }

  .map_form.mobile_v .form-group {
    width: 45%;
  }

  .map_form.mobile_v form .search_bar button img {
    position: initial;
    left: 0;
    top: auto;
    transform: translateY(0px);
  }

  /* ============ */

  .on_mobile_mapslide ul li strong {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .on_mobile_mapslide ul li {
    padding: 16px;
  }

  .on_mobile_mapslide ul li p {
    font-size: 12px;
    color: rgb(255 255 255 / 60%);
    line-height: 20px;
  }

  .on_mobile_mapslide ul li button.default_btn {
    font-size: 12px;
    padding: 7px 37px 7px 16px;
  }

  .Dealers_sec {
    display: none;
  }

  .on_mobile_mapslide {
    display: block;
    height: 100%;
    width: 100%;
  }

  .on_mobile_mapslide button.slick-arrow {
    display: none !important;
  }

  .Dealers_sec::before {
    display: none;
  }

  .on_mobile_mapslide .slick-slider .slick-list .slick-slide {
    padding-right: 16px;
  }

  span.review_btn {
    display: block;
    position: absolute;
    right: 24px;
    top: 25px;
    color: #00eaff;
    font-size: 18px;
    font-weight: 700;
    font-family: "Lato", sans-serif;
    cursor: pointer;
  }

  .reviews .uk-grid {
    height: calc(100% - 25px);
    width: calc(100% + 0px);
    margin: 0px 0px;
  }

  .reviews .three_img {
    display: none;
  }

  .reviews .three_img:nth-child(1) {
    display: block;
    margin-bottom: 0px !important;
    background: rgb(0 0 0 / 0%);
  }

  .reviews .three_img:nth-child(2) {
    display: block;
    margin-bottom: 0px !important;
    background: rgb(0 0 0 / 0%);
  }

  .reviews .pure-modal-backdrop .panel-body .three_img {
    display: block;
    background: transparent;
    margin-bottom: 4px !important;
    height: auto;
  }

  .reviews .pure-modal-backdrop .panel-body .three_img .click_img {
    position: inherit;
    bottom: 40px;
  }

  .reviews .pure-modal-backdrop .panel-body .three_img .click_img p {
    display: -webkit-box;
    -webkit-line-clamp: initial;
    overflow: auto;
  }

  .reviews .pure-modal-backdrop .panel .panel-body {
    background: #2e2e2e;
    padding: 112px 20px;
    padding-bottom: 20px;
  }

  .reviews .pure-modal-backdrop .panel .panel-body .three_img:nth-child(2) {
    margin-bottom: 4px !important;
  }

  .img_sha figure::before {
    display: none;
  }

  .three_img figure img {
    width: 100%;
    border-radius: 12px 12px 12px 12px;
    height: 200px;
    object-fit: cover;
    filter: blur(2px);
  }

  .three_img .click_img p {
    font-size: 12px;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Dealers_sec ul {
    width: 100%;
    height: 100%;
  }

  .reviews .pure-modal-backdrop .panel .close {
    right: 24px;
    top: 24px;
    margin: 0px auto;
    background: rgba(0, 234, 255, 0.1);
    border: 1px solid #00eaff;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    border-radius: 50%;
    color: #00eaff;
    height: 48px;
    width: 48px;
    line-height: 40px;
    font-size: 35px;
  }

  .Features_section::before {
    display: none;
  }

  .Features_section {
    z-index: auto;
    position: relative;
    display: flex;
    align-items: center;
  }

  .Features_section::after {
    display: none;
  }

  .features_step::before {
    display: none;
  }

  .Features_section::before {
    display: block;
    height: 350px;
    position: absolute;
    right: -200px;
    top: -200px;
    width: 350px;
    background: linear-gradient(211deg, #14214e, #14214e);
    border-radius: 50%;
    content: "";
    filter: blur(50px);
    left: 0;
    z-index: 0;
    margin-left: auto;
  }

  .Features_section::after {
    display: block;
    background: linear-gradient(211deg, #2a63a3, #2a63a3);
    border-radius: 50%;
    content: "";
    filter: blur(50px);
    height: 400px;
    position: absolute;
    left: -200px;
    width: 400px;
    z-index: -1;
    margin-left: auto;
    top: 400px;
  }

  .tech_specs_sec .panel-body.scrollable .sec_heading.on_pop_Specs {
    display: block;
  }

  .color_step form .form-check label {
    font-size: 14px;
    padding-left: 0;
    line-height: 20px;
    width: 100%;
    font-weight: 600;
    letter-spacing: 0.5px;
    display: none;
    margin-top: 12px;
  }

  .change_color_bike .color_step .base.pro {
    margin-top: 20px;
  }

  .change_color_bike .color_step .base {
    width: 20%;
  }

  .color_step form .form-check input.acive::after,
  .color_step.onmobile_360 form .form-check label.acive::after {
    content: "";
    background: #50ebff;
    position: absolute;
    bottom: -20px;
    left: 0px;
    right: 0;
    height: 4px;
    border-radius: 5px;
  }

  .color_step.onmobile_360 form .form-check label.acive::after {
    bottom: -27px;
  }

  .bike_color .landing_page_container .sec_heading h2 {
    width: 100%;
    text-align: center;
    font-weight: 900;
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 0;
    color: rgb(255 255 255 / 80%);
  }

  .features_step .slider_show .slick-list .pop_slideron figure img {
    height: auto !important;
    border-radius: 8px;
  }

  .features_step .pure-modal-backdrop .pure-modal .panel-body > .slider_show {
    height: 100%;
  }

  .features_step .slider_show .slick-list .pop_slideron .slide_cont {
    padding-top: 10px;
    position: relative;
    width: 100%;
    left: inherit;
    padding: 20px 0px;
  }

  .features_step .slider_show .slick-list .pop_slideron .slide_cont::before {
    display: none;
  }

  .map_sec .gmnoprint {
    left: 5px;
  }

  .video_sec_step img {
    /* height: 800px; */
  }
  .landing_banner {
    height: 100vh;
  }

  .ActiveOxoPrimeContent .pure-modal-backdrop .tech_deails .top_sec_oxo_x {
    display: none;
  }

  .oxo_both_headar.first {
    align-items: center;
    background: #0a0a0a;
    border: 1px solid hsla(0, 0%, 100%, 0.4);
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .oxo_both_headar.first .btn-price-oxo {
    width: 35% !important;
    text-align: center;
    border-bottom: none;
    margin-bottom: 0px;
    padding: 10px 15px;
  }

  .oxo_both_headar.first .btn-price-oxo.activeBtnOxo1 {
    background: #00eaff;
    color: #000;
    width: 65% !important;
    border-radius: 8px;
  }

  .hop-leo .tech_specs_sec .modal-content .right,
  .hop-leo .tech_specs_sec .modal-content .third {
    display: none;
  }

  .hop-leo .tech_specs_sec .modal-content.contentofLH .right {
    display: block !important;
  }

  .hop-leo .tech_specs_sec .modal-content.contentofHS .third {
    display: block !important;
  }

  .for_hopleo .tabing_section h2 {
    margin-left: inherit !important;
    font-size: 22px;
    margin-bottom: 20px;
  }

  .tabing_data_second .inner_cont .content_main li {
    margin-left: 2%;
    width: 50%;
  }

  .right.oxo_both_headar,
  .third.oxo_both_headar {
    font-size: 22px !important;
    text-align: center;
    width: 100% !important;
  }

  .left.oxo_both_headar1 {
    display: none;
  }

  .HOP_LEO_FAQ .sec_heading h2 {
    z-index: 0;
  }
}

/* @media screen and (max-width:600px) {
  

} */

@media only screen and (min-width: 501px) and (max-width: 767px) {
  .bike_color .landing_page_container {
    max-width: 500px;
    margin: auto;
  }

  .tech_specs_sec .panel-body.scrollable {
    padding: 110px 40px 20px 40px;
  }
}

@media screen and (max-width: 450px) {
  .btn-group-price button.btn-price-oxo {
    font-size: 10px;
  }

  .btn-group-price button.btn-price-oxo.activeBtnOxo1 {
    font-size: 14px;
  }
}
