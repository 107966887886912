.sub_page_title {
  max-width: 80%;
  margin: 40px auto;
}
.sub_page_title .title_both {
  background: #ffffff;
  padding: 20px;
  text-align: center;
}

.sub_page_title .title_both p {
  font-size: 16px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.78);
  margin-bottom: 0;
  margin: 5px 0 0px 0;
  font-weight: 400;
}
.sub_page_title .title_both h6 {
  font-size: 30px;
  font-weight: 650;
  line-height: 1.4;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
  color: #000;
}

@media screen and (max-width: 767px) {
  .sub_page_title {
    max-width: 100%;
    margin: 20px auto;
  }
  .sub_page_title .title_both h6 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
  }

}
