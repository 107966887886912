.comments_list {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #e1e1e1;
}
.comments_list .comments_header {
  display: table;
  width: 100%;
  margin-bottom: 10px;
}
.comments_list .comments_header h4 {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  margin: 0;
  margin-bottom: 10px;
}

.comments_list .comments_header p {
  font-size: 15px;
  color: #8b8b8b;
  margin-bottom: 0;
}

.comments_list .comments_content p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  margin: 5px 0 10px 0;
}
