.order_youcity .react_form_group.next_popu button.default_btn {
    width: 100% !important;
    margin-right: 0px !important;

}

.graphics_sec .Confirmation_left::before {
    display: none;
}


.color_step form .form-check input.active::before {
    outline: 1px solid #50ebff;
}

.graphics_sec .Confirmation_left::after {
    height: 180px;
    width: 100%;
    bottom: -50px;
    background: rgb(10, 10, 10);
    background: linear-gradient(180deg, rgba(10, 10, 10, 0.2637429971988795) 0%, rgba(10, 10, 10, 1) 26%, rgba(10, 10, 10, 1) 100%);
}

/* .graphics_sec .Confirmation_left .after_savings {
    bottom: 50px;
    z-index: 0;
} */

.Pur_Pote_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(23 22 22 / 41%);
    border-radius: 16px;
    margin-bottom: 30px;
    flex-wrap: wrap;
}

.Pur_Pote_btn .default_btn {
    width: calc(50% - 10px);
    font-weight: 900;
    font-size: 16px;
    text-transform: capitalize;
    color: #fff;
    margin: 0px 5px;
    padding: 16px 15px;
    background: transparent;
}

.Pur_Pote_btn .default_btn.activeBtn{
    background: #00EAFF;
    color: #000000;
}

.Pur_Pote_btn .btn_pot {
    background: transparent;
    color: rgb(255 255 255 / 60%);
    position: relative;
}

.Pur_Pote_btn .btn_pot span {
    color: #f00;
    position: absolute;
    right: 5px;
}

.order_Launch_edit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(23 22 22 / 41%);
    border-radius: 16px;
    margin-bottom: 25px;
    border: 1px solid #00EAFF;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: capitalize;
    color: rgb(255 255 255 / 60%);
    padding: 17px 24px;
}

.order_Launch_edit p {
    margin-bottom: 0;
}

.order_Launch_edit span {
    color: #fff;
}

.incentives_sec {
    margin-bottom: 15px;
}

.incentives_sec span {
    font-family: 'Lato';
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: rgb(255 255 255 / 60%);
}

.graphics_colors form {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
}
.Changecolorpreferences_page .graphics_colors form{
    flex-wrap: inherit !important;
}

.graphics_colors form .form-check {
    margin-right: 0;
    display: block;
    text-align: center;
    width: calc(25% - 10px);
    margin-bottom: 25px;
}

.testride_map .graphics_rigth {
    padding: 64px 40px;
}

.graphics_colors form .form-check label {
    padding-left: 0;
    width: 100%;
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin-top: 30px;
}

.graphics_colors {
    margin-bottom: 25px;
}

.graphics_rigth .by_continuing p {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}

.graphics_colors h3 {
    margin-bottom: 30px !important;
}

.Order_Confirmation .Confirmation_left figure img {
    height: auto;
}

.incentives_sec .pure-modal-backdrop {
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(16.25px);
}

.incentives_sec .pure-modal-backdrop .panel-body.scrollable {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 750px;
}
 

.incentives_sec .pure-modal {
    height: 100%;
    width: 100%;
     margin: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.incentives_sec .learn_more_popupcont {
    width: 100%;
}

.incentives_sec .learn_more_popupcont h2 {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    color: #FFFFFF;
    margin-bottom: 24px;
}


.incentives_sec .learn_more_popupcont .incenti_contents.Delivery_state h4 {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
}

.incentives_sec .learn_more_popupcont .incenti_contents h4 {
    font-family: 'Lato';
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #fff;
    margin-bottom: 12px;
}

.incentives_sec .learn_more_popupcont .incenti_contents p {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgb(255 255 255 / 60%);
    margin: 0;
}

.incentives_sec .learn_more_popupcont .incenti_contents p span {
    color: #FFFFFF;
}

.learn_more_popupcont .Delivery_state .state {
    background: rgba(0, 0, 0, 0.41);
    border: 1px solid rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(30px);
    border-radius: 8px;
    padding: 13px 24px;
}

.learn_more_popupcont .incenti_contents {
    margin-bottom: 24px;
}

.learn_more_popupcont .Delivery_state .state p {
    margin-bottom: 0px;
    font-size: 24px;
    line-height: 36px;
    color: #fff;
}

.incentives_sec .pure-modal .panel-default .close {
    right:200px;
    top: 50%;
    background: rgba(0, 234, 255, 0.1);
    border: 1px solid #00EAFF;
    border-radius: 50%;
    color: #00EAFF;
    height: 64px;
    width: 64px;
    line-height: 52px;
    font-size: 45px;
    position: absolute !important;
}


.graphics_colors form .form-check input.acive::before {
    outline: 1px solid #50ebff;
}

.graphics_colors form form .form-check input::before {
    left: -9px;
}

.testride_map .graphics_rigth .logo_right_cont {
    padding-left: 40px;
    padding-right: 40px;
}

.testride_map .graphics_rigth .btn_banner.Pur_Pote_btn {
    margin-right: 40px;
    margin-left: 40px;
}

.testride_map .graphics_rigth .order_Launch_edit {
    margin-left: 40px;
    margin-right: 40px;
}

.testride_map .graphics_rigth .next_btn {
    margin-left: 40px;
    margin-right: 40px;
}

.graphics_sec .Confirmation_left>figure img {
    height: 100%;
}

.graphics_colors form .form-check input::before {
    left: 3px;
    top: 3px;
}

.learn_more_popupcont .price_sec{
    width: 100%;
}

.learn_more_popupcont .price_sec ul{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #fff;
    margin-bottom: 20px;
}

.learn_more_popupcont .price_sec ul:nth-last-child(1){
    border-bottom: 0px solid #fff;   
}

.learn_more_popupcont .price_sec ul:nth-last-child(2){
    border-bottom: 0px solid #fff;   
}

.learn_more_popupcont .price_sec ul:nth-last-child(3){
    border-bottom: 0px solid #fff;   
}

.learn_more_popupcont .price_sec ul li{

    font-family: 'Lato';
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 10px;
}
    
.user_order_detail{
    padding-top: 30px;
}

.user_order_detail ul{
    border-top: solid 1px rgb(255 255 255 / 30%);
    padding: 25px 0px 10px;
    text-align: left;
}

.user_order_detail ul li{
    color: #fff;
    font-size: 14px;
    margin-bottom: 10px;
}

.user_order_detail ul li strong{
    min-width: 100px;
    display: inline-block;
}
 

/* ==================================== RESPONSIVE START =================================== */

@media only screen and (min-width:1600px) and (max-width:1799px) {


    .incentives_sec .pure-modal .panel-default .close {
 
        right: 100px;
    }

    .incentives_sec .pure-modal {
         margin: 100px;
    }
 

    .incentives_sec .learn_more_popupcont .incenti_contents h4 {
        font-size: 20px;
        ;
        line-height: 28px;
        margin-bottom: 5px;
    }

    .incentives_sec .learn_more_popupcont .incenti_contents p {
        line-height: 20px;
    }

    .learn_more_popupcont .incenti_contents {
        margin-bottom: 20px;
    }

    .learn_more_popupcont .Delivery_state .state p {
        font-size: 20px;
    }

    .graphics_colors form .form-check {
        width: 25%;
        align-items: start;
    }

 
    .testride_map .graphics_rigth .logo_right_cont {
        padding-left: 30px;
        padding-right: 30px;
    }

    .testride_map .graphics_rigth .btn_banner.Pur_Pote_btn {
        margin-right: 30px;
        margin-left: 30px;
    }

    .testride_map .graphics_rigth .order_Launch_edit {
        margin-left: 30px;
        margin-right: 30px;
    }

    .testride_map .graphics_rigth .next_btn {
        margin-left: 30px;
        margin-right: 30px;
    }

    .graphics_colors form .form-check input::before {
        left: 6px;
    }

    .testride_map .graphics_rigth {
        padding: 64px 30px;
    }

    .graphics_colors form .form-check label {
        font-size: 15px;
    }

    .order_Launch_edit {
        padding: 15px 20px;
        margin-bottom: 20px;
    }

    .incentives_sec {
        margin-bottom: 20px;
    }

    .Pur_Pote_btn {
        margin-bottom: 25px;
    }

    .graphics_colors {
        margin-bottom: 25px;
    }

    .Pur_Pote_btn .default_btn {
        padding: 14px 15px;
    }


}


@media only screen and (min-width:1200px) and (max-width:1599px) {
    .incentives_sec .pure-modal {
         margin: 50px;
    }
    .learn_more_popupcont .price_sec ul li {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 8px;
    }

    .learn_more_popupcont .price_sec ul{
        margin-bottom: 15px;
    }


    .testride_map .graphics_rigth {
        padding: 60px 30px;
    }

    .testride_map .graphics_rigth .logo_right_cont {
        padding-left: 0px;
        padding-right: 0px;
    }

    .testride_map .graphics_rigth .btn_banner.Pur_Pote_btn {
        margin-right: 0px;
        margin-left: 0px;
    }

    .testride_map .graphics_rigth .order_Launch_edit {
        margin-left: 0px;
        margin-right: 0px;
        border-radius: 12px;
    }

    .testride_map .graphics_rigth .next_btn {
        margin-left: 0px;
        margin-right: 0px;
    }

    .graphics_colors form .form-check {
        width: 25%;
        align-items: start;
    }

 
    .graphics_colors form .form-check label {
        font-size: 14px;
        margin-top: 20px;
    }

    .Pur_Pote_btn {
        margin-bottom: 20px;
    }

    .Pur_Pote_btn .default_btn {
        font-size: 14px;
        margin: 0px 5px;
        padding: 12px 5px;
        border-radius: 12px;
    }

    .order_Launch_edit {
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 18px;
        padding: 14px 15px;
    }

    .incentives_sec {
        margin-bottom: 15px;
    }

    .graphics_colors h3 {
        margin-bottom: 25px;
    }

    .graphics_colors {
        margin-bottom: 20px;
    }

    .graphics_colors form .form-check input::before {
        left: 6px;
    }

    .incentives_sec .pure-modal .panel-default .close {
     
        right: 100px;
        height: 54px;
        width: 54px;
        line-height: 45px;
        font-size: 38px;
    }

 
 

    .incentives_sec .learn_more_popupcont .incenti_contents h4 {
        font-size: 18px;
        ;
        line-height: 26px;
        margin-bottom: 5px;
    }

    .incentives_sec .learn_more_popupcont .incenti_contents p {
        line-height: 20px;
        font-size: 14px;
    }

    .learn_more_popupcont .incenti_contents {
        margin-bottom: 20px;
    }

    .learn_more_popupcont .Delivery_state .state p {
        font-size: 18px;
    }

   
    .incentives_sec .learn_more_popupcont h2 {
        font-size: 28px;
        line-height: 26px;
        margin-bottom: 20px;
    }

}


@media only screen and (min-width:1200px) and (max-width:1499px) {
   
 



    .incentives_sec .pure-modal .panel-default .close {
    
        right: 100px;
        height: 54px;
        width: 54px;
        line-height: 45px;
        font-size: 38px;
    }

    .incentives_sec .learn_more_popupcont .incenti_contents h4 {
        font-size: 18px;
        ;
        line-height: 26px;
        margin-bottom: 5px;
    }

    .incentives_sec .learn_more_popupcont .incenti_contents p {
        line-height: 20px;
        font-size: 14px;
    }

    .learn_more_popupcont .incenti_contents {
        margin-bottom: 20px;
    }

    .learn_more_popupcont .Delivery_state .state p {
        font-size: 18px;
    }
 
    .incentives_sec .learn_more_popupcont h2 {
        font-size: 28px;
        line-height: 26px;
        margin-bottom: 20px;
    }


}

@media only screen and (min-width:992px) and (max-width:1199px) {

    .incentives_sec .pure-modal-backdrop .panel-body.scrollable {
        width: 600px;
   }
    .incentives_sec .pure-modal {
        margin: 50px;
   }
   .learn_more_popupcont .price_sec ul li {
       font-size: 18px;
       line-height: 24px;
       margin-bottom: 8px;
   }
   .learn_more_popupcont .price_sec ul{
       margin-bottom: 15px;
   }




    .testride_map .graphics_rigth {
        padding: 60px 20px;
    }

    .testride_map .graphics_rigth .logo_right_cont {
        padding-left: 0px;
        padding-right: 0px;
    }

    .testride_map .graphics_rigth .btn_banner.Pur_Pote_btn {
        margin-right: 0px;
        margin-left: 0px;
    }

    .testride_map .graphics_rigth .order_Launch_edit {
        margin-left: 0px;
        margin-right: 0px;
    }

    .testride_map .graphics_rigth .next_btn {
        margin-left: 0px;
        margin-right: 0px;
    }

    .graphics_rigth .logo_right_cont.destop_show img {
        margin-bottom: 0;
    }
    .graphics_rigth .logo_right_cont.destop_show img {
        width: 250px;
        margin: auto;
    }

    .incentives_sec .pure-modal .panel-default .close {
      
        right: 50px;
        height: 54px;
        width: 54px;
        line-height: 45px;
        font-size: 38px;
    }
  

    .incentives_sec .learn_more_popupcont .incenti_contents h4 {
        font-size: 18px;
        ;
        line-height: 26px;
        margin-bottom: 5px;
    }

    .incentives_sec .learn_more_popupcont .incenti_contents p {
        line-height: 20px;
        font-size: 14px;
    }

    .learn_more_popupcont .incenti_contents {
        margin-bottom: 20px;
    }

    .learn_more_popupcont .Delivery_state .state p {
        font-size: 18px;
    }
 
    .incentives_sec .learn_more_popupcont h2 {
        font-size: 28px;
        line-height: 26px;
        margin-bottom: 20px;
    }

    .Pur_Pote_btn .default_btn {
        width: calc(50% - 0px);
        margin: 0px 0px;
        padding: 10px 10px;
    }

    .Pur_Pote_btn {
        margin-bottom: 15px;
    }

    .order_Launch_edit {
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 18px;
        padding: 12px 8px;
        flex-wrap: wrap;
        justify-content: space-between;
        border-radius: 12px;
    }
    
    .graphics_rigth .by_continuing p {
        font-size: 14px;
        line-height: 22px;
    }

    .order_Launch_edit p {
        width: 50%;
        margin-bottom: 0px;
        font-size: 14px !important;
    }


    .order_Launch_edit span{
        font-size: 14px;
    }

    .incentives_sec {
        margin-bottom:10px;
    }

    .graphics_colors h3 {
        margin-bottom: 20px;
    }

    .graphics_colors form .form-check label {
        font-size: 14px;
        line-height: 18px;
        margin-top: 6px;
    }

    .graphics_colors form .form-check {
        width: 50%;
    }

    .graphics_colors {
        margin-bottom: 5px;
    }
    .graphics_colors form .form-check input::before {
        left: 6px;
    }


}


@media only screen and (min-width:768px) and (max-width:991px) {
 
    .incentives_sec .pure-modal-backdrop .panel-body.scrollable {
        width: 550px;
   }
    .incentives_sec .pure-modal {
        margin: 50px;
   }
   .learn_more_popupcont .price_sec ul li {
       font-size: 16px;
       line-height: 22px;
       margin-bottom: 5px;
       font-weight: 500;

   }
   .learn_more_popupcont .price_sec ul{
       margin-bottom: 10px;
   }
   .incentives_sec .pure-modal .panel-default .close {
  
    right: 50px;
    height: 44px;
    width: 44px;
    line-height: 35px;
    font-size: 28px;
}




    .testride_map .graphics_rigth {
        padding: 40px 15px;
    }

    .testride_map .graphics_rigth .logo_right_cont {
        padding-left: 0px;
        padding-right: 0px;
    }

    .testride_map .graphics_rigth .btn_banner.Pur_Pote_btn {
        margin-right: 0px;
        margin-left: 0px;
    }

    .testride_map .graphics_rigth .order_Launch_edit {
        margin-left: 0px;
        margin-right: 0px;
    }

    .testride_map .graphics_rigth .next_btn {
        margin-left: 0px;
        margin-right: 0px;
    }

    .graphics_rigth .logo_right_cont.destop_show img {
        margin-bottom: 0;
    }
    .graphics_rigth .logo_right_cont.destop_show img {
        width: 180px;
        margin: auto;
        margin-bottom: 0px !important;
    }
    .graphics_rigth .by_continuing p {
        font-size: 14px;
        line-height: 20px;
    }

    .Pur_Pote_btn .default_btn {
        padding: 10px 10px;
        font-size: 14px;
        border-radius: 12px !important;
    }

    .Pur_Pote_btn {
        margin-bottom: 15px;
    }

    .order_Launch_edit {
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 18px;
        padding: 10px 12px;
        flex-wrap: wrap;
        justify-content: space-between;
        border-radius: 12px;
    }

    .order_Launch_edit p {
        width: 50%;
        margin-bottom: 0px;
    }

    .incentives_sec {
        margin-bottom: 10px;
    }

    .graphics_colors h3 {
        margin-bottom: 15px;
    }
    .graphics_colors form .form-check input::before {
        left: 6px;
    }
    .graphics_colors form .form-check label {
        font-size: 14px;
        line-height: 18px;
        margin-top: 6px;
    }

    .graphics_colors form .form-check {
        width: 50%;
    }

    .graphics_colors {
        margin-bottom: 5px;
    }


}


@media only screen and (min-width:320px) and (max-width:767px) {
    #Changecolorpreferences .graphics_colors.color_step.Changecolor_sec{
        display: block;
    }

    #Changecolorpreferences .mobile_show.das_orderimg{
        background-image: url(https://hopelectric.in/static/media/Confirmation_bike.017a8d70fcfcd0307c63.png);
        background-position: top;
        background-size: cover;
        padding: 30px;
    }
    .order_id .order_sec_id p.mobile_show {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
    }

    
    .incentives_sec .pure-modal-backdrop .panel-body.scrollable {
        width: 100%;
   }
    .incentives_sec .pure-modal {
        margin: 50px;
   }
   .learn_more_popupcont .price_sec ul li {
       font-size: 14px;
       line-height: 20px;
       margin-bottom: 5px;
       font-weight: 500;
       width: 50%;
   }
   .learn_more_popupcont .price_sec ul{
       margin-bottom: 10px;
   }

   .learn_more_popupcont .price_sec ul li:first-child{
    text-align: left;
   }
   .learn_more_popupcont .price_sec ul li:last-child{
    text-align: right;
   }


   .incentives_sec .pure-modal .panel-default .close {
    right: 30px;
    height: 44px;
    width: 44px;
    line-height: 35px;
    font-size: 28px;
    top: 10px;
}

 
    .testride_map .graphics_rigth .logo_right_cont {
        padding-left: 0px;
        padding-right: 0px;
    }
    .testride_map .graphics_rigth .btn_banner.Pur_Pote_btn {
        margin-right: 0px;
        margin-left: 0px;
    }
    .testride_map .graphics_rigth .order_Launch_edit {
        margin-left: 0px;
        margin-right: 0px;
    }
    .testride_map .graphics_rigth .next_btn {
        margin-left: 0px;
        margin-right: 0px;
    }
    
    #graphics {
        height: auto;
    }
    .testride_map .graphics_rigth {
        padding: 60px 20px;
    }




    .Pur_Pote_btn .default_btn {
        width: calc(100% - 0px);
        margin: 0px 0px;
        padding: 14px 15px;
    }

    .Pur_Pote_btn {
        margin-bottom: 10px;
    }

    .order_Launch_edit {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 18px;
        padding: 13px 15px;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .order_Launch_edit span {
        font-size: 14px;
    }

    .incentives_sec {
        margin-bottom: 15px;
    }

    .graphics_colors h3 {
        margin-bottom: 20px;
    } 
  
  
    .graphics_colors form .form-check input{ 
        position: relative;
    }
    .graphics_colors:after{
        bottom: -25px;
    }


   
 
    .graphics_colors form .form-check{
        position: relative;
        width: calc(20% - 10px);
        margin-bottom: 0px;
    }
    .graphics_colors form .form-check label {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        margin-top: 0;
        display: none;
    }
    
    .graphics_colors form .form-check input.active ~ label {
        display: block;
        position: absolute;
        top: 60px;
        width: 110px;
        left: 50%;
        transform: translateX(-50%);
    }


    .graphics_sec .graphics_rigth .graphics_colors form .form-check input.active::after{
        content: "";
        background: #50ebff;
        position: absolute;
        bottom: 0;
        top: 41px;
        left: 0px;
        width: 100%;
        right: 0;
        height: 4px;
        border-radius: 5px;
    }
    
    .graphics_colors {
        margin-bottom: 90px;
        position: relative;
    }

    .graphics_rigth .Pur_Pote_btn {
        position: inherit;
        margin-bottom: 24px;
        border: none;
    }

    .graphics_rigth .Pur_Pote_btn .default_btn {
        border-radius: 8px;
        width: calc(50% - 2px);
        margin: 0px 0px;
        padding: 12px 10px;
        font-size: 14px;
    }

    .graphics_rigth .Pur_Pote_btn button.default_btn.btn_pot {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 8px;
    }

    .order_Launch_edit p {
        font-size: 12px;
        line-height: 20px;
    }

    .graphics_colors form .form-check input::before {
        left: 0px;
        top: 0px;
    }

    
}