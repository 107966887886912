/* sction 11 css */

.sec_joinus_content{
    background-color: #0a0a0a;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.sec_joinus_content figure.join_now_img img {
    margin: 0px auto;
    max-width: 100%;
    /* margin-bottom: 90px; */
}
.sec_joinus_content .QR_code_img figure img {
    margin: 0px auto;
}

/* .sec_joinus_content:before {
    background: linear-gradient(211deg,#14204e,#14204e);
    border-radius: 50%;
    content: "";
    -webkit-filter: blur(60px);
    filter: blur(60px);
    height: 650px;
    position: absolute;
    right: -400px;
    top: -400px;
    width: 650px;
    z-index: 0;
}

.sec_joinus_content:after {
    background: linear-gradient(211deg,#2a63a3,#2a63a3);
    border-radius: 50% 80% 0 0;
    bottom: -450px;
    content: "";
    -webkit-filter: blur(80px);
    filter: blur(80px);
    height: 650px;
    left: -450px;
    position: absolute;
    width: 650px;
    z-index: 0;
} */


/* media query */

@media only screen and (max-width: 767px){

    .sec_joinus_content:before {
        height: 460px;
        width: 600px;
    }
    
    .sec_joinus_content:after {
        height: 450px;
        width: 630px;
    }

    /* .sec_joinus_content figure.join_now_img img {
        margin-bottom: 40px;
    } */

    .sec_joinus_content .QR_code_img figure img, 
    .sec_joinus_content figure.join_now_img img{
        max-width: 70%;
    }
}

