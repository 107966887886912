.top_barsearch {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.top_barsearch .form-group {
  width: calc(28% - 20px);
  margin: 0px 10px;
}

.top_barsearch .form-group:last-child {
  width: 16%;
  align-items: center;
}

.top_barsearch .form-group:nth-child(1) {
  margin-left: 0;
}

/* .toast-message {
    background: rgb(30, 29, 29) !important;
    color: #fff !important;
    font-size: 20px;
    width: 34vw;
    padding: 30px 20px;
} */

/* ..toast-message .Toastify__close-button > svg{
    color: #fff I !important;
} */

/* .toast-message {
    background: rgb(30, 29, 29) !important;
    color: #fff !important;
    font-size: 20px;
    width: 34vw;
    padding: 30px 20px;
   
} */

/* .toast-message svg {
    color: #fff !important;
}

.Toastify__close-button--light {
    color: #fff !important;
    opacity: 1 !important;
}

.Toastify__progress-bar--error {
    background: #00EAFF !important;
}

.Toastify__toast-icon {
    color: #00EAFF !important;
}

.toast-message svg {
    fill: #00EAFF !important;
} */

.top_barsearch .form-group:last-child {
  margin-right: 0;
}

.top_barsearch .form-group button.third {
  background: none;
  border: none;
  padding: 5px;
  margin-left: 5px;
}

.top_barsearch .button_group {
  display: flex;
}

.top_barsearch .button_group button.second {
  background-color: transparent;
  border: none;
  padding-left: 0px;
}

.top_barsearch .button_group button.second img {
  max-width: 30px;
}

.top_barsearch .button_group button.first {
  /* color: #000; */
  padding: 5px 15px;
  /* background-color: #00eaff; */
  /* background: rgba(0,0,0,.4); */
  /* border: 1px solid #00eaff; */
  /* color: #00eaff; */
  /* border-radius: 10px; */
  /* height: 40px; */
  /* font-weight: 600; */
}

.top_barsearch .button_group button.form-control {
  width: calc(50% - 10px);
}

.top_barsearch .form-group button.form-control {
  text-align: center;
}

.top_barsearch .form-group button.form-control img {
  margin: auto;
  max-width: 30px;
}

.top_barsearch .form-group .form-control {
  width: 100%;
  border: 1px solid rgb(135 135 135);
  border-radius: 5px;
  background-color: #fff;
  padding: 10px 15px;
  color: #000;
  font-weight: 400;
}

/* .top_barsearch .form-group .form-control::placeholder {
    color: rgb(168, 168, 168);
} */

.datatable_show .bzRnkJ {
  background: #54bbff;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 15px 10px;
}

/* tooltip css */

.dealer_detail .tooltip {
  position: relative;
}

.dealer_detail .tooltip .tooltiptext {
  /* visibility: visible; */
  display: none;
  width: auto;
  background-color: transparent;
  border: 1px solid #00eaff;
  color: #00eaff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  padding: 5px 10px;
  top: -35px;
  z-index: 100;
}

.dealer_detail .tooltip .tooltiptext.last {
  left: -15px;
}

.dealer_detail .top_barsearch .button_group button:hover .tooltiptext {
  /* visibility: visible; */
  display: block;
  z-index: 999;
}

.search_calendar {
  position: relative;
}

.search_calendar img.date_calendar {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  z-index: 0;
}

.dealer_detail {
  z-index: 1;
  position: relative;
}

.datatable_show .rdt_TableHead .rdt_TableHeadRow {
  background: #495061;
  color: #fff;
  font-size: 16px;
  border: none;
}

.datatable_show {
  background: #495061;
  padding: 15px 15px;
  border-radius: 5px;
}

.datatable_show .rdt_TableBody .rdt_TableRow {
  background: #4d5966;
  color: #fff;
  font-size: 16px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.datatable_show .rdt_Table .rdt_TableBody {
  background: #495061;
}

.datatable_show .rdt_Table .rdt_TableBody .rdt_ExpanderRow {
  background: #495061;
}

.datatable_show .rdt_TableBody .rdt_TableRow .jNRUar {
  /* background: #252c3f; */
  color: #fff;
}

.datatable_show .rdt_TableBody .rdt_TableRow .jNRUar button svg {
  color: #fff;
  transform: rotate(90deg);
  pointer-events: none;
}

.datatable_show .rdt_TableBody .rdt_TableRow .jNRUar button.ourclass svg {
  transform: rotate(180deg);
}

.datatable_show .rdt_TableBody .dealer_alldata {
  background: #2b3443;
  color: #fff;
  padding: 15px 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  position: relative;
}

.dealer_alldata {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: left;
}

.dealer_alldata .data_cont {
  width: 33%;
  padding: 0px 5px;
}

.dealer_alldata .data_cont li p {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}

.dealer_alldata .data_cont li {
  color: #fff;
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 400;
}

.datatable_show .rdt_Pagination {
  background: #2b3443;
  color: #fff;
  justify-content: center;
  border-radius: 5px;
}

.datatable_show .rdt_Pagination .hoQsHK button#pagination-first-page {
  display: none;
}

.datatable_show .rdt_Pagination .hoQsHK button#pagination-last-page {
  display: none;
}

.datatable_show .rdt_Pagination .hoQsHK button#pagination-previous-page svg {
  color: #fff;
}

.datatable_show .rdt_Table {
  background: #495061;
}

.datatable_show .rdt_Pagination .hoQsHK button#pagination-previous-page {
  /* background: #495061; */
  background: rgba(0, 234, 255, 0.1);
  border: 1px solid #00eaff;
}

.datatable_show .rdt_Pagination .hoQsHK button#pagination-next-page {
  margin-left: 8px;
  /* background: #495061; */
  background: rgba(0, 234, 255, 0.1);
  border: 1px solid #00eaff;
}

.datatable_show .rdt_Pagination .hoQsHK button svg {
  fill: #fff;
}

.dealer_alldata .data_cont li span {
  padding-left: 5px;
}

.datatable_show .rdt_Pagination .fyrdjl:disabled svg {
  fill: rgb(255, 255, 255, 0.3);
}

/* new css for data table */
/* .dealer_alldata .data_cont{
    border-right: 1px solid #fff;
    padding-right: 10px;
} */
.dealer_alldata .data_cont:nth-child(1) li:first-child {
  border-right: transparent;
}

.dealer_alldata .data_cont li:first-child p {
  font-size: 20px;
  display: block;
  color: #00eaff;
}

.dealer_alldata .data_cont:nth-child(2),
.dealer_alldata .data_cont:nth-child(3) {
  padding-top: 35px;
}

.dealer_alldata .data_cont:nth-child(3) {
  border-right: transparent;
}

.dealer_alldata .data_cont li {
  font-size: 15px;
  display: flex;
  width: 100%;
  text-align: left;
  justify-content: space-between;
  border-right: 1px solid;
  margin-bottom: 0pc;
  padding-bottom: 6px;
  padding-right: 10px;
}

.dealer_alldata .data_cont li span {
  color: rgb(255, 255, 255, 0.7);
  width: 56%;
  text-align: left;
  display: block;
  line-height: normal;
}

.datatable_show .sc-hHTYSt.rdt_TableBody .sc-hLBbgP button::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 234, 255, 0.1);
}

.datatable_show .rdt_TableBody .dealer_alldata::before {
  content: "";
  position: absolute;
  width: 96%;
  height: 1px;
  background-color: #fff;
  top: 50px;
}

.dealer_alldata .data_cont:nth-child(1) li:nth-child(2) {
  padding-top: 15px;
}

.dealer_alldata .data_cont:nth-child(2) li:nth-child(1),
.dealer_alldata .data_cont:nth-child(3) li:nth-child(2) {
  padding-top: 10px;
}

.dealer_alldata .data_cont:nth-child(3) li {
  border-right: transparent;
}

.height_one_csrol .top_barsearch .search_calendar {
  position: relative;
}

.height_one_csrol .top_barsearch .search_calendar span {
  position: absolute;
  top: -25px;
  color: #00eaff;
  font-size: 18px;
  left: 0px;
}

.datatable_show .rdt_TableCol .hbotQl {
  font-size: 18px;
  color: #00eaff;
}

/* .header_hidecss .form_container{
        max-width: 1240px;
        margin: auto;
        padding: 120px 15px 50px;
        height: 100%;
        overflow: scroll;
    } */

.datatable_show .rdt_Table .rdt_TableBody {
  background: #495061;
  height: 500px;
  overflow-y: scroll;
}

.datatable_show .rdt_Table .rdt_TableBody::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.datatable_show .rdt_Table .rdt_TableBody::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.datatable_show .rdt_Table .rdt_TableBody::-webkit-scrollbar-thumb {
  background-color: #00eaff;
  border: 2px solid #555555;
}

.dealer_detail {
  /* display: flex; */
  justify-content: center;
}
.dealer_detail .content_error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.dealer_detail .content_error h3 {
  font-size: 50px;
  color: #fff;
  border-bottom: 1px solid #27bdbd;
  padding: 20px;
}
.dealer_detail .content_error p {
  font-size: 20px;
  color: aqua;
  display: flex;
  align-items: center;
}
.dealer_detail .content_error p span {
  display: flex;
  border: 2px solid #00eaff;
  margin-left: 15px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
}

.source_detail .pure-modal-backdrop {
  left: 0 !important;
  right: 0 !important;
  margin: 0px auto;
}

@media screen and (max-width:991px) {
  .source_detail .pure-modal-backdrop {
    max-width: 580px ;
    width: auto !important;
  }
}

/* style={{
    color: "red",
    border: "2px solid aqua",
    padding: "5px 10px",
    borderRadius: "50%",
    marginLeft: "15px",
  }} */
