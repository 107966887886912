.sub_paragraph p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
}
.sub_paragraph p span{
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
}

@media screen and (max-width:767px) {
  .sub_paragraph p{
    font-size: 14px;
    line-height: 22px;
  }
}
