.mega_deliveries{
    margin-top: 15px;
}
.mega_del_parent{
    background-color: #53aad6;
    padding: 20px 30px;
    display: flex;
}

.mega_del_parent .left_mega_sec{
    padding-top: 50px;
    padding-right: 50px;
    width: 50%;
}
.mega_del_parent .left_mega_sec h2{
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    color: #fff;
    margin-bottom: 20px;
}
.mega_del_parent .left_mega_sec p{
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #fff;
}
.konw_more_community{
    display: flex;
}
.mega_del_parent .right_mega_sec{
   width: 50%;
}

@media screen and (max-width:991px) {
    .mega_del_parent .left_mega_sec{
        padding-top: 0px;
    }
    .konw_more_community .vieew_all_btn{
        margin-top: 15px;
    }
    .mega_del_parent .left_mega_sec h2{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .mega_del_parent .left_mega_sec p{
        font-size: 14px;
    }
}

@media screen and (max-width: 767px) {
    .mega_del_parent{
        flex-direction: column;
        gap: 20px;
    }
    .mega_del_parent .left_mega_sec{
        width: 100%;
        padding: 0px;
    }
    .mega_del_parent .right_mega_sec{
        width:100%;
    }
}