.PopularSec h3{
    color: #000000;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 17px;
    margin-left: 10%;
}
@media screen and (max-width:767px) {
    .PopularSec h3{
        margin-left:0px ;
      }
}