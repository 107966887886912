.blog_subpage {
  background-color: #f4f4f4;
}
.blog_subpage .menues_subheader {
  margin-top: 20px;
}
.blog_subpage .subinner_image {
  padding: 20px 0px;
}
.blog_subpage .ninty_container {
  max-width: 90%;
  width: 100%;
  margin: 0px auto;
}
.blog_subpage .ninty_container .eighty_container {
  max-width: 80%;
  width: 100%;
  margin: 0px auto;
}

/* css for form section  */

.blog_subpage .form_section {
  border-top: 1px solid #e1e1e1;
  padding-top: 40px;
  margin-top: 40px;
}

.blog_subpage .form_section .inner_section {
  max-width: 90%;
  margin: 0px auto;
  padding-bottom: 20px;
}
.blog_subpage .form_section .vieew_all_btn {
  justify-content: end;
}

.More_blogs_motor {
  display: none;
}

/* css for hop vheicles inner page ui  */
/* hopvhiclemain_sec class only see when url have "hopvhicles" path  */
.Blog_inner {
  background-color: #f4f4f4;
}
.hopvhiclemain_sec {
  display: flex;
  gap: 20px;
  max-width: 90%;
  margin: 0px auto;
  background-color: #f4f4f4;
  margin-top: 40px;
}
.hopvhiclemain_sec .blog_subpage {
  width: 70%;
}
.hopvhiclemain_sec .More_blogs_motor {
  width: 30%;
  display: block !important;
  padding-bottom:30px;
}
.hopvhiclemain_sec .More_blogs_motor h3 {
  font-size: 20px;
  margin: -7px 0 5px 8px;
  text-align: left;
  color: #000;
  font-weight: 600;
}
.hopvhiclemain_sec .More_blogs_motor .more_blogs{
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.hopvhiclemain_sec .blog_subpage .ninty_container .eighty_container{
  max-width: 100%;
}

@media screen and (max-width: 991px) {
  .hopvhiclemain_sec {
    flex-direction: column;
  }
  .hopvhiclemain_sec .blog_subpage {
    width: 100%;
  }
  .hopvhiclemain_sec .More_blogs_motor {
    width: 100%;
  }
}


@media screen and (max-width: 767px) {
  .blog_subpage .ninty_container .eighty_container {
    max-width: 100%;
  }
}
