.video_img_card {
    position: relative;
}
.video_img_card .image_card_cont {
  /* height: 440px; */
  position: relative;
}
.video_play_btn{
    position: absolute;
    bottom: 20px;
    left: 20px;
    background-color: #fff;
    font-size: 12px;
    height: 25px;
    padding:0px 10px;
    border-radius: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 5px;
}
.video_play_btn svg{
    font-size: 15px;
}
.video_img_card .image_card_cont img {
  height: 100%;
  width: 100%;
}
.video_img_card .content_card h6 {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: #222222;
  padding: 10px 0px 30px 0px;
}
