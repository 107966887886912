.fix_social_sec {
  position: absolute;
  right: 35px;
  bottom: 25px;
  z-index: 99999;
}
.icons {
  margin-bottom: 5px;
}
.fix_social_sec .icons a img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 50%;
}
.gm-style-cc {
  display: none;
}

@media only screen and (max-width: 767px) {
  .fix_social_sec {
    position: fixed;
    right: 15px;
    bottom: 8vh;
    /* bottom: 15px; */
    z-index: 99999;
  }

  .fix_social_sec .icons a img {
    width: 35px;
    height: 35px;
  }
}
