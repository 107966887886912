.pagination {
  /* width: 40%; */
  display: flex;
  justify-content: space-between;
  /* list-style: none;
  margin: 0px auto;
  cursor: pointer;
  margin-bottom: 15px;
  height: 100px;
  display: flex;
  position: relative;
  z-index: 2; */
  align-items: center;
  max-width: 1340px;
  margin: 40px auto;
  padding: 0 10px !important;
}

.pagination a {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #00eaff;
  color: #00eaff;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff;
  background: #00eaff8c;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid #ffffff;
}

/* /////////////////////////// Media Quarry start  //////////////////// */

@media screen and (max-width: 1200px) {
  .pagination {
    /* width: 50%; */
  }
}

@media screen and (max-width: 991px) {
  .pagination {
    /* width: 60%; */
  }
}

@media screen and (max-width: 767px) {
  .pagination {
    /* width: 90%; */
  }
  .pagination__link--disabled a,
  .pagination a {
    font-size: 12px;
    /* padding: 5px 10px; */
  }
  .pagination {
    /* height: 70px; */
  }
}
