.community_search_home {
  padding: 30px 0px;
}

.community_search_home .home_Search_input {
  width: 100%;
}
.community_search_home .home_Search_input input {
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  border: 1px solid #e8e8e8;
  height: 45px;
  padding: 0px 20px;
  border-radius: 5px;
  font-weight: 600;
}

.community_search_home .home_Search_input input:focus {
  outline: none;
}

/* bubbles css (input search result)  */
.subpages_of_homepage {
  padding-bottom: 30px;
}

.community_search_home .home_Search_bubbles {
  padding: 30px 0px;
}

.community_search_home .home_Search_bubbles {
  padding: 30px 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  border-bottom: 1px solid #e8e8e8;
}

.community_search_home .home_Search_bubbles button {
  border: 1px solid #222222;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #222222;
  border-radius: 20px;
  padding: 8px 25px;
}

@media screen and (max-width:767px) {
  .community_search_home .home_Search_bubbles button{
    font-size: 14px;
    padding: 5px 20 px;
  }
}