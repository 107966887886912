.tipsTrick{
    margin: 0px auto 0px auto;
    width: 90%;
    padding: 30px 0px;
}
.tipsTrick .tips_tricks_containers{
    display: flex;
    gap: 30px;
    row-gap: 15PX;
    flex-wrap: wrap;
}
.tipsTrick .tips_tricks_containers .overRecommended_sec{
    width: calc(33.33% - 20px);
}


@media screen and (max-width:767px) {
    .tipsTrick .tips_tricks_containers .overRecommended_sec{
        width: calc(50% - 20px);
    }
}

@media screen and (max-width:500px) {
    .tipsTrick .tips_tricks_containers .overRecommended_sec{
        width: 100%;
    }
}