.slider_parent .image_container .content_For_image {
  position: absolute;
  /* top: 75%; */
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(248, 80, 50, 0) 0,
    rgba(0, 0, 0, 0.66) 49%,
    black 74%
  );
  bottom: 0;
  right: 0;
  padding: 0px 20px;
  padding-bottom: 10px;
}
.slider_parent .image_container p span {
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 14px;
  color: #fff;
}
.slider_parent .image_container p span:nth-child(1) {
  border-right: 1px solid #fff;
  padding-right: 15px;
  margin-right: 15px;
}
.slider_parent .image_container h4 a {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
  color: #fff;
  overflow: hidden;
  display: block;
}
.slider_parent .image_container {
  margin: 0px 10px;
  position: relative;
}
.slider_parent .image_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .slider_parent .image_container h4 a {
    font-size: 14px;
  }
}
