.active_rides .pagination {
  width: 40% ;
  display: flex ;
  justify-content: space-between ;
  list-style: none ;
  margin: 10px auto ;
  cursor: pointer ;
  height: auto;
  /* margin-bottom: 15px; */
}

.active_rides .pagination a {
  padding: 10px ;
  border-radius: 5px ;
  border: 1px solid #00eaff ;
  color: #00eaff ;
}

.active_rides .pagination__link {
  font-weight: bold;
}

.active_rides .pagination__link--active a {
  color: #fff;
  background: #00eaff ;
}

.active_rides .pagination__link--disabled a {
  color: rgb(198, 197, 202) ;
  border: 1px solid #00eaff ;
}

@media screen and (max-width:767px){
  .active_rides .pagination{
    width: 90%;
    height: 60px;
  }

}
