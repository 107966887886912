.testride_righsec p {
  font-family: "Lato";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: rgb(255 255 255 / 80%);
}
.Confirmation .testride_righsec p {
  text-align: center;
}

.order_iddetails {
  margin-top: 30px;
  background: #060606;
  border: 1px solid rgb(255 255 255 / 60%);
  border-radius: 16px;
  padding: 45px 15px 30px 15px;
}

.order_iddetails h4 {
  font-family: "Lato";
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 30px;
  color: #ffffff;
}

.order_iddetails h4 span {
  color: rgba(255, 255, 255, 0.5);
}

.order_iddetails .order_details li {
  font-family: "Lato";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
  border-bottom: 1px solid rgb(255 255 255 / 60%);
  margin-bottom: 20px;
  padding-bottom: 3px;
}

.order_iddetails .order_details li > ul li:first-child {
  color: rgba(255, 255, 255, 0.5);
  text-align: left;
}

.order_iddetails .order_details li ul {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.order_iddetails .order_details li ul li {
  width: 50%;
  text-align: right;
  border-bottom: 0px solid #ffffff;
  margin-bottom: 0px;
}

.order_iddetails .orderid_btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.order_iddetails .orderid_btn .default_btn {
  width: calc(50% - 10px);
  padding: 18px 10px;
  font-size: 16px;
}

.order_iddetails .orderid_btn .test_again {
  width: auto;
  text-align: center;
  background: transparent;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #00eaff;
  padding: 0;
  display: inline-block;
  margin: 24px auto 0px;
  font-weight: 500;
  padding: 0px !important;
}

.orderid_btn .tran_btn {
  background: rgba(0, 234, 255, 0.1);
  border: 1px solid #343838;
  color: #00eaff;
}

.orderid_btn .tran_btn:hover {
  background: #00eaff;
  color: #000;
}

.orderid_btn .disabled_btn {
  background: rgba(0, 234, 255, 0.1);
  border: 1px solid #343838;
  width: 70%;
  margin: auto;
  margin-bottom: 15px;
  /* background: #00eaff; */
  /* padding: 21px 49px; */
  border-radius: 16px;
  font-size: 20px;
  color: #000;
  color: #00eaff;

  display: inline-block;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  transition: all 0.3s;
  text-transform: uppercase;
  padding: 14px 10px;
  font-size: 16px;
}

.order_iddetails .order_details {
  margin-bottom: 40px;
}

.dashboard_orde .dashboard_rightsec {
  padding: 64px 40px;
}

.dashboard_orde .dashboard_leftsec {
  object-fit: cover;
}

.color_preferences {
  margin-top: 50px;
}

.steps_colorsec {
  position: relative;
}

.steps_colorsec::before {
  content: "";
  background: #fff;
  height: 96%;
  width: 1px;
  position: absolute;
  left: 0;
  top: 9px;
}

.steps_colorsec li {
  font-family: "Lato";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  text-align: left;
  padding-left: 24px;
  margin-bottom: 50px;
  position: relative;
  cursor: pointer;
}

.steps_colorsec li::before {
  content: "";
  background: #848484;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  position: absolute;
  left: -7px;
  top: 5px;
  z-index: -1;
}

.steps_colorsec li.compelled::before {
  background: #16aa51;
}

.steps_colorsec li.process::before {
  background: #16aa51;
}

.steps_colorsec li.process::after {
  content: "";
  background: rgb(22 170 81 / 25%);
  height: 26px;
  width: 26px;
  border-radius: 50%;
  position: absolute;
  left: -12px;
  top: 0px;
  z-index: -2;
}

.next_pageview::before {
  display: none;
}

.next_pageview li {
  padding-left: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.35);
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.next_pageview li:last-child {
  border: none;
  color: #ff0000;
  padding-bottom: 0;
}

.next_pageview li::before {
  display: none;
}

.next_pageview li a {
  transition: all 0.3s;
  letter-spacing: 0.2px;
}

.next_pageview li a:hover {
  color: #16aa51;
  letter-spacing: 0.3px;
}

.Colorpreferences_page .Colorpreferences_right {
  padding: 64px 50px !important;
}

.registration_detai {
  text-align: left;
  margin-top: 40px;
}

.registration_detai h4 {
  font-family: "Lato";
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 10px;
}

.registration_detai p {
  text-align: left;
}

.regiadd_form {
  background: transparent;
  padding: 0px !important;
  border: none;
  border-radius: 0;
}

.regiadd_form .form_group {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 15px;
}

.regiadd_form .form_group .form-control {
  width: 100%;
  padding: 15px 0px;
  background: transparent;
  font-family: "Lato";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.5);
}

.regiadd_form .form_group .form-control:focus-visible {
  border: none;
  outline: none;
}

.regiadd_form form span {
  font-family: "Lato";
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  text-align: left;
  margin: 40px 0px 15px;
  display: block;
}

.regiadd_form .form_group .form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.regiadd_form .orderid_btn button.tran_btn {
  width: 50%;
  margin: auto;
}

.regiadd_form .orderid_btn button.test_again {
  width: -webkit-fill-available;
  color: #fff;
}

.regiadd_form form {
  margin-bottom: 30px;
}

.viewreceipts_page .viewreceipts {
  height: auto;
  padding: 100px 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Viewreceipts_email {
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid #ffffff;
  backdrop-filter: blur(8.125px);
  border-radius: 16px;
  padding: 40px 50px 30px;
}

.Viewreceipts_email form h4 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 15px;
}

.Viewreceipts_email form span {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 15px;
  display: block;
}

.Viewreceipts_email form .form_group input {
  background: rgba(0, 0, 0, 0.41);
  border: 1px solid rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(15px);
  border-radius: 8px;
  padding: 11px 15px;
  width: 100%;
  margin-bottom: 15px;

  font-family: "Lato";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgb(255 255 255 / 60%);
}

.Viewreceipts_email .orderid_btn button.tran_btn {
  padding: 16px 30px;
}

.Viewreceipts_email form .form_group input:focus-visible {
  outline: none;
}

.Changecolorpreferences_right h3 {
  margin-bottom: 40px;
}

.Changecolorpreferences_right .Changecolor_sec {
  position: relative;
  margin-bottom: 50px;
}

.Changecolorpreferences_right .Changecolor_sec::before {
  content: "";
  background: rgb(255 255 255 / 50%);
  height: 2px;
  width: 100%;
  border-radius: 2px;
  position: absolute;
  top: 65px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 0;
}

.Changecolorpreferences_right
  .Changecolor_sec
  form
  .form-check
  input.active::before {
  height: 42px;
  width: 42px;
  /* left: -14px;
    top: -14px; */
}

.Changecolorpreferences_right .Changecolor_sec form .form-check label {
  display: none;
}

.Changecolorpreferences_right
  .Changecolor_sec
  form
  .form-check
  input.active
  ~ label {
  display: block;
  margin-top: 60px;
}

.Changecolorpreferences_right
  .Changecolor_sec
  form
  .form-check
  input.active::after {
  content: "";
  background: #00eaff;
  height: 4px;
  border-radius: 4px;
  width: 100px;
  position: absolute;
  top: 59px;
  left: -40px;
  right: 0;
}

.colorsele_btn .orderid_btn button.sel_Back_home {
  padding: 0;
  background: transparent;
  font-family: "Lato";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  color: #ffffff;
  display: block;
  margin: auto;
  margin-top: 15px;
}

.Changecolorpreferences_page .Confirmation_left {
  height: auto;
}

.Cancelbooking_page .regiadd_form p {
  margin-bottom: 20px;
}

.cong_revolution .cong_colors_sec {
  font-family: "Lato";
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.cong_revolution .cong_color_second {
  color: #00eaff;
  margin-bottom: 15px;
}

.cong_revolution .count_step {
  margin-bottom: 25px !important;
  max-width: 250px;
  margin: auto;
  text-align: center;
}

.cong_revolution .count_step h5 {
  font-family: "Lato";
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin-bottom: 8px;
  width: auto;
  display: inline-block;
  padding: 1px 12px;
  border: 1px solid #ffffff;
  border-radius: 4px;
}

.cong_revolution .count_step p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin-bottom: 8px;
}

.cong_revolution .count_step p span {
  font-weight: 700;
  width: 100%;
  display: block;
}

.cong_revolution .count_step .get_sta {
  font-family: "Lato";
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  color: #00eaff;
}

.cong_revolution {
  margin-bottom: 60px;
}

.congrats_right h3 {
  margin-bottom: 40px;
}

.cong_revolution .count_step .active_step {
  color: #00eaff;
  border: 1px solid #00eaff;
  position: relative;
}

.cong_revolution .count_step .active_step::after {
  background-image: url("../../assets/images/VerifiedAccount.png");
  content: "";
  position: absolute;
  bottom: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
}

.Confirmationdash_right .logo_right_cont p {
  margin: 20px 0px;
}

.Confirmationdash_right > p {
  padding: 0px 100px;
  margin-bottom: 15px;
}

.color_id h4 {
  font-family: "Lato";
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  color: #00eaff;
  margin-bottom: 25px;
}

.color_id h4 span {
  color: rgba(255, 255, 255, 0.6);
}

.Confirmationdash_right .orderid_btn button.default_btn {
  padding: 16px 45px;
}

#date_input_mask::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

#date_input_mask::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

#date_input_mask:focus-visible {
  outline: none;
}

/*================================ RESPONSIVE START ================================*/

@media only screen and (min-width: 1601px) and (max-width: 1800px) {
  .order_iddetails .orderid_btn .default_btn {
    width: calc(50% - 6px);
    padding: 16px 10px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .order_iddetails .orderid_btn .default_btn {
    width: calc(50% - 5px);
    padding: 16px 7px;
    font-size: 13px;
  }

  .dashboard_orde .dashboard_rightsec {
    padding: 64px 20px;
  }

  .order_iddetails h4 {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 25px;
  }

  .order_iddetails {
    padding: 30px 15px 25px 15px;
  }

  .order_iddetails .order_details {
    margin-bottom: 25px;
  }

  .order_iddetails .order_details li {
    line-height: 24px;
    margin-bottom: 15px;
  }

  .steps_colorsec li {
    margin-bottom: 30px;
  }

  .steps_colorsec::before {
    top: 7px;
  }

  .next_pageview li {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .registration_detai {
    margin-top: 25px;
  }

  .registration_detai h4 {
    font-size: 20px;
    line-height: 28px;
  }

  .regiadd_form .form_group {
    margin-bottom: 10px;
  }

  .regiadd_form form span {
    margin: 20px 0px 10px;
  }

  .Viewreceipts_email {
    padding: 20px 30px 30px;
  }

  .Viewreceipts_email form h4 {
    font-size: 24px;
    line-height: 36px;
  }

  .Changecolorpreferences_right
    .Changecolor_sec
    form
    .form-check
    input.active::before {
    height: 32px;
    width: 32px;
    /* left: -16px;
        top: -16px; */
  }

  .Changecolorpreferences_right .Changecolor_sec::before {
    top: 55px;
  }

  .Changecolorpreferences_right
    .Changecolor_sec
    form
    .form-check
    input.active
    ~ label {
    margin-top: 50px;
  }

  .Changecolorpreferences_right
    .Changecolor_sec
    form
    .form-check
    input.active::after {
    width: 80px;
    top: 49px;
    left: -35px;
  }

  .cong_revolution .cong_colors_sec {
    font-size: 24px;
    line-height: 34px;
  }

  .Confirmationdash_right > p {
    padding: 0px 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .order_iddetails .orderid_btn .default_btn {
    width: 100%;
    padding: 16px 10px;
    font-size: 16px;
    margin-bottom: 15px;
  }

  .order_iddetails .orderid_btn .test_again {
    margin: 0px auto 0px;
  }

  .dashboard_orde .dashboard_rightsec {
    padding: 64px 20px;
  }

  .order_iddetails h4 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .order_iddetails {
    margin-top: 20px;
    padding: 20px 15px 20px 15px;
  }

  .order_iddetails .order_details {
    margin-bottom: 25px;
  }

  .order_iddetails .order_details li {
    line-height: 24px;
    margin-bottom: 15px;
    font-size: 14px;
  }

  .steps_colorsec li {
    margin-bottom: 30px;
  }

  .steps_colorsec::before {
    top: 7px;
  }

  .next_pageview li {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .Colorpreferences_page .Colorpreferences_right {
    padding: 64px 30px !important;
  }

  .registration_detai {
    margin-top: 20px;
  }

  .registration_detai h4 {
    font-size: 20px;
    line-height: 28px;
  }

  .regiadd_form .form_group {
    margin-bottom: 10px;
  }

  .regiadd_form form span {
    margin: 20px 0px 10px;
  }

  .regiadd_form .form_group .form-control {
    padding: 12px 0px;
  }

  .regiadd_form .orderid_btn button.tran_btn {
    width: 70%;
    margin-bottom: 15px;
  }

  .Viewreceipts_email {
    padding: 20px 20px 30px;
  }

  .Viewreceipts_email form h4 {
    font-size: 24px;
    line-height: 36px;
  }

  .Viewreceipts_email .orderid_btn button.tran_btn {
    padding: 15px 15px;
    font-size: 16px;
  }

  .Changecolorpreferences_right
    .Changecolor_sec
    form
    .form-check
    input.active::before {
    height: 28px;
    width: 28px;
    /* left: -12px;
        top: -12px; */
  }

  .Changecolorpreferences_right .Changecolor_sec::before {
    top: 55px;
  }

  .Changecolorpreferences_right
    .Changecolor_sec
    form
    .form-check
    input.active
    ~ label {
    margin-top: 50px;
  }

  .Changecolorpreferences_right
    .Changecolor_sec
    form
    .form-check
    input.active::after {
    width: 80px;
    top: 49px;
    left: -35px;
  }

  .Changecolorpreferences_right .Changecolor_sec form .form-check {
    width: 25%;
  }

  .colorsele_btn .orderid_btn button.tran_btn {
    padding: 15px 15px;
    font-size: 16px;
  }

  .Changecolorpreferences_right .Changecolor_sec {
    margin-bottom: 30px;
  }

  .cong_revolution .cong_colors_sec {
    font-size: 22px;
    line-height: 34px;
  }

  .Confirmationdash_right > p {
    padding: 0px 15px;
  }

  .color_id h4 {
    font-size: 18px;
    line-height: 20px;
  }

  .Confirmationdash_right .orderid_btn button.default_btn {
    padding: 15px 30px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .order_iddetails .orderid_btn .default_btn {
    width: 100%;
    padding: 14px 10px;
    font-size: 16px;
    margin-bottom: 15px;
  }

  .order_iddetails .orderid_btn .test_again {
    margin: 0px auto 0px;
  }

  .dashboard_orde .dashboard_rightsec {
    padding: 64px 20px;
  }

  .order_iddetails h4 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .order_iddetails {
    margin-top: 20px;
    padding: 20px 15px 20px 15px;
  }

  .order_iddetails .order_details {
    margin-bottom: 25px;
  }

  .order_iddetails .order_details li {
    line-height: 24px;
    margin-bottom: 15px;
    font-size: 14px;
  }

  .steps_colorsec li {
    margin-bottom: 30px;
  }

  .steps_colorsec::before {
    top: 7px;
  }

  .next_pageview li {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .Colorpreferences_page .Colorpreferences_right {
    padding: 64px 25px !important;
  }

  .registration_detai {
    margin-top: 20px;
  }

  .registration_detai h4 {
    font-size: 20px;
    line-height: 28px;
  }

  .regiadd_form .form_group {
    margin-bottom: 10px;
  }

  .regiadd_form form span {
    margin: 20px 0px 10px;
  }

  .regiadd_form .form_group .form-control {
    padding: 12px 0px;
  }

  .regiadd_form .orderid_btn button.tran_btn {
    width: 70%;
    margin-bottom: 15px;
  }

  .Viewreceipts_email {
    padding: 20px 20px 30px;
  }

  .Viewreceipts_email form h4 {
    font-size: 20px;
    line-height: 26px;
  }

  .Viewreceipts_email .orderid_btn button.tran_btn {
    padding: 15px 15px;
    font-size: 16px;
    border-radius: 8px;
  }

  .viewreceipts_page .viewreceipts {
    padding: 120px 20px !important;
  }

  .Changecolorpreferences_right
    .Changecolor_sec
    form
    .form-check
    input.active::before {
    height: 28px;
    width: 28px;
    /* left: -12px;
        top: -12px; */
  }

  .Changecolorpreferences_right .Changecolor_sec::before {
    top: 55px;
  }

  .Changecolorpreferences_right
    .Changecolor_sec
    form
    .form-check
    input.active
    ~ label {
    margin-top: 50px;
  }

  .Changecolorpreferences_right
    .Changecolor_sec
    form
    .form-check
    input.active::after {
    width: 80px;
    top: 49px;
    left: -35px;
  }

  .Changecolorpreferences_right .Changecolor_sec form .form-check {
    width: 25%;
  }

  .colorsele_btn .orderid_btn button.tran_btn {
    padding: 15px 15px;
    font-size: 16px;
  }

  .Changecolorpreferences_right .Changecolor_sec {
    margin-bottom: 30px;
  }

  .cong_revolution .cong_colors_sec {
    font-size: 20px;
    line-height: 28px;
  }

  .cong_revolution {
    margin-bottom: 40px;
  }

  .congrats_right h3 {
    margin-bottom: 20px;
  }

  .Confirmationdash_right > p {
    padding: 0px 0px;
  }

  .color_id h4 {
    font-size: 18px;
    line-height: 20px;
  }

  .Confirmationdash_right .orderid_btn button.default_btn {
    padding: 15px 30px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .order_iddetails .orderid_btn .default_btn {
    width: calc(50% - 5px);
    padding: 14px 10px;
    font-size: 12px;
    margin-bottom: 15px;
    border-radius: 10px;
  }

  .order_iddetails .orderid_btn .test_again {
    margin: 0px auto 0px;
  }

  .dashboard_orde .dashboard_rightsec {
    padding: 64px 20px;
  }

  .order_iddetails h4 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .order_iddetails {
    margin-top: 20px;
    padding: 0px;
    border: none;
    background: transparent;
  }

  .order_iddetails .order_details {
    display: none;
  }

  .dashboard_orde .dashboard_leftsec {
    display: none;
  }

  .dashboard_orde .dashboard_rightsec h3 {
    font-size: 32px;
    line-height: 48px;
    margin-top: 15px;
    margin-bottom: 0px;
    font-weight: 700;
  }

  .dashboard_orde .dashboard_rightsec p {
    margin-bottom: 24px;
  }

  .dashboard_orde .dashboard_rightsec .das_orderimg img {
    border-radius: 10px;
    width: 100%;
  }
  .dashboard_orde .mobile_show.das_orderimg {
    background-image: url(https://hopelectric.in/static/media/Confirmation_bike.017a8d70fcfcd0307c63.png);
    background-position: top;
    background-size: cover;
    padding: 30px;
  }

  .steps_colorsec li {
    margin-bottom: 30px;
  }

  .steps_colorsec::before {
    top: 7px;
  }

  .next_pageview li {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .Colorpreferences_page .Colorpreferences_right {
    padding: 90px 20px 20px !important;
  }

  .Colorpreferences_page .Colorpreferences_right h3 {
    display: none;
  }

  .color_preferences {
    margin-top: 30px;
    padding: 0px 15px;
  }

  .dashboard_orde .dashboard_rightsec::after {
    content: "";
    background: linear-gradient(211deg, #2a63a3, #2a63a3);
    border-radius: 50%;
    filter: blur(50px);
    width: 450px;
    height: 400px;
    position: absolute;
    left: -270px;
    bottom: 50px;
    z-index: -2;
  }

  .Registration_add_right h3 {
    display: none;
  }

  .regiadd_form .orderid_btn button.tran_btn {
    width: 70%;
    margin-bottom: 15px;
  }

  .Viewreceipts_email {
    padding: 20px 20px 30px;
  }

  .Viewreceipts_email form h4 {
    font-size: 20px;
    line-height: 26px;
  }

  .Viewreceipts_email .orderid_btn button.tran_btn {
    padding: 15px 15px;
    font-size: 16px;
    border-radius: 8px;
  }

  .viewreceipts_page .viewreceipts {
    padding: 120px 20px !important;
  }

  .Changecolorpreferences_right
    .Changecolor_sec
    form
    .form-check
    input.active::before {
    height: 28px;
    width: 28px;
    /* left: -12px;
        top: -12px; */
  }

  .Changecolorpreferences_right .Changecolor_sec::before {
    top: 47px;
  }

  .Changecolorpreferences_right
    .Changecolor_sec
    form
    .form-check
    input.active
    ~ label {
    margin-top: 10px;
  }

  .Changecolorpreferences_right
    .Changecolor_sec
    form
    .form-check
    input.active::after {
    width: 80px;
    top: 49px;
    left: -35px;
  }

  .Changecolorpreferences_right .Changecolor_sec form .form-check {
    width: 25%;
    margin: 0;
  }

  .colorsele_btn .orderid_btn button.tran_btn {
    padding: 15px 15px;
    font-size: 16px;
  }

  .Changecolorpreferences_right .Changecolor_sec {
    margin-bottom: 100px;
  }

  .Changecolorpreferences_page .Confirmation_left {
    display: none;
  }

  .Changecolorpreferences_page .Changecolorpreferences_right {
    padding: 100px 0px 20px !important;
  }

  .Changecolorpreferences_right .Changecolor_sec::after {
    display: none;
  }

  .Changecolorpreferences_page .Changecolorpreferences_right .das_orderimg img {
    border-radius: 10px;
    width: 100%;
  }

  .Changecolorpreferences_page .Changecolorpreferences_right h3 {
    font-size: 24px;
    margin-bottom: 25px;
  }

  .Changecolorpreferences_page .Changecolorpreferences_right .das_orderimg {
    margin-bottom: 35px;
  }

  .Cancelbooking_page .Cancelbooking_right {
    padding: 80px 20px 50px;
  }

  .Cancelbooking_page .Cancelbooking_right .das_orderimg {
    margin-bottom: 20px;
  }

  .cong_revolution .cong_colors_sec {
    font-size: 20px;
    line-height: 28px;
  }

  .cong_revolution {
    margin-bottom: 40px;
  }

  .congrats_right h3 {
    margin-bottom: 20px;
  }

  .congrats_right h4.mobile_show {
    font-family: "Lato";
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.05em;
    margin: 20px 0px;
    color: #ffffff;
  }

  .congrats_right h4.mobile_show span {
    color: rgba(255, 255, 255, 0.5);
  }

  .Confirmationdash_right > p {
    padding: 0px 0px;
  }

  .color_id h4 {
    font-size: 18px;
    line-height: 20px;
  }

  .Confirmationdash_right .orderid_btn button.default_btn {
    padding: 15px 30px;
    font-size: 16px;
  }

  .Confirmationdash_page .map_left.dashboard_leftsec {
    display: none !important;
  }

  .Confirmationdash_page .Confirmationdash_right {
    padding: 100px 20px 30px !important;
  }

  .Confirmationdash_page .Confirmationdash_right h3 {
    font-size: 24px;
    margin: 30px 0px;
  }
}
