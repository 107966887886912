.overRecommended_sec {
  margin-bottom: 20px;
  border: 1px solid #e5e5e5;
  background: #ffffff;
}
.overRecommended_sec .content_container {
  padding: 20px 20px;
}
.overRecommended_sec .image_container {
  overflow: hidden;
  transition: all 0.2s ease-in;
  height: 200px;
}
.overRecommended_sec .content_container p span {
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 14px;
  color: #626262;
}
.overRecommended_sec .content_container p span:nth-child(1) {
  border-right: 1px solid #626262;
  padding-right: 15px;
  margin-right: 15px;
}
.overRecommended_sec .content_container h4 a {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  color: #000000;
  overflow: hidden;
  display: block;
}
.overRecommended_sec img {
  transition: all 0.3s ease-in-out;
}
.overRecommended_sec:hover img {
  transform: scale(1.1);
}

.overRecommended_sec .mini {
  display: flex;
  column-gap: 30px;
}
.overRecommended_sec .mini .image_container {
  width: 30%;
  height: auto;
}
.overRecommended_sec .mini .image_container a {
  display: block;
  height: 100%;
}
.overRecommended_sec img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.overRecommended_sec .mini .content_container {
  width: 70%;
  padding: 4px 5px;
}
.overRecommended_sec .mini .content_container p span {
  font-size: 12px;
}
.overRecommended_sec .mini .content_container h4 a {
  font-size: 14px;
  color: #000;
  font-weight: 600;
}
.overRecommended_sec .content_container h6 {
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  .overRecommended_sec .content_container p span {
    font-size: 12px;
  }
  .overRecommended_sec .content_container h4 a {
    font-size: 16px;
  }
  .overRecommended_sec .content_container {
    padding: 15px;
  }
  .overRecommended_sec .mini {
    column-gap: 10px;
  }
}
