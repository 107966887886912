.Community_review {
  margin-bottom: 30px;
}
.Community_review .mini_card_community_home {
  display: flex;
  gap: 20px;
}
.Community_review .mini_card_community_home {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.Community_review .home_mini_parent {
  width: calc(33.33% - 20px);
}

.Community_review .vieew_all_btn {
  margin-top: 15px;
}


@media screen and (max-width:767px) {
  .Community_review .home_mini_parent{
    width: 100%;
  }
}