/* section 4 css */

.sec_content_steper {
    background-color: #0a0a0a;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.sec_content_steper .container_about_us {
    max-width: 1440px;
    padding: 76px 140px 76px 140px;
    margin: 0px auto;
}

.sec_content_steper .container_about_us h2 {
    color: #FFF;
    font-size: 54px;
    font-family: Lato, sans-serif;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 5.4px;
    text-transform: uppercase;
    margin-bottom: 50px;
    text-align: center;
}

/* stepper */

.sec_content_steper .wrapper-progressBar .step-headings {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
}

.sec_content_steper .wrapper-progressBar .step-headings .step-box {
    width: 100%;

}

.sec_content_steper .wrapper-progressBar .step-headings .step-box p {
    font-size: 32px;
    font-weight: 400;
    font-family: Lato, sans-serif;
    line-height: 50px;
    letter-spacing: 6.4px;
    background: linear-gradient(rgba(0, 234, 255, 1), rgb(8 143 155));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.sec_content_steper .wrapper-progressBar .step-headings .step-box p.in_year {
    font-size: 30px;
    font-family: Lato, sans-serif;
    line-height: 50px;
    letter-spacing: 0px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 6px;
}

/* progress bar css */

.sec_content_steper .wrapper-progressBar {
    width: 100%;
}

.sec_content_steper .wrapper-progressBar .progressBar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.sec_content_steper .wrapper-progressBar .progressBar li {
    font-size: 28px;
    font-family: Lato, sans-serif;
    font-weight: 600;
    line-height: 50px;
    list-style-type: none;
    float: left;
    width: 33%;
    position: relative;
    text-align: center;
    background: linear-gradient(rgba(0, 234, 255, 1), rgb(0 234 255));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    padding-top: 50px;
}

.sec_content_steper .wrapper-progressBar .progressBar li:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background-image: linear-gradient(rgba(0, 234, 255, 1), rgba(0, 234, 255, 1));
    top: 15px;
    left: 0%;
    z-index: 0;
}

.sec_content_steper .wrapper-progressBar .progressBar li:after {
    content: " ";
    line-height: 30px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    display: block;
    text-align: center;
    margin: 0 auto 10px;
    background-image: linear-gradient(rgba(133, 252, 101, 1), rgba(0, 234, 255, 1));
    transform: rotate(270deg) !important;
    position: absolute;
    top: 0;
    right: 50%;
    transform: translateX(50%);
}

.progressBar li:first-child:after {
    content: none;
}

/* timeline content list items */

.sec_content_steper .wrapper-progressBar .timeline_items {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
}

.sec_content_steper .wrapper-progressBar .timeline_items ul.progressBar_items li {
    color: rgba(255, 255, 255, 0.60);
    font-size: 24px;
    font-weight: 400;
    font-family: Lato, sans-serif;
    line-height: 43px;
    list-style: disc;
}

.sec_content_steper .wrapper-progressBar .timeline_items ul.progressBar_items {
    max-width: 33%;
    margin: 0px 30px;
}

.sec_content_steper .year_subheading {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 1px solid;
    padding-bottom: 15px;
}

.sec_content_steper .year_subheading h4 {
    font-size: 20px;
    line-height: 18px;
    letter-spacing: 1px;
    color: rgb(0, 234, 255, 0.7);
    text-align: center;
    margin: 0px;
}

.sec_content_steper .year_subheading h4:nth-child(2) {
    font-size: 22px;
    color: rgb(0, 234, 255, 1);
}

.sec_content_steper .tech_deails {
    padding: 15px 10px 15px 15px;
}

.sec_content_steper.mobile .tech_deails ul {
    padding: 10px 0px;
}

.sec_content_steper.mobile .tech_deails li {
    width: 100%;
    list-style-type: disc;
    margin-left: 15px;
    font-size: 14px;
    line-height: 20px;
}

.sec_content_steper.mobile .progressBar_items21 li {
    display: list-item !important;
}

.sec_content_steper.mobile {
    display: none;
}

.sec_content_steper.mobile .newtech_specs ul:last-child li:nth-child(n + n) {
    list-style: disc !important;
}

.sec_content_steper.mobile .btn-group-price.oxo_both {
    justify-content: center;
}

.sec_content_steper.mobile .btn-group-price.oxo_both .btn-price-oxo {
    width: 33.33%;
    height: 43px;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    position: relative;
    display: flex;
    justify-content: center;
}

/* .sec_content_steper.mobile .btn-group-price.oxo_both svg{
    color: #FFF;
    position: absolute;
    right: -10px;
    top:50%;
    transform: translateY(-50%);
} */
.sec_content_steper.mobile .btn-price-oxo.activeBtnOxo11 {
    background-color: rgb(0, 234, 255, 1);
    color: #000;
}

.sec_content_steper.mobile .btn-price-oxo:nth-child(2) {
    border-radius: 0px;
}

.sec_content_steper.mobile .btn-price-oxo:nth-child(3) {
    border-radius: 0px 5px 5px 0px;
}



/* arrow css  */
.sec_content_steper.mobile .btn-price-oxo.activeBtnOxo11 span {
    position: absolute;
    border: 10px solid rgb(0, 234, 255, 0.2);
    /* content: ""; */
    right: -43px;
    top: 0;
    width: 0;
    height: 0;
    border-color: transparent transparent transparent rgb(0, 234, 255, 1);
    border-style: solid;
    border-width: 22px;
}

.sec_content_steper.mobile .btn-price-oxo:nth-child(3).activeBtnOxo11 span,
.sec_content_steper.mobile .btn-price-oxo:nth-child(2).activeBtnOxo11 span.one {
    position: absolute;
    border: 10px solid rgb(0, 234, 255, 0.2);
    /* content: ""; */
    left: -43px;
    top: 0;
    width: 0;
    height: 0;
    border-color: transparent transparent transparent rgb(0, 234, 255, 1);
    border-style: solid;
    border-width: 22px;
    transform: rotate(180deg);
}






/* media query */

@media screen and (max-width:1200px) {
    .sec_content_steper .wrapper-progressBar .step-headings .step-box p{
        font-size: 28px;
    }
    .sec_content_steper .wrapper-progressBar .timeline_items ul.progressBar_items li{
        font-size: 20px;
        line-height: 34px;
    }
    
}

@media only screen and (max-width: 991px) {
    .sec_content_steper .wrapper-progressBar .progressBar li{
        font-size: 22px;
    }
    .sec_content_steper .wrapper-progressBar .timeline_items ul.progressBar_items li{
        font-size: 18px;
        line-height: 26px;
    }
    .sec_content_steper .wrapper-progressBar .timeline_items ul.progressBar_items{
        max-width: 100%;
    }

    .sec_content_steper .wrapper-progressBar .progressBar {
        justify-content: space-between;
    }

    .sec_content_steper .wrapper-progressBar .progressBar li {
        width: 100% !important;
    }

    .sec_content_steper .wrapper-progressBar .timeline_items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 30px;
        margin: 0 15px;
    }
    

}

@media only screen and (max-width: 767px) {
    .sec_content_steper {
        display: none;
    }

    .sec_content_steper.mobile {
        display: block;
    }

    .sec_content_steper .wrapper-progressBar .progressBar li:after {
        right: 45%;
    }

    .sec_content_steper .container_about_us {
        padding: 50px 0px;
        width: 100%;
    }

    .sec_content_steper .container_about_us h2 {
        margin-bottom: 15px;
        font-size: 24px;
        line-height: 26px;
    }

    .sec_content_steper .wrapper-progressBar .progressBar li {
        line-height: normal;
        width: auto;
        font-size: 22px;
    }

    .sec_content_steper .wrapper-progressBar .timeline_items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 30px;
    }

    .sec_content_steper .wrapper-progressBar .timeline_items ul.progressBar_items {
        max-width: initial;
        margin: inherit;
    }

    .sec_content_steper .wrapper-progressBar .timeline_items ul.progressBar_items li {
        font-size: 14px;
        line-height: 22px;
    }

    .sec_content_steper .wrapper-progressBar .step-headings .step-box p {
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 1.4px;
    }

    .sec_content_steper .wrapper-progressBar .step-headings .step-box p.in_year {
        font-size: 20px;
        line-height: 18px;
        letter-spacing: 1px;
    }

    .sec_content_steper .tech_specs_sec .landing_page_container {
        padding: 30px 5px;
    }
}

@media screen and (max-width:450px) {
    .sec_content_steper .btn-group-price button.btn-price-oxo {
        font-size: 11px;
    }
}