.career_one .form_main_sec {
  background: rgba(17, 17, 17, 01);
}

.career_one .form_sec_new {
  width: 100%;
  max-width: 1280px;
  margin: auto;
  padding-bottom: 60px;
}

.min_color {
  height: 100%;
}

.min_color::before {
  background: linear-gradient(211deg, #14204e, #14204e);
  border-radius: 50%;
  content: "";
  filter: blur(60px);
  height: 800px;
  position: absolute;
  right: -400px;
  top: -400px;
  width: 800px;
  z-index: 0;
}

.min_color::after {
  background: linear-gradient(211deg, #2a63a3, #2a63a3);
  border-radius: 50% 80% 0% 0%;
  content: "";
  filter: blur(80px);
  height: 900px;
  position: absolute;
  left: -450px;
  bottom: -450px;
  width: 900px;
  z-index: 0;
  overflow: hidden;
}

.career_one .form_sec_new .form_head h2 {
  text-align: center;
  z-index: 2;
  position: relative;
}

.career_one .form_sec_new form .form-group .form-control,
.career_one .form_sec_new form .form-group textarea {
  /* border-radius: 0;
  padding: 18px 15px;
  height: 60px;
  border-radius: 8px; */

  margin: 0;
  padding: 15px 12px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #ffffff;
  background-color: transparent;
  width: 100%;
}

.career_one .form_sec_new form .form-group .form-control#myfile {
  line-height: initial;
}

.career_one .form_sec_new form .form-group {
  width: calc(50% - 20px);
  margin: 0px 10px;
  margin-bottom: 30px !important;
}

.career_one .form_sec_new form .form-group:first-child {
  width: 100%;
}

.career_one .send_btn {
  display: flex;
  justify-content: center;
}

/* .career_one .send_btn .form_btnsend {
  border-radius: 0;
  width: auto;
  min-width: 150px;
  text-transform: uppercase;
  padding: 20px;
  display: inline-block;
} */

.career_one .form_sec_new form .form-group {
  position: relative;
}

.career_one .form_sec_new form .form-group .send_otp_form {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  padding: 5px 10px;
  background-color: #00eaff;
  border-radius: 5px;
  font-weight: 600;
}

.career_one .form_sec_new form .form-group .otp_verified {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  -webkit-box-shadow: inset 1px -1px 11px 0px rgba(36, 36, 36, 1);
  -moz-box-shadow: inset 1px -1px 11px 0px rgba(36, 36, 36, 1);
  box-shadow: inset 1px -1px 11px 0px rgba(36, 36, 36, 1);
  padding: 5px;
  /* background-color: #005207; */
  border-radius: 50px;
  font-weight: 600;
  margin: 0px;
}

.service_textarea textarea {
  width: 100%;
  min-height: 58px;
  border-radius: 5px;
}

.service_ticket textarea:focus-visible,
.service_ticket .form-control:focus-visible {
  border: none;
  outline: none;
}

.service_ticket form .form-group:first-child {
  width: calc(50% - 20px) !important;
}

.otp_cont > h2 {
  color: #00eaff;
  font-size: 26px;
  line-height: 32px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 35px;
  display: block;
  width: 100%;
  text-transform: capitalize;
}

.opt_popup.service_otppop .pure-modal .close {
  display: block;
  position: absolute !important;
  top: -14px;
  right: -8px;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  border: 1px solid #00eaff;
  background-color: rgba(0, 234, 255, 0.1) !important;
}

.opt_popup.service_otppop .pure-modal .close .close_btn {
  margin-right: inherit;
  margin-top: inherit;
}
.opt_popup.service_otppop .pure-modal {
  overflow: inherit;
  max-width: 360px;
  padding: 40px;
}

.opt_popup.service_otppop .otp_cont .form-group {
  margin: 15px 0px;
}
.opt_popup.service_otppop .pure-modal .close .close_btn::before {
  height: 3px;
  width: 23px;
  bottom: 10px;
  top: 12px;
  right: 2px;
  background-color: #00eaff;
}

.opt_popup.service_otppop .pure-modal .close .close_btn::after {
  bottom: 10px;
  top: 12px;
  left: 2px;
  height: 3px;
  width: 23px;
  background-color: #00eaff;
}

.otp_resend_div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp_resend_div p {
  padding: 0;
  margin: 0;
}


/* css for service page extra gradiunt hide  */

.before_remove::after{
  display: none;
}



@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .forms_click_btn .default_btn {
    padding: 21px 49px !important;
    border-radius: 16px !important;
    font-size: 20px !important;
  }
  .career_one .form_sec_new form .form-group .form-control,
  .career_one .form_sec_new form .form-group textarea {
    /* padding: 12px; */
    height: 50px;
  }
}

@media screen and (max-width: 991px) {
  .min_color {
    height: auto;
  }

  .height_one_csrol.career_one {
    scroll-snap-type: none;
  }

  .form_sec_new_inner {
    position: relative;
    overflow-y: initial;
  }

  .form_sec_new {
    height: auto;
  }

  .form_sec_new_inner .form_head,
  .form_sec_new_inner .Locations {
    position: relative;
    z-index: 2;
  }

  .form_sec_new::after {
    background: linear-gradient(211deg, #2a63a3, #2a63a3);
    border-radius: 50% 80% 0% 0%;
    content: "";
    filter: blur(80px);
    height: 900px;
    position: absolute;
    left: -450px;
    bottom: -450px;
    width: 900px;
    z-index: 0;
    overflow: hidden;
  }

  .form_sec_new::before {
    background: linear-gradient(211deg, #14204e, #14204e);
    border-radius: 50%;
    content: "";
    filter: blur(60px);
    height: 800px;
    position: absolute;
    right: -400px;
    top: -400px;
    width: 800px;
    z-index: 0;
  }
}

@media screen and (max-width: 767px) {
  .form_sec_new.before_remove{
    overflow: hidden;
    position: relative;
  }
  .forms_click_btn button {
    padding: 12px 20px 10px;
    font-size: 14px;
  }
  .career_one .form_sec_new form .form-group {
    margin-bottom: 20px !important;
  }
  .career_one .form_sec_new form .form-group .form-control {
    /* height: 45px; */
    padding: 7px !important;
    height: auto !important;
  }
  .form_sec_new {
    overflow: auto;
    /* overflow-y: hidden; */
  }
  #contact_us_form_page  .form_sec_new {
    overflow: hidden;
    position: relative;
  }

  .form_sec_new::before,
  .form_sec_new::after {
    height: 550px;
    width: 600px;
  }

  .career_one .form_sec_new {
    padding-bottom: 20px;
  }

  .career_one .form_sec_new form .form-group,
  .service_ticket form .form-group:first-child {
    width: 100% !important;
  }

  .service_ticket .other_label {
    display: none !important;
  }

  .min_color::before {
    filter: blur(30px);
    height: 300px;
    right: -150px;
    top: -180px;
    width: 300px;
  }

  .min_color::after {
    filter: blur(40px);
    height: 500px;
    left: -300px;
    bottom: -200px;
    width: 500px;
  }
}
