.home_imagecommunity {
  height: 700px;
}
.home_imagecommunity img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media screen and (max-width: 1440px) {
  .home_imagecommunity {
    height: 400px;
  }
}

@media screen and (max-width: 767px) {
  .home_imagecommunity {
    height: 280px;
  }
}
