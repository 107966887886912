
@mixin hamburger-line {
  width: 100%;
  height: 3px;
  background-color: black;
  position: absolute;
  left: 0;
}

.hamburger,
.navigation {
  transition: transform 0.3s 0.1s ease-in-out;
}

.hamburger {

  &active {
    transform: translateX(0px);
  }

  &__box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  &__inner {
    @include hamburger-line;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 0.1s 0.2s ease-in-out;
  }

  &__inner::before,
  &__inner::after {
    @include hamburger-line;
    content: "";
    transition: transform 0.1s 0.2s ease-in-out;
  }

  &__inner::before {
    top: -10px;
  }

  &__inner::after {
    top: 10px;
  }

  &active &__inner {
    background-color: transparent;
  }

  &active &__inner::before {
    transform: translateY(10px) rotate(45deg);
  }

  &active &__inner::after {
    transform: translateY(-10px) rotate(-45deg);
  }
}

.navigation {
  // height: 120vh;
  // width: 100vw;
  background-color: #000 !important;
  // position: absolute;
  // left: 0;
  // top: 0;
  // right: 0;
  // transform: translateX(250px);
  position: fixed;
  right: -100% !important;
  left: 100%;
  top: 0;
  bottom: inherit;
  height: 100vh;
  width: 100vw;
  transform: translateX(99%);
  padding: 40px 80px;

  &active {
    transform: translateX(-100%);
  }

  &__list {
    list-style: none;
    margin-top: 50px;
  }

  &__item {
    margin: 20px;
    padding: 4px;
  }
}




// *::before,
// *::after {
//   box-sizing: inherit;
// }

// a {
//   text-decoration: none;
//   color: white;
// }

// a:focus {
//   outline: 2px solid white;
//   padding: 4px;
// }

// @mixin hamburger-line {
//   width: 100%;
//   height: 3px;
//   background-color: #FFF;
//   // background-color: black;
//   position: absolute;
//   left: 0;
// }

// .hamburger,
// .navigation {
//   transition: transform 0.3s 0.1s ease-in-out;
// }

// .hamburger {
//   padding: 10px;
//   display: inline-block;
//   cursor: pointer;
//   background-color: transparent;
//   border: 0;
//   margin: 0;
//   // float: right;

//   &active {
//     transform: translateX(-250px);
//   }

//   &__box {
//     width: 40px;
//     height: 24px;
//     display: inline-block;
//     position: relative;
//   }

//   &__inner {
//     @include hamburger-line;
//     top: 50%;
//     transform: translateY(-50%);
//     transition: background-color 0.1s 0.2s ease-in-out;
//   }

//   &__inner::before,
//   &__inner::after {
//     @include hamburger-line;
//     content: "";
//     transition: transform 0.1s 0.2s ease-in-out;
//   }

//   &__inner::before {
//     top: -10px;
//   }

//   &__inner::after {
//     top: 10px;
//   }

//   &active &__inner {
//     background-color: transparent;
//   }

//   &active &__inner::before {
//     transform: translateY(10px) rotate(45deg);
//   }

//   &active &__inner::after {
//     transform: translateY(-10px) rotate(-45deg);
//   }
// }

// .navigation {
//   height: 100vh;
//   width: 250px;
//   // background-color: blue;
//   position: absolute;
//   top: 0;
//   right: 0;
//   transform: translateX(250px);

//   &active {
//     transform: translateX(0);
//   }

//   &__list {
//     list-style: none;
//     margin-top: 50px;
//   }

//   &__item {
//     margin: 20px;
//     padding: 4px;
//   }
// }
