.Community_welcome_h1 h1{
    font-size: 36px;
    line-height: 40px;
    font-weight: 500;
    color: #222222;
    margin-bottom: 15px;
}

@media screen and (max-width:991px) {
    .Community_welcome_h1 h1{
        font-size:32px;
    }
   
}

@media screen and (max-width:767px) {
    .Community_welcome_h1 h1{
        font-size:26px;
        margin-bottom: 5px;
    }
   
}