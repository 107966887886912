.Community_welcome_p p {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #222222;
}

@media screen and (max-width: 767px) {
  .Community_welcome_p p {
    font-size: 14px;
  }
}
