.product_features {
  max-width: 1410px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0 30px;
  margin-top: 80px;
  margin-bottom: 60px;
  /* background-image: url("../../../assets/images/Enquiry/silenceBG.jpg"); */
  background-repeat: no-repeat;
  background-position: center top;
}


.product_features .feature_head {
  max-width: 880px;
  margin: 10px auto 0;
  transform: translateZ(0);
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.product_features .feature_head h4 {
  font-size: 36px;
  color: #000;
  font-weight: 600;
  text-align: center;
  letter-spacing: -1px;
}

.product_features .feature_head p {
  font-size: 18px;
  color: #000;
  text-align: center;
  letter-spacing: -1px;
}

.product_features .feature_body {
  display: flex;
  /* gap: 20px; */
}

.product_features .feature_body .hover_item {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  cursor: pointer;
  transition-timing-function: ease;
  transition-duration: 1s;
  transition-property: opacity, transform;
}
.product_features .feature_body .hover_item.active .image_item {
  background: #1ea4de;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  padding: 8px;
}
.product_features .feature_body .hover_item .image_item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  background: #fff;
  border-radius: 50%;
  margin-right: 12px;
  transition: 0.5s;
}
.product_features .feature_body .hover_item .image_item img {
  object-fit: cover;
  width: 40px;
}
.product_features .feature_body .item_detail {
  font-size: 16px;
  color: #0098da;
  text-transform: uppercase;
  width: calc(100% - 67px);
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.product_features .feature_body .item_detail h6 {
  color: #0d0d0d;
  text-align: center;
  font-weight: 500;
  margin: 0px;
}
.product_features .feature_body .item_detail p {
  font-weight: 500;
  text-align: center;
  line-height: 20px;
  margin: 0px;
}
.product_features .section2 {
  width: 61%;
  margin-top: 10px;
  margin-left: 1%;
}
.product_features .section1,
.product_features .section3 {
  width: 18%;
  padding: 0 0 0 25px;
  position: relative;
}

.product_features .img_chnage_sec {
  max-height: 550px;
  overflow: hidden;
}
.product_features .img_chnage_sec img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product_features .section1.for_Mob,
.section3.for_Mob {
  display: none !important;
}

@media screen and (max-width: 1200px) {
  .product_features .section1 {
    margin-right: 20px;
  }
}
@media screen and (max-width: 991px) {
  .product_features .feature_body {
    flex-direction: column;
  }
  .product_features .section1,
  .product_features .section3 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .product_features .section1 .hover_item,
  .product_features .section3 .hover_item {
    min-width: 150px;
  }
  .product_features .section2 {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .product_features .feature_head p {
    font-size: 14px;
  }
  .product_features .img_chnage_sec {
    height: 230px;
  }
  .product_features .for_desk {
    display: none;
  }
  .product_features .section1.for_Mob,
  .section3.for_Mob {
    display: block !important;
  }
  .product_features .feature_head h4 {
    font-size: 30px;
    line-height: normal;
  }
  .product_features {
    padding: 0px 15px;
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .product_features
    .feature_body
    .hover_item
    .image_item
    .product_features
    .feature_head
    p {
    font-size: 16px;
  }
  .product_features .section1,
  .product_features .section3 {
    padding: 0px;
    gap: 10px;
    padding: 10px 0px;
  }
  .product_features .section1 .hover_item,
  .product_features .section3 .hover_item {
    padding: 10px;
    background: #fff;
    box-shadow: 0 2px 13px 0 rgba(166, 166, 166, 0.33);
    border-radius: 8px;
    margin-bottom: 0px;
    height: 60px;
    overflow: hidden;
  }
  .product_features .feature_body .hover_item .image_item {
    width: 25px;
    height: 25px;
    min-width: 25px;
  }
  .product_features .feature_body .hover_item.active .image_item {
    width: 45px;
    height: 45px;
    padding: 7px;
    min-width: 45px;
  }
  .product_features .feature_body .hover_item.active .image_item img {
    object-fit: cover;
  }
  .product_features .feature_body .item_detail p,
  .product_features .feature_body .item_detail h6 {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .product_features .feature_body .item_detail {
    width: auto;
  }
  .product_features .section2 {
    margin: 20px 0px;
    margin-left: 0px;
  }
  .content_second_slide {
    display: flex;
    align-items: center;
  }
  .feature_body .slick-slide {
    margin: 0px 10px;
  }
  .feature_body .slick-track {
    display: flex;
    padding: 5px 0px;
  }
  .feature_body .slick-dots li button:before {
    font-size: 8px;
    color: #0098da;
  }
  .product_features .feature_head {
    margin-bottom: 20px;
  }
  .product_features .feature_body .slick-track{
    padding-left: 15%;
  }
  .product_features .feature_body .slick-list{
    padding: 0px;
  }
}
