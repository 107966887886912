/* section 10 css */

.sec_news_article{
    background-color: #0a0a0a;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.sec_news_article::before{
    background: linear-gradient(211deg,#14204e,#14204e);
    border-radius: 50%;
    content: "";
    -webkit-filter: blur(60px);
    filter: blur(60px);
    height: 650px;
    position: absolute;
    right: -400px;
    top: -400px;
    width: 650px;
    z-index: 0;
}

.sec_news_article::after{
    background: linear-gradient(211deg,#2a63a3,#2a63a3);
    border-radius: 50% 80% 0 0;
    bottom: -450px;
    content: "";
    -webkit-filter: blur(80px);
    filter: blur(80px);
    height: 650px;
    left: -450px;
    position: absolute;
    width: 650px;
    z-index: 0;
}

.sec_news_article figure.news img {
    margin: 0px auto;
    width: 100%;
    max-width: 870px;
    margin-bottom: 40px;
}

.sec_news_article .partners_logo figure img {
    margin: 0px auto;
}

.sec_news_article .container_about_us h2 {
    color: #FFF;
    font-size: 54px;
    font-family: Lato, sans-serif;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 5.4px;
    text-transform: uppercase;
    margin-bottom: 40px;
    text-align: center;
}

.sec_news_article .recoganized, .partners_logo .featured-text{
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    background: linear-gradient(rgba(24, 98, 161, 1), rgba(0, 234, 255, 1));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    margin-bottom: 30px !important;
    margin: 0px;
}

.selection_background::selection{
    -webkit-text-fill-color: #fff !important; 
}



/* media query */

@media only screen and (max-width: 767px){

    .sec_news_article .container_about_us h2{
        margin-bottom: 10px;
        font-size: 24px;
        line-height: 36px;
    }

    .sec_news_article .recoganized, .partners_logo .featured-text{
        line-height: 26px;
        font-size: 20px;
        margin-bottom: 15px !important;
    }

    .sec_news_article figure.news img{
        margin-bottom: 20px;
    }
    
    .sec_news_article::before, 
    .sec_news_article::after{
        height: 500px;
        width: 500px;
    }
}
