
.image_sec_community_home{
  position: relative;
}
.home_mini_parent .content_sec_community_home {
  padding: 10px 0px 20px;
}
.home_mini_parent .image_sec_community_home img {
  width: 100%;
}
.home_mini_parent .content_sec_community_home .content_mini {
  display: flex;
  justify-content: space-between;
}
.home_mini_parent .content_sec_community_home .content_mini p {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}
.home_mini_parent .content_sec_community_home .content_mini span {
  font-size: 12px;
  color: #000;
}

.home_mini_parent .contet_of_card {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #666666;
  padding: 10px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  height: 49px;
  -webkit-box-orient: vertical;
}
