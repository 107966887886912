/* section 8 css */

.sec_8_content {
  background-color: #0a0a0a;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.sec_8_content .container_about_us {
  max-width: 1440px;
  padding: 76px 140px 76px 140px;
  margin: 0px auto;
  position: relative;
  z-index: 1;
}

.sec_8_content:before {
  background: linear-gradient(211deg, #14204e, #14204e);
  border-radius: 50%;
  content: "";
  -webkit-filter: blur(60px);
  filter: blur(60px);
  height: 650px;
  position: absolute;
  right: -400px;
  top: -400px;
  width: 650px;
  z-index: 0;
}
.sec_8_content:after {
  background: linear-gradient(211deg, #2a63a3, #2a63a3);
  border-radius: 50% 80% 0 0;
  bottom: -450px;
  content: "";
  -webkit-filter: blur(80px);
  filter: blur(80px);
  height: 650px;
  left: -450px;
  position: absolute;
  width: 650px;
  z-index: 0;
}

/* bike images css */

.sec_8_content .container_about_us h2 {
  color: #fff;
  font-size: 54px;
  font-family: Lato, sans-serif;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: 5.4px;
  text-transform: uppercase;
  margin-bottom: 80px;
  text-align: center;
}

.sec_8_content .container_about_us .image_box_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  column-gap: 30px;
}

.sec_8_content .container_about_us .image_box_wrapper .image_box_list1 {
  /* margin: 0px 30px 0px 30px; */
  width: 100%;
}

/* images name */

.image_box_list1 .bikes_type {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.image_box_wrapper .image_box_list1 .bikes_type .bike_name1 {
  text-align: center;
  min-height: 155px;
}

/* .image_box_wrapper .image_box_list1 .bikes_type .bike_name1 {
    text-align: right;
} */

.image_box_wrapper .image_box_list1 .bike_name2 p.hop_leo,
.image_box_wrapper .image_box_list1 .bike_name1 p.hop_lf {
  color: #fff;
  font-size: 26px;
  font-family: Lato;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  background: linear-gradient(rgba(24, 98, 161, 1), rgba(0, 234, 255, 1));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 5px;
}
.image_box_wrapper .image_box_list1 .bike_name1 p.hop_lf::selection {
  -webkit-text-fill-color: #fff;
}

.image_box_wrapper .image_box_list1 .bike_name1 p.lfe_desc,
.image_box_wrapper .image_box_list1 .bike_name2 p.leo_desc {
  color: #fff;
  font-size: 18px;
  line-height: 26px;
}

/* oxo */

.image_box_wrapper .image_box_list1 figure {
  margin: 0;
  padding: 0 15px;
}

.image_box_wrapper .image_box_list1 figure img {
  width: 100%;
  min-height: 375px;
  object-fit: fill;
}

.image_box_wrapper .image_box_list1 .bike_name1 {
  text-align: center;
  /* margin-bottom: 30px; */
}

.image_box_wrapper .image_box_list1 .bike_name1 p.hop_ox {
  color: #fff;
  font-size: 26px;
  font-family: Lato;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  background: linear-gradient(rgba(24, 98, 161, 1), rgba(0, 234, 255, 1));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 5px;
}

.selection_background::selection {
  -webkit-text-fill-color: #fff !important;
}

.image_box_wrapper .image_box_list1 .bike_name1 p.hop_ox::selection {
  -webkit-text-fill-color: #fff;
}

.image_box_wrapper .image_box_list1 .bike_name1 p.ox_desc {
  color: #fff;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 1px;
  font-weight: 400;
}

/* media query */

@media screen and (max-width: 1400px) {
  .sec_8_content .container_about_us .image_box_wrapper {
    align-items: flex-end;
  }
  .image_box_wrapper .image_box_list1 figure {
    padding: 0 15px;
    height: 290px;
  }
  .image_box_wrapper .image_box_list1 figure img {
    width: 100%;
    min-height: auto;
    object-fit: fill;
    height: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .image_box_wrapper .image_box_list1 .bike_name2 p.hop_leo,
  .image_box_wrapper .image_box_list1 .bike_name1 p.hop_lf,
  .image_box_wrapper .image_box_list1 .bike_name1 p.hop_ox {
    font-size: 24px;
  }
  .image_box_list1 .bikes_type {
    margin-bottom: 10px;
  }
  .image_box_wrapper .image_box_list1 .bike_name1 p.lfe_desc,
  .image_box_wrapper .image_box_list1 .bike_name2 p.leo_desc {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .image_box_wrapper .image_box_list1 .bike_name1 p.ox_desc {
    font-size: 18px;
  }
}
@media screen and (max-width: 991px) {
  .image_box_wrapper .image_box_list1 .bike_name2 p.hop_leo,
  .image_box_wrapper .image_box_list1 .bike_name1 p.hop_lf,
  .image_box_wrapper .image_box_list1 .bike_name1 p.hop_ox {
    font-size: 20px;
    line-height: normal;
  }
  .image_box_wrapper .image_box_list1 .bike_name1 p.lfe_desc, .image_box_wrapper .image_box_list1 .bike_name2 p.leo_desc,
  .image_box_wrapper .image_box_list1 .bike_name1 p.ox_desc{
    font-size: 16px;
  }
  .image_box_wrapper .image_box_list1 figure{
    height: 200px;
    margin-top: 20px !important;
  }
  .sec_8_content .container_about_us .image_box_wrapper{
    gap: 10px;
  }
  .image_box_wrapper .image_box_list1 .bikes_type .bike_name1{
    min-height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .sec_8_content .container_about_us {
    padding: 50px 16px;
    width: 100%;
  }

  .sec_8_content .container_about_us h2 {
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 36px;
  }

  .sec_8_content:before,
  .sec_8_content:after {
    height: 530px;
    width: 600px;
  }

  .sec_8_content .container_about_us .image_box_wrapper {
    display: block;
  }

  .sec_8_content .container_about_us .image_box_wrapper .image_box_list1 {
    margin-bottom: 40px;
    margin-left: 0;
    margin-right: 0;
  }

  .image_box_wrapper .image_box_list1 .bikes_type .bike_name2 p.hop_leo,
  .image_box_wrapper .image_box_list1 .bikes_type .bike_name1 p.hop_lf,
  .image_box_wrapper .image_box_list1 .bike_name1 p.hop_ox {
    font-size: 20px;
    line-height: 30px;
  }

  .image_box_wrapper .image_box_list1 .bikes_type .bike_name1 p.lfe_desc,
  .image_box_wrapper .image_box_list1 .bikes_type .bike_name2 p.leo_desc,
  .image_box_wrapper .image_box_list1 .bike_name1 p.ox_desc {
    font-size: 16px;
    line-height: 24px;
    margin: 0px;
  }
  .image_box_wrapper .image_box_list1 figure{
    min-height: 300px;
  }
  .image_box_wrapper .image_box_list1 figure img {
    margin-bottom: 8px;
    object-fit: contain;
  }

  .image_box_wrapper .image_box_list1 .bikes_type .bike_name1 {
    min-height: 50px;
  }
}
