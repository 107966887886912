
.smart_energy_sec {
    background-color: #fff;
    font-family: "Poppins", sans-serif;
}


.smart_energy_sec .home_img_main {
    height: auto;
}

.smart_energy_sec .home_img_main img {
    width: 100%;
    max-width: 1921px;
    object-fit: cover;
}

.smart_energy_sec2 {
    padding: 50px 15px 50px 15px;
    background-color: transparent;
    background-image: linear-gradient(180deg, #17346E 0%, #3780F2 100%);
    margin-top: -10px;
    text-align: center;
    font-family: "Poppins", sans-serif;
}

.smart_energy_sec2 .swap_outside figure {
    max-width: 30%;
    margin: 0px auto !important;
}

.smart_energy_sec2 .swap_outside .content_samrtenergy h2 {
    margin-bottom: 26px;
    color: #FFFFFF;
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
    margin-top: 5px;
}

.smart_energy_sec2 .swap_outside .content_samrtenergy p {
    color: #FFFFFF;
    text-align: center;
}

.fully_charge_sec .fully_container {
    max-width: 1160px;
    padding: 0px 10px;
    margin: 0px auto;
    text-align: center;
    padding: 70px;
}

.fully_charge_sec h2 {
    font-size: 48px;
    font-weight: 400;
    line-height: 62px;
    color: rgba(51, 51, 51);
}

.fully_charge_sec p {
    color: #7a7a7a;
}

.content_box_smart {
    display: flex;
    max-width: 1140px;
    margin: 0px auto;
    padding: 30px 0px;
}

.content_box_smart .boxes_container {
    width: calc(20% - 50px);
    background-image: radial-gradient(#2073c9, #00356b);
    border-radius: 15px;
    padding: 15px 10px;
    -webkit-transition: all 0.3s ease;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.content_box_smart .boxes_container:hover {
    box-shadow: 0 0px 15px #000;
}

.content_box_smart .boxes_container .content h5 {
    margin-bottom: 20px;
}

.content_box_smart .boxes_container .content h5,
.content_box_smart .boxes_container .content p {
    font-size: 16px;
    line-height: 27px;
    font-weight: 400;
    color: #fff;
}

.box_bottom_content {
    text-align: center;
}

.box_bottom_content p {
    font-size: 20px;
    line-height: 34px;
    font-weight: 400;
}

.box_bottom_content p span {
    font-size: 17px;
}

.hop_tree_sec img {
    width: 100%;
}

.Battery_Features_sec {
    margin-top: 40px;
    max-width: 1140px;
    margin: 0px auto;
    font-family: "Poppins", sans-serif;
    padding: 50px 10px;
}

.Battery_Features_sec .upper_header {
    margin-bottom: 40px;
    text-align: center;
}

.Battery_Features_sec .upper_header h2 {
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    color: rgba(51, 51, 51);
}

.Battery_Features_sec .upper_header p {
    color: rgba(122, 122, 122);
    font-size: 16px;
    font-weight: 400;
}

.Battery_Features_sec .battery_body {
    display: flex;
}

.Battery_Features_sec .battery_body .body_sec1 {
    width: 50%;
    padding: 20px;
}

.Battery_Features_sec .battery_body .body_sec2 {
    width: 50%;
    padding: 20px;
}

.Battery_Features_sec .battery_body .body_sec1 .content_1,
.Battery_Features_sec .battery_body .body_sec2 .content_1 {
    border: 1px solid #CCC;
    text-align: center;
    padding: 15px;
    border-radius: 10px;
}

.Battery_Features_sec .battery_body hr {
    border: 1px solid #ccc;
    margin: 20px 15px;
}

.Battery_Features_sec .battery_body .battery_cont1 h4 {
    font-size: 16px;
    font-weight: 400;
    color: #08417d;
    margin-bottom: 10px;
}

.Battery_Features_sec .battery_body .battery_cont1 p,
.Battery_Features_sec .battery_body .battery_cont2 p {
    font-size: 16px;
    font-weight: 400;
    color: rgba(122, 122, 122);
}

.battery_img_sec {
    display: flex;
}

.battery_img_sec .battery_img {
    width: 33.33%;
}

.battery_img_sec .battery_cont {
    align-self: center;
    padding-left: 50px;
    width: 66.66%;
}

.battery_img_sec .battery_cont p {
    color: #08417d;
}

.battery_footer {
    font-family: "Poppins", sans-serif;
}

.latest_news {
    padding: 90px 0px;
    text-align: center;
}

.latest_news h2 {
    font-size: 2rem;
    font-weight: 500;
}

.latest_news p {
    margin-top: 20px;
}

.latest_news p a {
    font-size: 20px;
    letter-spacing: 3px !important;
    color: #09417d;
    text-decoration: none;
    border-bottom: 1px solid #09417d;
    padding-bottom: 6px;
}

.latest_news p a:hover {
    opacity: 0.8;
}

.smart_energy_sec2 figure img {
    width: 100%;
}

@media screen and (max-width:767px) {
    .smart_energy_sec2 .swap_outside .content_samrtenergy h2 {
        font-size: 28px;
    }

    .fully_charge_sec .fully_container {
        padding: 30px;
    }

    .fully_charge_sec h2 {
        font-size: 36px;
        line-height: 46px;
        margin-bottom: 20px;
    }

    .content_box_smart {
        display: block;
        padding: 20px;

    }

    .content_box_smart .boxes_container {
        width: auto;
        margin: 15px 0px;
    }
    .box_bottom_content p span{
        font-size: 16px;
        line-height: normal;
    }
    .Battery_Features_sec .upper_header h2{
        font-size: 30px;
        margin-bottom: 15px;
    }
    .Battery_Features_sec{
        padding: 15px;
    }
    .Battery_Features_sec .battery_body{
        display: block;
    }
    .Battery_Features_sec .battery_body .body_sec1{
        width: auto;
        margin-bottom: 20px;
    }
    .Battery_Features_sec .battery_body .body_sec2{
        width: auto;
    }
    .battery_img_sec{
        display: block;
    }
    .battery_img_sec .battery_img{
        width: auto;
    }
    .battery_img_sec .battery_img img{
        width: 100%;
    }
    .battery_img_sec .battery_cont{
        padding-left: 0px;
        width: auto;
    }

}
