/* section 9 css */

.sec_9_content{
    background-color: #0a0a0a;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.sec_9_content .container_about_us{
    max-width: 1440px;
    padding: 76px 140px 76px 140px;
    margin: 0px auto;
}

.sec_9_content .container_about_us h2 {
    color: #FFF;
    font-size: 54px;
    font-family: Lato, sans-serif;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 5.4px;
    text-transform: uppercase;
    margin-bottom: 80px;
    text-align: center;
}


/* images css start */

.sec_9_content ul.team-list.team-row3{
    margin-bottom: 0;
    flex-wrap: inherit;
}

.sec_9_content ul.team-list {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    margin-bottom: 40px;
}

.sec_9_content ul.team-list li.team-item {
    text-align: center;
    padding: 0px 20px;
}


ul.team-list li.team-item figure img {
    width: 100%;
    max-width: 140px;
    margin: 0px auto;
    min-height: 140px;
    object-fit: cover;
}

p.member-name {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    background: linear-gradient(rgba(24, 98, 161, 1), rgba(0, 234, 255, 1));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    margin-bottom: 8px;
}

ul.team-list li.team-item figure {
    margin-bottom: 10px !important;
}

ul.team-list li.team-item p.member-degi {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    background: linear-gradient(rgba(24, 98, 161, 1), rgba(0, 234, 255, 1));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    margin: 0px;
}


.sec_9_content ul.team-list.team-row2 li.team-item {
    width: calc(20% - 40px);
    padding: 0px;
}

.sec_9_content ul.team-list.team-row3 li.team-item {
    width: calc(20% - 40px);
    padding: 0px;
}


/* media query */

@media screen and (max-width:1200px) {
    .sec_9_content ul.team-list.team-row3{
        flex-wrap: wrap;
    }
}

@media screen and (max-width:991px) {
    .sec_9_content ul.team-list.team-row2 li.team-item,
    .sec_9_content ul.team-list.team-row3 li.team-item{
        width: 25%;
    }
}

@media only screen and (max-width: 767px){
    .sec_9_content ul.team-list.team-row2 li.team-item, 
    .sec_9_content ul.team-list.team-row3 li.team-item {
        width: 100%;
    }

    .sec_9_content ul.team-list.team-row3{
        flex-wrap: wrap;
    }
    .sec_9_content .container_about_us h2{
        margin-bottom: 40px;
        font-size: 40px;
    }
}
