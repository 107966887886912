.user_dashboardmain {
  background: #151a2a;
  overflow: hidden;
  height: 100vh;
  scroll-snap-align: start;
}

.user_dashboardmain .dashboardmain {
  height: 100%;
}

.dashboardmain .dashboard_header {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  background: #1f263c;
  padding: 12px 15px;
  box-shadow: 0px 4px 5px 0px #1a1f30;
  z-index: 3;
  position: relative;
}

.dashboardmain .dashboard_header .dash_logo {
  width: 265px;
}

.dashboardmain .dashboard_header .haeder_rigth {
  width: calc(100% - 265px);
  padding: 0px 15px;
}

.dashboardmain .dashboard_header .user_login {
  width: 100%;
}

.dashboard_sec .dashboard_menu_left::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); */
  background-color: #181d31;
  border-radius: 10px;
}

.dashboard_sec .dashboard_menu_left::-webkit-scrollbar {
  width: 5px;
  background-color: #181d31;
  border-radius: 10px;
}

.dashboard_sec .dashboard_menu_left::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #111422;
}

.menu_list ul .dropdown_menu {
  position: relative;
}

.menu_list ul .dropdown_menu .bottom_show {
  position: absolute;
  right: 18px;
  top: 15px;
  width: 10px;
  transition: all 0.3s;
}

.menu_list ul .arrow_show .bottom_show {
  transform: rotate(90deg);
  transition: all 0.3s;
}

.dashboard_header .haeder_rigth .user_login {
  display: flex;
  align-items: center;
  justify-content: end;
}

.dashboard_header .haeder_rigth .user_login figure img {
  border-radius: 50%;
  width: 40px;
}

.dashboard_header .haeder_rigth .user_login span {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  margin-left: 10px;
  font-family: "Lato", sans-serif;
}

.dashboard_sec {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  height: 100%;
  padding-bottom: 70px;
}

.dashboard_sec .dashboard_menu_left {
  width: 280px;
  background: #1f263c;
  height: 100%;
  overflow-y: auto;
}

.dashboard_sec .dashboard_right {
  width: calc(100% - 280px);
  overflow-y: auto;
  height: 100%;
}

.dashboard_sec .dashboard_right .header {
  display: none;
}

.dashboard_sec .dashboard_tosec {
  padding: 30px 0px;
}

.dashboard_menu_left .dash_logo {
  padding: 15px 15px;
  border-bottom: 1px solid #3b3b3b;
}

.dashboard_menu_left .menu_list {
  padding: 0px;
}

.menu_list ul li a {
  font-family: "Lato", sans-serif;
  color: #fff;
  font-size: 18px;
  text-transform: capitalize;
  padding: 10px 15px;
  display: block;
  border-bottom: 1px solid #15192a;
  transition: all 0.3s;
}

.menu_list ul li a:hover {
  color: #00eaff;
}

.menu_list ul li .active {
  color: #00eaff;
}

.dropdown_menu .drop_menu {
  transition: all 0.3s;
  background: #252c3f;
}

.dashboardmain .dashboard_header .dash_logo img {
  width: 60px;
}

/* table- start */

.user_info_table {
  width: 100%;
  font-family: "Lato", sans-serif;
  background: #1f263c;
  border-radius: 10px;
  margin-bottom: 10px;
}

.user_info_table thead tr th {
  color: #fff;
  padding: 13px 15px;
  text-align: left;
  width: 25%;
  font-size: 16px;
  text-transform: uppercase;
  background: #252c3f;
}

.five_sec thead tr th {
  width: 20%;
}

.user_info_table thead tr th:first-child {
  border-radius: 10px 0px 0px 0px;
}

.user_info_table thead tr th:last-child {
  border-radius: 0px 10px 0px 0px;
}

.user_info_table tbody tr td {
  color: #ddd;
  padding: 7px 15px;
  text-align: left;
  width: 25%;
}

.icon_sec button.cancel_btn {
  margin-right: 40px;
}

.icon_sec button.cancel_btn:last-child {
  margin-right: 0px !important;
}

.icon_sec button.cancel_btn img {
  border: 1px solid #444e6c;
  padding: 4px;
  border-radius: 2px;
  transition: all 0.3s;
}

.icon_sec button.cancel_btn img:hover {
  border: 1px solid #00eaff;
}

.five_sec tbody tr td {
  width: 20%;
}

.five_sec .service_de {
  padding: 0px 0px !important;
}

.user_btn {
  /* background: #00EAFF;
    padding: 8px 8px; */
  width: calc(50% - 10px);
  /* border-radius: 5px;
    color: #000; */
  margin: 0px 5px;
  /* text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.5px;
    border: 1px solid #00EAFF;
    display: inline-block;
    font-size: 14px; */
}

.user_btn:hover {
  background: #fff;
  color: #000;
  border: 1px solid #00eaff;
}

.dashboard_tosec.dashboard_right::before {
  content: " ";
  background-image: url(../../assets/assets/images/dash_bg.jpg);
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  background-position: center;
}

.dashboard_sec .dashboard_right .active_rides {
  padding: 40px 15px;
  background: rgb(0 0 0 / 35%);
  height: 100%;
}

.dashboard_sec .dashboard_right {
  padding: 0;
  position: relative;
  z-index: 2;
}
.dashboardmain .mobile_menu_respon{
  cursor: pointer;
}
.coming_main h4 {
  color: #00eaff;
  font-size: 34px;
  letter-spacing: 0.5px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: left;
}

.dashboard_heading h2 {
  color: #00eaff;
  font-size: 26px;
  letter-spacing: 0.5px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  /* margin-bottom: 20px; */
}

.user_info_table thead {
  border-bottom: 1px solid #15192a;
}

/* .user_info_table tbody tr {
    border-bottom: 1px solid #15192a;
} */

.menu_mobile {
  display: none;
}

.mobile_menu_respon .menu_list {
  display: none;
}

.user_info_table .cancel_btnsec {
  padding: 8px 8px;
  width: calc(50% - 10px);
  display: inline-block;
  text-align: center;
}

.user_info_table .cancel_btnsec .cancel_btn {
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  margin: 0px 5px;
  transition: all 0.3s;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 14px;
  transition: all 0.3s;
}

.user_info_table .cancel_btnsec .cancel_btn:hover {
  color: #00eaff;
}

.border_btn {
  background: transparent;
  color: #fff;
}

.reschedule_infobtn .border_btn:hover {
  background: #00eaff !important;
  color: #000 !important;
}

.reschedule_infobtn .user_btn:hover {
  background: transparent;
  color: #fff;
}

.reschedule_info {
  max-width: 750px;
  margin: auto;
  border-radius: 10px;
  padding: 10px 15px 10px;
}

.reschedule_infobtn .user_btn {
  padding: 15px 10px;
  font-size: 16px;
  /* margin-bottom: 20px !important; */
  border-radius: 8px;
  display: block;
  margin: auto;
  background-color: #00eaff;
  color: 000;
  border: 1px solid #00eaff;
  font-weight: 500;
}

.reschedule_infobtn .border_btn {
  background-color: transparent;
}

.reschedule_info p {
  font-family: "Lato";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgb(255 255 255 / 80%);
  margin-bottom: 15px;
}

.loc_add li strong {
  font-size: 20px;
  font-family: "Raleway", sans-serif;
  color: #00eaff;
  margin-bottom: 5px;
  display: block;
}

.loc_add li {
  margin-bottom: 25px;
}

.loca_info h2 {
  line-height: normal !important;
  margin-bottom: 30px !important;
}

.loca_info .loc_add p {
  font-weight: 600;
}

.active_req li p {
  font-size: 18px;
  font-weight: 500 !important;
}

.coming_sec {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.coming_sec .coming_main {
  width: 40%;
  text-align: center;
}

.das_showlink ul li {
  text-align: center;
}

.coming_sec .upcoming_sec {
  background: rgb(31 38 60 / 90%);
  border-radius: 10px;
  padding: 30px 20px;
  border: 1px solid #4c4c4c;
  /* height: calc(100vh - 325px); */
  height: auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dasboard_pop_p .up_com_cent > p:nth-child(1){
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.headin_hight.up_testride .upcoming_sec {
  /* height: calc(100vh - 390px); */
  height: auto;
  display: flex;
  align-items: center;
}

.coming_sec .upcoming_sec .up_com_cent {
  padding: 20px 0px;
}

.coming_sec .upcoming_sec .up_com_cent::-webkit-scrollbar {
  display: none;
}

.upcoming_sec .up_com_cent h4 {
  font-size: 30px;
  text-transform: capitalize;
  font-weight: 500;
  margin-bottom: 15px;
  text-align: center;
}

.upcoming_sec h4 {
  font-size: 22px;
  color: #00eaff;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
  font-weight: 600;
}

.upcoming_sec p {
  color: #fff;
  font-size: 25px;
  font-family: "Lato", sans-serif;
  margin-bottom: 20px;
}
.dasboard_pop_p

.coming_sec .upcoming_sec .add_up {
  color: rgb(215, 215, 215);
  font-size: 19px;
  font-family: "Lato", sans-serif;
  margin-bottom: 20px;
}

.upcoming_sec ul {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px !important;
  max-width: 90%;
  /* max-width: 300px; */
  padding-left: 0px;
  text-align: left;
  margin: 0px auto;
}

.coming_main .upcoming_sec ul li {
  font-family: "Lato", sans-serif;
  color: #fff;
  font-size: 18px;
  margin-bottom: 10px;
}

.coming_main .upcoming_Detail ul li {
  width: 100%;
}

.upcoming_Detail .up_com_cent p span {
  display: block;
  font-size: 30px;
}

.coming_main .upcoming_sec ul li span {
  font-size: 20px;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #00eaff;
  font-weight: 500;
  /* display: block; */
  margin-right: 5px;
}

.coming_sec .upcoming_sec .event_btn .default_btn {
  font-size: 18px;
  padding: 15px 15px;
  border-radius: 10px;
  width: 200px;
}

.coming_slider .slick-arrow.slick-next {
  margin-right: 8px;
}

.coming_slider .slick-arrow.slick-prev {
  margin-left: 8px;
}

.coming_sec .upcoming_sec .slick-dots li button::before {
  color: #fff;
  font-size: 10px;
}

.userdata_show {
  color: #00eaff;
  font-size: 26px;
  letter-spacing: 0.5px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
}

.recent_acti .order_co {
  color: #00eaff;
}

.coming_sec .upcoming_sec .slick-dots {
  bottom: 0px;
}

.das_showlink {
  margin: 30px 0px 0px;
}

.das_showlink ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

.das_showlink ul li button {
  font-size: 18px;
  padding: 15px 15px;
  border-radius: 10px;
  display: block;
  margin: auto auto 15px auto;
  width: 200px;
}

.das_showlink ul li button:last-child {
  margin-bottom: 0px;
}

.upcoming_sec p span {
  color: #00eaff;
}

.coming_slider .event_detail {
  position: relative;
  padding: 80px 30px;
  background: rgb(0 0 0 / 25%);
}

.coming_slider .event_detail ul {
  display: block;
}

.coming_slider .event_detail::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/event_bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.4s ease-out;
  z-index: -1;
  background-size: cover;
  border-radius: 10px;
}

.coming_sec .upcoming_sec.coming_slider {
  padding: 0px;
  width: 480px;
}

.coming_sec .coming_slider .eventdetail_popup .pure-modal-backdrop {
  position: relative;
}

.wWelcomeuser_sec .eventdetail_popup .pure-modal-backdrop::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/event_bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.4s ease-out;
  z-index: -1;
  background-size: cover;
  border-radius: 10px;
  filter: blur(0px) !important;
}

.wWelcomeuser_sec .eventdetail_popup .pure-modal-backdrop {
  max-width: 80%;
  left: 0;
}

.filter_sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.coming_sec .upcoming_sec .event_btn {
  text-align: center;
}

.data_filter .data_table {
  background: #1f263c;
  border-radius: 6px;
  padding: 8px 10px;
  border: 1px solid #4c4c4c;
  color: #fff;
  outline: none;
}

.close_btn .close {
  top: 30px !important;
  right: 30px !important;
  width: 50px;
  height: 50px;
  background: rgba(0, 234, 255, 0.1) !important;
  border: 1px solid #00eaff;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  border-radius: 50px;
  padding: 6px;
}

.dasboard_cancel .pure-modal-backdrop {
  /* left: 0% !important;
    margin: 0 auto; */
  left: calc(50% - 160px) !important;
}

.dasboard_cancel.new_activity_icon .pure-modal-backdrop {
  left: calc(50% - 250px) !important;
}

.service_detaildash .pure-modal-backdrop {
  width: 80% !important;
}

.service_main {
  border: 1px solid #424649;
  border-radius: 5px;
  background: linear-gradient(
    114.11deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.25) 100%
  );
}

.service_ifo {
  margin: auto;
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid rgb(85, 85, 85);
  padding: 10px 15px;
}

.first_secserv h3 {
  margin-bottom: 15px;
}

.service_ifo .service_ifo_cont {
  margin-top: 10px;
  cursor: auto;
}

.service_ifo .reschedule_infobtn {
  margin-bottom: 15px;
}

.service_ifo .arrow_ser {
  position: absolute;
  top: 20px;
  width: 20px;
}

.service_ifo h3 {
  margin-bottom: 0px;
}

.service_ifo p {
  font-size: 18px;
}

.customer_sec .arrow_ser {
  left: 20px;
}

.customer_sec {
  text-align: right;
}

.customer_sec p {
  text-align: right;
}

.hop_sec .arrow_ser {
  right: 20px;
}

.hop_sec {
  text-align: left;
}

.hop_sec p {
  text-align: left;
}

.service_ifo .reschedule_infobtn button.user_btn {
  margin-bottom: 0 !important;
  padding: 8px 40px;
  width: auto;
}

.first_secserv h3 {
  border: none;
  cursor: auto;
}

.service_main .service_ifo:last-child {
  border: none;
}

.service_main .service_ifo:second-last-child {
  border: none;
}

.first_secserv h3 {
  text-align: center;
  text-transform: uppercase;
  color: #00eaff;
}

.first_secserv p {
  text-align: center;
}

.received_ord .upcoming_Detail {
  /* height: calc(100vh - 255px); */
  height: auto !important;
  padding: 30px 20px !important;
}

.received_ord .upcoming_Detail .max_dash {
  /* max-width: 330px; */
  max-width: 90%;
}
.dashboard_sec_pop{
  text-align: center !important;
}

.received_ord .upcoming_Detail .del_addre {
  font-size: 18px;
  margin-bottom: 10px;
}

.received_ord .upcoming_Detail .del_addre span {
  font-size: 20px;
  text-transform: uppercase;
}

.request_subt .max_dash {
  max-width: 190px;
}

.service_content .max_dash {
  max-width: 90% !important;
}

.user_info_table tbody tr td span {
  display: none;
}

.coming_main .upcoming_Detail ul li a:hover {
  color: #00eaff;
}

#cancel_testride.singleBook .popup_cont ul {
  list-style-type: none;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
}

#cancel_testride.singleBook .popup_cont ul li {
  list-style-type: none;
  width: 50%;
  margin-bottom: 10px;
  text-align: left;
  font-size: 16px;
}

#cancel_testride.singleBook .popup_cont ul li strong {
  font-weight: 500;
  color: #00eaff;
}

#cancel_testride.singleBook .popup_cont ul li:nth-child(odd) {
  padding-right: 7.5px;
}

#cancel_testride.singleBook .popup_cont ul li:nth-child(even) {
  padding-left: 7.5px;
}

.singleBook.modal_popup.test_ride_popup .pure-modal-backdrop .pure-modal {
  padding: 20px 15px 20px;
  /* padding: 50px 15px 20px; */
}

.recent_acti .icon_sec button.cancel_btn {
  margin-right: 20px;
}

.testride_righsec .select_dealer .icon_sec {
  /* padding: 0px 20px; */
  justify-content: center !important;
  gap: 10px !important;
}

.testride_righsec .select_dealer .icon_sec > div {
  color: #fff;
}

.modal_popup.test_ride_popup .dissmiss {
  display: none;
}
.webPOP_testride .map_left.testride_map {
  display: block !important;
  width: 100% !important;
  height: 300px;
}
.dasboard_cancel .reschedule_info .reschedule_infobtn {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* ======================= RESPONSIVE START ========================= */
@media only screen and (max-width: 1680px) {
  .upcoming_sec p {
    margin-bottom: 15px;
  }

  .coming_sec .upcoming_sec .add_up {
    margin-bottom: 15px;
  }

  .upcoming_sec ul {
    margin-bottom: 30px;
  }

  .das_showlink ul li button {
    font-size: 16px;
    padding: 12px 20px;
  }

  .coming_sec .upcoming_sec .event_btn .default_btn {
    font-size: 16px;
    padding: 12px 20px;
  }

  .upcoming_sec p {
    font-size: 22px;
  }

  .upcoming_Detail .up_com_cent p span {
    font-size: 26px;
  }
}

@media only screen and (max-width: 1440px) {
  .icon_sec .approx {
    width: 100%;
  }

  .testride_righsec .select_dealer .icon_sec {
    flex-wrap: wrap;
  }

  /* .user_info_table thead tr th:nth-child(1) {
        width: 20%;
    }

    .user_info_table thead tr th:nth-child(2) {
        width: 20%;
    }

    .user_info_table thead tr th:nth-child(3) {
        width: 20%;
    }

    .user_info_table thead tr th:nth-child(4) {
        width: 40%;
    }

    .user_info_table tbody tr td:nth-child(1) {
        width: 20%;
    }

    .user_info_table tbody tr td:nth-child(2) {
        width: 20%;
    }

    .user_info_table tbody tr td:nth-child(3) {
        width: 20%;
    }

    .user_info_table tbody tr td:nth-child(4) {
        width: 40%;
    } */

  .coming_slider .event_detail {
    padding: 50px 30px;
  }

  .upcoming_sec p {
    font-size: 22px;
  }

  .coming_sec .coming_main {
    width: 50%;
  }

  .upcoming_sec p {
    margin-bottom: 15px;
  }

  .coming_sec .upcoming_sec .add_up {
    margin-bottom: 15px;
  }

  .upcoming_sec ul {
    margin-bottom: 30px;
  }

  .das_showlink ul li button {
    font-size: 16px;
    padding: 10px 20px;
  }

  .coming_sec .upcoming_sec .event_btn .default_btn {
    font-size: 16px;
    padding: 10px 20px;
  }

  .upcoming_sec p {
    font-size: 20px;
  }

  .upcoming_Detail .up_com_cent p span {
    font-size: 24px;
  }

  .upcoming_sec .up_com_cent h4 {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .dashboard_sec .dashboard_right .active_rides {
    padding: 30px 15px;
  }

  .coming_sec .upcoming_sec {
    height: calc(100vh - 290px);
  }

  .headin_hight.up_testride .upcoming_sec {
    height: calc(100vh - 350px);
    align-items: start;
    overflow: auto;
  }

  .received_ord .upcoming_Detail {
    height: calc(100vh - 220px);
  }

  .icon_sec button.cancel_btn {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 1199px) {
  .dashboard_sec .dashboard_menu_left {
    width: 230px;
  }

  .dashboard_sec .dashboard_right {
    width: calc(100% - 230px);
  }

  .dasboard_cancel .pure-modal-backdrop {
    left: calc(50% - 185px) !important;
  }

  .menu_list ul li a {
    font-size: 16px;
  }

  .coming_main h4 {
    font-size: 24px;
  }

  .userdata_show {
    font-size: 24px;
  }

  .dashboard_heading h2 {
    font-size: 22px;
    margin-bottom: 20px;
  }

  /* .dashboard_sec .dashboard_right {
        padding: 40px 25px;
    } */

  .user_btn {
    letter-spacing: 0;
  }

  .coming_sec .upcoming_sec {
    /* width: 280px; */
    padding: 15px 15px;
  }

  .coming_sec .upcoming_sec.coming_slider {
    width: 380px;
  }

  .upcoming_sec h4 {
    font-size: 20px;
    margin-bottom: 3px;
  }

  .upcoming_sec ul li {
    font-size: 16px;
  }

  .wWelcomeuser_sec .eventdetail_popup .pure-modal-backdrop {
    max-width: 90%;
  }

  .service_ifo p span {
    display: inline-block;
  }

  .coming_sec .coming_main {
    width: 60%;
  }

  .headin_hight.up_testride .upcoming_sec {
    /* height: calc(100vh - 330px); */
    height: auto;
  }
}

@media only screen and (max-width: 991px) {
  .dasboard_cancel .pure-modal-backdrop {
    left: calc(50% - 200px) !important;
  }

  .reschedule_infobtn .user_btn {
    width: calc(100% - 10px);
  }

  .dashboard_sec .dashboard_menu_left {
    width: 200px;
  }

  .dashboard_sec .dashboard_right {
    width: calc(100% - 200px);
  }

  .dashboard_heading h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .user_btn {
    letter-spacing: 0;
    font-size: 14px;
    padding: 8px 5px;
  }

  .user_info_table tbody tr td {
    padding: 5px 15px;
  }

  .coming_main {
    margin-right: 15px;
  }

  .service_ifo {
    width: auto;
  }

  .coming_main .upcoming_sec ul li {
    font-size: 16px;
  }

  .coming_main .upcoming_sec ul li span {
    font-size: 18px;
  }

  .dashboard_sec .dashboard_right .active_rides {
    height: auto;
  }

  .active_sec .dasboard_cancel .pure-modal-backdrop {
    top: 55% !important;
  }
}

@media only screen and (max-width: 991px) {
  .user_dashboardmain {
    height: auto;
    scroll-snap-align: inherit;
  }

  .dashboard_sec {
    padding-bottom: 0px;
  }

  .menu_mobile {
    display: block;
    margin-left: 5px;
  }

  .menu_mobile span {
    position: relative;
  }

  .menu_mobile span {
    width: 36px;
    height: 3px;
    border-radius: 2px;
    background: #fff;
    margin: 6px;
    display: block;
    transition: all 0.3s;
  }

  .dashboardmain .dashboard_header .haeder_rigth {
    justify-content: end;
    display: flex;
    align-items: center;
    padding-right: 0;
  }

  .dashboardmain .dashboard_header {
    padding: 10px 15px;
  }

  .dashboardmain .dashboard_header .dash_logo {
    width: 30%;
  }

  .dashboardmain .dashboard_header .haeder_rigth {
    width: 70%;
    padding-left: 5px;
  }

  .dashboard_sec .dashboard_menu_left {
    display: none;
  }

  .dashboard_sec .dashboard_right {
    width: 100%;
  }

  .mobile_menu_respon .menu_list {
    display: block;
    width: 100%;
    z-index: 9;
    height: 100vh;
    background: rgb(31 38 60 / 40%);
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    display: block;
    position: fixed;
    right: -100%;
    top: 82px;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    margin: 0;
    border: 0;
    border-radius: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .menu_responsive.mobile_menu_respon .menu_list {
    right: 0;
    -webkit-transition: right 0.4s ease;
    -moz-transition: right 0.4s ease;
    -ms-transition: right 0.4s ease;
    -o-transition: right 0.4s ease;
    transition: right 0.4s ease;
    width: 65%;
    margin: auto;
  }

  .mobile_menu_respon .menu_list ul {
    height: 100%;
  }

  .menu_responsive.mobile_menu_respon .menu_mobile span:nth-child(1) {
    transform: rotate(45deg);
    margin-bottom: -4px;
  }

  .menu_responsive.mobile_menu_respon .menu_mobile span:nth-child(2) {
    display: none;
  }

  .menu_responsive.mobile_menu_respon .menu_mobile span:nth-child(3) {
    transform: rotate(-45deg);
    margin-top: -2px;
  }

  /* .dashboard_sec .dashboard_right {
        padding: 30px 15px;
    } */

  .dashboard_header .haeder_rigth .user_login span {
    margin-right: 10px;
  }

  .dashboard_header .haeder_rigth .user_login {
    flex-direction: row-reverse;
  }

  .userdata_show {
    font-size: 22px;
  }

  .upcoming_sec .up_com_cent h4 {
    font-size: 24px;
  }

  .headin_hight.up_testride .upcoming_sec,
  .headin_hight .upcoming_sec,
  .coming_sec .upcoming_sec {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .dashboard_sec .up_testride .up_com_cent h4,
  .dashboard_sec .up_testride .upcoming_sec ul,
  .dashboard_sec_pop{
    text-align: left !important;
    max-width: 100%;
  }
  .received_ord .upcoming_Detail .max_dash{
    max-width: 100%;
  }
  /* .my_book_modal.book_test .close{
        position: inherit !important;
    } */
  #booktest-ridepage .highlight {
    border: 1px solid #00eaff;
  }

  .booktr_slider .select_dealer {
    border: none;
  }

  .booktr_slider .select_dealer {
    padding: 12px 10px;
  }

  .testride_righsec .select_dealer .icon_sec {
    justify-content: left !important;
    gap: 0px !important;
    margin-bottom: 0px !important;
  }

  .service_content .max_dash {
    max-width: 100% !important;
  }

  .icon_sec .approx {
    width: inherit;
  }

  .info_tooltip {
    right: 35px !important;
    min-width: 110px;
    top: -4px !important;
  }

  .dasboard_cancel.new_activity_icon .pure-modal-backdrop {
    left: calc(50% - 350px) !important;
  }

  .dashboard_tosec.dashboard_right::before {
    content: " ";
    background-image: url(../../assets/assets/images/dash_bgMoB1.png);
  }

  .dashboard_sec .dashboard_right {
    height: 95vh;
    z-index: 2;
  }

  .dashboard_sec .dashboard_right .active_rides {
    height: 100%;
  }

  .dashboardmain .dashboard_header .dash_logo {
    width: 23%;
  }

  .dashboardmain .dashboard_header .haeder_rigth {
    width: 77%;
  }

  .coming_sec .coming_main {
    width: 100%;
    margin: 0;
    text-align: left;
    padding-bottom: 10px;
  }

  .coming_sec .upcoming_sec {
    height: auto;
    position: inherit;
  }

  .coming_sec .upcoming_sec .up_com_cent {
    position: inherit;
    transform: none;
    padding: 0px;
    text-align: left;
  }

  .coming_main .upcoming_sec ul li {
    margin-bottom: 10px;
  }

  .coming_main .upcoming_sec ul li span {
    font-size: 18px;
  }

  /* .das_showlink{
        margin: 30px 0px 0px;
    } */
  .das_showlink ul li {
    text-align: center;
  }

  .das_showlink ul li button {
    margin-bottom: 10px;
  }

  .das_showlink ul li button:last-child {
    margin-bottom: 0px;
  }

  .das_showlink {
    margin: 30px 0px 0px;
  }

  /* .dashboard_sec .dashboard_right .active_rides {
        width: 780px;
    } */

  .reschedule_info h2 {
    margin-bottom: 20px !important;
  }

  .reschedule_infobtn .user_btn {
    width: 100%;
    margin: 0px 0px 10px 0px;
  }

  .coming_slider .event_detail {
    padding: 40px 30px;
  }

  #cancel_testride .reschedule_info {
    padding-top: 0px;
  }

  .service_ifo {
    padding: 15px 15px;
  }

  .user_info_table thead {
    display: none;
  }

  .filter_sec {
    flex-wrap: wrap;
  }

  .filter_sec .dashboard_heading {
    margin-right: 10px;
  }

  .user_info_table tbody tr {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
  }

  .user_info_table tbody tr td {
    width: 100% !important;
    border-bottom: 1px solid #15192a;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .user_info_table tbody tr td:last-child {
    border-bottom: none;
  }

  .coming_sec .upcoming_sec .add_up {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .upcoming_Detail .up_com_cent p span {
    font-size: 22px;
  }

  .received_ord .upcoming_Detail .del_addre {
    font-size: 16px;
  }

  .received_ord .upcoming_Detail .del_addre span {
    font-size: 18px;
  }

  .reschedule_infobtn .user_btn {
    padding: 12px 10px;
  }

  .active_sec .dasboard_cancel .pure-modal-backdrop {
    top: 15% !important;
    left: 0 !important;
  }

  .dasboard_cancel .reschedule_info.requested {
    text-align: left;
  }

  .dasboard_cancel .reschedule_info.requested .location_ali {
    text-align: left;
  }

  .dasboard_cancel .reschedule_info.requested p {
    text-align: left;
  }

  .dasboard_cancel .reschedule_info.requested p a:hover {
    color: #00eaff;
  }

  .user_info_table tbody tr td.icon_sec {
    text-align: center;
  }
/* 
  .dashboard_header .haeder_rigth .user_login {
    justify-content: space-between;
  } */

  .dashboard_header .haeder_rigth .user_login span {
    font-size: 14px;
  }

  .user_info_table tbody tr td span:nth-child(1) {
    display: block;
  }

  .user_info_table tbody tr td span.info_tooltip {
    font-size: 16px;
    color: #00eaff;
    width: 50%;
    text-align: left;
  }

  .recent_acti .order_co {
    color: #fff;
  }
}

@media only screen and (max-width: 500px) {
  /* css for testride i popup modal (mobile css) start*/

  .my_book_modal .pure-modal-backdrop .pure-modal {
    height: 100% !important;
  }

  .new_activity_icon#service_cancel .pure-modal-backdrop .pure-modal {
    height: 100% !important;
    border: none !important;
  }
  .modal_popup.test_ride_popup
    .my_book_modal.book_test
    .pure-modal-backdrop
    .panel.panel-default
    .close {
    position: inherit !important;
  }

  /* css for testride i popup modal (mobile css) end*/
  .dasboard_cancel.new_activity_icon .pure-modal-backdrop {
    /* left: 0 !important;
        width: 95% !important;
        margin: auto auto !important; */
    border: none !important;
    height: 100% !important;
    background: #000 !important;
    width: 100% !important;
    left: 0px !important;
    right: 0px !important;
  }

  .my_book_modal.book_test .panel.panel-default .close {
    position: inherit !important;
    width: auto !important;
    height: auto !important;
    max-width: 200px !important;
    margin: 0px auto;
    background: #00eaff !important;
    backdrop-filter: blur(15px) !important;
    border-radius: 16px;
    width: 100% !important;
    margin-top: 10px !important;
  }

  .my_book_modal.book_test .panel.panel-default .close .close_btn {
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    margin: 0px;
  }

  div#book-test-ride .select_dealer .map_left {
    height: 45vh !important;
  }

  #service_cancel .requested {
    padding: 0px !important;
  }

  .my_book_modal .booktest_direction > div,
  .my_book_modal .booktest_direction > p {
    width: calc(50% - 7.5px);
  }

  .my_book_modal .booktest_direction p span img,
  .my_book_modal .booktest_direction div img {
    margin-right: 10px !important;
  }

  .modal_popup.test_ride_popup .pure-modal-backdrop .popup_cont h2 {
    margin-top: 15px;
  }

  .modal_popup.test_ride_popup .dissmiss {
    display: block;
  }

  .modal_popup.test_ride_popup .close_btn::after,
  .modal_popup.test_ride_popup .close_btn::before {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .service_ifo p span {
    display: block;
  }
  .infor_tool_main:hover .info_tooltip {
    display: none !important;
  }
}
