.hop_vhiecles_sec {
  padding-top: 30px;
  max-width: 90%;
  margin: 0px auto;
}

.hop_vhiecles_sec .vhiecleAlldataList {
  display: flex;
  padding: 50px 0px;
}
.hop_vhiecles_sec .vhiecleAlldataList .Allblogs {
  width: 70%;
}
.hop_vhiecles_sec .vhiecleAlldataList .Allblogs h3 {
  color: #000000;
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 17px;
  padding-left: 20px;
}

.hop_vhiecles_sec .vhiecleAlldataList .diff_cont_left {
  display: flex;
}

.hop_vhiecles_sec .popular_vhiecls {
  width: 30%;
}
.hop_vhiecles_sec .blog_content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 50%;
}
.hop_vhiecles_sec .overRecommended_sec .main_dection_recom a {
  overflow: hidden;
  border-radius: 15px;
  display: block;
}
.vhiecleAlldataList .slider_parent .image_container {
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  height: 210px;
}
.vhiecleAlldataList .slider_parent {
  transition: all 0.3s ease-in-out;
}
.vhiecleAlldataList .slider_parent:hover img {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 1200px) {
  .hop_vhiecles_sec {
    max-width: 95%;
  }
}

@media screen and (max-width: 880px) {
  .hop_vhiecles_sec {
    max-width: 95%;
  }
  .hop_vhiecles_sec .vhiecleAlldataList {
    flex-direction: column;
  }
  .hop_vhiecles_sec .vhiecleAlldataList .Allblogs {
    width: 100%;
  }
  .hop_vhiecles_sec .popular_vhiecls {
    width: 100%;
    max-width: 60%;
    margin: 0px auto;
    margin-top: 50px;
  }
}
@media screen and (max-width: 768px) {
  .hop_vhiecles_sec .vhiecleAlldataList .diff_cont_left {
    flex-direction: column;
    gap: 15px;
  }
  .hop_vhiecles_sec .blog_content {
    width: 100%;
  }

  .hop_vhiecles_sec .popular_vhiecls {
    width: 100%;
    max-width: 100%;
  }
  .hop_vhiecles_sec .overRecommended_sec img {
    width: 100%;
  }
}
