.overview_slider .image_container {
  margin: 0px 10px;
  position: relative;
}
.overview_slider {
  margin: 20px 0px 50px 0px;
  overflow: hidden;
  width: 100%;
}

