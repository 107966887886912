.techbox_page {
  display: flex;
  max-width: 90%;
  margin: 0px auto;
  padding: 40px 0px;
}
.search_tech_head {
  width: 66.66%;
}
.techbox_page .box_container {
  display: flex;
  column-gap: 15px;
  flex-wrap: wrap;
}
.techbox_page .popular_content {
  width: 33.33%;
}
.techbox_page .box_container .overRecommended_sec {
  width: calc(50% - 15px);
}
.techbox_page .box_container .overRecommended_sec:hover img {
  transform: scale(1);
}
.techbox_page .community_header {
  margin-top: 0px;
}
.techbox_page .community_Subheader.one{
    margin-bottom: 15px;
}

.left_vhiecle_h{
  color: #000000;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 17px;
    margin-left: 10%;
}

@media screen and (max-width:768px) {
  .techbox_page{
    flex-direction: column;
    max-width: 95%;
  }
  .search_tech_head{
    width: 100%;
  }
  .techbox_page .popular_content{
    width: 100%;
  }
  .techbox_page .box_container{
    justify-content: space-between;
    gap: 0px;
  }
  .techbox_page .box_container .overRecommended_sec{
    width: calc(50% - 10px);
  }
 
}

@media screen and (max-width:550px) {
  .techbox_page .box_container .overRecommended_sec{
    width: 100%;
  }
}