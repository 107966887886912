/* section 2 css */

.sec_content{
    background-color: #0a0a0a;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.sec_content .container_about_us{
    max-width: 1440px;
    padding: 76px 140px 76px 140px;
    margin: 0px auto;
}

.sec_content .container_about_us .content_box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.sec_content .container_about_us .content_box h2 {
    color: #FFF;
    font-size: 54px;
    font-family: Lato, sans-serif;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 5.4px;
    text-transform: uppercase;
    margin-bottom: 50px;
}

.sec_content .container_about_us .content_box .sm-description{
    color: rgba(255, 255, 255, 0.60);
    text-align: left;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 24px;
    font-family: Lato, sans-serif;
    font-weight: 500;
    line-height: 150%;
    text-transform: inherit;
    width: 100%;
    max-width: 1125px;
    margin-bottom: 15px;
}

.sec_content .container_about_us .content_box .sm-description.mb-8{
    margin-bottom: 8px;
    font-size: 24px;
}


.sec_content .container_about_us .content_box .sm-description.mt-8 {
    margin-top: 8px;
}

.sec_content .container_about_us .content_box .sm-description .bold-text-heaing {
    font-weight: 700;
    font-size: 24px;
}

/* media query */

@media screen and (max-width:1200px) {
    .sec_content .container_about_us .content_box .sm-description,
    .sec_content .container_about_us .content_box .sm-description.mb-8{
        font-size: 22px;
    }
    .sec_content .container_about_us .content_box .sm-description .bold-text-heaing{
        font-size: 23px;
    }
}



@media only screen and (max-width: 767px){

    .sec_content .container_about_us {
        padding: 50px 16px;
        width: 100%;
    }
    
    .sec_content .container_about_us .content_box h2 {
        margin-bottom: 15px;
        font-size: 24px;
        line-height: 26px;
    }
    
    .sec_content .container_about_us .content_box .sm-description {
        font-size: 18px;
        width: 100%;
    }

    .sec_content .container_about_us .content_box .sm-description.mb-8 {
        font-size: 18px;
    }

    .sec_content .container_about_us .content_box .sm-description .bold-text-heaing {
        font-size: 20px;
    }
    .sec_content .container_about_us .content_box .sm-description{
        margin:0px 0px 10px 0px ;
    }
}
