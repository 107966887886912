#about_us {
    background: #0a0a0a;
    width: 100%;
}

/* .sec_1_content .container_about_us{
    padding: 76px 0px 0px 0px !important;
} */

.container_about_us{
    max-width: 1280px !important;
    padding: 76px 0px !important;
    margin: 0px auto !important;
}


/* media css for all about us page sections due to code issue by doveloper there is all different css for same thing  */
/* vikash css start  */

@media screen and (max-width:1400px) {
    .container_about_us{
        padding: 66px 30px !important;
        position: relative;
    }
}
@media screen and (max-width:1200px) {
    #about_us .container_about_us h2{
        font-size: 44px;
        margin-bottom: 50px;
    }
}
@media screen and (max-width:991px) {
    #about_us .container_about_us h2{
        font-size: 38px;

    }
}




@media only screen and (max-width: 767px){
    .container_about_us {
        padding: 30px 16px !important;
    }
    #about_us .container_about_us h2{
        font-size: 28px;
        margin-bottom: 20px;
    }
}