
#community_page_container{
  padding-top: 120px;
}
#community_page_container .header{
  width: 100%;
}
#community_page_container .container_home {
  max-width: 1280px;
  margin: 0px auto;
}

#community_page_container .Welcome_community {
  padding: 40px 0 0px;
}

.slider_main_community {
  padding: 20px 0px;
}

.music_of_silence {
  padding: 20px 0px;
  margin-top: 30px;
  border-top: 1px solid #ccc;
}

#community_page_container .vieew_all_btn .default_btn {
  padding: 11px 49px;
  font-size: 16px;
}

/* css for subpages which is effect all pages inside  */

.subpages_of_homepage .video_img_card .content_card h6 {
  padding: 10px 0px 0px 0px;
  font-size: 18px;
}

.subpages_of_homepage .community_categaries {
  padding: 40px 0px 20px 0px;
}

@media screen and (max-width: 1440px) {
  #community_page_container .container_home {
    padding: 0px 40px;
  }
}

@media screen and (max-width: 991px) {
  .slider_main_community {
    max-width: 80%;
    margin: 0px auto;
  }
  .slider_main_community .video_img_card .content_card h6 {
    padding: 10px 0px 10px 0px;
    font-size: 22px;
  }
  #community_page_container .vieew_all_btn .default_btn {
    padding: 9px 29px;
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  #community_page_container .Welcome_community {
    padding: 30px 0 0px;
  }
  .slider_main_community .video_img_card .content_card h6{
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size: 17px;
  }
  .slider_main_community{
    max-width: 90%;
  }
  #community_page_container .container_home {
    padding: 0px 20px;
  }
  .subpages_of_homepage .video_img_card .content_card h6{
    font-size: 16px;
    margin-bottom: 0px;
  }
  #community_page_container{
    padding-top: 60px;
  }
  
}
