.form_container {
  max-width: 1140px;
  margin: auto;
  padding: 120px 15px 50px;
  height: 100%;
  overflow: scroll;
}


.dealership_new_sec .form_container {
  padding-top: 50px;
}

.form_container::-webkit-scrollbar {
  display: none;
}

h1 {
  color: #fff;
}

.form_cont {
  text-align: center;
  z-index: 1;
  position: relative;
}

.form_cont h1 {
  font-size: 26px;
  line-height: 32px;
  color: #fff;
  margin-bottom: 25px;
}

.form_cont button.default_btn {
  padding: 15px 28px;
  font-size: 16px;
  border-radius: 0px;
  line-height: 20px;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 25px;
}

.form_cont strong {
  display: block;
  text-align: left;
  color: #fff;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 400;
}

.form_cont p {
  text-align: left;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 35px;
}

.form_cont p sub {
  font-size: 28px;
  color: #00eaff;
  padding: 2px 10px;
}

.back_color ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  padding: 0px;
}

.back_color ul li {
  box-sizing: border-box;
  width: 33.33%;
  font-size: 26px;
  color: #fff;
  text-align: center;
  margin: 0;
  position: relative;
  padding: 20px 30px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  line-height: normal;
}

.back_color ul li::before {
  content: " ";
  position: absolute;
  right: 0;
  height: 45px;
  width: 1px;
  background-color: #fff;
}

.back_color ul li:last-child::before {
  display: none;
}

.form_container form h2 {
  color: #00eaff;
  font-size: 26px;
  line-height: 32px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 35px;
  display: block;
  width: 100%;
  text-transform: capitalize;
}

.form_container form h3 {
  color: #fff;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 35px;
  display: block;
  width: 100%;
  text-transform: capitalize;
}

.form_container form {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: flex-start;
  z-index: 1;
  position: relative;
}

.form_container form .form-group {
  width: 50%;
  margin-bottom: 25px;
  padding: 0px 10px;
}

.form_container form .form-group input {
  width: 100%;
  border: 1px solid rgb(135 135 135);
  border-radius: 5px;
  background: transparent;
  padding: 14px 15px;
  color: #fff;
  font-weight: 300;
}

.form_container form .form-group span.error {
  margin-top: 3px;
  display: block;
}

.form_container form .form-group input::placeholder {
  color: #fff;
  font-weight: 300;
}

.form_container form .form-group select {
  width: 100%;
  border: 1px solid rgb(135 135 135);
  border-radius: 5px;
  padding: 15px 15px;
  color: #fff;
  background: #060606;
  font-weight: 300;
}

.form_container form .form-group select {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.form_container form .form-group select:focus-visible {
  outline: none;
}

.form_container form .submit_btn {
  text-align: center;
  width: 100%;
}

.form_container form .submit_btn .default_btn {
  padding: 15px 40px;
  font-size: 16px;
  border-radius: 12px;
  line-height: 20px;
  cursor: pointer;
  display: inline-block;
}

.form_container form .form-check {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  padding: 0px 15px 15px;
  width: 100%;
  margin-bottom: 0px;
}
.form_container form .captcha{
  padding-left: 15px;
  margin-bottom: 15px;
}

.form_container form .form-check input {
  margin-right: 10px;
  height: 15px;
  width: 15px;
}

.form_container form .form-check label {
  color: #fff;
}

.form_container form .form-check label a {
  transition: all 0.3s;
  color: rgb(176, 176, 176);
}

.form_container form .form-check label a:hover {
  color: #00eaff;
}

.design_effa {
  position: relative;
  /* overflow: hidden; */
  background: #060606;
  height: 100vh;
  scroll-snap-align: start;
}

.design_effa::before {
  background: linear-gradient(211deg, rgb(19 32 77), rgb(19 32 77));
  border-radius: 50%;
  content: "";
  filter: blur(30px);
  height: 400px;
  position: absolute;
  right: -150px;
  left: 0;
  top: -150px;
  width: 400px;
  z-index: 0;
  margin-left: auto;
  z-index: 1;
}

.design_effa::after {
  background: linear-gradient(211deg, #2a63a3, #2a63a3);
  border-radius: 0% 80% 0% 0%;
  content: "";
  filter: blur(50px);
  height: 400px;
  position: absolute;
  left: -150px;
  bottom: -150px;
  width: 400px;
  z-index: 0;
}

.form_container form .move_label {
  position: relative;
}

.send_otp p {
  color: #fff;
  position: absolute;
  top: 15px;
  right: 25px;
  /* cursor: pointer; */
  transition: all 0.3s;
  margin: 0px;
}

.send_otp p:hover {
  color: rgb(175, 175, 175);
}

.opt_popup .pure-modal {
  background-color: rgb(0 0 0 / 40%);
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  width: calc(50% - 20px);
  max-width: 300px;
  padding: 20px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin: 0px 15px;
  border: 1px solid #4b4949;
}

.otp_cont p {
  margin-bottom: 10px;
  color: #fff;
}

.opt_popup .scrollable {
  background: transparent;
  padding: 0px;
}

.opt_popup .otp_cont .form-group {
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}

.opt_popup .otp_cont .form-group input {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 5px;
  padding: 8px 15px;
  width: auto;
}

.opt_popup .pure-modal .close {
  display: none;
}

.otp_cont .default_btn {
  padding: 10px 25px;
  font-size: 14px;
  border-radius: 5px;
  text-align: center;
  margin-top: 12px;
  font-weight: 600;
  letter-spacing: 1px;
}

.otp_cont {
  text-align: center;
}

.opt_popup .otp_cont h2 {
  margin-bottom: 12px;
  font-size: 22px;
}

.other_input_div {
  width: 100% !important;
  padding: 0px !important;
  margin-top: 20px;
}
.select_label {
  position: relative;
}
.select_label label,
.form_container form .move_label label {
  color: #fff;
  position: absolute;
  top: 0px;
  left: 26px;
  background: #000;
  padding: 0px 5px;
  line-height: 2px;
}

/* 
.form_container form .move_label label {
    color: #fff;
    padding: 15px 15px;
    position: absolute;
    top: 0;
    left: 15px;
    transition: 0.3s ease all;
    -moz-transition: 0.3s ease all;
    -webkit-transition: 0.3s ease all;
    pointer-events: none;
} */
/* .form_container form .move_label input label,
.form_container form .move_label input:valid+label {
    font-size: 14px;
    color: #fff;
    top: -10px;
    left: 10px;
    background: #060606;
    padding: 0px 5px 0px 5px;
    margin-left: 10px;
    position: absolute;
    color: #fff;
} */

/* vikash new css for banner modification and form 25-09-2023 */

.design_effa.dealership_new_sec {
  height: auto;
}
.design_effa .dealer_banner {
  /* max-height: 450px; */
  overflow: hidden;
  position: relative;
  padding-top: 120px
}
.design_effa .dealer_banner img {
  width: 100%;
  height: 100%;
}
.revolution_section {
  position: absolute;
  bottom: 10px;
  left: 50%;
  /* right: 0; */
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding: 10px 60px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  transform: translateX(-50%);
  border-radius: 50px;
  border: 1px solid #ffff;
  width: max-content;
}
.revolution_section h2 {
  color: #fff;
  font-size: 26px;
  line-height: 32px;
  font-weight: 600;
  text-align: center;
  /* margin-bottom: 35px; */
  display: block;
  /* width: 100%; */
  text-transform: capitalize;
}
.revolution_section button {
  margin-bottom: 0px !important;
  border-radius: 10px !important;
}
.design_effa.dealership_new_sec::before{
    display: none;
}

/* Responsive-start */
@media only screen and (max-width: 1440px) {
  .design_effa::before {
    height: 300px;
    right: -100px;
    top: -100px;
    width: 300px;
  }

  .design_effa::after {
    height: 300px;
    left: -100px;
    bottom: -150px;
    width: 300px;
  }
}

@media only screen and (max-width: 992px) {

    /* vikash css for banner  */
    /* .revolution_section{
        bottom: 20px;
        padding: 20px 50px;
    } */
    .revolution_section h2{
        font-size: 20px;
    }



  .form_cont h1 {
    font-size: 20px;
    line-height: 24px;
  }

  .form_container form h2 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 25px;
  }

  .form_container form .form-group input {
    padding: 10px 15px;
  }

  .form_container form .form-group select {
    padding: 12px 15px;
  }

  .form_container form .form-group {
    width: 100%;
    padding: 0px 0px;
    margin-bottom: 25px;
  }

  .form_cont button.default_btn {
    padding: 12px 28px;
    font-size: 14px;
  }

  .form_container form .submit_btn .default_btn {
    padding: 12px 28px;
    font-size: 14px;
  }

  .form_cont h1 {
    font-size: 24px;
    line-height: 28px;
  }

  .form_container form h2 {
    font-size: 24px;
    line-height: 28px;
  }

  .form_container form .form-group input {
    padding: 10px 15px;
  }

  .form_container form .form-group select {
    padding: 12px 15px;
  }

  .design_effa {
    height: auto;
    min-height: auto;
    scroll-snap-align: inherit;
  }
  .header_hidecss {
    height: auto;
  }

  .back_color ul li {
    font-size: 22px;
    padding: 15px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .design_effa .dealer_banner{
    padding-top: 80px;
  }
  .form_container {
    padding: 90px 15px 30px;
  }

  .form_container form .move_label input:focus + label,
  .form_container form .move_label input:valid + label {
    left: 0;
  }

  .form_container form .move_label label {
    padding: 10px 15px;
    left: 0;
    margin-left: 12px;
    padding-left: 0px;
  }
  .select_label label {
    left: 9px;
  }

  .form_container form .move_label label {
    top: -10px;
  }
  .design_effa::after {
    filter: blur(40px);
    height: 200px;
    left: -80px;
    bottom: -80px;
    width: 200px;
  }

  .design_effa::before {
    filter: blur(30px);
    height: 200px;
    right: -80px;
    top: -80px;
    width: 200px;
  }
  .form_container form .form-check label {
    width: 90%;
    line-height: normal;
  }

  .back_color ul li {
    width: 100%;
    font-size: 20px;
    padding: 15px 15px;
  }

  .back_color ul li::before {
    bottom: 0;
    height: 1px;
    width: 25%;
    margin: auto !important;
    left: 0;
    right: 0;
  }

  .back_color ul li:last-child::before {
    display: block;
  }
  .design_effa .dealer_banner img{
    /* min-height: 450px;
    object-fit: cover; */
    min-height: 220px;
    object-fit: contain;
  }
  .revolution_section{
    /* flex-direction: column; */
    gap: 10px;
    width: 99%;
    bottom: 10px;
    padding: 10px 0px;
  }
  .revolution_section h2{
    font-size: 16px;
  }
  .revolution_section button.default_btn{
    padding: 7px 18px;
    font-size: 13px;
  }
}
