.form_sec_enq .enq_form_content h1 {
  font-size: 50px;
  padding-bottom: 20px;
  text-align: center;
  color: #2a2a2a;
  font-weight: 500;
}
.form_sec_enq .enq_form_content h3 {
  font-size: 26px;
  color: #2a2a2a;
  letter-spacing: 1px;
  padding-bottom: 20px;
}
.form_sec_enq form .form_field {
  margin-bottom: 10px;
  width: 100%;
}
.form_sec_enq form .captcha{
  margin-bottom: 15px;
}
.form_sec_enq form .form_field .input {
  -webkit-transition: all 0.25s ease-in 0s;
  outline: 0;
  text-decoration: none;
  border: 0;
  height: 52px;
  background-color: #f2f2f2;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  padding: 0px 15px;
}

.form_sec_enq form .form_field button {
  padding: 13px 29px;
  display: inline-block;
  border-radius: 4px;
  background-color: #0098da;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  transition: 0.5s;
  border: 1px solid #0098da;
  width: 100%;
}
.form_sec_enq form .form_field p {
  font-size: 18px;
  line-height: 26px;
  color: #0d0d0d;
  margin: 0;
}
.form_sec_enq form .form_field p a {
  color: #007bff;
  text-decoration: underline;
}

.otp_field {
  position: relative;
}
.otp_field .OTP_BTN {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #0098da;
  border: none;
  border-radius: 5px !important;
  padding: 5px 10px !important;
  max-width: fit-content;
  font-weight: 500;
}

.enquiry_modal .otp_cont h2 {
  color: #007bff;
  font-size: 18px;
  margin-bottom: 5px;
}
.enquiry_modal .otp_resend_div button {
  font-weight: 500;
  margin-left: 15px;
  color: #007bff;
  padding: 0px 8px;
  border: 1px solid;
  border-radius: 5px;
}
.enquiry_modal .default_btn {
  background-color: #007bff;
  color: #fff;
}
.enquiry_modal .pure-modal .close {
  border: 1px solid #fff !important;
  background-color: rgba(0, 234, 255, 0.1) !important;
}
.enquiry_modal .close .close_btn::before,
.enquiry_modal .close .close_btn::after {
  background-color: #fff !important;
}
.enquiry_modal.otp_cont .form-group{
  margin-top: 25px !important;
}

.enquiry_modal .close{
  display: none !important;
}
.otp_field .bg_remove {
  background-color: transparent !important;
  right: 0px;
}



@media screen and (max-width: 1500px) {
  .form_sec_enq form .form_field button {
    padding: 10px 29px;
  }
  .form_sec_enq form .form_field .input {
    height: 40px;
  }
  .form_sec_enq .enq_form_content h1 {
    padding-bottom: 10px;
    font-size: 40px;
  }
  .form_sec_enq form .form_field p {
    letter-spacing: -1px;
  }
  .form_sec_enq form .form_field{
    margin-bottom: 14px;
  }
}

@media screen and (max-width: 1325px) {
  .form_sec_enq .selectale_form {
    display: flex;
    /* gap: 10px; */
    flex-direction: column;
  }
}

@media screen and (max-width: 1200px) {
  /* .slide_enquiry {
    height: 500px;
  } */
  .slide_enquiry img {
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 767px) {
  .slide_enquiry img{
    object-fit: contain !important;
    margin-bottom: 0px !important;
  }
  .enquiry_modal.otp_cont .form-group{
    margin-top: 15px !important;
  }
  
  .enquiry_modal.service_otppop .pure-modal {
    padding: 40px 20px;
    width: auto !important;
  }
  .enquiry_modal .otp_cont .form-group input {
    font-size: 15px;
    padding: 6px 15px;
  }
  .enquiry_modal .default_btn {
    margin-top: 0px;
    padding: 7px 25px;
  }
  .enquiry_modal .otp_cont h2 {
    font-size: 16px;
  }
  .enquiry_modal .otp_cont h2:nth-child(1) {
    margin-bottom: 0px;
  }
  .enquiry_modal .otp_cont p {
    font-size: 12px;
  }
  .form_sec_enq form .form_field p {
    font-size: 15px;
    line-height: 20px;
  }
  .form_sec_enq .enq_form_content h3 {
    font-size: 17px !important;
    padding-bottom: 10px;
  }
  .form_sec_enq .selectale_form {
    flex-direction: column;
    gap: 0px;
  }
  .slide_enquiry {
    height: 300px;
  }
  .form_sec_enq form .form_field button {
    padding: 7px 29px;
  }
  .otp_field .OTP_BTN {
    padding: 2px 5px !important;
    right: 5px;
    font-size: 13px !important;
  }

  .otp_field .bg_remove {
    background-color: transparent !important;
    right: 0px;
  }
}

@media screen and (max-width: 1600px) {
  .form_sec_enq form .form_field button {
    padding: 10px 29px;
  }
  .form_sec_enq form .form_field .input {
    height: 45px;
  }
  .form_sec_enq .enq_form_content h1 {
    padding-bottom: 10px;
    font-size: 40px;
  }
  .form_sec_enq form .form_field p {
    letter-spacing: -1px;
  }
}

@media screen and (max-width: 1325px) {
  .form_sec_enq .selectale_form {
    display: flex;
    /* gap: 10px; */
  }
}

@media screen and (max-width: 1200px) {
  .form_sec_enq .enq_form_content h1
  {
    font-size: 30px;
  }
  /* .slide_enquiry {
    height: 500px;
  } */
  .slide_enquiry img {
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 767px) {
  .form_sec_enq form .form_field p {
    font-size: 15px;
    line-height: 20px;
  }
  .form_sec_enq .enq_form_content h3 {
    font-size: 17px;
    padding-bottom: 10px;
  }
  .form_sec_enq .selectale_form {
    flex-direction: column;
    gap: 0px;
  }
  .slide_enquiry {
    height: auto !important;
  }
}
