.subheader_community {
  padding-top: 170px;
}
.subheader_community .menues_subheader{
  margin-top: 30px;
}
.subheader_community .menues_subheader ul {
  display: flex;
  gap: 15px;
  justify-content: center;
  flex-wrap: wrap;
}

.subheader_community li {
  list-style: none;
}

.subheader_community li a {
  font-size: 15px;
  color: var(--Text_white);
  /* color: #f12a00; */
  text-transform: uppercase;
  line-height: 20px;
  padding: 15px 10px;
  font-weight: 500;
  position: relative;
  display: block;
}

.subheader_community li a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  /* background-color: #f12a00; */
  background-color: #00eaff;
  transition: all 0.3s;
}

.subheader_community a:hover:after {
  width: 100%;
}
.subheader_community .menues_subheader ul li .active {
  background-color:#fff;
  color: var(--Btn_background);
}
.subheader_community .menues_subheader ul li .active:after {
  width: 100%;
}

.Mobile_subheader {
  display: none;
}

@media screen and (max-width: 767px) {
  .Mobile_subheader {
    display: block !important;
  }
  .Desk_subheader {
    display: none;
  }
  .subheader_community.Mobile_subheader ul {
    flex-direction: column;
    gap: 0px;
    background-color: #fff;
  }
  .subheader_community.Mobile_subheader ul li{
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;

  }
  .subheader_community li a {
    width: 100%;
    display: block;
    padding: 12px 10px;
    color: var(--Btn_color);
  }

  .subheader_value_put {
    text-transform: uppercase;
    font-size: 15px;
    color: #000;
    text-transform: uppercase;
    line-height: 20px;
    padding: 12px 10px;
    font-weight: 500;
    position: relative;
    background-color: #fff;
    border: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
  }
  .subheader_community .menues_subheader{
    max-width: 95%;
    margin: 0px auto;
  }
  .subheader_community .menues_subheader ul{
    padding: 0px;
  }
}
