.searchbar{
    max-width: 1440px;
    margin: 0px auto;
    padding: 50px 0px;
}
.searchbar .search_input {
    max-width:700px;
    margin: 0px auto;
    position: relative;
}
.searchbar .search_input input{
    width: 100%;
    border: none;
    border-bottom:1px solid #ccc;
    min-height: 56px;
    font-size: 16px;
    padding-left: 70px;
    background-size: inherit;
    background-color: transparent;
}
.searchbar .search_input input:focus-visible{
    outline: none;
    border: none;
    border-bottom:1px solid #ccc;

}
.searchbar .search_input svg{
    position: absolute;
    font-size: 20px;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #a7a7a7;
}
.searchbar .search_input input::placeholder{
    color: #a7a7a7;
    font-size: 16px;
    font-weight: 500;
}

@media screen and (max-width:767px) {
    .searchbar{
        max-width: 90%;
        padding: 30px 0px;
    }
}