
/* for light theme */

:root{
    --Theme_background:#f4f4f4;
    --Btn_background:#00eaff;
    --Btn_color:#000;
    --Bredcrumb_menu_color:#1e87f0;
    --Text_white:#000;
    --Grey_border:#000;
    --Grey_Svg:#a7a7a7;
    --cards_text:#626262;
}

/* for dark theme */

/* :root{
    --Theme_background:#000;
    --Btn_background:#00eaff;
    --Btn_color:#000;
    --Bredcrumb_menu_color:#1e87f0;
    --Text_white:#fff;
    --Grey_border:#ccc;
    --Grey_Svg:#a7a7a7;
    --cards_text:#626262;
} */