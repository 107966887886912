.community_slider {
  max-width: 800px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.community_slider .sider_tab {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.community_slider .sider_tab li {
  opacity: 1;
  border: 1px solid #222222;
  background-color: #ffffff;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #222222;
  padding: 8px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.community_slider .sider_tab li.active {
  border: 1px solid #00eaff;
}
.prister_slider,
.Mega_slidr {
  display: none;
}

.prister_slider.active,
.Mega_slidr.active {
  display: block;
}

/* css for slider arrow  */
.slider_community {
  position: relative;
}
.slider_community .slick-slider .slick-arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slider_community .slick-slider .slick-arrow.slick-prev {
  top: 44%;
  border: 2px solid #222222;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  left: -60px;
}

.slider_community .slick-slider .slick-arrow.slick-next {
  top: 44%;
  border: 2px solid #222222;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  right: -60px;
}
.slider_community .slick-slider .slick-arrow::before{
  color: #000;
}
.slider_community .slick-slider .slick-dots li button:before{
  font-size: 10px;
}
.slider_community .slick-dots li{
  margin: 0px 2px;
  width: 12px;
}
.slider_community .slick-slider .slick-dots li button{
  width: 10px;
}

.community_slider .video_img_card .image_card_cont{
  height: 440px;
}

@media screen and (max-width:1440px) {
  .video_img_card .image_card_cont,
  .community_slider .video_img_card .image_card_cont{
    height: auto;
  }
}
@media screen and (max-width:767px) {
  .slider_community .slick-slider .slick-arrow.slick-next {
    right: -30px;
  }
  .slider_community .slick-slider .slick-arrow.slick-prev{
    left: -30px;
  }
  .community_slider .sider_tab{
    padding: 0px;
    gap: 10px;
  }
  .community_slider .sider_tab li{
    font-size: 12px;
  }
}