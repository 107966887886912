.community_header {
  font-weight: 700;
  color: var(--Text_white);
  text-transform: capitalize;
  margin-top: 0px;
  line-height: normal;
  padding: 0px;
  font-size: 36px;
  text-align: center;
  margin-top: 15px;
}
.community_Subheader {
  font-size: 18px;
  margin: 0px auto;
  color: var(--Grey_border);
  line-height: inherit;
  margin-bottom: 48px;
  max-width: 810px;
  text-align: center;
  margin-top: 10px;
  font-weight: 500;
}
.community_Subheader.one {
  max-width: 90%;
  margin: 15px auto 0px auto;
}

@media screen and (max-width: 767px) {
  .community_header {
    font-size: 24px;
    font-weight: 700;
  }
  .community_Subheader{
    font-size: 17px;
    max-width: 95%;
    margin: 15px auto;
  }
}
