/* section 6 css */

.sec_content_image_6 {
  background-image: url("../../../assets/images/about_us/hop_store.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.sec_content_image_6 .overlay {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(90deg, rgba(23, 35, 75, 0), rgba(0, 0, 0, 4.5));
  opacity: 5;
  width: 1200px;
}

.sec_content_image_6 .container_about_us {
  max-width: 1440px;
  padding: 76px 140px 76px 140px;
  margin: 0px auto;
}

.sec_content_image_6 .container_about_us h2 {
  color: #fff;
  font-size: 54px;
  font-family: Lato, sans-serif;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: 5.4px;
  text-transform: uppercase;
  margin-bottom: 80px;
  text-align: center;
  position: relative;
  text-shadow: 0.5px 2px #000000;
}

.sec_content_image_6 .net_counters {
  width: 100%;
  position: relative;
}

.sec_content_image_6 .net_counters .count_box .count_items p.count_num {
  font-size: 70px;
  font-weight: 600;
  line-height: 100px;
  text-transform: capitalize;
  background: linear-gradient(rgba(24, 98, 161, 1), rgba(0, 234, 255, 1));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.sec_content_image_6 .net_counters .count_box .count_items p.count_lable {
  font-size: 28px;
  font-family: Lato, sans-serif;
  font-weight: 600;
  line-height: 40px;
  text-transform: capitalize;
  background: linear-gradient(rgba(24, 98, 161, 1), rgba(0, 234, 255, 1));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.sec_content_image_6 .net_counters .count_box .count_items {
  text-align: center !important;
  margin-right: 0px;
  max-width: 500px;
  margin-left: auto;
  margin-bottom: 82px;
}

.sec_content_image_6
  .net_counters
  .count_box
  .count_items
  p.count_lable::selection,
.sec_content_image_6
  .net_counters
  .count_box
  .count_items
  p.count_num::selection {
  -webkit-text-fill-color: #fff;
}

@media screen and (max-width: 991px) {
  .sec_content_image_6 .net_counters .count_box .count_items p.count_num {
    font-size: 50px;
    line-height: 60px;
  }
  .sec_content_image_6 .net_counters .count_box .count_items p.count_lable{
    font-size: 26px;
  }
}

/* media query */

@media only screen and (max-width: 767px) {
  .sec_content_image_6 .net_counters .count_box .count_items {
    text-align: right !important;
  }

  .sec_content_image_6 .overlay {
    /* background: linear-gradient(90deg,rgba(23, 35, 75, 0), rgba(0, 0, 0, 4.5)); */
    background: linear-gradient(172deg, rgb(23 35 75 / 41%), #000000f0);
  }

  .sec_content_image_6 {
    background-position: inherit;
  }

  .sec_content_image_6 .overlay {
    width: 100%;
  }
  .sec_content_image_6 .container_about_us {
    padding: 50px 16px 50px 16px;
  }

  .sec_content_image_6 .container_about_us h2 {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 36px;
  }

  .sec_content_image_6 .net_counters .count_box .count_items {
    margin-bottom: 24px;
  }

  .sec_content_image_6 .net_counters .count_box .count_items p.count_num {
    font-size: 34px;
    line-height: 36px;
    margin-bottom: 8px;
  }

  .sec_content_image_6 .net_counters .count_box .count_items p.count_lable {
    font-size: 18px;
    line-height: 20px;
  }
}
