/* body {
  font-family: Inter, sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  background-color: #171717;
} */
.bike_img_sec {
  background-image: url("../../assets/images/home_img.jpg");
  width: 65%;
  background-position: 59%;
  object-fit: cover;
}
.form_main_sec {
  /* background-image: url("../../assets/images/home_img.jpg"); */
  height: calc(100%);
  scroll-snap-align: start;
  background-repeat: no-repeat;
  /* padding-top: 120px; */
}
.form_main_sec:before {
  background: linear-gradient(180deg, #0a0a0a, hsla(0, 0%, 4%, 0));
  content: "";
  height: 240px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.form_sec_new {
  width: 45%;
  padding: 20px 30px;
  background-color: rgba(17, 17, 17, 01);
  box-sizing: border-box;
  padding-top: 120px;
  height: 100%;
  overflow-y: scroll;
}
.career_one.service_ticket .form_main_sec{
  overflow-y: scroll;
}
.career_one.service_ticket .form_sec_new_inner,
.career_one.service_ticket .form_sec_new{
  overflow-y:initial;
}
.career_one.service_ticket .form_sec_new form{
  padding-bottom: 30px;
}

.form_sec_new_inner {
  background: transparent;
  overflow-y: scroll;
  height: 100%;
  display: flex;
}
.form_sec_new_inner::-webkit-scrollbar {
  /* opacity: 0; */
  display: none;
}

.form_sec_new::-webkit-scrollbar {
  opacity: 0;
}

.form_sec_new .form_head {
  margin-bottom: 30px;
  padding-left: 10px;
}

.form_sec_new .form_head h2 {
  font-size: 42px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 20px;
}

.form_sec_new .form_head p {
  font-size: 26px;
  color: #bbb8b8;
  font-weight: 500;
  margin-bottom: 0px;
}

.form_sec_new form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  z-index: 1;
}

.form_sec_new form .form-group {
  margin-bottom: 20px !important;
  width: calc(50% - 20px);
  margin: 0px 10px;
}

.form_sec_new form .form-group:nth-child(3) {
  width: 100%;
}

.form_sec_new form .form-group:nth-child(6) {
  width: 100%;
}

.form_sec_new form .form-group:nth-child(7) {
  width: 100%;
}

.form_sec_new form .form-group:nth-child(8) {
  width: 100%;
}

/* .form_sec_new form .form-group label.cv_upload{
  font-size: 23px;
} */

.form_sec_new form .form-group label {
  font-size: 22px;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: #ffffff;
  opacity: 0.6;
  margin-bottom: 10px;
  padding: 0px;
  display: block;
}
.form_sec_new form .form-group .form-control::placeholder {
  color: #fff;
}
/* 
.form_sec_new form .form-group .form-control:focus-visible {
  border-radius: 0px;
  outline: none !important;
} */

.form_sec_new form select.form-control {
  padding: 16px 15px !important;
}
.form_sec_new form .form-group .form-control {
  border: 1px solid rgba(255, 255, 255, 0.4);
  background-color: transparent;
  transition: background-color 200ms ease;
  color: #fff;
  font-size: 16px;
  padding: 14px 15px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 500;
}
.form_sec_new form .form-group .form-control:focus-visible {
  outline: inherit !important;
}

.send_btn {
  width: 100%;
  margin: 0px 10px;
}


.send_btn p {
  font-family: Inter, sans-serif;
  color: #737373;
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 500;
}

.send_btn p > a {
  color: #00eaff;
}

.Locations .box_sec {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 50px;
}

.Locations .box_sec .box_cont {
  /* width: calc(70% - 20px); */
  width: 100%;
  margin: 0px 10px;
}

.Locations .box_sec .box_cont figure {
  margin-bottom: 20px;
}

.Locations .box_sec .box_cont figure img {
  width: 100%;
}

.Locations .box_sec .box_cont h3 {
  color: #fff;
  font-size: 20px;
  margin-bottom: 15px;
}

.Locations .box_sec .box_cont p {
  color: #fff;
  font-size: 18px;
  margin-bottom: 15px;
}

.Locations .box_sec .box_cont a {
  color: #fff;
  font-size: 16px;
}

.direction button {
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #00eaff;
  font-family: "Lato", sans-serif;
  color: #000;
  font-size: 16px;
  font-weight: 900;
  border: none;
  cursor: pointer;
}

#ContactUs .send_btn {
  display: flex;
  width: auto;
  flex-direction: column;
  gap: 20px;
}
#ContactUs .send_btn .default_btn {
  width: fit-content;
  margin: 0px auto;
}
@media screen and (max-width: 1440px) {
  .form_sec_new form .form-group label {
    font-size: 16px;
    margin-bottom: 7px;
    line-height: 27px;
  }
}
@media screen and (max-width: 1200px) {
  .form_sec_new {
    width: 65%;
  }
}
@media screen and (max-width: 991px) {
  .bike_img_sec {
    display: none;
  }
  .form_sec_new {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .form_sec_new form .form-group{
    margin-bottom: 20px !important;
  }
  .form_sec_new form .form-group label {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 5px;
  }
  .form_sec_new form .form-group .form-control {
    padding: 7px !important;
    height: auto !important;
  }
  .Locations .box_sec .box_cont h3 {
    margin-left: 0px !important;
  }
  .form_sec_new .form_head {
    margin-bottom: 15px;
  }
  .form_main_sec:before {
    display: none;
  }
  /* .form_main_sec::before {
    height: 300px;
    position: absolute;
    right: -200px;
    top: -130px;
    width: 350px;
    background: linear-gradient(211deg, #14214e, #14214e);
  } */
  .form_main_sec::after {
    height: 250px;
    left: -300px;
    width: 480px;
    filter: blur(60px);
  }
  .form_sec_new {
    width: 100%;
    padding: 10px;
    padding-top: 100px;
  }
  .form_sec_new form .form-group {
    width: 100%;
  }
  .form_sec_new .form_head {
    padding-left: 10px;
  }
  .form_sec_new .form_head h2 {
    font-size: 32px;
  }
  .form_sec_new .form_head p {
    font-size: 18px;
  }
  .send_btn p {
    font-size: 16px;
  }
  .form_sec_new .form_head h2 {
    font-size: 22px;
    margin: 0px;
  }
  .form_sec_new .form_head p {
    font-size: 16px;
  }
  .Locations .box_sec .box_cont h3 {
    font-size: 18px;
    margin: 5px;
  }
  .Locations .box_sec .box_cont p {
    font-size: 15px;
  }
  .Locations .box_sec .box_cont a {
    font-size: 14px;
  }
}
