.product_slider.enquiry_product_page {
  padding: 0 30px;
  max-width: 1540px;
  margin: 0px auto;
}

.product_slider.enquiry_product_page .slick-track {
  display: flex;
  /* gap: 40px; */
}
.product_slider.enquiry_product_page .slick-slide {
  transform: scale(0.75);
  transition: all 0.3s ease-in-out;
}

.product_slider.enquiry_product_page .slick-track .slick-active.slick-current {
  transform: scale(1);
}
.product_slider.enquiry_product_page .slide_enquiry img {
  border: 3px solid #adadad;
  box-shadow: -22px 22px 22px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 25px;
}

.product_slider.enquiry_product_page .slide_enquiry .content_slide h2 {
  font-size: 22px;
  color: #0d0d0d;
  text-transform: uppercase;
  margin-top: 15px;
  font-weight: 700;
}

.product_slider.enquiry_product_page .slick-arrow.slick-prev {
  background: url(../../../assets/images/Enquiry/prev_arrow.jpg) no-repeat;
  left: 0;
  background-position: center center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 3px #848484 solid !important;
  border-radius: 50%;
  width: 53px;
  height: 53px;
  transition: 0.5s;
  z-index: 8;
}
.product_slider.enquiry_product_page .slick-arrow.slick-next {
  background: url(../../../assets/images/Enquiry/next_arrow.jpg) no-repeat;
  background-position: center center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 3px #848484 solid !important;
  border-radius: 50%;
  width: 53px;
  height: 53px;
  transition: 0.5s;
  right: 0;
}
.product_slider.enquiry_product_page .slick-arrow.slick-next::before,
.product_slider.enquiry_product_page .slick-arrow.slick-prev::before {
  display: none;
}

.product_slider.enquiry_product_page h1 {
  font-size: 36px;
  color: #0d0d0d;
  font-weight: 500;
}

.product_slider.enquiry_product_page .slick-list::before {
  content: "";
  background-color: #f2f2f2;
  width: 60px;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 5;
}
.product_slider.enquiry_product_page .slick-list::after {
  content: "";
  background-color: #f2f2f2;
  width: 60px;
  height: 100%;
  position: absolute;
  right: 0;
  z-index: 5;
  top: 0;
}
.product_slider.enquiry_product_page :focus-visible {
  outline: none;
}




@media screen and (max-width: 1200px) {
  .product_slider.enquiry_product_page .slide_enquiry {
    height: auto;
  }

  .product_slider.enquiry_product_page .slide_enquiry .content_slide h2 {
    font-size: 18px;
  }
  .product_slider.enquiry_product_page .slick-list::after,
  .product_slider.enquiry_product_page .slick-list::before {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .product_slider.enquiry_product_page {
    padding: 0px;
  }
  .product_slider.enquiry_product_page .slick-arrow.slick-prev,
  .product_slider.enquiry_product_page .slick-arrow.slick-next {
    display: none !important;
  }
  .product_slider.enquiry_product_page .slick-slide {
    transform: scale(1);
  }
  .product_slider.enquiry_product_page .slide_enquiry .content_slide h2 {
    font-size: 14px;
    font-weight: 500;
    padding-left: 10px;
    margin-top: 5px;
    padding-bottom: 15px;
  }
  .product_slider.enquiry_product_page .slick-track {
    gap: 10px;
  }
  .product_slider.enquiry_product_page h1 {
    font-size: 30px;
    text-align: center;
  }
  .product_slider.enquiry_product_page {
    background: #f2f2f2;
    padding: 10px 0px !important;
    padding-bottom: 0px !important;
  }
  .product_slider.enquiry_product_page .slick-slider {
    margin-bottom: 10px;
  }
  .product_slider.enquiry_product_page .slick-track{
    padding-left: 20%;
  }
  /* .product_slider.enquiry_product_page .slick-slide .slide_enquiry{
    padding-left: 25px;
  } */

  /* .fix_social_sec{
    position: fixed;
  } */
}
