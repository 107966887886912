#Enquiryy {
  padding-top: 120px;
  position: relative;
}
#Enquiryy ~ .fix_social_sec {
  position: fixed;
}
#Enquiryy::before {
  background: linear-gradient(180deg, #000000, hsl(0deg 0% 4% / 0%));
  content: "";
  height: 120px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
#Enquiryy .header {
  width: 100%;
  background: transparent;
}

#Enquiryy .slider_with_form {
  padding-bottom: 30px;
}
#Enquiryy .slider_with_form .home_sec {
  position: relative;
  overflow: hidden;
  display: flex;
}
#Enquiryy .slider_with_form .home_sec .product_slide {
  width: 70%;
  border-radius: 0px 0px 70px 0px;
  overflow: hidden;
  height: fit-content;
}
#Enquiryy .enq_form {
  padding: 30px;
  /* position: absolute; */
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: #fff;
  max-width: 550px;
  width: 100%;
}

#Enquiryy .enq_form::before {
  content: "";
  background-image: url("../../assets/images/Enquiry/corner_of_form.jpg");
  position: absolute;
  left: -50px;
  bottom: 4px;
  width: 50px;
  height: 50px;
}
.call_now_sec {
  display: none;
}

.product_slide_paresnbt{
  background-color: #f2f2f2;
  padding: 25px 0px;

}

@media screen and (max-width: 1500px) {
  #Enquiryy .enq_form {
    padding: 30px;
  }
}

@media screen and (max-width: 1200px) {
  #Enquiryy .enq_form {
    padding: 20px;
  }
}

@media screen and (max-width: 1024px) {
  #Enquiryy .enq_form {
    padding: 20px;
  }
  #Enquiryy .enq_form {
    max-width: 400px;
  }
  .form_sec_enq .enq_form_content h1 {
    font-size: 35px;
  }
  .form_sec_enq .enq_form_content h3 {
    font-size: 22px;
    letter-spacing: -1px;
  }
}

@media screen and (max-width: 767px) {
  .product_slide_paresnbt{
    padding: 20px 0;
    padding-bottom: 5px;
  }
  #Enquiryy .slider_with_form .home_sec .product_slide{
    width: 100%;
    border-radius: 0px;
  }
  #Enquiryy {
    padding-top: 60px;
  }
  #Enquiryy .slider_with_form .home_sec {
    display: flex;
    flex-direction: column;
  }
  #Enquiryy .enq_form {
    position: inherit;
    max-width: 100%;
  }
  .form_sec_enq .enq_form_content h1 {
    font-size: 30px;
    padding-bottom: 10px;
  }
  #Enquiryy .slider_with_form {
    padding-bottom: 5px;
  }

  .call_now_sec.scrolled {
    bottom: 0px;
    opacity: 1;
  }
  .call_now_sec {
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 99;
    transition: all 0.6s;
    bottom: 75px;
    opacity: 0;
    display: block;
  }
  .call_now_sec ul {
    display: flex;
  }
  .call_now_sec ul li {
    width: 50%;
  }
  .call_now_sec ul li {
    width: 100%;
    background-color: #ed3237;
    padding: 0 10px;
    line-height: 70px;
    text-align: center;
    color: #fff;
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 500;
  }
  .call_now_sec ul li:nth-child(2) {
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
  #Enquiryy::before {
    height: 80px;
  }
  #Enquiryy .header {
    padding: 5px 0px;
  }
}
