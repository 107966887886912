.search_andmenubar {
  max-width: 70%;
  width: 100%;
  border-radius: 15px;
  background-color: #fff;
  border: solid 1px rgba(217, 217, 217, 0.44);
  box-shadow: 2.6px 9.7px 20px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  margin: 0px auto;
}
.search_andmenubar .search_bar {
  width: 50%;
}
.search_andmenubar .search_bar input {
  width: 100%;
  min-height: 64px;
  padding-left: 56px;
  border-right: 1px solid #e2e2e2;
  border-radius: 15px 0px 0px 15px;
}
.search_andmenubar .search_bar input:focus {
  outline: none;
}
.search_andmenubar .menu_fields {
  width: 50%;
  position: relative;
}
.search_andmenubar .menu_fields ul {
  display: flex;
  justify-content: space-around;
  height: auto;
  margin: 0px;
  padding: 0px;
}
.search_andmenubar .menu_fields ul li {
  position: relative;
}
.search_andmenubar .menu_fields ul li a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background-color: #f12a00;
  transition: all 0.3s;
}
.search_andmenubar .menu_fields ul li:hover a::after {
  width: 100%;
}

.search_andmenubar .menu_fields ul li a {
  padding: 22px 15px;
  white-space: nowrap;
  display: block;
  font-size: 15px;
  color: #000000;
  line-height: 20px;
  font-weight: 600;
}

.search_andmenubar .search_bar .input_sec {
  position: relative;
}
.search_andmenubar .search_bar .input_sec > span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  font-size: 18px;
  color: #959595;
}

.search_andmenubar .menu_fields .icon_sec {
  margin-left: 5px;
}

/* for images on hover  */
.onhover_section {
  position: absolute;
  background: #fff;
  border-radius: 0px 0px 10px;
  box-shadow: 0px 15px 27px 0 rgba(97, 97, 97, 0.57) !important;
  width: auto;
  z-index: 99;
  /* width: 100%;
  overflow-x: scroll;
  padding-left: 50px; */
}
.onhover_section .hover_leo ul {
  flex-wrap: wrap;
  justify-content: left;
}
.onhover_section .hover_leo ul li figure figcaption {
  position: static;
  font-size: 14px;
  color: #000;
  font-weight: 400;
  text-align: center;
  text-transform: inherit;
  line-height: normal;
}
.onhover_section ul li {
  margin: 0 16px;
}
.onhover_section ul figure img {
  padding: 0 10px;
  max-width: 100px;
  margin: 0 auto;
  height: 100px;
  object-fit: contain;
}

@media screen and (max-width: 1200px) {
  .search_andmenubar {
    max-width: 90%;
  }
}
@media screen and (max-width: 1024px) {
  .search_andmenubar {
    max-width: 95%;
  }
}
@media screen and (max-width: 991px) {
  .search_andmenubar {
    flex-direction: column;
    gap: 10px;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  .search_andmenubar .search_bar,
  .search_andmenubar .menu_fields{
    width: 100%;
  }
  .search_andmenubar .menu_fields ul{
    justify-content: inherit;
    gap: 20px;
  }
  .hop_vhiecles_sec .onhover_section .hover_leo{
    padding-bottom: 20px;
  }
  .search_andmenubar .search_bar input{
    border-right: none;
    border-radius: 15px;
    box-shadow:   2.6px 9.7px 20px 0 rgba(0, 0, 0, 0.05);
  }

}
@media screen and (max-width: 767px) {
  .search_andmenubar {
    max-width: 100%;
  }
}
