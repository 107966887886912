.common_image_inner{
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
}
.common_image_inner .home_mini_parent{
    width: calc(33.33% - 30px);
}
.common_image_inner .content_sec_community_home{
    padding: 10px 0px;
    padding-bottom: 0px;
}
.common_image_inner .home_mini_parent .contet_of_card{
    padding-bottom: 0px;
}



@media screen and (max-width:767px) {
    .common_image_inner{
        flex-direction: column;
    }
    .common_image_inner .home_mini_parent{
        width: 100%;
    }
}