/* section 3 css */

.sec_content_image {
    background-image: url("../../../assets/images/about_us/sec_3_img.jpg");
    background-position: inherit;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.sec_content_image .container_about_us{
    max-width: 1440px;
    padding: 76px 140px 76px 140px;
    margin: 0px auto;
}

/* box */
.box-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-wrapper .box-content-bottom {
    margin: auto;
    border-radius: 25px;
    background: rgba(0, 0, 0, 0.75);
    width: 100%;
    max-width: 800px;
}

.sec_content_image .container_about_us {
    padding: 76px 0px 76px 0px;
}

.box-wrapper .box-content-bottom .box-header{
    padding: 20px 0px;
    border-radius: 25px 25px 0px 0px;
    background: var(--for-bike, linear-gradient(180deg, rgba(0, 234, 255, 0.50) 0%, #00EAFF 100%));
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 34px;
}

.box-wrapper .box-content-bottom p {
    color: #FFF;
    text-align: center;
    font-size: 34px;
    font-family: Lato, sans-serif;
    font-weight: 600;
    line-height: 58.5px;
    /* padding: 0 40px; */
    margin-bottom: 24px;
}

.box-wrapper .box-content-bottom .polygon {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.box-wrapper .box-content-bottom p.sm-description {
    color: #FFF;
    text-align: center;
    font-size: 30px;
    font-family: Lato, sans-serif;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 0.9px;
    margin-bottom: 10px;
}

.box-wrapper .box-content-bottom p.benifit_mili {
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    font-family: Lato, sans-serif;
    line-height: 34px;
    letter-spacing: 0.66px;
    margin-bottom: 24px;
}

.box-wrapper .box-content-bottom .poly_count {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* margin-bottom: 15px; */
}

.box-wrapper .box-content-bottom .poly_count p.text-white {
    font-size: 24px;
    font-family: Lato, sans-serif;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 1.44px;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 16px;
}

.box-wrapper .box-content-bottom .source {
    display: block;
    margin: 0px auto;
    color: #FFF;
    font-size: 16px;
    font-family: Lato, sans-serif;
    line-height: 42px;
    text-align: center;
    margin-bottom: 46px;
    letter-spacing: 0.48px;

}


/* media query */

@media only screen and (max-width: 991px){
    .box-wrapper .box-content-bottom {
        max-width: 90%;
    }
    .box-wrapper .box-content-bottom p,
    .box-wrapper .box-content-bottom p.sm-description{
        font-size: 28px;
    }
    .box-wrapper .box-content-bottom p.benifit_mili{
        font-size: 20px;
    }
    .box-wrapper .box-content-bottom .poly_count p.text-white{
        font-size: 22px;
    }
}


@media only screen and (max-width: 767px){
    .sec_content_image {
        background-image: url("../../../assets/images/about_us/sec_3_img_mobile.jpg");
        background-position: right;
    }

    .box-wrapper .box-content-bottom .box-header{
        padding: 15px 0;
        margin-bottom: 10px;
    }


    .sec_content_image .container_about_us {
        padding: 76px 16px 76px 16px;
    }
    
    .box-wrapper .box-content-bottom p {
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 8px 0;
    }

    .box-wrapper .box-content-bottom p.sm-description, 
    .box-wrapper .box-content-bottom p.benifit_mili{
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 8px;
    }

    .box-wrapper .box-content-bottom .poly_count p.text-white {
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 8px;
    }

    .box-wrapper .box-content-bottom .polygon{
        margin-bottom: 8px;
    }

    .box-wrapper .box-content-bottom .polygon img {
        width: 100%;
        max-width: 100px;
        height: 80px;
    }

    .box-wrapper .box-content-bottom .poly_count{
        margin-bottom: 0;
    }

    .box-wrapper .box-content-bottom .source {
        font-size: 12px;
        margin: 0px 15px 20px 15px;
        line-height: 18px;
        letter-spacing: 0.8px;
    }

    .box-wrapper .box-content-bottom {
        opacity: 0.88;
    }
}