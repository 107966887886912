.footer_link li a,
.footer_link li .footer_link_a {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgb(255 255 255 / 60%);
  position: relative;
  letter-spacing: 1px;
  cursor: pointer;
}

.footer_link li a::after,
.footer_link li .footer_link_a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 2px;
  width: 0%;
  background: rgb(38, 3, 251);
  background: linear-gradient(
    90deg,
    rgba(38, 3, 251, 1) 0%,
    rgba(220, 6, 255, 1) 100%
  );
  border-radius: 2px;
  transition: all 0.3s ease-in;
}

.footer_link li a:hover::after,
.footer_link li .footer_link_a:hover::after {
  width: 100%;
}
.social_icon_sectio {
  display: flex;
  justify-content: center;
}

#footer.landing_footer::before{
  z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer_link li a,
  .footer_link li .footer_link_a {
    font-size: 15px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .footer_link li a,
  .footer_link li .footer_link_a {
    font-size: 14px;
  }
}

@media only screen and (max-height: 900px)  and (min-width: 991px){
  #footer .faq_sec#faq{
    padding-top: 50px !important;
  }
}


@media only screen and (max-height: 800px)  and (min-width: 991px) {
  #footer .faq_sec#faq{
    padding-top: 20px !important;
  }
  .faq_sec .landing_page_container{
    padding: 40px 20px !important;
  }
}
@media only screen and (max-height: 750px)  and (min-width: 991px) {
  #footer .faq_sec#faq{
    padding-top: 0px !important;
  }
  .faq_sec .landing_page_container{
    padding: 20px 20px !important;
  }
}

