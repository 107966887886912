.contact-header-component {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.contact-header-form-wrapper {
  width: 50%;
  padding: 8rem 1.5rem 3rem;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #111;
}

.header-form-title-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  padding-right: 2rem;
  padding-left: 2rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-row-gap: 1rem;
}

.heading-style-h2 {
  font-size: 3rem;
  line-height: 1.2;
}

.text-color-white {
  color: #fafafa;
}

.header-form-title-wrapper > h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Inter, sans-serif;
  font-size: 76px;
  line-height: 1.2;
  font-weight: 600;
  letter-spacing: -1px;
}

.contact-form-block {
  margin-top: 2.5rem;
  margin-bottom: 0px;
}

.w-form {
  margin: 0 0 15px;
}

.contact-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 2rem;
  padding-left: 2rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-row-gap: 1rem;
}

.w-form-done {
  display: none;
  padding: 20px;
  text-align: center;
  background-color: #dddddd;
}

.w-form-fail {
  display: none;
  margin-top: 10px;
  padding: 10px;
  background-color: #ffdede;
}

.form-field-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 1rem;
}

.cta-button {
  padding: 0.625rem 1rem;
  border-radius: 8px;
  background-color: #d4d4d4;
  -webkit-transition: background-color 275ms ease;
  transition: background-color 275ms ease;
  font-family: Inter, sans-serif;
  color: #404040;
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 600;
  text-decoration: none;
}

.w-button {
  display: inline-block;
  padding: 9px 15px;
  background-color: #3898ec;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
}

.text-size-regular {
  font-size: 1rem;
}

.form-field {
  width: 100%;
}

.form-label-field {
  margin-bottom: 6px;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
}

.form-text-field {
  height: 3rem;
  margin-bottom: 0px;
  padding: 0.75rem 1rem;
  border: 1px solid #525252;
  background-color: #171717;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  color: #737373;
  font-size: 16px;
}

.w-input,
.w-select {
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}



@media screen and (max-width: 991px) {
  .contact-header-component {
    margin-top: 5rem;
  }
  .contact-header-form-wrapper {
    width: 100%;
    padding-top: 4rem;
  }

  .header-form-title-wrapper > h1 {
    font-size: 64px;
  }

  .header-form-title-wrapper > p {
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 0;
  }

  .cta-button {
    width: 20rem;
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .header-form-title-wrapper {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .heading-style-h2 {
    font-size: 2rem;
  }
  .text-size-regular {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 479px) {
  .form-field-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-row-gap: 1rem;
  }

  .cta-button {
    width: 100%;
  }

  .text-size-regular {
    font-size: 0.8rem;
  }
}
