.privacy_policy_sec .pri_policy {
    position: inherit;
    transform: none;
    left: 0;
    right: 0;
    overflow: auto;
    top: 0;
    height: 100%;
    padding: 20px 0px 20px;
    z-index: 1;
}

#privacy-policy .payment_secmain .payment_cont .container h2 {
    margin-bottom: 20px;
}

#privacy-policy .payment_secmain .payment_cont .container {
    max-width: 1140px;
    width: 100%;
    margin: 0px auto;
    padding: 0px 15px;
}

#privacy-policy .payment_secmain .payment_cont .content {
    width: 100%;
    height: 100%;
    overflow: hidden scroll;
    padding: 100px 0px 0px;
}

#privacy-policy .payment_secmain .payment_cont .content::-webkit-scrollbar {
    display: none;
}

#privacy-policy .payment_secmain .payment_cont p {
    color: rgb(255, 255, 255, 0.75);
    font-size: 18px;
    margin-bottom: 20px;
}

#privacy-policy .payment_secmain .payment_cont ul li {
    color: rgb(255, 255, 255, 0.75);
    font-size: 16px;
    margin-bottom: 10px;
    list-style-type: disc;
}

#privacy-policy .payment_secmain .payment_cont h5 {
    color: #fff;
    font-size: 20px;
    margin-bottom: 25px;
    font-weight: 600;
}

#privacy-policy .payment_secmain .payment_cont ul {
    padding-left: 20px;
    margin-bottom: 25px;
    list-style-type: disc;
}

/*========================= RESPONSIVE START ========================== */



@media only screen and (max-width:991px) {

    div#privacy-policy {
        z-index: 0;
        height: auto;
    }

    #privacy-policy .payment_secmain .payment_cont .content {
        padding: 60px 0px 0px;
    }
}

@media only screen and (max-width:767px) {


    #privacy-policy .payment_secmain .payment_cont .content {
        padding: 60px 0px 0px;
    }

    #privacy-policy .payment_secmain .payment_cont .container h2 {
        margin-bottom: 15px;
    }


    #privacy-policy .payment_secmain .payment_cont p {
        margin-bottom: 20px;
        font-size: 16px;
    }

    #privacy-policy .payment_secmain .payment_cont h5 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    #privacy-policy .payment_secmain .payment_cont ul {
        margin-bottom: 15px;
    }


}
