.slide_enquiry {
  position: relative;
}
.slide_enquiry img {
  width: 100%;
}

.slide_enquiry .content_of_slide {
  position: absolute;
  left: 100px;
  bottom: 40px;
  z-index: 9;
  width: 100%;
  padding-bottom: 20px;
  max-width: 1540px;
  padding: 0 30px;
  margin: 0px auto;
  border-left: 1px solid #fff;
  padding-left: 5px;
}
.slide_enquiry .content_of_slide h2 {
  color: #fff;
  background-color: #0098da;
  display: inline-block;
  text-transform: uppercase;
  font-size: 50px;
  line-height: 56px;
  padding: 0 10px;
  border-radius: 5px;
}
.slide_enquiry .content_of_slide h3 {
  color: #fff;
  font-size: 50px;
}

.product_slide h1{
  font-size: 30px;
  color: #0d0d0d;
}

.product_slide .slick-dots{
  bottom: 20px;
}

.product_slide .slick-dots li button::before{
  font-size: 12px;
  color: #fff;
}
@media screen and (max-width:1400px) {
  .product_slide .banner_image{
    height: 550px;
  }

}

@media screen and (max-width:1200px) {
  .slide_enquiry .content_of_slide{
    left: 20px;
  }
  .slide_enquiry .content_of_slide h3,
  .slide_enquiry .content_of_slide h2{
    font-size: 40px;
  }
}


@media screen and (max-width:767px) {
  .slide_enquiry .content_of_slide h3,
  .slide_enquiry .content_of_slide h2{
    font-size: 24px;
    line-height: 30px;
  }
  .product_slide .slick-dots li button::before{
    font-size: 13px;
    color: #fff;
  }
  .product_slide .slick-dots{
    bottom: 10px;
  }
  .product_slide .slide_enquiry{
    height: 300px !important;
  }
  .product_slide .slide_enquiry img{
    object-fit: cover !important;
    height: 300px !important;

  }

}