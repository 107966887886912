.over_view{
    display: flex;
    gap: 15px;
    width: 90%;
    margin: 15px auto;
    margin-bottom: 50px;
}
.over_view .overRecommended_sec{
    width: calc(33% - 15px);
}
@media screen and (max-width:767px) {
    .over_view{
        flex-direction: column;
        max-width: 95%;
    }
}