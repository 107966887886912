.custom_input_section {
  margin: 40px 0 20px;
  position: relative;
}
.custom_input_section input {
  width: 100%;
  background: none;
  color: #000;
  padding: 10px 10px 10px 0;
  display: block;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #c6c6c6;
  outline: 0;
  font-size: 18px;
  font-weight: 500;
}

.custom_input_section label {
  color: #7f7f7f;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  left: 0;
  top: 12px;
  pointer-events: none;
  transition: 300ms ease all;
}

.custom_input_section input:focus ~ label,
.custom_input_section input:required:valid ~ label,
.custom_input_section textarea:focus ~ label,
.custom_input_section textarea:required:valid ~ label,
.custom_input_section select:focus ~ label,
.custom_input_section select:required:valid ~ label {
  top: -20px;
  font-size: 14px;
  color: #00eaff;
  font-weight: 600;
}
