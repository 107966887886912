.dealers_main {
  background-color: #171717;
  font-family: Inter, sans-serif;
  color: #737373;
  background: #060606;
  position: relative;
  overflow: hidden;
  /* height: 100vh;
  scroll-snap-align: start; */
  /* max-width: 1280px; */
  /* margin: 0px auto; */
}

.dealers_main::before {
  background: linear-gradient(211deg, rgb(19 32 77), rgb(19 32 77));
  border-radius: 50%;
  content: "";
  filter: blur(30px);
  height: 400px;
  position: absolute;
  right: -150px;
  left: 0;
  top: -150px;
  width: 400px;
  z-index: 0;
  margin-left: auto;
  z-index: 1;
}

/* .dealers_main::after {
  background: linear-gradient(211deg, #2a63a3, #2a63a3);
  border-radius: 0% 80% 0% 0%;
  content: "";
  filter: blur(50px);
  height: 400px;
  position: absolute;
  left: -150px;
  bottom: -150px;
  width: 400px;
  z-index: 0;
} */

.dealers_main .headings {
  margin: 0px auto;
  text-align: center;
  max-width: 675px;
}

.dealers_main .dealer_container {
  max-width: 1440px;
  margin: 0px auto;
  height: 100%;
  /* overflow: scroll; */
}

.dealers_main .dealer_container::-webkit-scrollbar {
  opacity: 0;
}

.dealers_main .headings h1 {
  margin-bottom: 1.5rem;
  font-size: 4rem;
  color: #fafafa;
  position: relative;
  z-index: 1;
}

.dealers_main .headings p {
  color: #737373;
  font-size: 24px;
  line-height: 1.5;
}

.dealer_main {
  width: 100%;
  overflow: hidden;
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.dealer_main .dealer_card {
  width: 385px;
  margin-left: 10px;
  margin-right: 10px;
  margin: 20px auto;
  position: relative;
  z-index: 2;
}

.image_card {
  height: 300px;
  border-radius: 10px;
  /* border: 1px solid; */
  position: relative;
}

.image_card::before {
  content: "";
  background-color: rgba(0, 234, 255, 0.1);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

/* .image_card::after {
    content: "DEALERS";
    color: 737373;
    position: absolute;
    font-size: 20px;
    bottom: 30px;
    right: 30px;
} */

.image_card img {
  height: 200px;
  /* max-width: 150px; */
  /* object-fit: contain; */
  /* position: absolute; */
  /* width: 100%; */
  /* left: 20%; */
  /* top: 50%; */
  /* transform: translateY(-50%); */
}

.dealer_main .dealer_card .card_body .heading_card {
  color: #fafafa;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.2;
  margin-top: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dealer_main .dealer_card .card_body .card_tex_body p {
  color: #d4d4d4;
  font-size: 20px;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.dealer_main .dealer_card .card_body .direction button {
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #262626;
  font-family: "Work Sans", sans-serif;
  color: #00eaff;
  font-size: 18px;
  font-weight: 500;
  border: none;
  cursor: pointer;
}

.Search_Location {
  display: flex;
  align-items: center;
  padding: 0px 42px;
  gap: 20px;
}

.input_section {
  width: 50%;
  padding: 30px 10px;
  transition: all 0.3s;
}

.input_section input {
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  border: none;
  border: 2px solid transparent;
  transition: all 0.3s;
}

.input_section input:focus {
  border: 2px solid #293865;
  outline: 0px;
}

.exper_center {
  width: 50%;
  background-color: #293865;
  border-radius: 10px;
  padding: 12px 0px;
  text-align: center;
}

.exper_center h3 {
  color: #fff;
  font-size: 1.2rem;
  margin: 0px;
}


.dealers_main .dealer_container .direction{
  margin-top: auto !important;
}
@media screen and (max-width: 767px) {
  .dealer_main .dealer_card .card_body .direction button {
    padding: 7px 20px;
    font-size: 16px;
  }
  .image_card {
    height: 230px;
  }
  .image_card img {
    height: 120px;
  }
  .dealer_main {
    padding: 0px 10px;
  }
  .height_one_csrol.dealer_parent {
    scroll-snap-type: inherit;
    height: auto;
    overflow-y: inherit;
    overflow-x: inherit;
  }
  .dealers_main {
    padding-top: 90px !important;
  }
  .dealers_main::before {
    height: 300px;
    position: absolute;
    right: -200px;
    top: -130px;
    width: 350px;
    background: linear-gradient(211deg, #14214e, #14214e);
  }

  .dealers_main::after {
    height: 250px;
    left: -300px;
    width: 480px;
    filter: blur(60px);
  }

  .Search_Location {
    padding: 0px 15px;
    display: block;
  }

  .input_section {
    width: 100%;
  }

  .input_section input {
    width: 100%;
    margin: 0px auto;
  }

  .exper_center {
    width: 95%;
    margin: 0px auto;
    font-size: 13px;
  }

  .dealers_main .headings h1 {
    margin-bottom: 1rem;
    font-size: 38px;
  }

  .dealers_main .headings p {
    font-size: 18px;
  }

  .dealer_main {
    margin-top: 50px;
    gap: 30px;
  }

  .dealer_main .dealer_card .card_body .heading_card {
    font-size: 1rem;
    margin-bottom: 0px;
  }

  .dealer_main .dealer_card .card_body .card_tex_body p {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .dealer_main .dealer_card .card_body .direction button {
    /* width: 50%; */
  }
  .dealer_main .dealer_card .card_body .direction {
    padding-right: 10px;
  }

  .image_card::after {
    font-size: 18px;
  }

  /* .direction {
    flex-direction: column;
  }

  .direction > button {
    order: 2;
  }
  .direction > .kms {
    order: 1;
  } */
}
.dealers_main {
  background-color: #171717;
  font-family: Inter, sans-serif;
  color: #737373;
  background: #060606;
  position: relative;
  overflow: hidden;
  /* height: 100vh;
  scroll-snap-align: start; */
  /* max-width: 1280px; */
  /* margin: 0px auto; */
}

.dealers_main::before {
  background: linear-gradient(211deg, rgb(19 32 77), rgb(19 32 77));
  border-radius: 50%;
  content: "";
  filter: blur(30px);
  height: 400px;
  position: absolute;
  right: -150px;
  left: 0;
  top: -150px;
  width: 400px;
  z-index: 0;
  margin-left: auto;
  z-index: 1;
}

/* .dealers_main::after {
  background: linear-gradient(211deg, #2a63a3, #2a63a3);
  border-radius: 0% 80% 0% 0%;
  content: "";
  filter: blur(50px);
  height: 400px;
  position: absolute;
  left: -150px;
  bottom: -150px;
  width: 400px;
  z-index: 0;
} */

.dealers_main .headings {
  margin: 0px auto;
  text-align: center;
  max-width: 675px;
}

.dealers_main .dealer_container {
  max-width: 1440px;
  margin: 0px auto;
  height: 100%;
  /* overflow: scroll; */
}

.dealers_main .dealer_container::-webkit-scrollbar {
  opacity: 0;
}

.dealers_main .headings h1 {
  margin-bottom: 1.5rem;
  font-size: 4rem;
  color: #fafafa;
  position: relative;
  z-index: 1;
}

.dealers_main .headings p {
  color: #737373;
  font-size: 24px;
  line-height: 1.5;
}

.dealer_main {
  width: 100%;
  overflow: hidden;
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.dealer_main .dealer_card {
  width: 385px;
  margin-left: 10px;
  margin-right: 10px;
  margin: 20px auto;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.image_card {
  height: 300px;
  border-radius: 10px;
  /* border: 1px solid; */
  position: relative;
}

.image_card::before {
  content: "";
  background-color: rgba(0, 234, 255, 0.1);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

/* .image_card::after {
    content: "DEALERS";
    color: 737373;
    position: absolute;
    font-size: 20px;
    bottom: 30px;
    right: 30px;
} */

.image_card img {
  height: 200px;
  /* max-width: 150px; */
  /* object-fit: contain; */
  /* position: absolute; */
  /* width: 100%; */
  /* left: 20%; */
  /* top: 50%; */
  /* transform: translateY(-50%); */
}

.dealer_main .dealer_card .card_body .heading_card {
  color: #fafafa;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.2;
  margin-top: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dealer_main .dealer_card .card_body .card_tex_body p {
  color: #d4d4d4;
  font-size: 20px;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 7px;
}

.dealer_main .dealer_card .card_body .direction button {
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #262626;
  font-family: "Work Sans", sans-serif;
  color: #00eaff;
  font-size: 18px;
  font-weight: 500;
  border: none;
  cursor: pointer;
}

.Search_Location {
  display: flex;
  align-items: center;
  padding: 0px 42px;
  gap: 20px;
}

.input_section {
  width: 50%;
  padding: 30px 10px;
  transition: all 0.3s;
}

.input_section input {
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  border: none;
  border: 2px solid transparent;
  transition: all 0.3s;
}

.input_section input:focus {
  border: 2px solid #293865;
  outline: 0px;
}

.exper_center {
  width: 50%;
  background-color: #293865;
  border-radius: 10px;
  padding: 12px 0px;
  text-align: center;
}

.exper_center h3 {
  color: #fff;
  font-size: 1.2rem;
}

@media screen and (max-width: 767px) {
  .dealer_main .dealer_card .card_body .direction button {
    padding: 7px 20px;
    font-size: 16px;
  }
  .image_card {
    height: 230px;
  }
  .image_card img {
    height: 120px;
  }
  .dealer_main {
    padding: 0px 10px;
  }
  .height_one_csrol.dealer_parent {
    scroll-snap-type: inherit;
    height: auto;
    overflow-y: inherit;
    overflow-x: inherit;
  }
  .dealers_main {
    padding-top: 90px !important;
  }
  .dealers_main::before {
    height: 300px;
    position: absolute;
    right: -200px;
    top: -130px;
    width: 350px;
    background: linear-gradient(211deg, #14214e, #14214e);
  }

  .dealers_main::after {
    height: 250px;
    left: -300px;
    width: 480px;
    filter: blur(60px);
  }

  .Search_Location {
    padding: 0px 15px;
    display: block;
  }

  .input_section {
    width: 100%;
  }

  .input_section input {
    width: 100%;
    margin: 0px auto;
  }

  .exper_center {
    width: 95%;
    margin: 0px auto;
    font-size: 13px;
  }

  .dealers_main .headings h1 {
    margin-bottom: 1rem;
    font-size: 38px;
  }

  .dealers_main .headings p {
    font-size: 18px;
  }

  .dealer_main {
    margin-top: 50px;
    gap: 30px;
  }

  .dealer_main .dealer_card .card_body .heading_card {
    font-size: 1rem;
    margin-bottom: 0px;
  }

  .dealer_main .dealer_card .card_body .card_tex_body p {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .dealer_main .dealer_card .card_body .direction button {
    /* width: 50%; */
  }
  .dealer_main .dealer_card .card_body .direction {
    padding-right: 10px;
  }

  .image_card::after {
    font-size: 18px;
  }

  /* .direction {
    flex-direction: column;
  }

  .direction > button {
    order: 2;
  }
  .direction > .kms {
    order: 1;
  } */
}
